import config from '../config';
import { isLocalhost } from './isLocalhost';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export const setupErrorLogging = () => {
  if (config.environment === 'production' && !isLocalhost()) {
    Sentry.init({
      dsn: config.sentryDSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.05,
      environment: config.environment,
    });
  }
};
