import React, { useMemo } from 'react';

import classNames from 'classnames';

import s from './DeliveryStatus.module.scss';

const cx = classNames.bind(s);

const DeliveryStatus = ({ status }) => {
  const formattedStatus = useMemo(() => status.toLowerCase(), [status]);
  const statusClassNames = useMemo(
    () =>
      cx({
        [s['status']]: true,
        [s['status-active']]: formattedStatus.toLowerCase() === 'active',
        [s['status-draft']]: formattedStatus.toLowerCase() === 'draft',
        [s['status-inactive']]: formattedStatus.toLowerCase() === 'inactive',
        [s['status-review']]: formattedStatus.toLowerCase() === 'in_review',
        [s['status-rejected']]: formattedStatus.toLowerCase() === 'rejected',
      }),
    [formattedStatus]
  );
  return (
    <>
      <div className={statusClassNames} />
    </>
  );
};

export default DeliveryStatus;
