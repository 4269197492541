import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  postProcessAgeData,
  postProcessGenderData,
  postProcessLocationData,
  postProcessIncludeTagsData,
  postProcessExcludeTagsData,
  postProcessSchedule,
  postProcessOsData,
  postProcessInterests,
  postProcessPassions,
} from '../../../utils/submitData/submitDataHelpers';

import { container } from '../../../container';

import { brandInfoSelector } from '../../brand';

import {
  activateSponsorshipSetFromCreationAsync,
  deactivateSponsorshipSetFromCreationAsync,
  updateSponsorshipSetAsync,
  fetchAdSetPricingOptionsAsync,
} from './actions';

import { toggleSavingToast } from '../../ui';
import { generatePath } from 'react-router';
import { fragments, routes } from '../../../constants';
import {
  adSetsSelector,
  campaignSelector,
  setAdSetIsDirty,
  setUnpublishedDifferences,
  unpublishedChangesSelector,
} from '../../data';
import { toast } from 'react-toastify';
import UnpublishedChangesToastContent from '../../../components/UnpublishChangesToastContent/UnpublishedChangesToastContent';
import { processDifferences } from '../../../utils/receivedData';
import { t } from '@transifex/native';
import { budgetParser } from '../../../utils/budget';

const sponsorshipSetService = container.get('SponsorshipSetService');
const brandService = container.get('BrandService');

function* updateSponsorshipSetSaga({ payload }) {
  try {
    const adSets = yield select(adSetsSelector);
    const differences = yield select(unpublishedChangesSelector);

    const adSetIndex = adSets.findIndex((adSet) => adSet.id === payload.sponsorshipSetId);

    const { form } = payload;

    let { pixelConversionEvent, isEightPixelEnabled, eightPixelAudiences } = form;

    const { timezoneId } = yield select(brandInfoSelector);

    const sponsorshipSetId = payload.sponsorshipSetId;
    const ageRanges = postProcessAgeData(form.age);
    const genders = postProcessGenderData(form.gender);
    const locations = postProcessLocationData(form.locations);
    const interests = postProcessInterests(form.interests);
    const passions = postProcessPassions(form.passions);
    const includeTags = postProcessIncludeTagsData(form.includeTags);
    const excludeTags = postProcessExcludeTagsData(form.excludeTags);
    const deviceType = postProcessOsData(form.deviceType ?? 'any');
    const { selectedCharity: charity, id: campaignId } = yield select(campaignSelector);
    const { samiPricingId } = yield select(brandInfoSelector);

    const { startDate, endDate, budget: budgetTotal, incentive, frequencyCap } = form;

    const { scheduleFrom, scheduleTo } = postProcessSchedule(startDate, endDate, timezoneId);

    const { charityDonation, netCashReward, weAre8Fee, pricePerMessage } = incentive;

    yield put(toggleSavingToast(true));

    const response = yield call(sponsorshipSetService.updateSponsorshipSet, {
      campaignId,
      sponsorshipSetId,
      name: form.name,
      isEightPixelEnabled,
      pixelConversionEvent,
      ageRanges,
      genders,
      includeLocations: locations,
      interests,
      passions,
      eightPixelAudiences,
      includeTags,
      excludeTags,
      deviceType,
      timezoneId,
      scheduleFrom,
      scheduleTo,
      budgetTotal: budgetParser(budgetTotal),
      timezoneInfo: {
        id: '8d2ed595-43be-4a24-9c57-5eda60f68f40',
        utcString: 'Europe/Athens',
        value: 'GTB Standard Time',
        abbr: 'GDT',
        offset: 3,
        isDst: true,
        text: '(UTC+02:00) Athens, Bucharest',
        textUtcString: '(UTC+03:00) Athens, Europe',
      },
      charityInfo: {
        id: charity.id,
        name: charity.name,
        logoKey: charity.logoUri,
      },
      minVideoLength: form.incentive.videoMinTimeInSeconds,
      maxVideoLength: form.incentive.videoMaxTimeInSeconds,
      charityDonation,
      netCashReward,
      weAre8Fee,
      pricePerMessage,
      samiPricingId,
      frequencyCap: parseInt(frequencyCap),
    });

    if (response.unpublishedDifferences) {
      const processedNewDifferences = processDifferences([response.unpublishedDifferences]);
      const filteredPrevDifferences = differences.filter((diff) => diff.id !== sponsorshipSetId);
      yield put(
        setUnpublishedDifferences([...filteredPrevDifferences, ...processedNewDifferences])
      );
      toast(<UnpublishedChangesToastContent />, {
        containerId: 'unpublished-changes',
        toastId: 'unpublished-toast',
        closeButton: false,
      });
    } else if (differences.length) {
      yield put(
        setUnpublishedDifferences(differences.filter((diff) => diff.id !== sponsorshipSetId))
      );
    }

    yield put(
      setAdSetIsDirty({
        isDirty: false,
        form: payload.form,
        adSetId: sponsorshipSetId,
        dirtyFields: [],
      })
    );

    yield put(
      updateSponsorshipSetAsync.success({ form: { ...form, incentive }, sponsorshipSetId })
    );

    yield put(toggleSavingToast(false));

    yield put(
      push(
        generatePath(routes.SPONSORSHIP_EDIT, {
          cid: campaignId,
          ssid: sponsorshipSetId,
          sid: adSets[adSetIndex]?.ads[0]?.id,
        }) + fragments.AD_NAME
      )
    );
  } catch (error) {
    const sponsorshipSetId = payload.sponsorshipSetId;
    yield put(updateSponsorshipSetAsync.failure(error));
    if (error.response.data.code === 160)
      toast.error(
        t(
          "There was an error with the brand's ratecard. Please check if there have been any changes to it. If there were changes, please create a new campaign."
        ),
        {
          autoClose: false,
          containerId: 'upload-success',
        }
      );
    if (error.response.data.code === 107)
      toast.error(t(`Budget must be greater than the Ad Set's amount spent.`), {
        autoClose: false,
        containerId: 'upload-success',
      });
    if (error.response.data.code === 12)
      toast.error(t(`Invalid include or exclude audience tag.`), {
        autoClose: false,
        containerId: 'upload-success',
      });
    yield put(
      setAdSetIsDirty({
        isDirty: true,
        form: payload.form,
        adSetId: sponsorshipSetId,
        dirtyFields: payload.dirtyFields,
      })
    );
    yield put(toggleSavingToast(false));
  }
}

function* activateSponsorshipSetSaga({ payload }) {
  try {
    const campaign = yield select(campaignSelector);
    const campaignId = campaign.id;
    const sponsorshipSetId = payload;

    yield call(sponsorshipSetService.activateSponsorshipSet, {
      campaignId,
      sponsorshipSetId,
    });
    yield put(activateSponsorshipSetFromCreationAsync.success(sponsorshipSetId));
  } catch (error) {
    yield put(activateSponsorshipSetFromCreationAsync.failure(error));
  }
}

function* deactivateSponsorshipSetSaga({ payload }) {
  try {
    const campaign = yield select(campaignSelector);
    const campaignId = campaign.id;
    const sponsorshipSetId = payload;

    yield call(sponsorshipSetService.deactivateSponsorshipSet, {
      campaignId,
      sponsorshipSetId,
    });
    yield put(deactivateSponsorshipSetFromCreationAsync.success(sponsorshipSetId));
  } catch (error) {
    yield put(deactivateSponsorshipSetFromCreationAsync.failure(error));
  }
}

function* fetchAdSetPricingOptionsSaga({ payload }) {
  try {
    let pricingList = yield call(brandService.getPricing, payload?.samiPricingId);

    pricingList = pricingList.sort((a, b) => a.videoMaxTimeInSeconds - b.videoMaxTimeInSeconds);

    yield put(
      fetchAdSetPricingOptionsAsync.success({
        pricingList,
      })
    );
  } catch (error) {
    yield put(fetchAdSetPricingOptionsAsync.failure(error.message));
    console.log(error);
  }
}

export function* sponsorshipSetActionWatcher() {
  yield takeLatest(updateSponsorshipSetAsync.request.type, updateSponsorshipSetSaga);
  yield takeLatest(
    activateSponsorshipSetFromCreationAsync.request.type,
    activateSponsorshipSetSaga
  );
  yield takeLatest(
    deactivateSponsorshipSetFromCreationAsync.request.type,
    deactivateSponsorshipSetSaga
  );
  yield takeLatest(fetchAdSetPricingOptionsAsync.request.type, fetchAdSetPricingOptionsSaga);
}
