import React, { useMemo } from 'react';

import { Button } from '../common/Button';

import classNames from 'classnames/bind';

import s from './Modal.module.scss';

import { useT } from '@transifex/react';

const cx = classNames.bind(s);

const Modal = ({
  children,
  closeModal,
  cancelCallback,
  actionCallback,
  dispatchActionCallback,
  customButtonText,
  title,
  withSubmit,
  noButtons,
  withoutCancel,
  notification,
  styling,
  fullwidthButton,
  customButtonCancelText,
  customButtonCancelStyle,
  withCloseIcon,
  isVideo,
}) => {
  // const modalIsOpen = useSelector(modalIsOpenSelector);

  const t = useT();

  const modalClassNames = useMemo(
    () =>
      cx({
        [s['modal']]: true,
        [s['modal--active']]: true,
        [s['modal__billing']]: styling === 'billing',
        [s['modal__video']]: isVideo,
      }),
    [styling, isVideo]
  );

  const modalButtonsClassNames = useMemo(
    () =>
      cx({
        [s['close__btn']]: true,
        [s['close__btn-black']]: notification,
        [s['close__btn-video']]: isVideo,
      }),
    [notification, isVideo]
  );

  const modalTitleClassNames = useMemo(
    () =>
      cx({
        [s['modal-title']]: true,
        [s['modal-title__error']]: styling === 'error',
      }),
    [styling]
  );

  const confirmButtonContainerClassNames = useMemo(
    () =>
      cx({
        [s['modal-buttons__confirm']]: true,
        [s['modal-buttons__confirm--fullwidth']]: fullwidthButton,
      }),
    [fullwidthButton]
  );

  const cancelButtonClassNames = useMemo(
    () =>
      cx({
        [s['modal-buttons__cancel']]: true,
        [s['modal-buttons__cancel--blue-text']]: customButtonCancelStyle === 'blue-text',
      }),
    [customButtonCancelStyle]
  );

  return (
    <div className={s['backdrop']}>
      <div className={modalClassNames}>
        {withCloseIcon && (
          <div className={s['modal-close']}>
            <div className={modalButtonsClassNames} onClick={closeModal} />
          </div>
        )}
        {title && <div className={modalTitleClassNames}>{title}</div>}
        <div className={s['modal-body']}>{children}</div>
        {!noButtons && (
          <div className={s['modal-buttons']}>
            {!withSubmit && (
              <div className={confirmButtonContainerClassNames}>
                <Button
                  type="button"
                  styling="primary"
                  customText={customButtonText ? customButtonText : t('Confirm')}
                  callback={actionCallback || dispatchActionCallback}
                  fullwidth={fullwidthButton}
                />
              </div>
            )}
            {!withoutCancel && (
              <div
                className={cancelButtonClassNames}
                onClick={cancelCallback ? cancelCallback : closeModal}
              >
                {customButtonCancelText ? customButtonCancelText : t('Cancel')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
