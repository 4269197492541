import React, { useMemo } from 'react';

import classNames from 'classnames';
import s from './GridWrapper.module.scss';

const cx = classNames.bind(s);

const GridWrapper = ({ children }) => {
  const gridWrapperClassNames = useMemo(
    () =>
      cx({
        [s['grid-wrapper']]: true,
      }),
    []
  );

  return <div className={gridWrapperClassNames}>{children}</div>;
};

export default GridWrapper;
