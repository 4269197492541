import React from 'react';
import ContentLoader from 'react-content-loader';

const InputPlaceholder = (props) => (
  <ContentLoader
    speed={2}
    width={450}
    height={105}
    viewBox="0 0 450 105"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="12" rx="3" ry="3" width="242" height="17" />
    <rect x="0" y="58" rx="3" ry="3" width="450" height="49" />
    <rect x="0" y="38" rx="3" ry="3" width="178" height="10" />
  </ContentLoader>
);

export default InputPlaceholder;
