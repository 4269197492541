import React from 'react';
import InputPlaceholder from '../../../components/Placeholders/InputPlaceholder/InputPlaceholder.js';
import ObjectivePlaceholder from '../../../components/Placeholders/ObjectivePlaceholder/ObjectivePlaceholder.js';
import { SectionTitlePlaceholder } from '../../../components/Placeholders/SectionTitlePlaceholder.js';
import UploadPlaceholder from '../../../components/Placeholders/UploadPlaceholder/UploadPlaceholder.js';
import { campaignConstants } from '../../../constants';
import Placeholder from '../Placeholder.js';

function CampaignPlaceholder() {
  return (
    <Placeholder gap="30px">
      <SectionTitlePlaceholder />
      <InputPlaceholder />
      <InputPlaceholder />
      <UploadPlaceholder type="circle" />
      <ObjectivePlaceholder items={campaignConstants.objectives} />
    </Placeholder>
  );
}

export default CampaignPlaceholder;
