import React, { useCallback, useEffect, useState } from 'react';
import { Switch, Route, Redirect, Prompt } from 'react-router';
import { SponsorshipForm } from '../CreateCampaign/Sponsorship/SponsorshipForm';
import { SponsorshipSetForm } from '../CreateCampaign/SponsorshipSet/SponsorshitSetForm';
import { CampaignSummaryForm } from '../CreateCampaign/CampaignSummary/CampaignSummaryForm';
import { AltSidebar } from '../../components/Sidebars/AltSidebar';
import { routes } from '../../constants';
import { CampaignForm } from '../CreateCampaign/Campaign/CampaignForm';
import { useDispatch, useSelector } from 'react-redux';
import { AltPageWrapper } from '../../components/wrappers/AltPageWrapper';
import { ToastContainer } from 'react-toastify';
import { defaultModalContentSelector, modalsSelector, toggleModalByName } from '../../store/ui';
import { Modal } from '../../components/Modal';
import { useT } from '@transifex/react';
import { NewCombinedCampaignMenu } from '../NewCombinedCampaignMenu';
import { campaignDataFetching, clearState, fetchCampaignAsync } from '../../store/data';
import { useRouter } from '../../hooks/useRouter';
import { clearCampaignDraft } from '../../store/campaign/campaign';
import {
  clearSponsorshipSet,
  sponsorshipSetCreateSelector,
} from '../../store/campaign/sponsorshipSet';
import { clearSponsorship, sponsorshipCreateSelector } from '../../store/campaign/sponsorship';
import { clearSummary } from '../../store/campaign/summary';
import { removeFromLocalStorage } from '../../utils/localStorage';
import {
  fetchCharitiesAsync,
  fetchLocationsAsync,
  locationsSelector,
  charitiesSelector,
  fetchTagsAsync,
  fetchedTagsSelector,
  fetchInterestsAsync,
  interestsSelector,
  fetchIncentivesAsync,
  incentivesSelector,
  fetchPixelStatsAsync,
  fetchedPixelStatsSelector,
  fetchPassionsAsync,
  fetchedPassionsSelector,
} from '../../store/brand';

const Campaign = () => {
  const dispatch = useDispatch();
  const t = useT();

  const { query } = useRouter();
  const [campaignId] = useState(query.cid !== 'new' ? query.cid : null);

  const { id: sponsorshipSetId } = useSelector(sponsorshipSetCreateSelector);
  const { id: sponsorshipId } = useSelector(sponsorshipCreateSelector);
  const locations = useSelector(locationsSelector);
  const charities = useSelector(charitiesSelector);
  const interests = useSelector(interestsSelector);
  const tags = useSelector(fetchedTagsSelector);
  const incentives = useSelector(incentivesSelector);
  const pixelStats = useSelector(fetchedPixelStatsSelector);
  const passions = useSelector(fetchedPassionsSelector);

  useEffect(() => {
    return () => {
      dispatch(clearCampaignDraft());
      dispatch(clearSponsorshipSet());
      dispatch(clearSponsorship());
      dispatch(clearSummary());
      dispatch(clearState());

      removeFromLocalStorage('campaignId');
      removeFromLocalStorage('sponsorshipSetId');
      removeFromLocalStorage('sponsorshipId');
    };
  }, [dispatch]);

  useEffect(() => {
    if (!locations.length) dispatch(fetchLocationsAsync.request());
    if (!charities.length) dispatch(fetchCharitiesAsync.request());
    if (!tags.length) dispatch(fetchTagsAsync.request({ limit: 20, offset: 0, keyword: '' }));
    if (!interests.length) dispatch(fetchInterestsAsync.request());
    if (!incentives.length) dispatch(fetchIncentivesAsync.request());
    if (!pixelStats.length) dispatch(fetchPixelStatsAsync.request());
    if (!passions.length) dispatch(fetchPassionsAsync.request());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (query.cid === 'new') dispatch(campaignDataFetching(false));
  }, [query.cid, dispatch]);

  const handleUnmount = useCallback((e) => {
    const confirmationMessage =
      'Are you sure you want to leave this page? All your unsaved changes will be lost.';

    e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return confirmationMessage;
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnmount);
    if (campaignId) dispatch(fetchCampaignAsync.request(campaignId));
    return () => {
      window.removeEventListener('beforeunload', handleUnmount);
    };
  }, [campaignId, dispatch, handleUnmount]);

  const modals = useSelector(modalsSelector);
  const modalContent = useSelector(defaultModalContentSelector);

  const handleBlockLocation = useCallback(
    (nextLocation) => {
      if (nextLocation.pathname === '/')
        return t(
          'Are you sure you want to leave this page? All your unsaved changes will be lost.'
        );
      return true;
    },
    [t]
  );

  return (
    <>
      <AltSidebar>
        <NewCombinedCampaignMenu />
      </AltSidebar>
      <AltPageWrapper>
        <Switch>
          <Route exact path={routes.CAMPAIGN_EDIT} component={CampaignForm} />
          <Route
            exact
            path={routes.SPONSORSHIP_SET_EDIT}
            render={(props) => <SponsorshipSetForm key={sponsorshipSetId} {...props} />}
          />
          <Route
            exact
            path={routes.SPONSORSHIP_EDIT}
            render={(props) => <SponsorshipForm key={sponsorshipId} {...props} />}
          />
          <Route path={routes.SPONSORSHIP_SUMMARY} component={CampaignSummaryForm} />
          <Redirect from="*" to={routes.CAMPAIGN_CREATE} />
        </Switch>
      </AltPageWrapper>
      <ToastContainer
        enableMultiContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        draggable={false}
        limit={1}
        containerId="upload-success"
      />
      {modals.default.isOpen && (
        <Modal
          title={modalContent.title || t('Warning')}
          closeModal={() => dispatch(toggleModalByName({ name: 'default', value: false }))}
          customButtonText={modalContent.buttonText}
          actionCallback={modalContent.actionCallback}
          withSubmit={modalContent.withSubmit}
          customButtonCancelText={modalContent.cancelText}
        >
          {modalContent.text}
        </Modal>
      )}

      <Prompt message={handleBlockLocation} />
      <ToastContainer
        enableMultiContainer
        position="bottom-right"
        autoClose={false}
        hideProgressBar={true}
        closeOnClick={false}
        draggable={false}
        limit={1}
        containerId="unpublished-changes"
      />
    </>
  );
};

export default Campaign;
