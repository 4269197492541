import React from 'react';

import { Button } from '../../common/Button';

import s from './ConfirmSuccess.module.scss';

const ConfirmSuccess = ({ confirmCallback, text }) => {
  return (
    <div className={s['confirm']}>
      <div className={s['confirm__text']}>{text}</div>
      <div className={s['confirm__buttons']}>
        <Button type="button" styling="primary" customText="Got it!" callback={confirmCallback} />
      </div>
    </div>
  );
};

export default ConfirmSuccess;
