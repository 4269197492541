import React from 'react';
import { NotFoundError } from '../../components/NotFoundError';
import { useRouter } from '../../hooks/useRouter';

const CampaignNotFound = () => {
  const { location } = useRouter();
  const text = location.state.text;

  return <NotFoundError text={text} />;
};

export default CampaignNotFound;
