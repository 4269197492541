import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';

import s from './Tooltip.module.scss';

const Tooltip = ({ content, customIcon = false, customClass = false }) => {
  const node = useRef();
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = useCallback(
    ({ target }) => {
      if (node.current.contains(target)) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    },
    [setIsVisible, node]
  );

  useEffect(() => {
    document.addEventListener('mouseover', handleMouseEnter);
    return () => {
      document.removeEventListener('mouseover', handleMouseEnter);
    };
  }, [handleMouseEnter]);

  const modalClass = useMemo(() => {
    if (customClass === 'action') return 'tooltip__action';
    return 'tooltip__modal';
  }, [customClass]);

  return (
    <div className={s['tooltip']} ref={node}>
      {isVisible && (
        <div className={s[modalClass]}>
          <span>{content}</span>
          <span className={s['tooltip__modal-arrow']} />
        </div>
      )}
      {customIcon || <div className={s['tooltip__icon']}>i</div>}
    </div>
  );
};

export default Tooltip;
