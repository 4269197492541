import { createAsyncAction, createAction } from 'eight.js.store-common';

export const publishCampaignAsync = createAsyncAction('@@summary/PUBLISH_CAMPAIGN');

export const clearSummary = createAction('@@summary/CLEAR_SUMMARY');

export const activateAllAsync = createAsyncAction('@@summary/ACTIVATE_ALL');

export const setAllModulesActivated = createAction('@@summary/SET_ALL_MODULES_ACTIVATED');

export const activateCampaignFromBilling = createAction('@@summary/ACTIVATE_CAMPAIGN_FROM_BILLING');

export const updateBrandInfoFromCampaignSummaryAsync = createAsyncAction(
  '@@summary/UPDATE_BRAND_INFO_FROM_CAMPAIGN_SUMMARY'
);

export const setIsPublishing = createAction('@@summary/SET_IS_PUBLISHING');
