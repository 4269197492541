import { useEffect, useState } from 'react';
import { htmlStringToElement } from '../../utils/formatting';
import { getFromLocalStorage, removeFromLocalStorage } from '../../utils/localStorage';

const useTrackingScript = () => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [scriptCode] = useState(getFromLocalStorage('tracking-script'));
  const [noScript, setNoScript] = useState(null);
  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (!scriptCode) setError(true);
    else {
      if (!scriptCode.toLowerCase().startsWith('<script')) {
        setNoScript(scriptCode);
        setSrc(null);
      } else {
        // eslint-disable-next-line
        const formattedScriptCode = scriptCode.replace('[timestamp]', '${CACHEBUSTER}');
        const scriptElement = htmlStringToElement(formattedScriptCode);
        setSrc(scriptElement.src);
        setNoScript(null);
      }
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
    return removeFromLocalStorage('tracking-script');
  }, [scriptCode, src, noScript]);

  return [loaded, error, noScript, src];
};

export default useTrackingScript;
