import React from 'react';

function Placeholder({ gap, marginTop = '75px', children }) {
  return (
    <div
      style={{
        margin: `${marginTop} 0`,
        display: 'flex',
        flexDirection: 'column',
        gap: gap,
      }}
    >
      {children}
    </div>
  );
}

export default Placeholder;
