import { createSelector } from 'reselect';

const campaignCreateStateSelector = (state) => state.campaign;

export const campaignCreateSelector = createSelector(
  campaignCreateStateSelector,
  (state) => state.campaignCreate
);

export const campaignEditSelector = createSelector(
  campaignCreateStateSelector,
  (state) => state.campaignEdit
);

export const campaignFetchingSelector = createSelector(
  campaignCreateStateSelector,
  (state) => state.fetching
);

export const campaignIdSelector = createSelector(campaignEditSelector, (state) => state.id);
