import { useT } from '@transifex/react';
import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { SectionTitle } from '../../../common/Titles/SectionTitle';

import SelfServeBrandOnboardingPlaybook from '../../../../assets/docs/SelfServeBrandOnboardingPlaybook.pdf';

import s from './BrandOnboardingContent.module.scss';

function BrandOnboardingContent() {
  const t = useT();

  return (
    <div className={s['container']}>
      <SectionTitle>{t('Brand Onboarding')}</SectionTitle>
      <a
        className={s['download-container']}
        href={SelfServeBrandOnboardingPlaybook}
        download="Self Serve Brand Onboarding Playbook.pdf"
      >
        <div className={s['download']}>
          <div className={s['download__button']}>
            <SvgIcon name="pdf-download" />
          </div>
        </div>
        <div className={s['details']}>
          <div className={s['title']}>{t('Brand Onboarding Playbook')}</div>
          <div className={s['description']}>
            {t('Download a copy to find everything you need to set up your campaign')}
          </div>
        </div>
      </a>
    </div>
  );
}

export default BrandOnboardingContent;
