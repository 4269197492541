import React from 'react';

import { SvgIcon } from '../common/SvgIcon';
import s from './ProgressSnackBar.module.scss';
import { useT } from '@transifex/react';

const ProgressSnackBar = ({
  refetchStatus,
  isRefetchLoading,
  fileURL,
  status = 'pending',
  closeModal,
}) => {
  const t = useT();

  return (
    <div className={s['progress']}>
      {status === 'pending' && (
        <>
          <div className={s['progress-icon-container']}>
            <button onClick={refetchStatus} className={isRefetchLoading ? s['refetching'] : ''}>
              <SvgIcon name="retry" />
              <span>{t('Refresh')}</span>
            </button>
          </div>
          <span className={s['label']}>{t('Preparing file...')}</span>
        </>
      )}
      {status === 'completed' && (
        <>
          <SvgIcon name="completed" />
          <span className={s['label']}>{t('Your report is ready')}</span>
          <a href={fileURL} download target="_blank" rel="noopener noreferrer">
            {t('Download')}
          </a>
        </>
      )}
      {(status === 'failed' || status === 'cancelled') && (
        <>
          <SvgIcon name="account-unverified" />
          <span className={s['label']}>{t('Sorry, something went wrong')}</span>
        </>
      )}
      <div className={s['clear-icon-container']} onClick={closeModal}>
        <SvgIcon name="clear" />
      </div>
    </div>
  );
};

export default ProgressSnackBar;
