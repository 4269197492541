import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import s from './MiniModal.module.scss';

const cx = classNames.bind(s);

const MiniModal = ({
  isShowing,
  firstItem,
  secondItem,
  modalRef,
  firsCallback,
  secondCallback,
  isNotActive,
}) => {
  const firstItemClassNames = useMemo(
    () =>
      cx({
        [s['mini-modal__content']]: true,
        [s['mini-modal__content--not-active']]: isNotActive,
      }),
    [isNotActive]
  );

  return isShowing ? (
    <>
      <div ref={modalRef} className={s['mini-modal']}>
        <div className={firstItemClassNames} onClick={(e) => firsCallback(e)}>
          {firstItem}
        </div>
        <div className={s['mini-modal__content']} onClick={(e) => secondCallback(e)}>
          {secondItem}
        </div>
      </div>
    </>
  ) : null;
};

export default MiniModal;
