import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { container } from '../../../container';

import {
  activateCampaignFromCreationAsync,
  deactivateCampaignFromCreationAsync,
  setFetching,
  updateCampaignAsync,
  duplicateCampaignAsync,
  archiveCampaignAsync,
  unarchiveCampaignAsync,
  submitNewCharityAsync,
} from './actions';

import { campaignIdSelector } from '../../data/selectors';

import { brandInfoSelector } from '../../brand';
import { setCampaignIsArchive, setCampaignIsUnarchive, setManageIsFetching } from '../../manage';
import { toggleModalByName, toggleSavingToast } from '../../ui';
import { toast } from 'react-toastify';
import { delay } from '../../../utils/manage';
import { routes } from '../../../constants';
import { generatePath } from 'react-router';
import { adSetsSelector, setCampaignIsDirty } from '../../data';
import { t } from '@transifex/native';

const campaignService = container.get('CampaignService');

function* updateCampaignSaga({ payload }) {
  try {
    const {
      name,
      category,
      campaignBrandName,
      campaignBrandLogoFile,
      campaignBrandLogoUri,
      selectedCharity,
    } = payload;

    const { id: charityId, name: charityName, logoUri: logoKey } = selectedCharity;

    const campaignId = yield select(campaignIdSelector);

    const { countryCode, id } = yield select(brandInfoSelector);

    const adSets = yield select(adSetsSelector);

    yield put(toggleSavingToast(true));

    yield call(campaignService.updateCampaign, {
      campaignId,
      name,
      campaignBrandName,
      category,
      campaignBrandLogoUri,
      media: campaignBrandLogoFile,
      countryCode,
      brandId: id,
      charity: {
        id: charityId,
        name: charityName,
        logoKey,
      },
      isActive: true,
    });

    yield put(updateCampaignAsync.success({ ...payload, id: campaignId }));

    yield put(toggleSavingToast(false));
    yield put(
      push(
        generatePath(routes.SPONSORSHIP_SET_EDIT, {
          cid: campaignId,
          ssid: adSets[0].id,
        })
      )
    );
    yield put(setCampaignIsDirty({ isDirty: false, form: payload }));
  } catch (error) {
    yield put(updateCampaignAsync.failure(error));
    yield put(setCampaignIsDirty({ isDirty: true, form: payload }));
    yield put(toggleSavingToast(false));
  }
}

function* activateCampaignSaga() {
  try {
    const campaignId = yield select(campaignIdSelector);

    yield call(campaignService.activateCampaign, {
      campaignId,
    });

    yield put(activateCampaignFromCreationAsync.success());
  } catch (error) {
    yield put(activateCampaignFromCreationAsync.failure(error));
  }
}

function* deactivateCampaignSaga() {
  try {
    const campaignId = yield select(campaignIdSelector);

    yield call(campaignService.deactivateCampaign, {
      campaignId,
    });
    yield put(deactivateCampaignFromCreationAsync.success());
  } catch (error) {
    yield put(deactivateCampaignFromCreationAsync.failure(error));
  }
}

function* duplicateCampaignSaga({ payload }) {
  try {
    yield put(setManageIsFetching(true));
    const response = yield call(campaignService.duplicateCampaign, {
      campaignId: payload,
    });

    toast.info(t('Campaign duplication in process...'), { containerId: 'top-center' });

    let status = '';

    let i = 0;

    while (i < 5) {
      let res = yield call(campaignService.getDuplicationStatus, {
        duplicateRequestId: response.requestId,
      });

      status = res.status;

      if (status === 'completed' || status === 'failed') break;
      i++;

      yield call(delay, 2000);
    }

    if (status === 'failed')
      toast.warn(t('Campaign duplication process failed.'), { containerId: 'top-center' });
    else {
      if (status === 'completed')
        toast.success(t('Campaign duplication process completed successfully.'), {
          containerId: 'top-center',
        });

      setTimeout(
        () =>
          toast.info(
            t(
              'The new campaign will be available in your campaign list sortly. Please check back later.'
            ),
            {
              containerId: 'top-center',
            }
          ),
        3000
      );
    }

    yield put(duplicateCampaignAsync.success(response));
    yield put(setManageIsFetching(false));
  } catch (error) {
    console.log(error);
    yield put(duplicateCampaignAsync.failure(error));
    yield put(setManageIsFetching(false));
    toast.warn(t('Unable to duplicate campaign.'), { containerId: 'top-center' });
  }
}

function* archiveCampaignAsyncSaga({ payload }) {
  try {
    yield put(toggleModalByName({ name: 'default', value: false }));

    yield call(campaignService.archiveCampaign, { campaignId: payload.id });

    yield put(setCampaignIsArchive({ id: payload.id }));

    yield put(archiveCampaignAsync.success());

    toast.success(t('Campaign archived successfully'), { containerId: 'top-center' });
  } catch (error) {
    yield put(archiveCampaignAsync.failure(error));
  }
}

function* unarchiveCampaignAsyncSaga({ payload }) {
  try {
    yield put(toggleModalByName({ name: 'default', value: false }));
    yield put(setManageIsFetching(true));

    yield call(campaignService.unarchiveCampaign, { campaignId: payload.id });
    yield put(unarchiveCampaignAsync.success());

    yield put(setCampaignIsUnarchive({ id: payload.id }));

    yield put(setManageIsFetching(false));
    let toastText = t('Campaign unarchived successfully');
    if (payload.activateNext)
      toastText = t(
        'Campaign unarchived successfully, you can now visit your Active / Inactive sets to activate it.'
      );

    toast.success(toastText, { containerId: 'top-center' });
    if (payload.activateNext) {
    }
  } catch (error) {
    yield put(unarchiveCampaignAsync.failure(error));
    yield put(setManageIsFetching(false));
  }
}

function* submitNewCharitySaga({ payload }) {
  try {
    const { newCharityName, newCharityWebsite } = payload;

    yield put(setFetching(true));

    yield call(campaignService.submitNewCharity, {
      newCharityName,
      newCharityWebsite,
    });
    yield put(submitNewCharityAsync.success());

    yield put(setFetching(false));

    yield put(toggleModalByName({ name: 'charity', value: false }));
  } catch (error) {
    yield put(submitNewCharityAsync.failure(error));
  }
}

// function* getDuplicationStatusSaga({ payload }) {
//   try {
//     const response = yield call(campaignService.getDuplicationStatusSaga, {
//       duplicateRequestId: payload,
//     });
//     console.log(getDuplicationStatusSaga);
//     yield put(getDuplicationStatusAsync.success(response));

//     return response;
//   } catch (error) {
//     console.log(error);
//     yield put(getDuplicationStatusAsync.failure(error));
//   }
// }

export function* createCampaignActionWatcher() {
  yield takeLatest(updateCampaignAsync.request.type, updateCampaignSaga);
  yield takeLatest(archiveCampaignAsync.request.type, archiveCampaignAsyncSaga);
  yield takeLatest(unarchiveCampaignAsync.request.type, unarchiveCampaignAsyncSaga);
  yield takeLatest(activateCampaignFromCreationAsync.request.type, activateCampaignSaga);
  yield takeLatest(deactivateCampaignFromCreationAsync.request.type, deactivateCampaignSaga);
  yield takeLatest(duplicateCampaignAsync.request.type, duplicateCampaignSaga);
  yield takeLatest(submitNewCharityAsync.request.type, submitNewCharitySaga);
}
