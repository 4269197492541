import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';

import { Layout } from '../../../components/Layout';
import { AccountContent } from '../../../components/PagesContent/SettingsContent/AccountContent';

import {
  brandInfoSelector,
  brandIsLoadingSelector,
  setBrandData,
  setBrandDataArray,
} from '../../../store/brand';

import { modalsSelector, toggleModalByName } from '../../../store/ui';
import { ToastContainer } from 'react-toastify';
import WithSavingToastContainer from '../../wrappers/WithSavingToastContainer/WithSavingToastContainer';
import { AccountInfoPlaceholder } from '../../Placeholders/AccountInfoPlaceholder/index';

const SettingsAccountInfo = () => {
  const dispatch = useDispatch();

  const brandInfo = useSelector(brandInfoSelector);
  const brandIsLoading = useSelector(brandIsLoadingSelector);
  const modals = useSelector(modalsSelector);

  const handleDefaultCountry = useMemo(() => {
    let targetCountry = brandInfo.fetchedCountries.filter(
      (country) => country.currencyCode === brandInfo.currencyCode
    );

    if (targetCountry.length > 0 && brandInfo.currencyCode === 'USD') {
      targetCountry = targetCountry.filter(
        (country) => country.label === 'United States of America'
      );
    }

    return targetCountry;
  }, [brandInfo.currencyCode, brandInfo.fetchedCountries]);

  const handleSelectField = useCallback(
    (name) => (value) => {
      dispatch(setBrandDataArray({ name, value }));
    },
    [dispatch]
  );

  const handleSelectCountry = useCallback(
    (name) => (value) => {
      dispatch(setBrandDataArray({ name, value }));
      dispatch(setBrandData({ name: 'countryCode', value: value.countryCode }));
      dispatch(setBrandData({ name: 'currencyCode', value: value.currencyCode }));
      dispatch(setBrandData({ name: 'timeZone', value: value.timezones[0] }));
    },
    [dispatch]
  );

  const handleChangeBrandData = useCallback(
    (e) => {
      const { name, value } = e.target;

      if (!/^\s+$/.test(value)) {
        //value contains not only whitespace
        dispatch(setBrandData({ name, value }));
      }
    },
    [dispatch]
  );

  const handleChangeBrandPhoneNumber = useCallback(
    (e) => {
      const { name, value } = e.target;
      const phoneNumber = parsePhoneNumber(String(value));

      dispatch(
        setBrandData({
          name,
          value: {
            regionCode: !phoneNumber || !phoneNumber.country ? '' : phoneNumber.country,
            number: value,
          },
        })
      );
    },
    [dispatch]
  );

  return (
    <Layout>
      <WithSavingToastContainer>
        {brandIsLoading ? (
          <AccountInfoPlaceholder />
        ) : (
          <AccountContent
            brandInfo={brandInfo}
            handleChangeBrandData={handleChangeBrandData}
            handleSelectField={handleSelectField}
            handleDefaultCountry={handleDefaultCountry}
            handleSelectCountry={handleSelectCountry}
            modals={modals}
            toggleModalByName={toggleModalByName}
            handleChangeBrandPhoneNumber={handleChangeBrandPhoneNumber}
          />
        )}
      </WithSavingToastContainer>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        draggable={false}
        limit={1}
      />
    </Layout>
  );
};

export default SettingsAccountInfo;
