/* eslint camelcase: "off" */
import { eightAuth } from '../../store/eightauth';
import APILockService from '../apiLock/service';
import instance from './axiosInstance';
import qs from 'query-string';
import { store } from '../../store';

const REFRESH_TRIGGERING_IN_SECONDS = 180;

export class FetchService {
  constructor() {
    this.tokenService = eightAuth.tokenService;
    this.authService = eightAuth.authService;
    this.apiLockService = APILockService;
  }

  fetch = async ({
    newVersionApi,
    body,
    bodyType,
    headers = {},
    method = 'POST',
    params,
    url,
    useMachineToken = false,
    useCustomToken = false,
  }) => {
    let request = {
      url,
      method,
      headers,
      params,
    };

    const { accessToken, machineToken, customToken } = this.tokenService.getTokens();

    let tokenType = '';
    let authorizationToken = '';

    if (useCustomToken && customToken) {
      tokenType = 'customToken';
      authorizationToken = customToken;
    } else if (useMachineToken) {
      tokenType = 'machineToken';
      authorizationToken = machineToken;
    } else {
      tokenType = 'accessToken';
      authorizationToken = accessToken;
    }

    authorizationToken = await this.validateToken(authorizationToken, tokenType, request);

    request = this.formatRequestHeaders(
      request,
      bodyType,
      body,
      newVersionApi,
      authorizationToken,
      tokenType
    );

    try {
      const response = await instance.request(request);
      return response;
    } catch (error) {
      throw error;
    }
  };

  refreshSelectedToken = async (type, lockToken, token) => {
    switch (type) {
      case 'accessToken':
        try {
          const refreshTokenResponse = await this.authService.refresh(lockToken);
          this.tokenService.setTokens({
            accessToken: refreshTokenResponse.accessToken,
            refreshToken: refreshTokenResponse.refreshToken,
          });
          store.dispatch(eightAuth.setAccessToken(refreshTokenResponse.accessToken));
          store.dispatch(eightAuth.setRefreshToken(refreshTokenResponse.refreshToken));
          return refreshTokenResponse.accessToken;
        } catch (error) {
          store.dispatch(eightAuth.setAccessToken(null));
          store.dispatch(eightAuth.setRefreshToken(null));
          store.dispatch(eightAuth.logoutAsync.request());
          console.log(error);
          return token;
        }
      case 'machineToken':
        try {
          const machineTokenResponse = await this.authService.fetchMachineToken(lockToken);
          this.tokenService.setTokens({
            machineToken: machineTokenResponse.accessToken,
          });
          store.dispatch(eightAuth.setMachineToken(machineTokenResponse.accessToken));
          return machineTokenResponse.accessToken;
        } catch (error) {
          store.dispatch(eightAuth.setMachineToken(null));
          store.dispatch(eightAuth.logoutAsync.request());
          console.log(error);
          return token;
        }
      default:
        return token;
    }
  };

  validateToken = async (token, tokenType, request) => {
    let isTokenExpired = true;
    let finalAuthorizationToken = token;

    if (token) isTokenExpired = this.tokenService.isExpiring(token, REFRESH_TRIGGERING_IN_SECONDS);

    if (isTokenExpired && !this.apiLockService.isLocked(request)) {
      const lockToken = new Date().toISOString();

      this.apiLockService.lock(lockToken);

      const newAccessToken = await this.refreshSelectedToken(tokenType, lockToken, token);

      finalAuthorizationToken = newAccessToken;

      this.apiLockService.releaseLock(lockToken, tokenType, finalAuthorizationToken);
    }

    return finalAuthorizationToken;
  };

  formatRequestHeaders = (
    request,
    bodyType,
    body,
    newVersionApi,
    authorizationToken,
    tokenType
  ) => {
    switch (bodyType) {
      case 'urlencoded':
        request.data = qs.stringify(body);
        request.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        break;
      case 'json':
      default:
        request.data = body;
        if (newVersionApi) {
          request.headers['Content-Type'] = 'application/json';
          request.headers['x-api-version'] = newVersionApi;
        }
        break;
    }
    request.headers.Authorization = `Bearer ${authorizationToken}`;
    request.tokenType = tokenType;
    return request;
  };
}
