import { createAction, createAsyncAction } from 'eight.js.store-common';

// ASYNC

export const activateCampaignFromCreationAsync = createAsyncAction(
  '@@campaign/ACTIVATE_CAMPAIGN_FROM_CREATION'
);

export const deactivateCampaignFromCreationAsync = createAsyncAction(
  '@@campaign/DEACTIVATE_CAMPAIGN_FROM_CREATION'
);

export const archiveCampaignAsync = createAsyncAction('@@campaign/ARCHIVE_CAMPAIGN');

export const unarchiveCampaignAsync = createAsyncAction('@@campaign/UNARCHIVE_CAMPAIGN');

export const updateCampaignAsync = createAsyncAction('@@campaign/UPDATE_CAMPAIGN');

export const duplicateCampaignAsync = createAsyncAction('@@campaign/DUPLICATE_CAMPAIGN');

export const submitNewCharityAsync = createAsyncAction('@@sponsorshipSet/SUBMIT_NEW_CHARITY');

// SYNC

export const clearCampaignDraft = createAction('@@campaign/CLEAR_CAMPAIGN_DRAFT');

export const toggleActivateCampaign = createAction('@@campaign/TOGGLE_CAMPAIGN_ACTIVE');

export const setCampaignData = createAction('@@campaign/SET_CAMPAIGN_DATA');

export const setFetching = createAction('@@campaign/SET_FETCHING');

export const setCampaignIsReadOnly = createAction('@@campaign/SET_READ_ONLY');

export const setCampaignEditId = createAction('@@campaign/SET_CAMPAIGN_EDIT_ID');

export const setActiveCampaign = createAction('@@campaign/SET_ACTIVE_CAMPAIGN');

export const setCampaignName = createAction('@@campaign/SET_CAMPAIGN_NAME');
