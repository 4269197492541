/* 
*** This file constructs distinct test ids. ***
Why? This makes QA UI automation identify each testable element easier.

~INSTRUCTIONS~
- We add test ids only to elements that are gonna be used in testing (ex. buttons, inputs, selects, uploads)
- We can do that by adding a data-testid attribute into the element (ex. <button data-testid='...'></button>)
(WARNING: some elements use 3rd party libraries that do not accept data-testid as an attribute. 
    In that occasion we can use id attribute [but only if its not needed for some other cause].)
- a) We have to import constructTestId function as well as the static string/array that represents the page section. (/constants/testids/{page})
- b) We have to add, to each section component a const string (SECTION) that represent's the section's name.
- c) Then in each element we add the data-testid attribute which should contain the constructTestId function [args : (SECTION, element type and name)]. 
    (ex. 
        import { constructTestId } from '../utils/test-ids/';
        import { CREATE_CAMPAIGN_PAGE } from '../constants/test-ids/pages';

        const SECTION = CREATE_CAMPAIGN_PAGE.TIMING_AND_BUDGET;
        ...
        ...
        <button data-testid={constuctTestId(SECTION, 'continue-btn')}/>
        ...
        <input data-testid={constuctTestId(SECTION, 'name-input')}/>
        ...
    )

~FORMAT~
PAGE_SECTION_ELEMENT
(ex.
    testId = 'create-campaing_timing-and-budget_continue-btn'
    
    ...which can translate to...

    PAGE = create-campaign
    SECTION = timing-and-budget
    ELEMENT = continue-btn
)
We can add a subsection as well : PAGE_SECTION_SUBSECTION_ELEMENT

 */

const processText = (text) => (text ? text.toLowerCase().replaceAll(' ', '-') : '');

export const constructSubname = (parent, name) => parent + '_' + name;

export const constructTestId = (section, type) =>
  section ? section + '_' + processText(type) : processText(type);
