import classNames from 'classnames';
import React from 'react';
import { useMemo } from 'react';
import zxcvbn from 'zxcvbn';
import s from './PasswordStrengthBar.module.scss';
import { useT } from '@transifex/react';

const PasswordStrengthBar = ({ password }) => {
  const score = useMemo(() => zxcvbn(password).score, [password]);
  const t = useT();

  const strength = useMemo(() => {
    const strengthMap = {
      1: 'very-weak',
      2: 'weak',
      3: 'good',
      4: 'strong',
    };
    return strengthMap[score];
  }, [score]);

  return (
    <div className={s['strength-container']}>
      <div className={s['strength-bar']}>
        <div className={classNames(s['bar'], s[strength])}></div>
        <div className={classNames(s['bar'], s[strength !== 'very-weak' ? strength : ''])}></div>
        <div
          className={classNames(
            s['bar'],
            s[strength !== 'weak' && strength !== 'very-weak' ? strength : '']
          )}
        ></div>
        <div className={classNames(s['bar'], s[strength === 'strong' ? strength : ''])}></div>
      </div>
      <span className={s['text']}>{t(strength.replace('-', ' '))}</span>
    </div>
  );
};
export default PasswordStrengthBar;
