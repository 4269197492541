const config = {
  adminAPI: process.env.REACT_APP_ADMIN_API,
  analyticsAPI: process.env.REACT_APP_ANALYTICS_API,
  apiToAuthenticate: process.env.REACT_APP_USERS_API,
  campaignsAPI: process.env.REACT_APP_CAMPAIGNS_API,
  communityTilesAPI: process.env.REACT_APP_COMMUNITY_TILES_API,
  charitiesAPI: process.env.REACT_APP_CHARITY_API,
  usersAPI: process.env.REACT_APP_USERS_API,
  identityAPI: process.env.REACT_APP_IDENTITY_API,
  paymentsAPI: process.env.REACT_APP_PAYMENTS_API,
  pwaDomain: process.env.REACT_APP_PWA_DOMAIN,
  environment: process.env.REACT_APP_CUSTOM_ENV,
  gtmId: process.env.REACT_APP_GTM_ID,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  auth: {
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    clientId: 'sami.client',
    machineSecret: process.env.REACT_APP_MACHINE_SECRET,
    machineId: 'sami.machine.client',
    type: 'brand',
    loginScope: [
      'users.api',
      'campaigns.api',
      'admin.api',
      'analytics.api',
      'payments.api',
      'charities.api',
    ],
    fetchMachineScope: ['identity.api', 'admin.api', 'users.api'],
  },
  stripePublishableKeyGB: process.env.REACT_APP_STRIPE_GB_PUBLISHABLE_KEY,
  stripePublishableKeyUSA: process.env.REACT_APP_STRIPE_USA_PUBLISHABLE_KEY,
  stripePublishableKeyAU: process.env.REACT_APP_STRIPE_AU_PUBLISHABLE_KEY,
  stripeMinDeposit: process.env.REACT_APP_STRIPE_MINIMUM_DEPOSIT,
  transifexToken: process.env.REACT_APP_TRANSIFEX_TOKEN,
  transifexSecret: process.env.REACT_APP_TRANSIFEX_SECRET,
};

export default config;
