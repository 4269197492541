import { T, useT } from '@transifex/react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { SvgIcon } from '../../../common/SvgIcon';
import { SectionTitle } from '../../../common/Titles/SectionTitle';

import { TableBilling } from '../../../TableBilling';
import { BillingInfoBox } from './BillingInfoBox';

import { useRouter } from '../../../../hooks/useRouter';

import { routes } from '../../../../constants';

import { billingConstants } from '../../../../constants';

import { getBalanceSum, getBalanceTitle, setPaymentName } from '../../../../utils/settingsBilling';

import { amountToCurrency, numberToThousandCurrency } from '../../../../utils/budget';
import s from './BillingContent.module.scss';

const BillingContent = ({
  brandInfo,
  transactions,
  handleBillingModalOpen,
  handleAddFunds,
  handleOpenRequestInvoiceModal,
  getBrandBillsAsync,
  setDataArray,
  handleBillingReport,
  handleApproveInvoice,
  lastStripeCharge,
  hasTransactionHistory,
}) => {
  const t = useT();
  const { match } = useRouter();

  const primaryMethod = useMemo(() => {
    return brandInfo?.paymentMethods?.filter((item) => item.isDefault)[0];
  }, [brandInfo.paymentMethods]);

  const balanceTitle = useMemo(() => {
    return getBalanceTitle(brandInfo.isInvoicePartner);
  }, [brandInfo.isInvoicePartner]);

  const balanceSum = useMemo(() => {
    return getBalanceSum(brandInfo.isInvoicePartner, brandInfo.balance);
  }, [brandInfo.isInvoicePartner, brandInfo.balance]);

  const hoverMessage = useMemo(() => {
    switch (brandInfo.accountStatus) {
      case 'unverified':
        return `Verify now`;
      case 'pending':
        return `Verification is pending`;
      default:
        return false;
    }
  }, [brandInfo.accountStatus]);

  return (
    <div className={s['billing']}>
      <SectionTitle>{t('Billing')}</SectionTitle>

      <div className={s['billing__info-boxes']}>
        <div className={s['billing__info-boxes__item']}>
          <BillingInfoBox
            title={balanceTitle}
            isDisabled={brandInfo.accountStatus !== 'verified'}
            hoverText={t('You need to have a verified account to be able to add funds')}
            hoverMessage={hoverMessage}
            accountStatus={brandInfo.accountStatus}
          >
            <div className={s['item__body']}>
              <div className={s['balance']}>
                <span className={s['sum']}>{amountToCurrency(balanceSum)}</span>
              </div>
            </div>
            {!brandInfo.isInvoicePartner && (
              <div className={s['item__link']}>
                <span className={s['link']} onClick={handleAddFunds}>
                  {t('add funds')}
                </span>
              </div>
            )}
          </BillingInfoBox>
        </div>

        <div className={s['billing__info-boxes__item']}>
          <BillingInfoBox title={t('Previous Charge')}>
            {!lastStripeCharge.paymentMethod ? (
              <div className={s['billing__no-charges']}>{t('No previous charges.')}</div>
            ) : (
              <div className={s['item__body']}>
                <div className={s['charge']}>
                  <span className={s['sum']}>{amountToCurrency(lastStripeCharge?.total)}</span>{' '}
                  {t('billed on {date}', { date: lastStripeCharge?.date })}
                </div>
                <div className={s['excerpt']}>
                  {t('Charged to {name}', {
                    name: setPaymentName(lastStripeCharge?.paymentMethod),
                  })}
                </div>
              </div>
            )}
            <div className={s['item__link']}>
              {!hasTransactionHistory ? (
                <span className={s['link']}>{t('No transaction history')}</span>
              ) : (
                <Link
                  className={s['link']}
                  to={`${match.url}${routes.SETTINGS_BILLING_TRANSACTIONS_HISTORY}`}
                >
                  {t('view transaction history')}
                </Link>
              )}
            </div>
          </BillingInfoBox>
        </div>

        <div className={s['billing__info-boxes__item']}>
          <BillingInfoBox title={t('Payment Method')}>
            <div className={s['item__body']}>
              {primaryMethod ? (
                <div className={s['card']}>
                  <div className={s['card-img']}>
                    {primaryMethod?.type !== 'bacs_debit' ? (
                      <SvgIcon name={`${primaryMethod?.cardBrand}-radio`} />
                    ) : (
                      t('Bank account')
                    )}
                  </div>
                  <div className={s['card-info']}>
                    <div className={s['card-info__number']}>
                      <span className={s['mask']}>•••• •••• •••• </span>
                      <span className={s['digits']}>
                        {' '}
                        {primaryMethod?.cardLast4 || primaryMethod?.bacsDebitLast4}
                      </span>
                    </div>
                    {primaryMethod?.type !== 'bacs_debit' && (
                      <div className={s['card-info__expiry']}>
                        {t('Expires')}{' '}
                        <span>{`${primaryMethod?.cardExpMonth}/${primaryMethod?.cardExpYear}`}</span>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                t('No active payment Method')
              )}
            </div>
            <div className={s['item__link']} onClick={handleBillingModalOpen}>
              <span className={s['link']}>{t('edit payment method')}</span>
            </div>
          </BillingInfoBox>
        </div>
      </div>
      {!brandInfo.isInvoicePartner && (
        <div className={s['billing__request-invoice']}>
          <div className={s['billing__request-invoice__content']}>
            <strong>{t('Note.')}</strong>
          </div>
          <div className={s['billing__request-invoice__content']}>
            <T
              _str="If you would like to run campaigns first and be invoiced monthly with a 2 week payment term - please send us a {requestLink}."
              requestLink={
                <span className={s['link']} onClick={handleOpenRequestInvoiceModal}>
                  {t('request')}
                </span>
              }
            />
          </div>
          <div className={s['billing__request-invoice__content']}>
            <T
              _str="{termsText}. Only available for {amount}+ monthly spends or agency pre agreed contracts. Additional brand verification is required."
              termsText={<strong>Terms apply</strong>}
              amount={numberToThousandCurrency(10000)}
            />
          </div>
        </div>
      )}
      <div className={s['billing__history']}>
        <div className={s['billing__history-title']}>{t('Monthly Spend')}</div>
        {transactions?.data?.length > 0 ? (
          <TableBilling
            headers={
              brandInfo.isInvoicePartner
                ? billingConstants.invoicePartnerMonthlySpendTableHeaders
                : billingConstants.monthlySpendTableHeaders
            }
            tableData={transactions}
            content="monthly spend"
            isInvoicePartner={brandInfo.isInvoicePartner}
            currencySymbol={brandInfo.country.currencySymbol}
            getBrandBillsAsync={getBrandBillsAsync}
            setDataArray={setDataArray}
            itemsPerPageOptions={billingConstants.itemsPerPage}
            handleBillingReport={handleBillingReport}
            handleApproveInvoice={handleApproveInvoice}
            brandInfo={brandInfo}
          />
        ) : (
          <div className={s['billing__history-empty']}>
            {t('No transaction yet.')}{' '}
            <span onClick={handleBillingModalOpen} className={s['link']}>
              {t('Add payment method')}{' '}
            </span>
            &
            <Link className={s['campaign-link']} to={`${routes.CAMPAIGN_CREATE}`}>
              {t('create a campaign')}
            </Link>
            {t('to get started')}
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingContent;
