import React, { useEffect } from 'react';
import { findEnvironment } from '../../utils/findEnvironment';

function PixelPurchase() {
  const environmentString = findEnvironment();
  const pixel = `!(function (e, t, i, n, s, c, a) {
    e[n] ||
      (((s = e[n] =
        function () {
          s.process ? s.process.apply(s, arguments) : s.queue.push(arguments);
        }).queue = []),
      (s.t = +new Date()),
      ((c = t.createElement(i)).async = 1),
      (c.src =
        "https://pixel-${environmentString}.test.aws.the8app.com/eightpixel.min.js?t=" +
        864e5 * Math.ceil(new Date() / 864e5)),
      (a = t.getElementsByTagName(i)[0]).parentNode.insertBefore(c, a));
  })(window, document, "script", "eight"),
    eight("event", "purchase", {purchaseAmount: '10'});`;
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = pixel;
    document.body.appendChild(script);
  }, [pixel]);
  return (
    <>
      <div>PixelPurchase</div>
    </>
  );
}

export default PixelPurchase;
