import React, { useMemo } from 'react';

import { Loader } from '../../../Loader';
import { ReportingBox } from '../ReportingBox';

import classNames from 'classnames/bind';
import s from './ReportingChart.module.scss';
import { Link } from 'react-router-dom';
import { useRouter } from '../../../../hooks/useRouter';
import { useT } from '@transifex/react';

const cx = classNames.bind(s);

const ReportingChart = ({
  children,
  chartStyle,
  downloadable,
  loading,
  question,
  title,
  titleStyle,
  withLink,
  csvDataType,
  handleDownloadCSV,
}) => {
  const titleContainerStyles = useMemo(
    () =>
      cx({
        [s['chart__title-container']]: true,
        [s['chart__title-container--full']]: withLink,
      }),
    [withLink]
  );

  const { match } = useRouter();

  const t = useT();

  return (
    <ReportingBox
      downloadable={downloadable}
      csvDataType={csvDataType}
      handleDownloadCSV={handleDownloadCSV}
      questionId={question?.id}
    >
      <div className={s['chart__container']}>
        <div className={titleContainerStyles}>
          {question ? (
            <div className={s['chart__question']} style={titleStyle}>
              <div className={s['chart__question-title']}>{t('Response')}</div>
              <div className={s['chart__question-text']}>
                <strong>Q{question.number}.</strong> {question.text}
              </div>
            </div>
          ) : (
            <>
              <div className={s['chart__title']} style={titleStyle}>
                {title}
              </div>
              {withLink && (
                // <div className={s['link']} onClick={handleChartLinkClick}>
                //   view demographics
                // </div>
                <Link className={s['link']} to={`${match.url}?type=demographics`}>
                  {t('view demographics')}
                </Link>
              )}
            </>
          )}
        </div>
        <div className={s['chart__chart-container']} style={chartStyle}>
          {loading ? <Loader /> : children}
        </div>
      </div>
    </ReportingBox>
  );
};

export default ReportingChart;
