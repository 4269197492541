import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { useRouter } from '../../hooks/useRouter';
import { routes } from '../../constants';
import { StripeSuccess } from './StripeSuccess';
import { StripeCancel } from './StripeCancel';
import { getFromSessionStorage } from '../../utils/sessionStorage';

import { modalsSelector, toggleModalByName, setModalDisplayMode } from '../../store/ui';

import { brandInfoSelector } from '../../store/brand';

const Stripe = () => {
  const dispatch = useDispatch();

  const modals = useSelector(modalsSelector);
  const brandInfo = useSelector(brandInfoSelector);

  const { push, query, pathname } = useRouter();
  const { session_id } = query;

  useEffect(() => {
    if (pathname === routes.STRIPE_SUCCESS) {
      const bacsSessionId = getFromSessionStorage('bacsSessionId');
      if (!bacsSessionId || bacsSessionId !== session_id) {
        push(routes.STRIPE_CANCEL);
      }
    }
  }, [push, session_id, pathname]);

  useEffect(() => {
    dispatch(toggleModalByName({ name: 'stripe', value: true }));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    push(routes.SETTINGS_BILLING);
    dispatch(toggleModalByName({ name: 'stripe', value: false }));
  }, [push, dispatch]);

  const handleRepeat = useCallback(() => {
    push(routes.SETTINGS_BILLING);
    brandInfo.countryCode !== 'GB' &&
      dispatch(setModalDisplayMode({ name: 'billing', value: false }));
    dispatch(toggleModalByName({ name: 'billing', value: true }));
    dispatch(toggleModalByName({ name: 'default', value: false }));
  }, [push, brandInfo.countryCode, dispatch]);

  return (
    <>
      <Switch>
        <Route path={routes.STRIPE_SUCCESS} exact>
          <StripeSuccess modals={modals} handleCloseModal={handleCloseModal} />
        </Route>
        <Route path={routes.STRIPE_CANCEL} exact>
          <StripeCancel
            modals={modals}
            handleCloseModal={handleCloseModal}
            handleRepeat={handleRepeat}
          />
        </Route>
        <Redirect from="*" to={routes.SETTINGS_BILLING} />
      </Switch>
    </>
  );
};

export default Stripe;
