import { createSelector } from 'reselect';

const dataStateSelector = (state) => state.data;

export const adSetsSelector = createSelector(dataStateSelector, (state) => state.campaign.adSets);

export const summaryAdSetsSelector = createSelector(
  dataStateSelector,
  (state) => state.campaignSummary.adSets
);

export const campaignSelector = createSelector(dataStateSelector, (state) => state.campaign);

export const campaignSummarySelector = createSelector(
  dataStateSelector,
  (state) => state.campaignSummary
);

export const campaignIdSelector = createSelector(dataStateSelector, (state) => state.campaign.id);

export const campaignDataFetchingSelector = createSelector(
  dataStateSelector,
  (state) => state.fetching
);

export const adSetSelector = createSelector(
  [dataStateSelector, (state, adSetId) => adSetId],
  (state, adSetId) => state.campaign.adSets.filter((adSet) => adSet.id === adSetId)[0]
);

export const adSetByAdIdSelector = createSelector(
  [dataStateSelector, (state, adId) => adId],
  (state, adId) => state.campaign.adSets.find((adSet) => adSet.ads.find((ad) => ad.id === adId))
);

export const unpublishedChangesSelector = createSelector(
  dataStateSelector,
  (state) => state.campaignDifferences
);
