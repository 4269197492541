import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { useT } from '@transifex/react';

import { Button } from '../../common/Button';

import { TextInput } from '../../FormElements/TextInput';

import { newBudgetAllocationSchema } from './validationSchema';

import s from './NewBudgetAllocation.module.scss';
import { constructTestId } from '../../../utils/test-ids';

const NewBudgetAllocation = ({
  brandInfo,
  watchIncentive,
  handleSubmitNewBudgetAllocation,
  customButtonText,
  'data-testid': SECTION,
}) => {
  const t = useT();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      netCashReward: watchIncentive.netCashReward,
      weAre8Fee: watchIncentive.weAre8Fee,
      charityDonation: watchIncentive.charityDonation,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(newBudgetAllocationSchema),
  });

  const watchNetCashReward = useWatch({ control, name: 'netCashReward' });
  const watchWeAre8Fee = useWatch({ control, name: 'weAre8Fee' });
  const watchCharityDonation = useWatch({ control, name: 'charityDonation' });

  useEffect(() => {
    reset({
      netCashReward: watchIncentive.netCashReward,
      weAre8Fee: watchIncentive.weAre8Fee,
      charityDonation: watchIncentive.charityDonation,
    });
  }, [reset, watchIncentive]);

  return (
    <div>
      <div className={s['modal-text']}>
        {t('Customize the distribution of your funds in the fields below.')}
      </div>
      <div className={s['modal-input__title']}>{t('Influencer cash reward')}</div>
      <TextInput
        type="text"
        id="netCashReward"
        name="netCashReward"
        label={t('ENTER {currencySymbol} AMOUNT', {
          currencySymbol: brandInfo.country.currencySymbol,
        })}
        watchValue={watchNetCashReward}
        register={register}
        error={errors.netCashReward}
        data-testid={constructTestId(SECTION, 'influencer-cash-reward')}
      />
      <div className={s['modal-input__title']}>{t('WeAre8 fee')}</div>
      <TextInput
        type="text"
        id="weAre8Fee"
        name="weAre8Fee"
        label={t('ENTER {currencySymbol} AMOUNT', {
          currencySymbol: brandInfo.country.currencySymbol,
        })}
        watchValue={watchWeAre8Fee}
        register={register}
        error={errors.weAre8Fee}
        data-testid={constructTestId(SECTION, 'weare8-fee')}
      />
      <div className={s['modal-input__title']}>{t('Charity donation')}</div>
      <TextInput
        type="text"
        id="charityDonation"
        name="charityDonation"
        label={t('ENTER {currencySymbol} AMOUNT', {
          currencySymbol: brandInfo.country.currencySymbol,
        })}
        watchValue={watchCharityDonation}
        register={register}
        error={errors.charityDonation}
        data-testid={constructTestId(SECTION, 'charity-donation')}
      />
      <div className={s['modal-button']}>
        <Button
          type="button"
          callback={handleSubmit(() => handleSubmitNewBudgetAllocation(getValues()))}
          styling="primary"
          customText={customButtonText ? customButtonText : t('Confirm')}
          data-testid={constructTestId(SECTION, 'budget-alloc-submit-btn')}
        />
      </div>
    </div>
  );
};

export default NewBudgetAllocation;
