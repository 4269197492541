import { createAction, createAsyncAction } from 'eight.js.store-common';

// ASYNC

export const activateSponsorshipFromCreationAsync = createAsyncAction(
  '@@sponsorship/ACTIVATE_SPONSORSHIP_FROM_CREATION'
);

export const deactivateSponsorshipFromCreationAsync = createAsyncAction(
  '@@sponsorship/DEACTIVATE_SPONSORSHIP_FROM_CREATION'
);
export const checkUploadStatusAsync = createAsyncAction('@@sponsorship/CHECK_UPLOAD_STATUS');

export const fetchUploadStatusAsync = createAsyncAction('@@sponsorship/FETCH_UPLOAD_STATUS');

export const updateSponsorshipAsync = createAsyncAction('@@sponsorship/UPDATE_SPONSORSHIP');

export const uploadVideoAsync = createAsyncAction('@@sponsorship/UPLOAD_VIDEO');

export const uploadCoverAsync = createAsyncAction('@@sponsorship/UPLOAD_COVER');

// SYNC

export const clearSponsorship = createAction('@@sponsorship/CLEAR_SPONSORSHIP');

export const resetProgress = createAction('@@sponsorship/RESET_PROGRESS');

export const toggleActivateSponsorship = createAction('@@sponsorship/TOGGLE_SPONSORSHIP_ACTIVE');

export const uploadCoverStart = createAction('@@sponsorship/UPLOAD_COVER_START');

export const uploadVideoStart = createAction('@@sponsorship/UPLOAD_VIDEO_START');

export const uploadMedia = createAction('@@sponsorship/UPDATE_MEDIA');

export const watchUploadStop = createAction('@@sponsorship/WATCH_UPLOAD_STOP');

export const setUploadPercentage = createAction('@@sponsorship/UPDATE_UPLOAD_PERCENTAGE');

export const setActiveAd = createAction('@@sponsorship/SET_ACTIVE_AD');

export const setSponsorshipName = createAction('@@sponsorship/SET_SPONSORSHIP_NAME');

export const setSponsorshipData = createAction('@@sponsorship/SET_SPONSORSHIP_DATA');

export const setAdData = createAction('@@sponsorship/SET_AD_DATA');

export const setDataArray = createAction('@@sponsorship/SET_AD_DATA_ARRAY');

export const setLocalQuestionsTags = createAction('@@sponsorship/SET_LOCAL_QUESTIONS_TAGS');

export const setUploadingVideo = createAction('@@sponsorship/SET_UPLOADING_VIDEO');

export const setInternalFetching = createAction('@@sponsorship/SET_INTERNAL_FETCHING');

export const setUpdatingSponsorship = createAction('@@sponsorship/SET_UPDATING_SPONSORSHIP');

export const setSponsorshipIsReadOnly = createAction('@@sponsorship/SET_READ_ONLY');
