import config from '../../config';
import { sponsorshipSetConstants } from '../../constants';
import { formatToBrandsTimezone } from '../../utils/date/dateHelpers';

import { processCharity, processReportingGenders } from '../../utils/receivedData';

import {
  getUrlFragmentInfluencers,
  processReportingLocations,
  processReportingOs,
} from '../../utils/reporting';

export class AnalyticsService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  formatUrlBasedOnType = (type, baseUrl, campaignId, adSetId, adId) => {
    let url = '';
    switch (type) {
      case 'campaign':
        url = `${baseUrl}/campaign/${campaignId}`;
        break;
      case 'ad-set':
        url = `${baseUrl}/sponsorshipset/${adSetId}`;
        break;
      case 'ad':
        url = `${baseUrl}/sponsorship/${adId}`;
        break;
      default:
        break;
    }
    return url;
  };

  fetchAcceptanceOverTime = async ({
    campaignId,
    adSetId,
    adId,
    type,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    brandId,
  }) => {
    const baseUrl = `${config.analyticsAPI}/watchandrespond/reporting/acceptance-over-time`;
    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url,
      method: 'POST',
      body: {
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      },
      useCustomToken: true,
      newVersionApi: '2.0',
    });

    return response.data;
  };

  fetchAnalyticsCampaign = async (campaignId) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/v3/campaigns/campaign/${campaignId}`,
      useCustomToken: true,
    });

    const { id, createdDate, name, brandLogoKey, charityName, sponsorshipsSets } = response.data;

    return {
      id,
      createdDate,
      name,
      brandLogoKey,
      charityName,
      sponsorshipsSets,
    };
  };

  fetchAnalyticsSponsorship = async (adId) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns/sponsorships/${adId}`,
      method: 'GET',
      useCustomToken: true,
    });

    const {
      id,
      name,
      videoHlsUri,
      coverTileUri,
      detail,
      cta,
      smsMessage,
      playImpressionUrl,
      videoCompletionUrl,
      videoKey,
      videoWidth,
      videoHeight,
    } = response.data;

    return {
      id,
      name,
      adCover: coverTileUri || '',
      adVideo: videoHlsUri || '',
      receivedQuestions: !!detail,
      smsMessage,
      impressionsUrl: playImpressionUrl || '',
      completionUrl: videoCompletionUrl || '',
      ctaCover: cta?.imageUrl || '',
      ctaLink: cta?.webLink || '',
      ctaButtonText: cta?.caption || '',
      ctaTrackingScript: cta?.adScript || '',
      ctaDiscount: cta?.discountCode || '',
      trackingUrl: cta?.clickTrackingUrl || '',
      videoKey: videoKey || '',
      detail: detail || null,
      videoWidth,
      videoHeight,
    };
  };

  fetchAnalyticsSponsorshipSet = async ({ adSetId, utcString }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/brand/campaigns/sponsorshipsets/${adSetId}`,
      method: 'GET',
      useCustomToken: true,
    });

    const {
      id,
      name,
      isUniteIncentive,
      includeAnyTags,
      includeLocations,
      deviceType,
      dailyBudget,
      totalBudget,
      ageRanges,
      genders,
      cashReward,
      donationReward,
      weAre8Fee,
      pricePerMessage,
      scheduleFrom,
      scheduleTo,
      timezoneInfo,
      charityId,
      charityName,
      charityLogoUri,
      isEightPixelEnabled,
      pixelConversionEvent,
    } = response.data;

    return {
      id,
      name,
      isEightPixelEnabled,
      pixelConversionEvent,
      isUnite: isUniteIncentive ? isUniteIncentive : false,
      ageRanges: ageRanges.length > 0 ? ageRanges : [],
      gender: genders
        ? processReportingGenders(genders, sponsorshipSetConstants.genderFilteredOptions)
        : [],
      deviceType: deviceType ? processReportingOs(deviceType) : [],
      totalBudget,
      dailyBudget,
      startDate: scheduleFrom ? formatToBrandsTimezone(scheduleFrom, utcString) : '',
      endDate: scheduleTo ? formatToBrandsTimezone(scheduleTo, utcString) : '',
      locations: includeLocations ? processReportingLocations(includeLocations) : [],
      includeTags: includeAnyTags ? includeAnyTags : [],
      selectedCharity: charityId ? processCharity(charityId, charityName, charityLogoUri) : {},
      cashReward: cashReward ? cashReward : '',
      donationReward: donationReward ? donationReward : '',
      weAre8Fee: weAre8Fee ? weAre8Fee : '',
      pricePerMessage: pricePerMessage ? pricePerMessage : '',
      timezoneInfo,
    };
  };

  fetchDemographics = async ({
    type,
    campaignId,
    adSetId,
    adId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    activity,
    responseFilter,
    brandId,
  }) => {
    const baseUrl = `${config.analyticsAPI}/watchandrespond/reporting/demographics`;
    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url,
      method: 'POST',
      body: {
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        activity,
        responseFilter,
        brandId,
      },
      useCustomToken: true,
    });

    return response.data.data;
  };

  fetchHeatMap = async ({ type, campaignId, adSetId, adId, brandId }) => {
    const baseUrl = `${config.analyticsAPI}/watchandrespond/reporting/heatmap`;
    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url,
      method: 'POST',
      body: {
        brandId,
      },
      useCustomToken: true,
    });

    return response.data.data;
  };

  fetchDataForCSV = async ({
    type,
    campaignId,
    adSetId,
    adId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    paginationId,
    csvDataType,
    brandId,
  }) => {
    const urlFragmentInfluencers = getUrlFragmentInfluencers(csvDataType);
    const query =
      csvDataType === 'fullReport'
        ? `?includeAnswersEvents=true&paginationId=${paginationId}`
        : `?paginationId=${paginationId}`;
    let unitName, unitId;
    switch (type) {
      case 'campaign':
        unitName = 'campaign';
        unitId = campaignId;
        break;
      case 'ad-set':
        unitName = 'sponsorshipset';
        unitId = adSetId;
        break;
      case 'ad':
        unitName = 'sponsorship';
        unitId = adId;
        break;
      default:
        break;
    }

    const url = `${config.analyticsAPI}/watchandrespond/reporting/${urlFragmentInfluencers}/${unitName}/${unitId}${query}`;

    const response = await this.fetchService.fetch({
      url,
      method: 'POST',
      body: {
        url,
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      },
      newVersionApi: '2.0',
      useCustomToken: true,
    });

    return response.data;
  };

  fetchAnsweredQuestionDataForCSV = async ({
    adId,
    questionId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    brandId,
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.analyticsAPI}/watchandrespond/reporting/influencers-data/${adId}/answered-question/${questionId}`,
      method: 'POST',
      body: {
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      },
      useCustomToken: true,
    });

    let data = response.data;

    // Keep only desired fields
    let res = data.Data.map(
      ({ QuestionText, Response, City, Region, Country, Gender, Age, Device }) => ({
        QuestionText: QuestionText,
        Response: Response,
        City: City,
        Region: Region,
        Country: Country,
        Gender: Gender,
        Age: Age,
        Device: Device,
      })
    );

    data.Data = res;

    return data;
  };

  fetchResponses = async ({
    type,
    campaignId,
    adSetId,
    adId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    brandId,
  }) => {
    const baseUrl = `${config.analyticsAPI}/watchandrespond/reporting/responses`;
    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url,
      method: 'POST',
      body: {
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      },
      useCustomToken: true,
    });

    return response.data.data;
  };

  fetchShortAnswerResponses = async ({
    type,
    campaignId,
    adSetId,
    adId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    brandId,
  }) => {
    const baseUrl = `${config.analyticsAPI}/watchandrespond/reporting/shortanswers`;
    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url,
      method: 'POST',
      body: {
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      },
      useCustomToken: true,
    });

    return response.data.data;
  };

  fetchTotals = async ({
    type,
    campaignId,
    adSetId,
    adId,
    gender,
    platform,
    city,
    fromAge,
    toAge,
    fromDate,
    toDate,
    brandId,
  }) => {
    const baseUrl = `${config.analyticsAPI}/watchandrespond/reporting/totals`;
    const url = this.formatUrlBasedOnType(type, baseUrl, campaignId, adSetId, adId);
    const response = await this.fetchService.fetch({
      url,
      method: 'POST',
      body: {
        gender,
        platform,
        city,
        fromAge,
        toAge,
        fromDate,
        toDate,
        brandId,
      },
      useCustomToken: true,
    });

    return response.data;
  };

  fetchSponsorshipSetBySponsorshipId = async ({ sponsorshipId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns/sponsorshipsets/sponsorship/${sponsorshipId}`,
      method: 'GET',
      useCustomToken: true,
    });

    return response.data;
  };

  fetchCSVCompletionStatus = async (requestId) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/generated-reports/${requestId}`,
      method: 'GET',
    });

    return response.data;
  };

  fetchPerformanceReportingCSV = async ({ body, csvDataType, type }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v2/generated-reports/${type === 'ad-set' ? 'adset' : type}`,
      body,
      method: 'POST',
    });

    return response.data;
  };
}
