import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from '../../components/Layout';
import { HomeContent } from '../../components/PagesContent/HomeContent';

import { removeFromLocalStorage } from '../../utils/localStorage';

import { brandInfoSelector } from '../../store/brand';
import { clearSponsorshipSet } from '../../store/campaign/sponsorshipSet';
import { clearSponsorship } from '../../store/campaign/sponsorship';
import { clearSummary } from '../../store/campaign/summary';
import { clearCampaignDraft } from '../../store/campaign/campaign';
import { clearState } from '../../store/data';

import { mainMenu, URIs } from '../../constants';
import { Modal } from '../../components/Modal';
import ReactPlayer from 'react-player';
import useRouter from '../../hooks/useRouter/useRouter';

const Home = () => {
  const dispatch = useDispatch();
  const { history } = useRouter();

  const brand = useSelector(brandInfoSelector);

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  const { items } = mainMenu;
  const tilesNavigation = items.filter((navItem) => navItem.name !== 'Home');

  useEffect(() => {
    dispatch(clearCampaignDraft());
    dispatch(clearSponsorshipSet());
    dispatch(clearSponsorship());
    dispatch(clearSummary());
    dispatch(clearState());

    removeFromLocalStorage('campaignId');
    removeFromLocalStorage('sponsorshipSetId');
    removeFromLocalStorage('sponsorshipId');
  }, [brand.brandName, brand.logoUri, dispatch]);

  useEffect(() => {
    if (history?.location?.state?.from === 'signup') setVideoModalOpen(true);
  }, [history?.location?.state?.from]);

  return (
    <Layout>
      <HomeContent brand={brand} navItems={tilesNavigation} />
      {videoModalOpen && (
        <Modal noButtons withCloseIcon isVideo closeModal={() => setVideoModalOpen(false)}>
          <ReactPlayer
            volume={1}
            controls={true}
            playing={true}
            height="100%"
            width="100%"
            url={URIs[process.env.NODE_ENV]}
          />
        </Modal>
      )}
    </Layout>
  );
};

export default Home;
