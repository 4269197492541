import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SignUpContent } from '../../components/PagesContent/SignUpContent';
import { Spinner } from '../../components/Spinner';

import {
  brandInfoSelector,
  clearData,
  fetchCountriesAsync,
  setBrandDataArray,
} from '../../store/brand';

import { authInternalFetchingSelector } from '../../store/auth';
import { modalsSelector, toggleModalByName } from '../../store/ui';

const SignUp = () => {
  const brandInfo = useSelector(brandInfoSelector);
  const authFetching = useSelector(authInternalFetchingSelector);
  const modals = useSelector(modalsSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearData());
    dispatch(fetchCountriesAsync.request());
  }, [dispatch]);

  const handleSelectField = useCallback(
    (name) => (value) => {
      dispatch(setBrandDataArray({ name, value }));
    },
    [dispatch]
  );

  return (
    <>
      {authFetching && <Spinner />}
      <SignUpContent
        brandInfo={brandInfo}
        handleSelectField={handleSelectField}
        modals={modals}
        toggleModalByName={(val) => dispatch(toggleModalByName(val))}
      />
    </>
  );
};

export default SignUp;
