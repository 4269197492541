import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { useT } from '@transifex/react';

import { SectionTitle } from '../../../common/Titles/SectionTitle';
import { SectionSubtitle } from '../../../common/Titles/SectionSubtitle';
import { UploadZone } from '../../../common/UploadZone';
import { Button } from '../../../common/Button';
import { ErrorMessage } from '../../../common/ErrorMessage';

import { FormGroup } from '../../../FormElements/FormGroup';
import { TextInput } from '../../../FormElements/TextInput';

import { ModalCrop } from '../../../ModalCrop';

import { CustomSelect } from '../../../FormElements/Selects/CustomSelect';

import { validationSchemaWithBrandAddress } from './validationSchema';

import { base64StringToFile, extractImageFileExtension } from '../../../../utils/media';

import s from './AccountContent.module.scss';
import { updateAccountInfo } from '../../../../store/brand';
import { useDispatch } from 'react-redux';
import { toggleModalByName } from '../../../../store/ui';
import Radio from '../../../FormElements/Radio/Radio';

import { FileSpecifications } from '../../../common/FileSpecifications';
import { brandConstants, campaignConstants } from '../../../../constants';
import Tooltip from '../../../common/Tooltip/Tooltip';
import Modal from '../../../Modal/Modal';
import { getVerificationIcon, getVerificationText } from '../../../../utils/settingsBilling';

const cx = classNames.bind(s);

const AccountContent = ({
  handleDefaultCountry,
  handleSelectCountry,
  brandInfo,
  handleChangeBrandData,
  modals,
  handleChangeBrandPhoneNumber,
}) => {
  const t = useT();

  const [processingBrandLogo, setProccessingBrandLogo] = useState('');
  const [currentBrandLogoUri, setCurrentBrandLogoUri] = useState('');

  const isBrandVerified = useMemo(
    () => brandInfo?.accountStatus === 'verified',
    [brandInfo?.accountStatus]
  );

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    defaultValues: {
      brandName: brandInfo.brandName,
      website: brandInfo.website,
      country: brandInfo.country,
      // timeZone: brandInfo.timeZone,
      emailAddress: brandInfo.emailAddress,
      brandLogoUri: brandInfo.logoUri,
      brandCountryCode: brandInfo.brandCountryCode,
      billingEmail: brandInfo.billingEmail,
      legalName: brandInfo.legalName,
      brandType: brandInfo.accountType,
      addressLineFirst: brandInfo?.addressLineFirst,
      addressLineSecond: brandInfo?.addressLineSecond,
      city: brandInfo?.city,
      postCode: brandInfo?.postCode,
      VATReceipt: brandInfo?.vatReceiptUri,
      VATFile: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchemaWithBrandAddress),
  });

  const watchBrandName = useWatch({ control, name: 'brandName' });
  const watchWebsite = useWatch({ control, name: 'website' });
  const watchEmailAddress = useWatch({ control, name: 'emailAddress' });
  const watchBrandCountryCode = useWatch({ control, name: 'brandCountryCode' });
  const watchBillingEmail = useWatch({ control, name: 'billingEmail' });
  const watchLegalName = useWatch({ control, name: 'legalName' });
  const watchAddressLineFirst = useWatch({ control, name: 'addressLineFirst' });
  const watchAddressLineSecond = useWatch({ control, name: 'addressLineSecond' });
  const watchCity = useWatch({ control, name: 'city' });
  const watchPostCode = useWatch({ control, name: 'postCode' });
  const watchBrandType = useWatch({ control, name: 'brandType' });
  const watchCountry = useWatch({ control, name: 'country' });
  const watchVATReceipt = useWatch({ control, name: 'VATReceipt' });

  const cropperRef = useRef(null);

  useEffect(() => {
    reset({
      brandName: brandInfo.brandName,
      website: brandInfo.website,
      country: brandInfo.country,
      emailAddress: brandInfo.emailAddress,
      // timeZone: brandInfo.timeZone,
      brandCountryCode: brandInfo.brandCountryCode,
      billingEmail: brandInfo.billingEmail,
      legalName: brandInfo.legalName,
      brandType: brandInfo.accountType,
      addressLineFirst: brandInfo?.addressLineFirst,
      addressLineSecond: brandInfo?.addressLineSecond,
      city: brandInfo?.city,
      postCode: brandInfo?.postCode,
      VATReceipt: brandInfo?.vatReceiptUri,
      VATFile: '',
    });

    setCurrentBrandLogoUri(brandInfo.logoUri);
  }, [
    reset,
    brandInfo.logoUri,
    brandInfo.logoFile,
    brandInfo.brandName,
    brandInfo.website,
    brandInfo.country,
    brandInfo.emailAddress,
    brandInfo.password,
    brandInfo.confirmPassword,
    brandInfo.signupCode,
    brandInfo?.addressLineFirst,
    brandInfo?.addressLineSecond,
    brandInfo.billingEmail,
    brandInfo.brandCountryCode,
    brandInfo.accountType,
    brandInfo?.city,
    brandInfo.legalName,
    brandInfo?.postCode,
    brandInfo.VATReceipt,
    brandInfo?.vatReceiptUri,
  ]);

  const handleCropImage = useCallback(() => {
    const cropper = cropperRef?.current.cropper;

    const cropperData = cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8);
    const fileExtension = extractImageFileExtension(cropperData);
    const fileName = `brandLogo${brandInfo.brandName}-cropped.${fileExtension}`;
    const croppedFile = base64StringToFile(cropperData, fileName);
    const croppedUrl = URL.createObjectURL(croppedFile);

    setValue('logoFile', croppedFile);
    setValue('logoUri', croppedUrl);

    setCurrentBrandLogoUri(croppedUrl);

    dispatch(toggleModalByName({ name: 'crop', value: false }));
  }, [dispatch, cropperRef, brandInfo.brandName, setValue]);

  const handleBrandLogoChange = useCallback(
    (acceptedFiles) => {
      const logoUri = URL.createObjectURL(acceptedFiles[0]);

      setProccessingBrandLogo(logoUri);

      dispatch(toggleModalByName({ name: 'crop', value: true }));
    },
    [dispatch]
  );

  const handleCloseCropModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'crop', value: false }));
  }, [dispatch]);

  const handleChangeVATReceipt = useCallback(
    (acceptedFiles) => {
      const blob = acceptedFiles[0].slice(0, acceptedFiles[0].size, acceptedFiles[0].type);
      const correctFileName = acceptedFiles[0].name
        .replace(/[&\\/\\#,+()$~%'":*?<>{}]/g, '')
        .toLowerCase();
      const newFile = new File([blob], correctFileName, { type: acceptedFiles[0].type });

      const vatReceipt = URL.createObjectURL(newFile);
      const vatFile = newFile;

      setValue('VATReceipt', vatReceipt);
      setValue('VATFile', vatFile);
    },
    [setValue]
  );

  const handleCloseBusinessSettingsWarningModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'businessSettingsWarning', value: false }));
  }, [dispatch]);

  const areAllRequiredBusinessDetailsFilled = useMemo(
    () =>
      watchBrandCountryCode &&
      watchBillingEmail &&
      watchLegalName &&
      watchAddressLineFirst &&
      watchCity &&
      watchPostCode &&
      watchBrandType &&
      watchVATReceipt,
    [
      watchAddressLineFirst,
      watchBillingEmail,
      watchBrandCountryCode,
      watchBrandType,
      watchCity,
      watchLegalName,
      watchPostCode,
      watchVATReceipt,
    ]
  );

  const handleSubmitAccountInfo = useCallback(() => {
    dispatch(toggleModalByName({ name: 'businessSettingsWarning', value: false }));
    dispatch(
      updateAccountInfo.request({
        form: getValues(),
        isCampainActivation: false,
        saveBusinessSettings: !isBrandVerified,
      })
    );
  }, [dispatch, getValues, isBrandVerified]);

  const submitHandler = useCallback(() => {
    if (areAllRequiredBusinessDetailsFilled) handleSubmitAccountInfo();
    else dispatch(toggleModalByName({ name: 'businessSettingsWarning', value: true }));
  }, [areAllRequiredBusinessDetailsFilled, dispatch, handleSubmitAccountInfo]);

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className={s['account-info']}>
          <SectionTitle>{t('Account Info')}</SectionTitle>

          <div className={s['account-info__section']}>
            <FormGroup
              title={t('Brand Logo')}
              subtitle={t('Upload or edit your brand logo')}
              required
            >
              <div className={s['account-info__logo-inner']}>
                <Controller
                  control={control}
                  name="logoUri"
                  render={({ field: { ref } }) => {
                    return (
                      <UploadZone
                        inputRef={ref}
                        styleType="circle"
                        name="logoUri"
                        control={control}
                        type="image"
                        error={errors.logoUri}
                        handleChange={handleBrandLogoChange}
                        contentType="image/jpeg, image/png"
                        fileUrl={currentBrandLogoUri}
                      />
                    );
                  }}
                />
                <div className={s['account-info__logo-inner__specs']}>
                  <FileSpecifications fileSpecs={campaignConstants.brandLogoFileSpecs} />
                </div>
              </div>
            </FormGroup>
          </div>

          <div className={s['account-info__section']}>
            <FormGroup title={t('Brand Name')} required>
              <TextInput
                type="text"
                id="brand-name"
                name="brandName"
                label={t('BRAND NAME')}
                watchValue={watchBrandName}
                register={register}
                error={errors.brandName}
                withIcon
                icon={
                  <Tooltip
                    customClass="action"
                    content={getVerificationText(brandInfo?.accountStatus)}
                    customIcon={getVerificationIcon(brandInfo?.accountStatus)}
                  ></Tooltip>
                }
              />
            </FormGroup>
          </div>

          <div className={s['account-info__section']}>
            <FormGroup title={t('Brand Website')} required>
              <TextInput
                type="text"
                id="brand-website"
                name="website"
                label={t('BRAND WEBSITE')}
                watchValue={watchWebsite}
                register={register}
                error={errors.website}
              />
            </FormGroup>
          </div>

          <div className={s['account-info__section']}>
            <FormGroup
              title={t('Country')}
              sideTitle={
                <div className={s['currency']}>
                  {t('Currency')}:
                  <span className={s['currency-code']}>
                    {brandInfo?.country?.currencySymbol}
                    {brandInfo?.country?.currencyCode}
                  </span>
                </div>
              }
              required
            >
              <CustomSelect
                control={control}
                watchValue={watchCountry}
                name="country"
                defaultValue={handleDefaultCountry}
                options={brandInfo?.fetchedCountries}
                handleSelect={handleSelectCountry('country')}
                disabled
              />
            </FormGroup>
          </div>

          <div className={s['account-info__section']}>
            <FormGroup title={t('Email')} required>
              <TextInput
                type="email"
                id="brand-email"
                name="emailAddress"
                label={t('BRAND EMAIL')}
                watchValue={watchEmailAddress}
                register={register}
                error={errors.emailAddress}
              />
            </FormGroup>
          </div>
        </div>
        <div className={s['account-info']}>
          <SectionTitle>{t('Business Details')}</SectionTitle>
          <SectionSubtitle>
            {t('Complete all the fields below to be able to publish  campaigns')}
          </SectionSubtitle>
          <div
            className={cx(s['account-info__section-checkboxes'], {
              [s['account-info__section-checkboxes--error']]: errors?.brandType?.message,
            })}
          >
            <FormGroup title={t('Brand Type')}>
              <div className={s['account-info__section-checkboxes-checkbox']}>
                <Radio
                  label={t('Brand')}
                  shape="big"
                  name="brandType"
                  value={'brand'}
                  checked={watchBrandType}
                  onChange={(e) => setValue('brandType', e.target.value)}
                  register={register}
                  disabled={isBrandVerified}
                />
              </div>
              <div className={s['account-info__section-checkboxes-checkbox']}>
                <Radio
                  label={t('Registered Charity')}
                  shape="big-circ"
                  name="brandType"
                  value="registered_charity"
                  checked={watchBrandType}
                  onChange={(e) => setValue('brandType', e.target.value)}
                  register={register}
                  disabled={isBrandVerified}
                />
              </div>
              {errors.brandType && <ErrorMessage error={errors.brandType.message} />}
            </FormGroup>
          </div>
          <div className={s['account-info__section']}>
            <FormGroup title={t('Legal Name')}>
              <TextInput
                type="text"
                id="brand-legal-name"
                name="legalName"
                label={t('LEGAL NAME')}
                watchValue={watchLegalName}
                register={register}
                error={errors.legalName}
                disabled={isBrandVerified}
              />
            </FormGroup>
          </div>
          <div className={s['account-info__section']}>
            <FormGroup title={t('Billing Email Address')}>
              <TextInput
                type="email"
                id="brand--biiling-email"
                name="billingEmail"
                label={t('BILLING EMAIL ADDRESS')}
                watchValue={watchBillingEmail}
                register={register}
                error={errors.billingEmail}
                disabled={isBrandVerified}
              />
            </FormGroup>
          </div>
          <div className={s['account-info__section']}>
            <FormGroup title={t('Brand Address')}>
              <TextInput
                type="text"
                id="brand-addressLineFirst"
                name="addressLineFirst"
                label={t('ADDRESS LINE 1')}
                watchValue={watchAddressLineFirst}
                register={register}
                error={errors.addressLineFirst}
                disabled={isBrandVerified}
              />
              <TextInput
                type="text"
                id="brand-addressLineSecond"
                name="addressLineSecond"
                label={t('ADDRESS LINE 2 (OPTIONAL)')}
                watchValue={watchAddressLineSecond}
                register={register}
                error={errors.addressLineSecond}
                customInputFieldClass={s['account-info__section-input']}
                disabled={isBrandVerified}
              />
              <TextInput
                type="text"
                id="brand-city"
                name="city"
                label={t('TOWN/CITY')}
                watchValue={watchCity}
                register={register}
                customInputFieldClass={s['account-info__section-input']}
                error={errors.city}
                disabled={isBrandVerified}
              />
              <TextInput
                type="text"
                id="brand-postcode"
                name="postCode"
                label={t('POSTCODE')}
                watchValue={watchPostCode}
                register={register}
                customInputFieldClass={s['account-info__section-input']}
                error={errors.postCode}
                disabled={isBrandVerified}
              />
              <TextInput
                type="text"
                id="brand-country-code"
                name="brandCountryCode"
                label={t('COUNTRY CODE (US, GB, etc.)')}
                watchValue={watchBrandCountryCode}
                register={register}
                customInputFieldClass={s['account-info__section-input']}
                error={errors.brandCountryCode}
                disabled={isBrandVerified}
              />
            </FormGroup>
          </div>

          {/* TODO Hide in task 4345
            maybe in future it will need
        */}
          {/* <div className={s['account-info__section']}>
          <FormGroup
            title='Time Zone'
            subtitle='Enter your Time Zone'
          >
            <CustomSelect
              control={control}
              name='timeZone'
              defaultValue={brandInfo.timeZone}
              options={brandInfo.country?.timezones}
              handleSelect={handleSelectField('timeZone')}
              error={errors.timeZone?.name}
            />
          </FormGroup>
        </div> */}

          <div className={s['account-info__section']}>
            <FormGroup
              title={t('Proof of registration document/Pre Approved Verification Document')}
              subtitle={t(`Upload document as proof of ID`)}
            >
              <div className={s['vat-inner']}>
                <div className={s['vat-inner__upload']}>
                  <UploadZone
                    styleType="small-square"
                    name="VATReceipt"
                    control={control}
                    type="file"
                    error={errors.campaignBrandLogoUri}
                    handleChange={handleChangeVATReceipt}
                    contentType="image/jpeg, image/png, application/pdf"
                    fileUrl={watchVATReceipt}
                    disabled={isBrandVerified}
                  />
                </div>
                <div className={s['vat-inner__specs']}>
                  <FileSpecifications fileSpecs={brandConstants.VATReceiptFileSpecs} />
                </div>
              </div>
            </FormGroup>
          </div>
          <div className={s['account-info__button']}>
            <Button customText={t('Save Changes')} type="submit" styling="primary" />
          </div>
          {modals.crop.isOpen && (
            <ModalCrop
              imageUri={processingBrandLogo}
              cropperRef={cropperRef}
              actionCallback={handleCropImage}
              closeCallback={handleCloseCropModal}
            />
          )}

          {modals.businessSettingsWarning.isOpen && (
            <Modal
              closeModal={handleCloseBusinessSettingsWarningModal}
              cancelCallback={handleCloseBusinessSettingsWarningModal}
              actionCallback={handleSubmitAccountInfo}
              customButtonText={t('Continue')}
              customButtonCancelText={t('Complete your business details')}
              customButtonCancelStyle="blue-text"
              title={t('Warning')}
              children={t(
                'You haven’t completed all required fields. If you continue you can navigate the platform but you will not be able to publish campaigns.'
              )}
              withCloseIcon
            />
          )}
        </div>
      </form>
    </>
  );
};

export default AccountContent;
