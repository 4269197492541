import React from 'react';
import { Layout } from '../../../components/Layout';
import { PixelDocContent } from '../../../components/PagesContent/SettingsContent/PixelDocContent';

function SettingsPixel() {
  return (
    <Layout>
      <PixelDocContent />
    </Layout>
  );
}

export default SettingsPixel;
