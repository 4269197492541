import { call, put, takeLatest, select } from 'redux-saga/effects';

import { container } from '../../../container';

import {
  setAllModulesActivated,
  activateCampaignFromBilling,
  updateBrandInfoFromCampaignSummaryAsync,
  publishCampaignAsync,
  activateAllAsync,
  setIsPublishing,
} from './actions';

import { setCampaignData, setCampaignIsReadOnly, toggleActivateCampaign } from '../campaign';

import {
  setSponsorshipSetData,
  setSponsorshipSetIsReadOnly,
  toggleActivateSponsorshipSet,
} from '../sponsorshipSet';

import {
  setAdData,
  setSponsorshipIsReadOnly,
  sponsorshipCreateSelector,
  toggleActivateSponsorship,
} from '../sponsorship';

import { setModalContent, setModalText, toggleModalByName } from '../../ui';

import { updateBrandInfoFromBillingAsync } from '../../brand';

import { textsConstants } from '../../../constants';
import { campaignSelector, setCampaignIsPublished, setUnpublishedDifferences } from '../../data';
import { toast } from 'react-toastify';
import { t } from '@transifex/native';

const sponsorshipService = container.get('SponsorshipService');

function* activateAllModulesSaga() {
  try {
    const { id: campaignId } = yield select(campaignSelector);
    const { moderationStatus } = yield select(sponsorshipCreateSelector);

    yield call(sponsorshipService.publishCampaign, {
      campaignId,
    });

    yield put(activateAllAsync.success());

    if (moderationStatus !== 'In Review' || moderationStatus !== 'Approved') {
      yield put(
        setModalContent({
          name: 'summary',
          content: {
            title: 'Success',
            text: t(textsConstants.publishSuccess),
          },
        })
      );
      yield put(toggleModalByName({ name: 'summary', value: true }));
      yield put(setAdData({ name: 'deliveryStatus', value: 'In review' }));
      yield put(setAdData({ name: 'moderationStatus', value: 'In review' }));
    }

    yield put(toggleActivateCampaign(true));
    yield put(setCampaignIsReadOnly(true));
    yield put(setCampaignData({ name: 'deliveryStatus', value: 'In review' }));
    yield put(setCampaignData({ name: 'moderationStatus', value: 'In review' }));

    yield put(toggleActivateSponsorshipSet(true));
    yield put(setSponsorshipSetIsReadOnly(true));
    yield put(setSponsorshipSetData({ name: 'deliveryStatus', value: 'In review' }));
    yield put(setSponsorshipSetData({ name: 'moderationStatus', value: 'In review' }));

    yield put(toggleActivateSponsorship(true));
    yield put(setSponsorshipIsReadOnly(true));

    yield put(setAllModulesActivated(true));
  } catch (error) {
    yield put(
      setModalText({
        name: 'errors',
        text: `- ${error?.response?.data?.message}`,
      })
    );
    yield put(toggleModalByName({ name: 'errors', value: true }));
    yield put(activateAllAsync.failure(error));
    yield put(setAllModulesActivated(false));
  }
}

function* publishCampaignSaga({ payload }) {
  try {
    yield put(setIsPublishing(true));

    // const { id: campaignId } = yield select(campaignSelector);
    const campaignId = payload;

    yield call(sponsorshipService.publishCampaign, {
      campaignId,
    });

    yield put(publishCampaignAsync.success());
    yield put(setIsPublishing(false));

    yield put(
      setModalContent({
        name: 'success',
        title: 'Success',
        text: t(textsConstants.publishSuccess),
        withoutCancel: true,
      })
    );

    yield put(setUnpublishedDifferences([]));
    yield put(setCampaignIsPublished());
    toast.dismiss();

    yield put(toggleModalByName({ name: 'success', value: true }));
  } catch (error) {
    yield put(setIsPublishing(false));
    if (error?.response?.data.code === 100) {
      yield put(
        setModalContent({
          name: 'summary',
          content: {
            title: 'Info',
            text: `There are no new changes on your Ad sets or Ads that need to be published.`,
            withoutCancel: true,
          },
        })
      );
      yield put(toggleModalByName({ name: 'summary', value: true }));
    }
    if (error?.response?.data.code === 60) {
      yield put(
        setModalContent({
          name: 'summary',
          content: {
            title: 'Error',
            text: `Your available balance is not enough to deliver the minimum number of ads.`,
            withoutCancel: true,
          },
        })
      );
      yield put(toggleModalByName({ name: 'summary', value: true }));
    }
    if (error?.response?.data.code === 110) {
      yield put(
        setModalContent({
          name: 'summary',
          content: {
            title: 'Error',
            text: error?.response?.data.message,
            withoutCancel: true,
          },
        })
      );
      yield put(toggleModalByName({ name: 'summary', value: true }));
    }

    yield put(publishCampaignAsync.failure(error));
  }
}

function* updateBrandInfoFromCampaignSummarySaga({ payload }) {
  try {
    yield put(updateBrandInfoFromBillingAsync.request({ ...payload, isCampainActivation: true }));
  } catch {
    yield put(updateBrandInfoFromCampaignSummaryAsync.failure());
  }
}

export function* summaryActionWatcher() {
  yield takeLatest(publishCampaignAsync.request.type, publishCampaignSaga);
  yield takeLatest(activateCampaignFromBilling, activateAllModulesSaga);
  yield takeLatest(
    updateBrandInfoFromCampaignSummaryAsync.request.type,
    updateBrandInfoFromCampaignSummarySaga
  );
}
