import sponsorshipSetConstants from '../sponsorshipSet/sponsorshipSet-data';

import { v4 as uuidv4 } from 'uuid';

const campaignConstants = Object.freeze({
  initialState: {
    id: uuidv4(),
    adSets: [sponsorshipSetConstants.initialState],
    isCreated: false,
    isDirty: false,
    isActive: false,
    isDraft: true,
    isReadOnly: false,
    isArchive: false,
    deliveryStatus: 'Inactive',
    moderationStatus: 'Unknown',
    name: 'New Campaign',
    campaignBrandName: '',
    campaignBrandLogoUri: '',
    campaignBrandLogoFile: {},
    processingBrandLogo: '',
    processingBrandLogoFile: {},
    category: 'brand_awareness',
    previousCampaignNames: [],
    selectedCharity: null,
  },
  objectives: [
    {
      title: 'Brand Awareness',
      value: 'brand_awareness',
      subtitle: 'Increase awareness of your brand or product',
      icon: 'horn',
    },
    {
      title: 'Conversions',
      value: 'conversions',
      subtitle: 'Drive valuable actions on your website',
      icon: 'arrow-top-left',
    },
    {
      title: 'Video Views',
      value: 'video_views',
      subtitle: 'Increase views for your video',
      icon: 'media',
    },
    {
      title: 'App Installs',
      value: 'app_installs',
      subtitle: 'Drive installs for your mobile app',
      icon: 'phone',
    },
    {
      title: 'Traffic',
      value: 'traffic',
      subtitle: 'Drive traffic to your website',
      icon: 'people',
    },
  ],
  brandLogoFileSpecs: [
    {
      name: 'File Type',
      values: '.jpg, .png',
      icon: 'file-type',
    },
    {
      name: 'Resolution',
      values: 'minimum width: 300p',
      icon: 'resolution',
    },
    {
      name: 'Aspect Ratio',
      values: '1:1',
      icon: 'aspect-ratio',
    },
  ],
  fieldNames: [
    {
      value: 'name',
      label: 'Campaign Name',
    },
    {
      value: 'campaignBrandLogoUri',
      label: 'Brand Logo',
    },
    {
      value: 'campaignBrandName',
      label: 'Brand Name',
    },
    {
      value: 'category',
      label: 'Objective',
    },
    {
      value: 'selectedCharity',
      label: 'Charity',
    },
  ],
  defaultCharityId: {
    gb: { id: '99fc0eb6-eb28-4515-92c0-b941e911d84c' },
    au: { id: 'fb80fdd2-c4c5-4146-8440-604abba299a0' },
    us: { id: '34df7d84-d5c9-4e60-b20c-55881e4343f7' },
    nz: { id: '44c8408d-3572-4c6a-b6ac-ca8f789278d0' },
  },
});

export default campaignConstants;
