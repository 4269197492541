import React from 'react';
import CheckboxesPlaceholder from '../../../components/Placeholders/CheckboxesPlaceholder.js/CheckboxesPlaceholder.js';
import InputPlaceholder from '../../../components/Placeholders/InputPlaceholder/InputPlaceholder.js';
import { SectionTitlePlaceholder } from '../../../components/Placeholders/SectionTitlePlaceholder.js';
import UploadPlaceholder from '../../../components/Placeholders/UploadPlaceholder/UploadPlaceholder.js';
import Placeholder from '../Placeholder';

function AccountInfoPlaceholder() {
  return (
    <Placeholder gap="50px">
      <SectionTitlePlaceholder />
      <UploadPlaceholder type="circle" />
      <InputPlaceholder />
      <InputPlaceholder />
      <InputPlaceholder />
      <InputPlaceholder />
      <SectionTitlePlaceholder />
      <CheckboxesPlaceholder items={[...Array(2)]} />
      <InputPlaceholder />
      <InputPlaceholder />
      <InputPlaceholder />
      <UploadPlaceholder type="square" />
    </Placeholder>
  );
}

export default AccountInfoPlaceholder;
