import React, { useCallback, useMemo } from 'react';
import s from './MenuOptionsModal.module.scss';
import SvgIcon from '../SvgIcon/SvgIcon';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { generatePath, useRouteMatch } from 'react-router';
import { routes } from '../../../constants';
import { useRouter } from '../../../hooks/useRouter';
import { useT } from '@transifex/react';

function MenuOptionsModal({
  options,
  modalYPosition,
  handleModalShow,
  modalRef,
  adId,
  adSetId,
  items = [],
  createFunction,
  deleteFunction,
  duplicateFunction,
  campaignId,
  type,
  isDisabled,
  unitIsApproved,
  campaign,
}) {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const match = useRouteMatch(
    type === 'adSet' ? routes.SPONSORSHIP_SET_EDIT : routes.SPONSORSHIP_EDIT
  );
  const isCurrentUnitOpen = useMemo(() => {
    if (type === 'adSet' && match?.params.ssid === adSetId) return true;
    if (type === 'ad' && match?.params.sid === adId) return true;
    return false;
  }, [type, match?.params, adId, adSetId]);
  const t = useT();

  const handleClick = useCallback(
    (action) => {
      const newId = uuidv4();
      if (action === 'create') {
        dispatch(createFunction({ adSetId, adId, newId }));
        !isDisabled &&
          push(
            type === 'campaign'
              ? generatePath(routes.SPONSORSHIP_SET_EDIT, { cid: campaignId, ssid: newId })
              : generatePath(routes.SPONSORSHIP_EDIT, {
                  cid: campaignId,
                  ssid: adSetId,
                  sid: newId,
                })
          );
      } else if (action === 'delete') {
        dispatch(deleteFunction({ adSetId, adId }));
        if (isCurrentUnitOpen)
          push(
            type === 'adSet'
              ? generatePath(routes.CAMPAIGN_EDIT, { cid: campaignId })
              : generatePath(routes.SPONSORSHIP_SET_EDIT, { cid: campaignId, ssid: adSetId })
          );
      } else if (action === 'duplicate') {
        dispatch(duplicateFunction({ adSetId, adId, newId }));
        !isDisabled &&
          push(
            type === 'adSet'
              ? generatePath(routes.SPONSORSHIP_SET_EDIT, { cid: campaignId, ssid: newId })
              : generatePath(routes.SPONSORSHIP_EDIT, {
                  cid: campaignId,
                  ssid: adSetId,
                  sid: newId,
                })
          );
      }
      handleModalShow();
    },
    [
      dispatch,
      handleModalShow,
      adSetId,
      adId,
      createFunction,
      deleteFunction,
      campaignId,
      type,
      duplicateFunction,
      push,
      isCurrentUnitOpen,
      isDisabled,
    ]
  );
  const oneItem = useMemo(() => items.length === 1, [items]);
  const oneUnsavedItem = useMemo(() => {
    if (type === 'campaign') return !campaign.isCreated;
    const adSet = items.filter((item) => item.id === adSetId)[0];
    return adSet?.ads?.length === 1 && !adSet?.ads[0]?.isCreated;
  }, [type, campaign?.isCreated, items, adSetId]);

  return (
    <motion.div
      key="modal"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.1 }}
      style={{ originX: 0, originY: 0, top: modalYPosition }}
      className={s['menu-modal']}
      ref={modalRef}
    >
      {options.map((option, index) => (
        <button
          className={
            (unitIsApproved && option.action === 'delete') ||
            (oneItem && option.action === 'delete') ||
            isDisabled ||
            (oneUnsavedItem && option.action === 'create')
              ? classNames(s['option'], s['disabled'])
              : s['option']
          }
          key={index}
          onClick={() => handleClick(option.action)}
        >
          <SvgIcon name={option.iconName} />
          <span className={s['title']}>{t(option.title)}</span>
        </button>
      ))}
    </motion.div>
  );
}

export default MenuOptionsModal;
