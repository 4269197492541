import React, { useMemo } from 'react';

import classNames from 'classnames/bind';

import s from './TabButton.module.scss';

const cx = classNames.bind(s);

const TabButton = ({ isActive, title, handleSelectTab, 'data-testid': testId }) => {
  const tabClassNames = useMemo(
    () =>
      cx({
        [s['tab']]: true,
        [s['tab-active']]: isActive,
      }),
    [isActive]
  );

  return (
    <div className={tabClassNames} onClick={handleSelectTab} data-testid={testId}>
      {title}
    </div>
  );
};

export default TabButton;
