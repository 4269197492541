import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import { savingToastSelector } from '../../../store/ui';
import { useT } from '@transifex/react';

const WithSavingToastContainer = ({ children }) => {
  const savingToast = useSelector(savingToastSelector);
  const t = useT();
  useEffect(() => {
    if (savingToast.isOpen) {
      toast.info(t('Saving...'), {
        delay: 0,
      });
    }
  }, [savingToast.isOpen]);
  return (
    <>
      {children}
      {savingToast.isOpen && (
        <ToastContainer
          position="top-center"
          autoClose={false}
          hideProgressBar={true}
          closeButton={false}
          draggable={false}
          containerId="with-saving"
        />
      )}
    </>
  );
};

export default WithSavingToastContainer;
