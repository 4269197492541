import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useT } from '@transifex/react';
import LogoAndTitle from '../../../LogoAndTitle/LogoAndTitle';
import s from './ResetPasswordReset.module.scss';
import { PasswordHints } from '../../../common/PasswordHints';
import { passwordRequirements } from '../../../../constants/signup/passwordRequirements';
import { useForm, useWatch } from 'react-hook-form';
import { FormGroup } from '../../../FormElements/FormGroup';
import { TextInput } from '../../../FormElements/TextInput';
import { SvgIcon } from '../../../common/SvgIcon';
import { Button } from '../../../common/Button';

import { useRouter } from '../../../../hooks/useRouter';
import { useDispatch } from 'react-redux';
import { clearData, resetPasswordAsync } from '../../../../store/brand';
import { eightAuth } from '../../../../store/eightauth';
import { ToastContainer } from 'react-toastify';
import { PasswordStrengthBar } from '../../../common/PasswordStrengthBar';

const ResetPasswordReset = () => {
  const t = useT();
  const [requirementsFulfilled, setRequirementsFulfilled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const dispatch = useDispatch();

  const { machineToken } = eightAuth.tokenService.getTokens();

  const { query } = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (!machineToken) {
      dispatch(clearData());
      dispatch(eightAuth.fetchMachineTokenAsync.request());
    }
  }, [dispatch, machineToken]);

  const watchPassword = useWatch({ control, name: 'password' });
  const watchConfirmPassword = useWatch({ control, name: 'confirmPassword' });

  const requirements = useMemo(() => {
    const temp = passwordRequirements;
    temp.minLength.fulfilled = watchPassword?.length >= 6 ? true : false;
    temp.uppercase.fulfilled = watchPassword !== watchPassword?.toUpperCase() ? true : false;
    temp.lowercase.fulfilled = watchPassword !== watchPassword?.toLowerCase() ? true : false;
    temp.digit.fulfilled = /\d/.test(watchPassword) ? true : false;
    const fulfilledValue = Object.keys(temp).filter((index) => !temp[index].fulfilled).length === 0;
    setRequirementsFulfilled(fulfilledValue ? true : false);
    return temp;
  }, [watchPassword]);

  const identicalPasswords = useMemo(
    () => watchPassword === watchConfirmPassword,
    [watchConfirmPassword, watchPassword]
  );

  const handleResetPasswordSubmit = useCallback(
    (form) => {
      dispatch(
        resetPasswordAsync.request({
          key: query?.code,
          newPassword: form.password,
          emailAddress: query?.emailAddress,
        })
      );
      setIsSubmitDisabled(true);
    },
    [query?.code, query?.emailAddress, dispatch]
  );

  return (
    <>
      <div className={s['reset-password-reset__container']}>
        <div className={s['reset-password-reset__inner']}>
          <LogoAndTitle />
          <form onSubmit={handleSubmit(handleResetPasswordSubmit)}>
            <div className={s['reset-password-reset__inner-section']}>
              <FormGroup
                title={t('New Password')}
                customClass={s['reset-password-reset__inner-formgroup-1']}
              >
                <TextInput
                  type="password"
                  id="account-password"
                  name="password"
                  label={t('CREATE A PASSWORD')}
                  watchValue={watchPassword}
                  register={register}
                  error={errors.password}
                  withIcon
                  icons={[<SvgIcon name="eye" />, <SvgIcon name="eye-off" />]}
                />
              </FormGroup>
              <PasswordHints requirements={requirements} />
              {requirementsFulfilled && <PasswordStrengthBar password={watchPassword} />}

              <FormGroup
                title={t('Confirm Password')}
                customClass={s['reset-password-reset__inner-formgroup-2']}
              >
                <TextInput
                  type="password"
                  id="account-confirm-password"
                  name="confirmPassword"
                  label={t('RETYPE YOUR PASSWORD')}
                  watchValue={watchConfirmPassword}
                  register={register}
                  error={errors.confirmPassword}
                  withIcon
                  icons={[<SvgIcon name="eye" />, <SvgIcon name="eye-off" />]}
                />
              </FormGroup>
            </div>
            <div className={s['reset-password-reset__inner-button']}>
              <Button
                customText={t('Reset')}
                fullwidth
                type="submit"
                styling="primary"
                disabled={!requirementsFulfilled || !identicalPasswords || isSubmitDisabled}
              ></Button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
      />
    </>
  );
};

export default ResetPasswordReset;
