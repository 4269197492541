import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames/bind';

import s from './List.module.scss';

const cx = classNames.bind(s);

const ListItem = ({ animate, x, y, metadata, isCapital }) => (
  <div className={s['list__container']}>
    <div className={s['list__text-container']}>
      <div
        className={cx(s['list__text-counter'], {
          [s['list__text-counter-capital']]: isCapital,
        })}
      >
        {x}
      </div>
      <div className={s['list__text-text']}>{`${y} (${(+metadata.percentage).toFixed(2)}%)`}</div>
    </div>
    <div className={s['list__bar-container']}>
      <div
        className={s['list__bar-percentage']}
        style={{ maxWidth: animate ? `${metadata.percentage}%` : `0%` }}
      ></div>
    </div>
  </div>
);

const List = ({ series, isCapital, detailQuestions = {} }) => {
  const sortedSeries = useMemo(() => {
    if (
      series.name === 'Multiple Choice Responses' ||
      series.name === 'Select All That Apply Responses'
    ) {
      const name =
        series.name === 'Multiple Choice Responses'
          ? 'multipleChoiceQuestions'
          : 'selectAllThatApplyQuestions';
      const sortedQuestion =
        detailQuestions[name]
          ?.filter((el) => el.id === series.metadata.questionId)
          .map((el) => {
            return {
              ...el,
            };
          })[0] || {};

      if (sortedQuestion.optionTexts)
        sortedQuestion.optionTexts = Object.values(sortedQuestion.optionTexts).map((el) =>
          el.toLowerCase()
        );

      return {
        ...series,
        data: series.data.slice().sort((a, b) => {
          return (
            sortedQuestion?.optionTexts?.indexOf(a.name) -
            sortedQuestion?.optionTexts?.indexOf(b.name)
          );
        }),
      };
    }

    return series;
  }, [series, detailQuestions]);

  const [animate, setAnimate] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 0);
  }, [sortedSeries]);

  const handleSeeAllClick = () => setExpanded(true);

  return (
    <div className={s['list__wrapper']}>
      {sortedSeries?.data?.slice(0, expanded ? sortedSeries.data.length : 5).map((item, i) => (
        <ListItem key={i} animate={animate} {...item} isCapital={isCapital} />
      ))}
      {!expanded && sortedSeries?.data?.length > 5 && (
        <p className={s['list__see-all']} onClick={handleSeeAllClick}>
          see all {'->'}
        </p>
      )}
    </div>
  );
};

export default List;
