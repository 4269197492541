import React, { useMemo, useCallback, useState, useEffect } from 'react';

import { T, useT } from '@transifex/react';

import { TagsGenerator } from '../../TagsGenerator';

import s from './CustomAudienceTagsModal.module.scss';
import { getTagOptions } from '../../../utils/form/formHelpers';
import Modal from '../../Modal/Modal';
import { useDispatch } from 'react-redux';
import { toggleModalByName } from '../../../store/ui';
import { cloneDeep } from 'lodash';

const CustomAudienceTagsModal = ({ modals, selectedQuestion, questions, update, localTags }) => {
  const t = useT();

  const dispatch = useDispatch();

  const [activeResponse, setActiveResponse] = useState('');

  const [questionState, setQuestionState] = useState('');

  useEffect(() => {
    !questionState && setQuestionState(selectedQuestion);
  }, [selectedQuestion, questionState]);

  const handleQuestionStateChange = useCallback((question) => {
    setQuestionState(question);
  }, []);

  useEffect(() => {
    !activeResponse && setActiveResponse(selectedQuestion?.responsesOptions[0]?.id);
  }, [selectedQuestion, activeResponse]);

  const questionResponses = useMemo(() => {
    if (questionState?.responsesOptions?.length > 0)
      return questionState?.responsesOptions?.map((option) => {
        return {
          ...option,
          content: (
            <TagsGenerator
              optionId={option?.id}
              tags={option?.tags}
              tagsOptions={getTagOptions(option, localTags) || []}
              localTags={localTags}
              handleQuestionStateChange={handleQuestionStateChange}
              question={cloneDeep(questionState)}
            />
          ),
        };
      });
    else return [];
  }, [questionState, localTags, handleQuestionStateChange]);

  const handleSelectResponse = useCallback(
    (id) => {
      setActiveResponse(id);
    },
    [setActiveResponse]
  );

  const handleAudienceModalSave = useCallback(() => {
    update(questionState);
    dispatch(toggleModalByName({ name: 'customAudiences', value: false }));
  }, [update, questionState, dispatch]);

  const handleAudiencesModalClose = useCallback(() => {
    dispatch(toggleModalByName({ name: 'customAudiences', value: false }));
  }, [dispatch]);

  return (
    <Modal
      title={t(modals.customAudiences.modalContent.title)}
      customButtonText={t('Save')}
      closeModal={handleAudiencesModalClose}
      actionCallback={handleAudienceModalSave}
      fullwidthButton
    >
      <div className={s['audience']}>
        <div className={s['audience__subtitle']}>
          {t(
            "Custom audience tags allows you to retarget citizens according to their response on a later campaign. To do this, select a response, in the ‘Tags’ text box type in your question, followed by the dash key, then type the response, e.g 'Do you like chocolate - yes'."
          )}
          <br />
          <T
            _str="Press Enter, do this for each response and Save. Please do {not} include any other symbols in your audience tag set up - e.g ?,!£&+%$"
            not={<strong>{t('not')}</strong>}
          />
        </div>
        <div className={s['audience__table']}>
          <div className={s['audience__table-header']}>
            <div className={s['audience__table-header__responses']}>{t('Responses')}</div>
            <div className={s['audience__table-header__tags']}>
              <div className={s['audience__table-header__tags-title']}>{t('Tags')}</div>
            </div>
          </div>
          <div className={s['audience__table-content']}>
            <div className={s['audience__table-content__responses']}>
              {questionResponses?.map((response, index) => (
                <div
                  key={index}
                  id={response.id}
                  className={
                    s[activeResponse === response.id ? 'response__row--active' : 'response__row']
                  }
                  onClick={() => handleSelectResponse(response.id)}
                >
                  <div className={s['response__row-text']}>
                    {questionState?.type?.name === 'viewersentiment' ? (
                      <div>{String.fromCodePoint(response?.text)}</div>
                    ) : response?.text?.length > 0 ? (
                      response.text
                    ) : (
                      '--'
                    )}
                  </div>
                  {response?.tags?.length > 0 && (
                    <div className={s['response__row-amount']}>{response.tags?.length}</div>
                  )}
                </div>
              ))}
            </div>
            <div className={s['audience__table-content__tags']}>
              {questionResponses?.map((response, index) => (
                <div key={index}>{activeResponse === response.id ? response.content : ''}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomAudienceTagsModal;
