import { useT } from '@transifex/react';
import { routes } from '../../../../constants';
import { Button } from '../../../common/Button';
import LogoAndTitle from '../../../LogoAndTitle/LogoAndTitle';

import s from './ResetPasswordSuccess.module.scss';

const ResetPasswordSuccess = () => {
  const t = useT();

  return (
    <div className={s['reset-password-success__container']}>
      <div className={s['reset-password-success__inner']}>
        <LogoAndTitle />
        <div className={s['reset-password-success__inner-title']}>{t('Success')}</div>
        <div className={s['reset-password-success__inner-body']}>
          {t('Your password has been reset.')}
        </div>
        <div className={s['reset-password-success__inner-button']}>
          <Button
            fullwidth
            type="button"
            styling="primary"
            as="Link"
            to={routes.LOGIN}
            customText={t('Login')}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
