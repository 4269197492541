import _ from 'lodash';
import { formatTimezonedDateToUTC } from '../date/dateHelpers';

export const postProcessAgeData = (ageData) =>
  ageData
    .filter((item) => item !== 'all_age')
    .map((item) => item.split(' - '))
    .map((item) => ({
      ageFrom: item[0],
      ageTo: item[item.length - 1] === '65' ? null : item[item.length - 1],
    }));

export const postProcessGenderData = (genderData) =>
  genderData.includes('all_gender') ? ['any'] : genderData;

export const postProcessLocationData = (locationData) =>
  locationData && (locationData.map((location) => location.value) || {});

export const postProcessInterests = (interests) =>
  interests && (interests.map((interest) => interest.value) || {});

export const postProcessPassions = (passions) =>
  passions && (passions.map((passion) => passion.value) || {});

export const postProcessIncludeTagsData = (tags) =>
  (tags &&
    tags.map((tag) => ({
      tag: tag.displayName || tag.name,
      operator: 'Or',
    }))) ||
  [];

export const postProcessExcludeTagsData = (tags) =>
  (tags && tags.map((excludeTag) => excludeTag.name)) || [];

export const postProcessSchedule = (startDate, endDate, timezoneUtcString) => {
  const brandsStartDate = formatTimezonedDateToUTC(startDate, timezoneUtcString);
  const brandsEndDate = formatTimezonedDateToUTC(endDate, timezoneUtcString);
  return {
    scheduleFrom: brandsStartDate,
    scheduleTo: brandsEndDate,
  };
};
export const postProcessOsData = (deviceType) => deviceType.value;

export const postProcessQuestions = (questions) => {
  const questionTypes = questions.map((question) => {
    const { id, text, questionOrder, type } = question;
    const questionKind = type.value;
    let options = {};

    if (questionKind === 'YesNo') {
      const yesTags = question.responsesOptions[0].tags.map((tag) => tag.value);
      const noTags = question.responsesOptions[1].tags.map((tag) => tag.value);

      options = { yesTags, noTags };
    } else if (questionKind === 'ViewerSentiment') {
      let sentimentTags = {};
      question?.responsesOptions?.forEach((option, index) => {
        let tags = [];

        if (option.tags.length) {
          let attrName = option.name;
          attrName = index + 1;

          option.tags.forEach((tag) => tags.push(tag.value));
          sentimentTags = { ...sentimentTags, [attrName]: tags };
          options = { ...options, sentimentTags };
        }
      });
    } else if (questionKind !== 'ShortAnswer') {
      let optionTags = {};
      let optionTexts = {};

      question?.responsesOptions?.forEach((option, index) => {
        let tags = [];

        if (option.tags.length) {
          let attrName = option.name;
          if (questionKind === 'SelectAllThatApply' || questionKind === 'MultipleChoice')
            attrName = index;

          option.tags.forEach((tag) => tags.push(tag.value));
          optionTags = { ...optionTags, [attrName]: tags };
        }
      });

      if (questionKind === 'SelectAllThatApply' || questionKind === 'MultipleChoice') {
        question.responsesOptions.forEach((option, index) => {
          if (option.text) {
            optionTexts = { ...optionTexts, [index + 1]: option.text };
          }
        });
      }

      if (!_.isEmpty(optionTags)) options = { ...options, optionTags };

      if (!_.isEmpty(optionTexts)) options = { ...options, optionTexts };
    }

    return {
      id,
      questionKind,
      questionText: text,
      questionOrder,
      ...options,
    };
  });

  return questionTypes;
};
