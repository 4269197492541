import { cloneDeep, isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { sponsorshipConstants, sponsorshipSetConstants } from '../../constants';
import { createNewTagsMapper } from '../receivedData';

export const multipleCheckboxesChangeHandler = (
  name,
  value,
  checked,
  previousSelections,
  options,
  onChange
) => {
  let newSelections = previousSelections;

  // 'All value' (e.g. all_gender or all_age)
  const selectAll = 'all_' + name;

  // We get all values out of the options, for easier comparison reasons
  const optionsValues = options.map((option) => option.value);

  // Handle 'All' checkbox
  if (value === selectAll) {
    if (checked) newSelections = optionsValues;
    else newSelections = [];
  } else {
    // Handle the rest of the checkbox options
    if (checked) {
      if (newSelections.length !== 0) newSelections.push(value);
      else newSelections = [value];
    } else {
      if (newSelections !== 0) {
        newSelections = newSelections.filter((item) => item !== value);
      } else {
        newSelections = [];
      }
    }

    const optionsWithoutAll = optionsValues.filter((item) => item !== selectAll);
    const selectionsWithoutAll = newSelections.filter((item) => item !== selectAll);

    // Remove 'All' option, if not everything is selected
    if (newSelections.includes(selectAll) && newSelections !== optionsValues)
      newSelections = selectionsWithoutAll;
    // Add 'All' option, if everything is selected
    else if (isEqual(newSelections.sort(), optionsWithoutAll.sort())) newSelections = optionsValues;
  }

  onChange(newSelections);
};

export const addQuestion = (questions, update) => {
  const questionOrder = questions.length + 1;

  const newQuestion = {
    ...sponsorshipConstants.defaultQuestion,
    questionOrder,
    updateId: uuidv4(),
    id: uuidv4(),
  };

  let updatedQuestions = questions;
  updatedQuestions.push(newQuestion);

  update(updatedQuestions);
};

export const removeQuestion = (questionId, questions, update) => {
  const index = getQuestionIndex(questionId, questions);

  const newQuestions = cloneDeep(questions);

  newQuestions.splice(index, 1);

  const reIndexedQuestions = newQuestions.map((question, index) => ({
    ...question,
    questionOrder: index + 1,
  }));

  update(reIndexedQuestions);
};

export const moveQuestion = (from, to, questions, update) => {
  let newQuestions = cloneDeep(questions);

  const question = newQuestions.splice(from, 1)[0];

  newQuestions.splice(to, 0, question);

  newQuestions = newQuestions.map((question, index) => {
    return { ...question, questionOrder: index + 1 };
  });

  update(newQuestions);
};

export const addOption = (questionId, questions, update) => {
  const newOption = {
    id: uuidv4(),
    name: 'Option',
    text: '',
    tags: [],
  };

  let question = getQuestion(questionId, questions);

  question.options.push({ ...newOption, id: uuidv4() });

  if (question.type.name === 'selectallthatapply' || question.type.name === 'multiplechoice')
    question.responsesOptions.push(newOption);

  update(question);
};

export const removeOption = (optionIndex, questionId, questions, update) => {
  let question = getQuestion(questionId, questions);

  let options = question.options;
  let responsesOptions = question.responsesOptions;

  // Substract tagsCount
  let tagsCount = question.tagsCount;
  let substractTagCount = responsesOptions[optionIndex].tags.length;
  tagsCount = tagsCount - substractTagCount;

  options.splice(optionIndex, 1);

  if (question.type.name === 'selectallthatapply' || question.type.name === 'multiplechoice')
    responsesOptions.splice(optionIndex, 1);

  question = { ...question, options, responsesOptions, tagsCount };

  update(question);
};

export const moveOption = (
  sourceOptionIndex,
  destinationOptionIndex,
  questionId,
  questions,
  update
) => {
  let question = getQuestion(questionId, questions);

  let options = question.responsesOptions;

  let option = options.splice(sourceOptionIndex, 1)[0];

  options.splice(destinationOptionIndex, 0, option);

  question = { ...question, options };

  update(question);
};

export const changeQuestionCatagory = (categoryType, questionId, questions, update) => {
  let question = getQuestion(questionId, questions);

  const { type } = question;

  let responsesOptions = [];
  let options = [];

  if (type !== categoryType) {
    if (categoryType.name === 'selectallthatapply' || categoryType.name === 'multiplechoice') {
      options = [sponsorshipConstants.defaultOption];
      responsesOptions = [sponsorshipConstants.defaultOption];
    } else if (categoryType.name === 'viewersentiment') {
      responsesOptions = sponsorshipConstants.viewerSentimentTagsOptions;
    } else if (categoryType.name === 'yesno') {
      responsesOptions = sponsorshipConstants.yesNoTagsOptions;
    }

    question = { ...question, options, responsesOptions, type: categoryType, tagsCount: 0 };

    update(question);
  }
};

export const getQuestion = (questionId, questions) => {
  const question = cloneDeep(
    questions.find((question) => question.updateId === questionId || question.id === questionId)
  );

  return question;
};

export const getQuestionIndex = (questionId, questions) => {
  const index = questions.findIndex(
    (question) => question.updateId === questionId || question.id === questionId
  );

  return index;
};

export const addResponseOptionTag = (optionId, question, tag, update) => {
  const responseOptions = question?.responsesOptions;
  const optionIndex = getOptionIndex(optionId, responseOptions);

  question.responsesOptions[optionIndex].tags.push(tag);

  question.tagsCount++;

  update(question);
};

export const removeResponseOptionTag = (optionId, question, tagId, update) => {
  const responseOptions = question?.responsesOptions;
  const optionIndex = getOptionIndex(optionId, responseOptions);
  let tags = question.responsesOptions[optionIndex].tags;

  const tagIndex = tags.findIndex((item) => item.id === tagId);

  question.responsesOptions[optionIndex].tags.splice(tagIndex, 1);

  question.tagsCount--;

  update(question);
};

export const getOptionIndex = (optionId, options) => {
  const index = options?.findIndex((option) => option.id === optionId);

  return index;
};

export const getTagOptions = (option, localTags) => {
  const tagsMapper = createNewTagsMapper(option.tags);

  return tagsMapper
    ? localTags.filter((tag) => tag?.label.toLowerCase() !== tagsMapper[tag.label.toLowerCase()])
    : localTags;
};

export const getUpdatedQuestions = (updatedQuestion, questions) => {
  const questionIndex = questions.findIndex(
    (question) => question.updateId === updatedQuestion.updateId
  );

  const updatedQuestions = cloneDeep(questions);

  updatedQuestions[questionIndex] = updatedQuestion;

  return updatedQuestions;
};

export const getAdSetAllDirtyFields = () => {
  let dirtyFields = {};

  sponsorshipSetConstants.fieldNames.forEach((field) => {
    dirtyFields = { [field.value]: true, ...dirtyFields };
  });

  return dirtyFields;
};

export const getAdAllDirtyFields = () => {
  let dirtyFields = {};

  sponsorshipConstants.fieldNames.forEach((field) => {
    dirtyFields = { [field.value]: true, ...dirtyFields };
  });

  return dirtyFields;
};
