import * as yup from 'yup';
import { t } from '@transifex/native';

import { PASSWORD_REGEXP, EMAIL_REGEXP } from '../../../utils/validate';

const fourMB = 4194304;

export const validationSchema = yup.object().shape({
  logoUri: yup.string().when('logoFile', (logoFile, schema) => {
    if (!logoFile?.size) return schema;

    return schema.test(
      'is-more-4MB',
      t('Cropped file should not be more 4MB'),
      () => logoFile.size < fourMB
    );
  }),
  brandName: yup
    .string()
    .min(3, t('Minimum length should be 3 chars'))
    .required(t('Brand name is a required field')),
  website: yup
    .string()
    .min(3, t('Minimum length should be 3 chars'))
    .required(t('Website is a required field')),
  emailAddress: yup
    .string()
    .matches(EMAIL_REGEXP, t('Email must be in e-mail format'))
    .required(t('Email is a required field')),
  country: yup
    .object()
    .shape({
      name: yup.string(),
      label: yup.string(),
      value: yup.string().required(t('Country is a required filed')),
    })
    .required(t('Country is a required filed')),
  // timeZone: yup
  //   .object().shape({
  //     name: yup.string(),
  //     label: yup.string(),
  //     value: yup.string().required('Time Zone is a required filed')
  //   }),
  password: yup
    .string()
    .matches(
      PASSWORD_REGEXP,
      t(
        'Must contain: One uppercase letter, one lowercase letter, one digit and be at least 6 characters'
      )
    )
    .required(t('Password is a required field')),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], t('Passwords must match'))
    .required(t('Confirm Password is a required field')),
  signupCode: yup.string().required(t('Signup Code is a required field')),
  termsAndConditions: yup.boolean().oneOf([true], t('This field must be checked')),
});
