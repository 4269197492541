import React from 'react';

import s from './ErrorMessage.module.scss';

const ErrorMessage = ({ error }) => (
  <div className={s['error']}>
    <p className={s['error__message']}>{error}</p>
  </div>
);

export default ErrorMessage;
