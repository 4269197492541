import React, { useMemo } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import more from 'highcharts/highcharts-more';

import { COLORS } from '../colors';

more(Highcharts);

const Cloud = ({ series }) => {
  const transformedSeries = useMemo(() => {
    return series?.data?.map((item) => ({
      data: [
        {
          name: `${item.name}`,
          value: item.y,
        },
      ],
    }));
  }, [series]);

  const options = {
    title: false,
    chart: {
      type: 'packedbubble',
    },
    colors: [COLORS.Blue, COLORS.Green, COLORS.Red, COLORS.Yellow, COLORS.DarkBlue, COLORS.Brown],
    legend: false,
    plotOptions: {
      packedbubble: {
        marker: {
          fillOpacity: 1,
        },
        minSize: '50%',
        maxSize: '100%',
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          format: '{point.value}',
          style: {
            textOutline: 'none',
            fontWeight: '500',
            fontFamily: 'Space grotesk',
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      padding: 10,
      backgroundColor: COLORS.White,
      borderWidth: 0,
      formatter: function () {
        return `<div class="highchart-map__tooltip-name">${this.point.name}</div>`;
      },
    },
    series: transformedSeries,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Cloud;
