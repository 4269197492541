import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { convertToK } from '../../../utils/reporting';

import { COLORS } from '../colors';

const Donut = ({ dataType, series }) => {
  const options = {
    title: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    colors: [COLORS.Blue, COLORS.Red, COLORS.Yellow, COLORS.Green, COLORS.Brown],
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: 'pointer',
        showInLegend: true,
        innerSize: '70%',
        size: '55%',
        dataLabels: {
          useHTML: true,
          connectorPadding: 15,
          connectorWidth: 0,
          distance: 20,
          alignTo: 'plotEdges',
          formatter() {
            let label;
            switch (dataType) {
              case 'gender':
                label = `<div class='highchart-donut__label-wrapper'>
                              <div class='label-number' style="color: ${this.point.color}">
                                ${convertToK(this.point.y)} (${this.point.percentage.toFixed(1)}%)
                              </div>
                              <div class='label-text'>${this.point.name}</div>
                           </div>
                          `;
                break;
              case 'device':
                label = `<div class='highchart-donut__label-wrapper'>
                              <div class='label-number' style="color: ${this.point.color}">
                                ${convertToK(this.point.y)} (${this.point.percentage.toFixed(1)}%)
                              </div>
                              <div class='label-text'>${this.point.name}</div>
                           </div>
                          `;
                break;
              default:
                label = `<div class='highchart-donut__label-wrapper'>
                            <div class='label-number' style="color: ${this.point.color}">
                              ${convertToK(this.point.y)}
                            </div>
                            <div class='label-number' style="color: ${this.point.color}">
                                ${this.point.percentage.toFixed(1)}%
                            </div>
                            <div class='label-text'>${this.point.name}</div>
                         </div>
                        `;
                break;
            }
            return label;
          },
        },
      },
    },
    series: [series],
    tooltip: {
      enabled: false,
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 410,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                innerSize: '70%',
                size: '25%',
              },
            },
          },
        },
        {
          condition: {
            maxWidth: 310,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                innerSize: '70%',
                size: '15%',
              },
            },
          },
        },
        {
          condition: {
            maxWidth: 275,
          },
          chartOptions: {
            plotOptions: {
              pie: {
                innerSize: '55%',
                size: '7%',
              },
            },
          },
        },
      ],
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} immutable={true} />;
};

export default Donut;
