import config from '../../../config';
import axios from 'axios';
import { store } from '../..';
import { setUploadPercentage } from './actions';
import { getDimensions } from '../../../utils/media/media-utils';

export class SponsorshipService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  updatePercentage = (percentage, cancel) => {
    const state = store.getState();
    if (state.sponsorship.sponsorshipCreate.uploadingVideo) {
      store.dispatch(setUploadPercentage(percentage));
    } else {
      cancel();
    }
  };

  publishCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/publish`,
      method: 'POST',
      newVersionApi: '4.0',
    });
    return res.data;
  };

  unpublishCampaign = async ({ campaignId, sponsorshipSetId, sponsorshipId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/sponsorshipset/${sponsorshipSetId}/sponsorship/${sponsorshipId}/unpublish`,
      method: 'POST',
    });

    return res.data;
  };

  activateSponsorship = async ({ campaignId, sponsorshipSetId: adSetId, sponsorshipId: adId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/adset/publish/${campaignId}/adset/${adSetId}/ad/${adId}/manually-activate`,
      method: 'POST',
    });

    return res.data;
  };

  deactivateSponsorship = async ({
    campaignId,
    sponsorshipSetId: adSetId,
    sponsorshipId: adId,
  }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/adset/publish/${campaignId}/adset/${adSetId}/ad/${adId}/manually-deactivate`,
      method: 'POST',
    });

    return res.data;
  };

  updateSponsorship = async ({
    sponsorshipId,
    campaignId,
    sponsorshipSetId,
    name,
    adVideoPreSignedUrlRequest,
    thirdPartyTrackingScripts,
    questionTypes,
    CTAUpdateRequest,
    ctaCover,
    ctaCoverFileName,
    additionalData,
  }) => {
    let ctaImagePreSignedUrlRequest;

    if (ctaCoverFileName) {
      const { aspectRatio, height, width } = await getDimensions(ctaCover);

      ctaImagePreSignedUrlRequest = {
        originalFileName: ctaCoverFileName,
        mediaWidth: width,
        mediaHeight: height,
        mediaAspectRatio: aspectRatio,
      };

      additionalData = { ...additionalData, ctaImagePreSignedUrlRequest };
    }

    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/sponsorshipset/${sponsorshipSetId}/sponsorship/`,
      method: 'POST',
      bodyType: 'json',
      body: {
        sponsorshipId,
        name,
        questionTypes,
        thirdPartyTrackingScripts: thirdPartyTrackingScripts ?? [],
        CTAUpdateRequest,
        ...additionalData,
      },
      newVersionApi: '4.2',
    });

    if (response?.data?.ctaImagePreSignedUrlDetail) {
      const { preSignedUrl } = response?.data?.ctaImagePreSignedUrlDetail;
      const { media } = ctaCover;

      await fetch(preSignedUrl, {
        method: 'PUT',
        body: media,
      });
    }

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };

  getCoverTilePreSignedUrl = async ({
    campaignId,
    sponsorshipSetId,
    sponsorshipId,
    filename,
    height,
    aspectRatio,
    width,
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/sponsorshipsets/${sponsorshipSetId}/sponsorships/${sponsorshipId}/cover-tile-pre-signed-url`,
      method: 'POST',
      bodyType: 'json',
      body: {
        originalFileName: filename,
        mediaAspectRatio: aspectRatio,
        mediaWidth: width,
        mediaHeight: height,
      },
    });

    return response.data;
  };

  getVideoPreSignedUrl = async ({
    campaignId,
    sponsorshipSetId,
    sponsorshipId,
    filename,
    height,
    aspectRatio,
    width,
    videoLength,
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/sponsorshipsets/${sponsorshipSetId}/sponsorships/${sponsorshipId}/video-pre-signed-url`,
      method: 'POST',
      bodyType: 'json',
      body: {
        originalFileName: filename,
        mediaAspectRatio: aspectRatio,
        mediaWidth: width,
        mediaHeight: height,
        videoLength,
      },
      newVersionApi: '2.0',
    });

    return response.data;
  };

  uploadCoverTile = async ({
    campaignId,
    sponsorshipSetId,
    sponsorshipId,
    media,
    adCover,
    mediaType,
  }) => {
    const { aspectRatio, height, width } = await getDimensions({
      media,
      mediaType,
      fileUrl: adCover,
    });

    const { preSignedUrl, ...response } = await this.getCoverTilePreSignedUrl({
      campaignId,
      sponsorshipSetId,
      sponsorshipId,
      aspectRatio,
      filename: media.name,
      height,
      mediaType,
      width,
    });

    await fetch(preSignedUrl, {
      method: 'PUT',
      body: media,
    });

    return response;
  };

  uploadVideo = async ({
    campaignId,
    sponsorshipSetId,
    sponsorshipId,
    media,
    mediaType,
    adVideo,

    videoLength,
  }) => {
    const { aspectRatio, height, width } = await getDimensions({
      media,
      mediaType,
      fileUrl: adVideo,
    });

    const { preSignedUrl, ...response } = await this.getVideoPreSignedUrl({
      campaignId,
      sponsorshipSetId,
      sponsorshipId,
      aspectRatio,
      filename: media.name,
      height,
      mediaType,
      width,
      videoLength,
    });

    const uploadRequest = axios.CancelToken.source();

    await axios({
      url: preSignedUrl,
      method: 'PUT',
      headers: {
        'Content-Type': mediaType,
      },
      data: media,
      cancelToken: uploadRequest.token,
      onUploadProgress: (event) => {
        return this.updatePercentage(
          Math.round((event.loaded * 100) / media.size),
          uploadRequest.cancel
        );
      },
    });

    return response;
  };

  getVodInfoStatus = async (videoKey) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/vodinfo/${videoKey}`,
      method: 'GET',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };
}
