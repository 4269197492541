import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { motion } from 'framer-motion';
import { generatePath } from 'react-router';
import { DisabledNavLink } from '../../common/DisabledNavLink';

import s from '../AnimatedMenu/AnimatedMenu.module.scss';
import { useT } from '@transifex/react';

function AnimatedMenu({
  show,
  menuItems,
  campaignId,
  adSetId,
  adId,
  isDisabled,
  stickyMenuItem,
  children,
}) {
  const t = useT();
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          animate={{ y: 0, opacity: 1 }}
          initial={{ y: -50, opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {menuItems && (
            <ul className={s['menu']}>
              {menuItems.map((menuItem) => (
                <li key={menuItem.fragment} className={s['menu__item']}>
                  <DisabledNavLink
                    to={
                      !adId
                        ? generatePath(menuItem.editPath, {
                            cid: campaignId,
                            ssid: adSetId,
                          })
                        : generatePath(menuItem.editPath, {
                            cid: campaignId,
                            ssid: adSetId,
                            sid: adId,
                          })
                    }
                    fragment={menuItem.fragment}
                    isDisabled={isDisabled}
                    stickyMenuItem={stickyMenuItem}
                    isOpen
                  >
                    {t(menuItem.name)}
                  </DisabledNavLink>
                </li>
              ))}
            </ul>
          )}

          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default AnimatedMenu;
