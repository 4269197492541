import React from 'react';
import { useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import { mapPropertiesByCountry } from '../../../constants/maps';
import ReactTooltip from 'react-tooltip';

import { COLORS } from '../colors';

import s from './Map.module.scss';

const Map = ({ country, series }) => {
  const [scaleFactor, setScaleFactor] = useState(1);
  const [content, setTooltipContent] = useState(<></>);

  const { map, projection, projectionConfig } = mapPropertiesByCountry(country);

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <ComposableMap
          projection={projection}
          projectionConfig={projectionConfig}
          style={{ height: '100%', width: '100%' }}
        >
          <ZoomableGroup onMove={({ zoom }) => setScaleFactor(zoom)}>
            <Geographies geography={map}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={COLORS.Green}
                    stroke="white"
                    strokeWidth={`${2 / scaleFactor}px`}
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none' },
                      pressed: { outline: 'none' },
                    }}
                  />
                ))
              }
            </Geographies>
            {series?.map((loc, index) => (
              <MarkerComponent
                key={index}
                loc={loc}
                index={index}
                scaleFactor={scaleFactor}
                setScaleFactor={setScaleFactor}
                setTooltipContent={setTooltipContent}
              />
            ))}
          </ZoomableGroup>
        </ComposableMap>
      </div>
      <ReactTooltip id="map" className={s['tooltip']}>
        {content}
      </ReactTooltip>
    </>
  );
};

const MarkerComponent = ({ loc, scaleFactor, setScaleFactor, index, setTooltipContent }) => {
  const [hoverScaleFactor, setHoverScaleFactor] = useState(1);
  return (
    <Marker
      data-tip
      data-for="map"
      className={s['marker']}
      coordinates={[loc.lon, loc.lat]}
      onMouseEnter={() => {
        setTooltipContent(
          <div className="tooltip">
            <h4>{loc.name}</h4>
            <span>{loc.docs_count}</span>
          </div>
        );
        setHoverScaleFactor((prevValue) => prevValue / 1.7);
      }}
      onMouseLeave={() => {
        setTooltipContent('');
        setHoverScaleFactor((prevValue) => prevValue * 1.7);
      }}
    >
      <circle
        className={s['marker']}
        r={7 / scaleFactor / hoverScaleFactor}
        fill={COLORS.Blue}
        stroke={hoverScaleFactor !== 1 ? 'white' : 'none'}
        strokeWidth={hoverScaleFactor !== 1 ? `${4 / scaleFactor}px` : '0px'}
      />
    </Marker>
  );
};

export default Map;
