import React from 'react';

import { useT } from '@transifex/react';

import s from './BillingReport.module.scss';
import { TableBilling } from '../../../../TableBilling';
import { billingConstants } from '../../../../../constants';
import { useDispatch } from 'react-redux';
import { useRouter } from '../../../../../hooks/useRouter';
import { useReportDataByUrl } from '../../../../../hooks/useReportDataByUrl';

const BillingReport = ({
  transactions,
  setDataArray,
  brandInfo,
  handlePerformanceClick,
  brandBills,
}) => {
  const t = useT();

  const dispatch = useDispatch();
  const { query } = useRouter();

  useReportDataByUrl(query, brandBills);

  return (
    <div className={s['billing-report']}>
      <h2 className={s['billing-report__title']}>{t('Billing Report')}</h2>
      <div className={s['billing-report__table']}>
        {transactions?.length ? (
          <TableBilling
            headers={billingConstants.billingReport.tableHeaders}
            tableData={transactions || []}
            content="billing report"
            setDataArray={(val) => dispatch(setDataArray(val))}
            currencySymbol={brandInfo.country.currencySymbol}
            handlePerformanceClick={handlePerformanceClick}
          />
        ) : (
          <div className={s['billing-report__no-data']}>
            {t('{noReportData}', { noReportData: t(billingConstants.billingReport.noData) })}
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingReport;
