import React from 'react';

import s from './StickyMenu.module.scss';

const StickyMenu = ({ children }) => {
  return (
    <div className={s['sticky-wrapper']} id="sticky-menu">
      <div>{children}</div>
    </div>
  );
};

export default StickyMenu;
