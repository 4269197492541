import { createSelector } from 'reselect';

const manageStateSelector = (state) => state.manage;

export const manageFetchingSelector = createSelector(
  manageStateSelector,
  (state) => state.internalFetching
);

export const campaignsAreFetchingSelector = createSelector(
  manageStateSelector,
  (state) => state.campaignsAreFetching
);

export const adSetsAreFetchingSelector = createSelector(
  manageStateSelector,
  (state) => state.adSetsAreFetching
);

export const adsAreFetchingSelector = createSelector(
  manageStateSelector,
  (state) => state.adsAreFetching
);

export const receivedCampaignsSelector = createSelector(
  manageStateSelector,
  (state) => state.receivedCampaigns
);

export const receivedNotificationsSelector = createSelector(
  manageStateSelector,
  (state) => state.receivedNotifications
);

export const receivedSponsorshipSetsSelector = createSelector(
  manageStateSelector,
  (state) => state.receivedSponsorshipSets
);

export const receivedSponsorshipsSelector = createSelector(
  manageStateSelector,
  (state) => state.receivedSponsorships
);

export const itemsPerPageSelector = createSelector(
  manageStateSelector,
  (state) => state.itemsPerPage
);

export const selectedNotificationSelector = createSelector(
  manageStateSelector,
  (state) => state.selectedNotification
);

export const isArchiveSelector = createSelector(manageStateSelector, (state) => state.isArchive);

export const isSearchSelector = createSelector(manageStateSelector, (state) => state.isSearch);

export const ManageCampaignNamesSelector = createSelector(
  manageStateSelector,
  (state) => state.campaignNames
);

export const ManageAdSetNamesSelector = createSelector(
  manageStateSelector,
  (state) => state.adSetNames
);
