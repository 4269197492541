import React from 'react';

import { Link } from 'react-router-dom';

import { SvgIcon } from '../SvgIcon';

import s from './HomeLink.module.scss';

const HomeLink = ({ path, name, style }) => (
  <div className={s['home-link']} style={style}>
    <Link className={s['home-link__container']} to={path}>
      <div className={s['home-link__container--icon']}>
        <SvgIcon name={'arrow-left'} />
      </div>
      <div className={s['home-link__container--label']}>{name}</div>
    </Link>
  </div>
);

export default HomeLink;
