export const comparingIncentivePrice = (adSetIncentive, adSetVideoDuration, isEdit, incentive) => {
  if (!adSetIncentive) return null;

  if (adSetIncentive && adSetVideoDuration && !isEdit) {
    const item = incentive.find(
      (item) => item?.videoMaxTimeInSeconds === adSetVideoDuration?.videoMaxTimeInSeconds
    );

    const propertiesToCheck = ['pricePerMessage', 'netCashReward', 'weAre8Fee', 'charityDonation'];

    const hasDifference = (item, adSetIncentive, properties) => {
      for (const prop of properties) {
        if (item[prop] !== adSetIncentive[prop]) return true;
      }
      return false;
    };

    const haveDiffValues = hasDifference(item, adSetIncentive, propertiesToCheck);

    if (haveDiffValues) return item;
  }

  return adSetIncentive;
};
