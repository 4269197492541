import { useT } from '@transifex/react';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CSVLink } from 'react-csv';

import { Layout } from '../../components/Layout';
import { ReportingContent } from '../../components/PagesContent/ReportingContent';
import { getCSVFileName } from './utils/csvDataHelpers';

import { ProgressSnackBar } from '../../components/ProgressSnackBar';
import { ProgressCircle } from '../../components/ProgressCircle';
import { useRouter } from '../../hooks/useRouter';
import {
  checkForCSVCompletionAsync,
  clearCSVDataForDownload,
  createPerformanceReportingCSVAsync,
  csvDataForDownloadSelector,
  csvDataNameSelector,
  csvFileURLSelector,
  csvRequestIdSelector,
  csvStatusSelector,
  downloadProgressSelector,
  getCSVDataAsync,
  isLoadingCSVDataSelector,
  isResponsesCSVLoadingSelector,
  setCSVRequestId,
} from '../../store/analytics';
import { brandInfoSelector } from '../../store/brand';
import { getFromLocalStorage, removeFromLocalStorage } from '../../utils/localStorage/localStorage';

export const Reporting = () => {
  const { query, params } = useRouter();

  const csvDataName = useSelector(csvDataNameSelector);
  const csvDataForDownload = useSelector(csvDataForDownloadSelector);
  const csvRequestId = useSelector(csvRequestIdSelector);
  const csvStatus = useSelector(csvStatusSelector);
  const csvFileURL = useSelector(csvFileURLSelector);
  const isResponsesCSVLoading = useSelector(isResponsesCSVLoadingSelector);

  const isLoadingCSVData = useSelector(isLoadingCSVDataSelector);
  const downloadProgress = useSelector(downloadProgressSelector);

  const brandInfo = useSelector(brandInfoSelector);

  const brandId = brandInfo.brandIdFromAdmin;

  const dispatch = useDispatch();
  const t = useT();

  const node = useRef(null);

  const handleCloseProgressModal = useCallback(() => {
    removeFromLocalStorage('csvRequestId');
    dispatch(clearCSVDataForDownload());
  }, [dispatch]);

  useEffect(() => {
    if (csvDataForDownload.length) {
      if (node.current) {
        node.current.click();
      }
    }
  }, [csvDataForDownload, dispatch]);

  useEffect(() => {
    const storedCSVRequestId = getFromLocalStorage('csvRequestId');
    if (storedCSVRequestId) dispatch(setCSVRequestId(storedCSVRequestId));

    return () => {
      removeFromLocalStorage('csvRequestId');
      dispatch(clearCSVDataForDownload());
    };
  }, [dispatch]);

  useEffect(() => {
    if (csvRequestId) {
      dispatch(checkForCSVCompletionAsync.request());
    }
  }, [csvRequestId, dispatch]);

  const setCSVDataFileName = useMemo(() => {
    return getCSVFileName(csvDataName);
  }, [csvDataName]);

  const type = useMemo(() => {
    if (params.sid) return 'ad';
    if (params.ssid) return 'ad-set';
    return 'campaign';
  }, [params.ssid, params.sid]);

  const handleDownloadCSV = useCallback(
    (csvDataType, questionId, filters) => () => {
      const tab = query.type === 'overview' ? 'overviewTab' : 'demographicTab';
      if (csvDataType === 'ShortAnswer')
        dispatch(
          getCSVDataAsync.request({
            csvDataType,
            currentTab: tab,
            questionId,
            brandId,
            campaignId: query.cid,
            adSetId: query.ssid,
            adId: query.sid,
            type,
            filters: filters ?? {},
          })
        );
      else
        dispatch(
          createPerformanceReportingCSVAsync.request({
            campaignId: query.cid,
            adSetId: query.ssid,
            adId: query.sid,
            filters,
            type,
            csvDataType,
          })
        );
    },
    [dispatch, brandId, query, type]
  );
  return (
    <Layout>
      {(csvRequestId || isLoadingCSVData) && (
        <ProgressSnackBar
          fileURL={csvFileURL}
          isRefetchLoading={isLoadingCSVData}
          status={csvStatus}
          closeModal={handleCloseProgressModal}
          refetchStatus={() => dispatch(checkForCSVCompletionAsync.request())}
        />
      )}
      {isResponsesCSVLoading && (
        <ProgressCircle
          title={t('Downloading CSV Data')}
          progress={downloadProgress}
          closeModal={handleCloseProgressModal}
        />
      )}
      <ReportingContent handleDownloadCSV={handleDownloadCSV} type={type} />
      <CSVLink data={csvDataForDownload} filename={setCSVDataFileName}>
        <div ref={node} />
      </CSVLink>
    </Layout>
  );
};

export default Reporting;
