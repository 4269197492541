import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Layout } from '../../../../components/Layout';
import { AdCallToAction } from '../../../../components/PagesContent/CreateContent/AdCallToAction';
import { AdNameAndCreative } from '../../../../components/PagesContent/CreateContent/AdNameAndCreative';
import { Questions } from '../../../../components/PagesContent/CreateContent/Questions';
import { ProgressCircle } from '../../../../components/ProgressCircle';
import { ScrollToTopWrapper } from '../../../../components/wrappers/ScrollToTopWrapper';

import { yupResolver } from '@hookform/resolvers/yup';

import { fragments, routes, sponsorshipConstants } from '../../../../constants';

import {
  activateSponsorshipFromCreationAsync,
  deactivateSponsorshipFromCreationAsync,
  resetProgress,
  setDataArray,
  setSponsorshipData,
  setUploadingVideo,
  sponsorshipCreateSelector,
  toggleActivateSponsorship,
  updateSponsorshipAsync,
  watchUploadStop,
} from '../../../../store/campaign/sponsorship';

import { setAdData } from '../../../../store/campaign/sponsorship';

import {
  modalsSelector,
  savingToastSelector,
  setStickyMenuItem,
  stateIsEqualSelector,
  toggleModalByName,
} from '../../../../store/ui';

import { DevTool } from '@hookform/devtools';
import { useT } from '@transifex/react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { generatePath } from 'react-router';
import { useUnmount } from 'react-use';
import Modal from '../../../../components/Modal/Modal';
import { StepButtons } from '../../../../components/StepButtons';
import { defaultQuestions } from '../../../../constants/sponsorship/sponsorship-data';
import { CREATE_CAMPAIGN_PAGE } from '../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { useActiveForm } from '../../../../hooks/useActiveForm';
import { useDirtyFields } from '../../../../hooks/useDirtyFields';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';
import { useRouter } from '../../../../hooks/useRouter';
import {
  brandInfoSelector,
  fetchUniqueSponsorshipNamesAsync,
  previousUniqueSponsorshipNamesSelector,
} from '../../../../store/brand';
import {
  adSetByAdIdSelector,
  adSetsSelector,
  campaignDataFetchingSelector,
  campaignSelector,
  setAdIsDirty,
} from '../../../../store/data';
import { getVideoDurationPromise, getVideoLengthInMinutes } from '../../../../utils/media';
import preventSubmitOnEnter from '../../../../utils/preventSubmitOnEnter';
import { processQuestions } from '../../../../utils/receivedData';
import { constructTestId } from '../../../../utils/test-ids';
import SponsorshipPlaceholder from '../../../Placeholders/SponsorshipPlaceholder/SponsorshipPlaceholder';
import { validationSchema } from './validationSchema';

const SECTION = CREATE_CAMPAIGN_PAGE.AD_NAME_AND_CREATIVE;
const SponsorshipForm = () => {
  const dispatch = useDispatch();
  const { push } = useRouter();

  const adNameRef = useRef(null);
  const creativeRef = useRef(null);
  const questionsRef = useRef(null);
  const ctaRef = useRef(null);

  const brand = useSelector(brandInfoSelector);

  const { fetchedTags, brandName } = brand;

  const fetching = useSelector(campaignDataFetchingSelector);
  const modals = useSelector(modalsSelector);
  const sponsorshipCreate = useSelector(sponsorshipCreateSelector);
  const stateStatus = useSelector(stateIsEqualSelector);
  const previousSponsorshipNames = useSelector(previousUniqueSponsorshipNamesSelector);
  const saving = useSelector(savingToastSelector).isOpen;
  const allAdSets = useSelector(adSetsSelector);

  const firstAd = allAdSets.length === 1 && allAdSets[0].ads.length === 1;

  const adIsLocked = !sponsorshipCreate.isDraft;

  const campaign = useSelector(campaignSelector);

  const [videoNotice, setVideoNotice] = useState('');

  const [clickedOnSave, setClickedOnSave] = useState(false);

  const { query } = useRouter();

  const sponsorshipSetCreate = useSelector((state) =>
    adSetByAdIdSelector(state, sponsorshipCreate?.id)
  );

  const t = useT();

  const { videoMinTimeInSeconds, videoMaxTimeInSeconds } =
    brand?.videoAdPricingData?.find(
      (item) => item.pricePerMessage === sponsorshipSetCreate?.incentive?.pricePerMessage
    ) ?? {};

  const maxLength = videoMaxTimeInSeconds ?? 20;
  const minLength = videoMinTimeInSeconds ?? 16;
  const {
    register,
    unregister,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({
    defaultValues: {
      name: sponsorshipCreate.name,
      // smsMessage: sponsorshipCreate.smsMessage,
      adCover: sponsorshipCreate.adCover,
      adVideo: sponsorshipCreate.adVideo,
      adVideoFile: sponsorshipCreate.adVideoFile,
      videoLength: sponsorshipCreate?.videoLength,
      questions: sponsorshipCreate.questions ?? processQuestions(defaultQuestions, brandName),
      ctaCoverFile: sponsorshipCreate.ctaCoverFile,
      ctaCover: sponsorshipCreate.ctaCover,
      ctaLink: sponsorshipCreate.ctaLink,
      ctaDiscount: sponsorshipCreate.ctaDiscount,
      thirdPartyTracking: sponsorshipCreate.thirdPartyTrackingScripts,
      ctaButtonText: sponsorshipCreate.ctaButtonText,
      trackingUrl: sponsorshipCreate.trackingUrl,
      appToTrack: sponsorshipCreate.appToTrack,
      isVideoUploaded: sponsorshipCreate.isVideoUploaded,
      // impressionsUrl: sponsorshipCreate.impressionsUrl,
      // completionUrl: sponsorshipCreate.completionUrl,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
    context: { brandRole: brand.role, minLength, maxLength },
  });

  useEffect(() => {
    reset(
      {
        name: sponsorshipCreate.name,
        // smsMessage: sponsorshipCreate.smsMessage,
        adCover: sponsorshipCreate.adCover,
        adVideo: sponsorshipCreate.adVideo,
        adVideoFile: sponsorshipCreate.adVideoFile,
        videoLength: sponsorshipCreate?.videoLength,
        questions: sponsorshipCreate.questions ?? processQuestions(defaultQuestions),
        thirdPartyTracking: sponsorshipCreate.thirdPartyTrackingScripts,
        ctaCoverFile: sponsorshipCreate.ctaCoverFile,
        ctaCover: sponsorshipCreate.ctaCover,
        ctaLink: sponsorshipCreate.ctaLink,
        ctaDiscount: sponsorshipCreate.ctaDiscount,
        ctaButtonText: sponsorshipCreate.ctaButtonText,
        trackingUrl: sponsorshipCreate.trackingUrl,
        appToTrack: sponsorshipCreate.appToTrack,
        isVideoUploaded: sponsorshipCreate.isVideoUploaded,
      },
      { keepDirtyValues: true, keepDirty: true }
    );
  }, [
    reset,
    sponsorshipCreate.name,
    sponsorshipCreate.isActive,
    sponsorshipCreate.adVideo,
    sponsorshipCreate.adVideoFile,
    sponsorshipCreate?.videoLength,
    sponsorshipCreate.questions,
    sponsorshipCreate.ctaCover,
    sponsorshipCreate.adCover,
    sponsorshipCreate.appToTrack,
    sponsorshipCreate.ctaButtonText,
    sponsorshipCreate.ctaCoverFile,
    sponsorshipCreate.ctaDiscount,
    sponsorshipCreate.ctaLink,
    sponsorshipCreate.trackingUrl,
    sponsorshipCreate.isVideoUploaded,
    sponsorshipCreate.thirdPartyTrackingScripts,
  ]);

  const { shouldSave, dirtyFieldsList } = useDirtyFields({
    storedDirtyFields: sponsorshipCreate?.dirtyFields,
    fieldNames: sponsorshipConstants.fieldNames,
    isDirty,
    formDirtyFields: dirtyFields,
  });

  const localTags = useMemo(() => fetchedTags, [fetchedTags]);

  // This runs when we switch to another entity or unmount (the component does unmount)
  useUnmount(() => {
    if (shouldSave && !clickedOnSave) {
      dispatch(
        setAdIsDirty({
          isDirty: true,
          dirtyFields: { ...dirtyFields, ...sponsorshipCreate.dirtyFields },
          form: getValues(),
          adSetId: sponsorshipSetCreate?.id || query.ssid,
          adId: sponsorshipCreate?.id || query.sid,
        })
      );
      dispatch(setSponsorshipData({ name: 'id', value: '' }));
    }
    dispatch(setSponsorshipData({ name: 'dirtyFields', value: {} }));
  });

  const watchQuestions = useWatch({ control, name: 'questions' });

  const submitForm = () => {
    setClickedOnSave(true);
    dispatch(
      updateSponsorshipAsync.request({
        form: { ...getValues() },
        sponsorshipId: sponsorshipCreate?.id || query.sid,
        campaignId: campaign?.id || query.cid,
        sponsorshipSetId: sponsorshipSetCreate?.id || query.ssid,
        dirtyFields: { ...dirtyFields, ...sponsorshipCreate.dirtyFields },
        localTags,
      })
    );
    dispatch(toggleModalByName({ name: 'audienceTagsWarning', value: false }));
  };

  // Show warning only if
  // a) If its a new ad
  // b) if its the only ad in the campaign
  // c) Brand hasn't selected any tags
  // d) Brand hasn't created any tags
  const showAudienceTagsWarning = useMemo(() => {
    let selectedTags = 0;

    selectedTags = watchQuestions?.map((q) => q.tagsCount).reduce((a, b) => a + b);

    return (
      !sponsorshipCreate?.isCreated &&
      firstAd &&
      selectedTags <= 0 &&
      sponsorshipCreate?.localTags?.length <= 0
    );
  }, [watchQuestions, sponsorshipCreate?.isCreated, sponsorshipCreate?.localTags?.length, firstAd]);

  const handleNextStep = () => {
    if (showAudienceTagsWarning) {
      dispatch(toggleModalByName({ name: 'audienceTagsWarning', value: true }));
    } else {
      submitForm();
    }
  };

  useActiveForm({
    type: 'ad',
    allAdSets,
    campaignId: query.cid,
    adSetId: query.ssid,
    adId: query.sid,
  });

  useEffect(() => () => dispatch(setStickyMenuItem(null)), [dispatch]);

  const resetVideo = useCallback(() => {
    setValue('adVideo', '');
    setValue('adVideoFile', '');
    setValue('videoLength', '');
    setValue('isVideoUploaded', false);
  }, [setValue]);

  const validDuration = useCallback(
    (duration, videoMaxTimeInSeconds, videoMinTimeInSeconds) =>
      duration < videoMaxTimeInSeconds + 1 && duration >= videoMinTimeInSeconds,
    []
  );

  const handleVideoDurationAndPricing = useCallback(
    (adVideoFile, adVideo) => {
      if (adVideoFile.name?.length > 0) {
        const durationPromise = getVideoDurationPromise(adVideoFile, adVideo);

        if (brand?.videoAdPricingData.length > 0)
          durationPromise().then((duration) => {
            if (brand.role !== 'WeAre8') {
              const { videoMinTimeInSeconds, videoMaxTimeInSeconds } =
                brand?.videoAdPricingData?.filter(
                  (item) =>
                    item.pricePerMessage === sponsorshipSetCreate?.incentive?.pricePerMessage
                )[0];

              if (validDuration(duration, videoMaxTimeInSeconds, videoMinTimeInSeconds))
                setValue('videoLength', getVideoLengthInMinutes(duration));
              else {
                resetVideo();
                setVideoNotice(
                  t(
                    "The selected video is {newVideoLength} sec long which doesn't match the ad set’s video duration. Please select a video within {acceptableDuration}",
                    {
                      newVideoLength: Math.floor(duration),
                      acceptableDuration: `${videoMinTimeInSeconds} - ${videoMaxTimeInSeconds} sec`,
                    }
                  )
                );
                dispatch(toggleModalByName({ name: 'videoLengthNotification', value: true }));
              }
            } else setValue('videoLength', getVideoLengthInMinutes(duration));
          });
      }
    },
    [
      dispatch,
      setValue,
      resetVideo,
      validDuration,
      brand?.videoAdPricingData,
      sponsorshipSetCreate?.incentive,
      t,
      brand.role,
    ]
  );

  const handleCloseVideoNoticeModal = useCallback(() => {
    setVideoNotice('');
    dispatch(toggleModalByName({ name: 'videoLengthNotification', value: false }));
  }, [dispatch, setVideoNotice]);

  const handleCloseAudienceTagsWarningModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'audienceTagsWarning', value: false }));
  }, [dispatch]);

  // useCheckUploadingStatus(sponsorshipCreate, sponsorshipEditId);

  const handleSelect = useCallback(
    (name) => (value) => {
      dispatch(setDataArray({ name, value }));
    },
    [dispatch]
  );

  const handleCloseProgressModal = useCallback(() => {
    dispatch(resetProgress(0));
    dispatch(setUploadingVideo(false));
    dispatch(watchUploadStop({ status: '' }));
  }, [dispatch]);

  const handlePreviousStep = useCallback(() => {
    push(
      generatePath(routes.SPONSORSHIP_SET_EDIT, {
        cid: query.cid,
        ssid: query.ssid,
      }) + fragments.BUDGET
    );
  }, [query.ssid, query.cid, push]);

  const handleAdActivation = useCallback(
    (e) => {
      const checked = e.target.checked;
      dispatch(toggleActivateSponsorship(checked));
      dispatch(
        checked
          ? activateSponsorshipFromCreationAsync.request({
              adSetId: query.ssid,
              adId: sponsorshipCreate.id || query.sid,
            })
          : deactivateSponsorshipFromCreationAsync.request({
              adSetId: query.ssid,
              adId: sponsorshipCreate.id || query.sid,
            })
      );
    },
    [dispatch, sponsorshipCreate.id, query.sid, query.ssid]
  );

  const {
    moreToFetch: moreNamesToFetch,
    fetchMore: fetchMoreNames,
    setKeyword: setNamesKeyword,
  } = useInfiniteScroll(fetchUniqueSponsorshipNamesAsync.request, previousSponsorshipNames);

  return (
    <Layout>
      <DevTool control={control} />
      <form onSubmit={handleSubmit(handleNextStep)} onKeyDown={preventSubmitOnEnter}>
        {sponsorshipCreate.uploadingVideo && (
          <ProgressCircle
            title={t('Uploading Video')}
            progress={sponsorshipCreate.progress}
            closeModal={handleCloseProgressModal}
          />
        )}
        {fetching ? (
          <SponsorshipPlaceholder />
        ) : (
          <ScrollToTopWrapper>
            <AdNameAndCreative
              fetchMoreSponsorshipNames={fetchMoreNames}
              moreSponsorshipNamesToFetch={moreNamesToFetch}
              setNamesKeyword={setNamesKeyword}
              brand={brand}
              modals={modals}
              sponsorshipCreate={sponsorshipCreate}
              stateStatus={stateStatus}
              toggleModalByName={toggleModalByName}
              sponsorshipSetCreate={sponsorshipSetCreate}
              previousSponsorshipNames={previousSponsorshipNames}
              setAdData={setAdData}
              setValue={setValue}
              errors={errors}
              control={control}
              register={register}
              trigger={trigger}
              handleAdActivation={handleAdActivation}
              adNameRef={adNameRef}
              creativeRef={creativeRef}
              adIsLocked={adIsLocked}
              dirtyFieldsList={dirtyFieldsList}
              handleVideoDurationAndPricing={handleVideoDurationAndPricing}
            />
            <Controller
              control={control}
              name={`questions`}
              render={({ field: { onChange, value, ref } }) => (
                <Questions
                  inputRef={ref}
                  brand={brand}
                  modals={modals}
                  isDisabled={adIsLocked}
                  sponsorshipCreate={sponsorshipCreate}
                  toggleModalByName={toggleModalByName}
                  setStickyMenuItem={setStickyMenuItem}
                  register={register}
                  control={control}
                  errors={errors}
                  questions={value}
                  onQuestionsChange={onChange}
                  questionsRef={questionsRef}
                  localTags={localTags}
                />
              )}
            />
            <AdCallToAction
              brand={brand}
              handleSelect={handleSelect}
              modals={modals}
              toggleModalByName={toggleModalByName}
              sponsorshipCreate={sponsorshipCreate}
              setStickyMenuItem={setStickyMenuItem}
              register={register}
              unregister={unregister}
              control={control}
              getValues={getValues}
              errors={errors}
              setValue={setValue}
              ctaRef={ctaRef}
              adIsLocked={adIsLocked}
            />
          </ScrollToTopWrapper>
        )}
        <StepButtons
          data-testid={constructTestId(SECTION, 'submit-button')}
          handlePreviousStep={handlePreviousStep}
          styling="review"
          saving={saving}
          isSubmitDisabled={!shouldSave}
        />
        {modals.videoLengthNotification.isOpen && (
          <Modal
            cancelCallback={handleCloseVideoNoticeModal}
            withSubmit
            customButtonCancelText={t('Got it')}
            title={videoNotice}
          />
        )}
        {modals.audienceTagsWarning.isOpen && (
          <Modal
            cancelCallback={handleCloseAudienceTagsWarningModal}
            actionCallback={submitForm}
            customButtonText={t('Continue without setting it')}
            title={t('Warning')}
            children={t("It seems you haven't set up an audience tag for retargeting.")}
          />
        )}
      </form>
    </Layout>
  );
};

export default SponsorshipForm;
