import { initialAsyncState, createReducer } from 'eight.js.store-common';

import { setAuthInternalFetching, signupAsync, setAdminAccessToken } from './actions';

const initialState = {
  ...initialAsyncState,
  accessToken: null,
  adminAccessToken: null,
  internalFetching: false,
  machineToken: null,
  refreshToken: null,
  refreshing: false,
  error: '',
  fetching: false,
};

export const authReducer = createReducer('@@auth', initialState, {
  [signupAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [setAuthInternalFetching]: ({ state, action }) => {
    state.internalFetching = action.payload;
  },

  [setAdminAccessToken]: ({ state, action }) => {
    state.adminAccessToken = action.payload;
  },
});
