const findGCD = (a, b) => {
  if (b > a) {
    const temp = a;
    a = b;
    b = temp;
  }

  while (b !== 0) {
    const m = a % b;
    a = b;
    b = m;
  }

  return a;
};

export const getAspectRatio = (width, height) => {
  const gcd = findGCD(width, height);

  return `${width / gcd}:${height / gcd}`;
};

export const base64StringToFile = (base64String, filename) => {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const blobString = atob(arr[1]);
  let n = blobString.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = blobString.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const extractImageFileExtension = (base64Data) =>
  base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'));

export const getVideoLengthInMinutes = (duration) => {
  const minutes = Math.floor(duration / 60);
  let seconds = Math.floor(duration) % 60;

  if (seconds < 10) {
    return `0:0${minutes}:0${seconds}`;
  }

  return `0:0${minutes}:${seconds}`;
};

export const getVideoLengthInSeconds = (videoLength) => {
  if (videoLength) {
    const minutes = videoLength.split(':')[1];
    const seconds = videoLength.split(':')[2];

    if (minutes && seconds) {
      return +minutes * 60 + +seconds;
    } else {
      return +seconds;
    }
  }

  return 16;
};

export const convertVideoLengthInSeconds = (videoLength) => {
  return videoLength?.split(':').reduce((biggestValue, time) => 60 * biggestValue + +time) ?? 16;
};

export const getSelectedVideoAdPrice = (videoAdPricingData, videoLength, role) => {
  const videoLengthInSec = isDurationInSeconds(videoLength)
    ? videoLength
    : getVideoLengthInSeconds(videoLength);

  if (
    role === 'WeAre8' &&
    videoLengthInSec > videoAdPricingData[videoAdPricingData.length - 1].videoMaxTimeInSeconds
  )
    return videoAdPricingData[videoAdPricingData.length - 1];

  const selectedVideoAdPrice = videoAdPricingData.find(
    (el) =>
      videoLengthInSec >= el.videoMinTimeInSeconds && videoLengthInSec <= el.videoMaxTimeInSeconds
  );

  if (videoLengthInSec < 5) {
    return videoAdPricingData.find((el) => el.videoMinTimeInSeconds === 5);
  }

  return selectedVideoAdPrice;
};

const isDurationInSeconds = (videoLength) => !String(videoLength).includes(':');

export const getVideoDurationPromise = (adVideoFile, adVideo) => () =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(adVideoFile);

    reader.onload = () => {
      const video = document.createElement('video');
      video.addEventListener(
        'loadedmetadata',
        () => {
          const { duration } = video;

          resolve(duration);
        },
        false
      );

      video.src = adVideo;
      video.load();
    };
  });

export const getDimensions = async ({ media, mediaType, fileUrl }) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(media);

    reader.onload = () => {
      if (mediaType.includes('image')) {
        const img = new Image();

        img.onload = () => {
          const { naturalHeight, naturalWidth } = img;
          const aspectRatio = getAspectRatio(naturalWidth, naturalHeight);

          resolve({
            aspectRatio,
            height: `${naturalHeight}`,
            width: `${naturalWidth}`,
          });
        };

        img.src = fileUrl;
      } else if (mediaType.includes('video')) {
        const video = document.createElement('video');

        video.addEventListener(
          'loadedmetadata',
          () => {
            const { videoHeight, videoWidth } = video;
            const aspectRatio = getAspectRatio(videoWidth, videoHeight);

            resolve({
              aspectRatio,
              height: `${videoHeight}`,
              width: `${videoWidth}`,
            });
          },
          false
        );
        video.src = fileUrl;
        video.load();
      }
    };
  });
};
