const selectStyles = (error, styleTypes, disabled) => ({
  container: (provided) => ({
    ...provided,
    border: 'none',
    '&:focus, &:active': {
      border: 'none',
    },
    opacity: styleTypes !== 'dropdown' || disabled ? `${disabled && '0.3'}` : 1,
  }),

  control: () => ({
    border: `1px solid ${error ? 'red' : styleTypes === 'gray' ? '#EBEBEB' : 'black'}`,
    borderLeft: `${styleTypes === 'no-border' && 'none'}`,
    minHeight: '50px',
    maxHeight: '174px',
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: `${styleTypes === 'gray' ? '#EBEBEB' : 'FF0000FF'}`,
    borderTopLeftRadius: `${styleTypes === 'no-border' ? '0px' : '4px'}`,
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: `${styleTypes === 'no-border' ? '0px' : '4px'}`,
    borderBottomRightRadius: '4px',
    '&:hover': {
      borderColor: '1px solid red',
    },
    pointerEvents: `${disabled ? 'none' : 'initial'}`,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    height: '40px',
    '& input': {
      height: '40px',
      scrollMarginTop: '120px',
    },
  }),

  loadingIndicator: (provided) => ({
    ...provided,
    color: '#6672fb',
  }),

  loadingMessage: (provided) => ({
    ...provided,
    color: '#6672fb',
    fontSize: '0.75em',
  }),

  multiValue: () => ({
    backgroundColor: `${disabled ? '#c9c9c9' : '#6672fb'}`,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '7px 3px 7px 10px',
    borderRadius: '4px',
    margin: '5px',
    '& span:last-child': {
      display: 'none',
    },
  }),

  multiValueLabel: () => ({
    color: '#fff',
    marginRight: '5px',
    fontSize: '0.75em',
    height: '100%',
    textTransform: `${styleTypes === 'interests' ? 'capitalize' : 'initial'}`,
  }),

  menu: () => ({
    position: 'absolute',
    left: '3%',
    zIndex: '20',
    width: '95%',
    margin: '-5px auto',
    backgroundColor: `${styleTypes === 'gray' ? '#EBEBEB' : '#fff'}`,
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.11)',
    padding: '0px',
  }),

  menuList: () => ({
    maxHeight: `${styleTypes === 'dropdown' ? '360px' : '250px'}`,
    overflowY: 'auto',
    padding: '4px 0px',
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    padding: '2px 4px',
    '&:hover': {
      background: '#fff',
      color: '#6672fb',
    },
  }),

  noOptionsMessage: (provided) => ({
    ...provided,
    color: 'black',
    fontSize: '0.75em',
  }),

  option: () => ({
    margin: '0',
    padding: `${styleTypes === 'dropdown' ? '0' : '10px 15px'}`,
    fontSize: '0.75em',
    letterSpacing: '0.3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: `${styleTypes === 'interests'}` ? 'capitalize' : 'initial',
    '&:hover': {
      background: `${styleTypes === 'dropdown' ? 'transparent' : '#6672fb'}`,
      color: `${styleTypes === 'dropdown' ? '#000' : '#fff'}`,
      cursor: `${styleTypes === 'dropdown' ? 'pointer' : 'initial'}`,
    },
  }),

  groupHeading: (provided) => ({
    ...provided,
    padding: '15px 20px',
    fontSize: '0.75em',
    textTransform: 'normal',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: '0.75em',
    color: '#7f7f7f',
  }),

  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.85em',
    fontWeight: '400',
    color: '#000',
  }),

  valueContainer: (provided) => ({
    ...provided,
  }),
});

export default selectStyles;
