import React, { useMemo } from 'react';

import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { useT } from '@transifex/react';

import s from './CustomSelect.module.scss';
import selectStyles from '../selectStyles';
import _ from 'lodash';

const CustomSelect = ({
  inputId,
  disabled,
  options = [],
  placeholder,
  isMulti,
  defaultValue,
  name,
  control,
  handleSelect,
  error,
  styleType,
  interestsOptionsStyle,
  charitiesSelect,
  customOptionLabelField,
  watchValue,
  selectAll,
  closeOnSelect = true,
}) => {
  const t = useT();

  const optionsWithSelectAll = useMemo(() => {
    return _.isEqual(watchValue, options)
      ? options
      : [{ label: t('All'), value: t('select-all') }, ...options];
  }, [options, watchValue, t]);

  const styleTypes = useMemo(() => {
    if (styleType === 'gray') {
      return 'gray';
    }

    if (styleType === 'no-border') {
      return 'no-border';
    }

    if (interestsOptionsStyle) return 'interests';
  }, [interestsOptionsStyle, styleType]);

  const interestsOptions = useMemo(() => {
    return [
      {
        label: t('Name'),
        description: t('Description'),
        options,
      },
    ];
  }, [options, t]);

  const formatOptionLabel = (data) => (
    <>
      <span className={s['label']}>{t(data.label)}</span>
      <span className={s['description']}>{t(data.description)}</span>
    </>
  );

  const formatGroupLabel = (data) => {
    return (
      <>
        <div className={s['options-header__name']}>{t(data.label)}</div>
        <div className={s['options-header__description']}>{t(data.description)}</div>
      </>
    );
  };

  const noOptionsMessage = () => {
    return <div>{t('Select Area of impact first')}</div>;
  };

  const handleSelectAll = (values, onChange) => {
    const includesSelectAll = values.some((value) => value.value === 'select-all');
    if (includesSelectAll) onChange(options);
    else onChange(values);
  };

  return control ? (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, ref } }) => {
        return (
          <div className={s['select']}>
            <Select
              ref={ref}
              inputId={inputId}
              closeMenuOnSelect={closeOnSelect}
              cacheOptions={true}
              // options={interestsOptionsStyle ? interestsOptions : options}
              options={selectAll ? optionsWithSelectAll : options}
              styles={selectStyles(error, styleTypes, disabled)}
              placeholder={placeholder}
              isMulti={isMulti}
              noOptionsMessage={charitiesSelect && noOptionsMessage}
              // formatGroupLabel={interestsOptionsStyle && formatGroupLabel}
              // formatOptionLabel={interestsOptionsStyle && formatOptionLabel}
              getOptionLabel={(option) =>
                customOptionLabelField ? t(option[customOptionLabelField]) : t(option['label'])
              }
              isDisabled={disabled}
              onChange={(v) => {
                selectAll ? handleSelectAll(v, onChange) : onChange(v);
                handleSelect && handleSelect(v);
              }}
              value={watchValue}
            />
            {error && <p className={s['error']}>{t(error.message)}</p>}
          </div>
        );
      }}
    />
  ) : (
    <div className={s['select']}>
      <Select
        inputId={inputId}
        closeMenuOnSelect={true}
        cacheOptions={true}
        options={interestsOptionsStyle ? interestsOptions : options}
        styles={selectStyles(error, styleTypes, disabled)}
        placeholder={placeholder}
        isMulti={isMulti}
        noOptionsMessage={charitiesSelect && noOptionsMessage}
        formatGroupLabel={interestsOptionsStyle && formatGroupLabel}
        formatOptionLabel={interestsOptionsStyle && formatOptionLabel}
        getOptionLabel={(option) =>
          customOptionLabelField ? option[customOptionLabelField] : option['label']
        }
        isDisabled={disabled}
        onChange={(v) => {
          handleSelect && handleSelect(v);
        }}
        value={watchValue}
      />
      {error && <p className={s['error']}>{t(error.message)}</p>}
    </div>
  );
};

export default CustomSelect;
