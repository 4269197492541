import React from 'react';

import { SvgIcon } from '../../../common/SvgIcon';

import s from './ReportingValue.module.scss';

const ReportingValue = ({ text, iconName, value }) => (
  <div className={s['value__container']}>
    <div className={s['icon']}>
      <SvgIcon name={iconName} />
    </div>
    <div className={s['text']}>
      <div>
        {text}: <strong>{value ? value : '--'}</strong>
      </div>
    </div>
  </div>
);

export default ReportingValue;
