import React, { useMemo } from 'react';

import classNames from 'classnames/bind';

import s from './Switch.module.scss';

const cx = classNames.bind(s);

const Switch = ({ checked, disabled, id, menuSwitch, name, onChange, value, register }) => {
  const switchClassNames = useMemo(
    () =>
      cx({
        [s['switch']]: true,
        [s['switch__menu']]: menuSwitch,
      }),
    [menuSwitch]
  );

  const switchLabelClassNames = useMemo(
    () =>
      cx({
        [s['switch-label']]: true,
        [s['switch-label__menu']]: menuSwitch,
        [s['switch-label__disabled']]: disabled,
      }),
    [menuSwitch, disabled]
  );

  const registerOption = register ? register(name, { onChange }) : {};

  return (
    <div className={switchClassNames}>
      <input
        className={s['switch-input']}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={id}
        name={name}
        disabled={disabled}
        {...registerOption}
      />
      <label className={switchLabelClassNames} htmlFor={id} />
    </div>
  );
};

export default Switch;
