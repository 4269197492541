import { T, useT } from '@transifex/react';
import React from 'react';
import { SectionTitle } from '../../../common/Titles/SectionTitle';

import s from './PixelDocContent.module.scss';
import SecondaryTitle from '../../../common/Titles/SecondaryTitle/SecondaryTitle';

function PixelDocContent() {
  const t = useT();

  return (
    <div className={s['container']}>
      <SectionTitle>8Pixel {t('Documentation')}</SectionTitle>
      <section>
        <SecondaryTitle className={s['title']}>{t(`What is the`)} 8Pixel</SecondaryTitle>
        <div className={s['description']}>
          {t('The')} 8Pixel{' '}
          {t(
            `is a piece of code that you can place on your website to track action and activity of WeAre8 citizens after they have watched a brand video via our platform. The data collected by the pixel is used by our platform for reporting back to you, to help you better optimize your campaign delivery, effectiveness and for retargeting.`
          )}
        </div>
      </section>
      <section>
        <SecondaryTitle className={s['title']}>
          {t(`How does the`)} 8Pixel {t('work')}
        </SecondaryTitle>
        <div className={s['description']}>
          {t('The')} 8Pixel{' '}
          {t(
            `measures a range of actions that directly tie back to business goals like driving product views, leads, subscriptions, or purchases.`
          )}
          <br />
          <br />
          {`${t('Having an')} 8Pixel ${t(
            'set up allows you to track customer events; so after a person has watched a brand video via our platform, and takes action, the'
          )} 8Pixel ${t(
            'tracks that event and allows you to attribute your brand video to their actions. As the'
          )} 8Pixel ${t(
            `measures more conversion events, WeAre8 learns more about the types of people who are most likely to take similar actions with your brand, allowing you to unlock conversion optimization for your campaigns.`
          )}`}
        </div>
      </section>
      <section>
        <SecondaryTitle className={s['title']}>
          {t(`What data does the`)} 8Pixel {t(`collect`)}
        </SecondaryTitle>
        <div className={s['description']}>
          {t('The')} 8Pixel{' '}
          {t(
            `collects information available in all standard web browsers like Chrome, Safari etc. More particularly the information collected includes:`
          )}
          <br />
          <ul>
            <li className={s['list-item']}>
              {t(
                `Ad & Event information: Information about the specific event that was triggered after the customer first lands on your destination url (PageViews, Purchases, Subscriptions/Sign Ups).`
              )}
            </li>
            <li className={s['list-item']}>
              {t(
                `Timestamp: The specific time that the pixel event was fired. This is used to determine when website actions took place, like when a page was viewed, when a product was purchased, etc.`
              )}
            </li>
            <li className={s['list-item']}>
              {t(`IP Address: Used to determine the geographic location of an event.`)}
            </li>
            <li className={s['list-item']}>
              {t(
                `User info: Used to determine the device make, model, operating system and browser information. `
              )}
            </li>
          </ul>
        </div>
      </section>
      <section>
        <SecondaryTitle className={s['title']}>{t(`Technical requirements`)}</SecondaryTitle>
        <div className={s['description']}>
          {`${t(
            'Access to your website code base is required as you will need to manually add the pixel code directly in your website. You will also need an activated SAM-i account with the'
          )} 8Pixel ${t(
            `ID generated specifically for you.  You can find more detailed implementation instructions below.`
          )}`}
          <br />
          <br />
          {t(
            `Additionally, cookies must be enabled as whenever a customer visits your website after watching a brand video, a cookie will be placed in their browser.`
          )}
          <br />
          <br />
          <T
            _str="Please find the WeAre8 cookie policy {link}."
            link={
              <a
                href="https://www.weare8.com/cookie-policy"
                target="_blank"
                rel="noreferrer"
                className={s['pixel-doc-link']}
              >
                <T _str="here" />
              </a>
            }
          />
          <br />
          <T
            _str="Please find the WeAre8 privacy policy {link}."
            link={
              <a
                href="https://www.weare8.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className={s['pixel-doc-link']}
              >
                <T _str="here" />
              </a>
            }
          />
        </div>
      </section>
    </div>
  );
}

export default PixelDocContent;
