import React from 'react';

import s from './Spinner.module.scss';

const Spinner = ({ position = 'fixed' }) => {
  const r = 500;
  const w = 10 * r;
  const h = 0.5 * w;
  const x = -0.5 * w;
  const y = -0.5 * h;
  const c = Math.round(r / Math.SQRT2);
  const l = Math.ceil((3 * Math.PI + 4) * r);
  const d = Math.PI * r;

  return (
    <div className={s['spinner']} style={{ position }}>
      <div className={s['spinner-content']}>
        <svg viewBox={[x, y, w, h].join(' ')} className={s['spinner-content__svg']}>
          <path
            id="inf"
            d={`M${c}${-c}A${r} ${r} 0 1 1 ${c} ${c}L${-c}${-c}A${r} ${r} 0 1 0${-c} ${c}z`}
          />
          <use xlinkHref="#inf" strokeDasharray={`${d} ${l - d}`} strokeDashoffset={`${l}px`} />
        </svg>
      </div>
    </div>
  );
};

export default Spinner;
