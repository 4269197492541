import React from 'react';
import InputPlaceholder from '../../../components/Placeholders/InputPlaceholder/InputPlaceholder.js';
import InputWithTextPlaceholder from '../../../components/Placeholders/InputWithTextPlaceholder/InputWithTextPlaceholder.js';
import { SectionTitlePlaceholder } from '../../../components/Placeholders/SectionTitlePlaceholder.js/index.js';
import SingleLinePlaceholder from '../../../components/Placeholders/SingleLinePlaceholder/SingleLinePlaceholder.js';
import UploadPlaceholder from '../../../components/Placeholders/UploadPlaceholder/UploadPlaceholder.js';
import Placeholder from '../Placeholder.js';

function SponsorshipPlaceholder() {
  return (
    <>
      <Placeholder gap="30px">
        <SectionTitlePlaceholder />
        <InputPlaceholder />
        <UploadPlaceholder type="square" style={{ marginTop: '1rem' }} />
        <div>
          {[...Array(2)].map((item, index) => (
            <SingleLinePlaceholder key={index} />
          ))}
        </div>
        <div>
          {[...Array(10)].map((item, index) => (
            <SingleLinePlaceholder key={index} />
          ))}
        </div>
        <InputPlaceholder />
        <InputPlaceholder />
        <InputPlaceholder />
      </Placeholder>
      <Placeholder gap="40px">
        <UploadPlaceholder type="square" />
        <InputPlaceholder />
        <InputPlaceholder />
        <InputPlaceholder />
        <InputWithTextPlaceholder rows={3} />
        <InputPlaceholder />
      </Placeholder>
    </>
  );
}

export default SponsorshipPlaceholder;
