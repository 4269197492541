import config from '../../config';
import { addTimezoneOffset, formatToBrandsTimezone } from '../../utils/date/dateHelpers';
import { processQuestionsFromList, processReceivedText } from '../../utils/receivedData';
import { eightAuth } from '../eightauth';

export class ManageService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  getCampaigns = async ({ offset = 0, limit }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns?offset=${offset}&limit=${limit}`,
      method: 'GET',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    const sortedByDate = response.data.data
      .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
      .map((campaign) => ({
        ...campaign,
        spendingLimit: campaign.spendingLimit ? campaign.spendingLimit : 0,
        budget: campaign.budget ? campaign.budget : 0,
        dailyBudget: campaign.dailyBudget ? campaign.dailyBudget : 0,
        conversions: '-',
        clickThrough: Object.keys(campaign).includes('clickThrough') ? campaign.clickThrough : '-',
        deliveryStatus: campaign.deliveryStatus ? processReceivedText(campaign.deliveryStatus) : '',
        moderationStatus: campaign.moderationStatus
          ? processReceivedText(campaign.moderationStatus)
          : 'Unknown',
      }));

    return {
      ...response.data,
      data: sortedByDate,
    };
  };

  searchCampaigns = async ({ offset = 0, limit, search = '', isArchive, utcString }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v3/campaigns/search?offset=${offset}&limit=${limit}&search=${search}&isArchive=${isArchive}`,
      method: 'GET',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    const transformedData = response.data.data.map((campaign) => {
      return {
        ...campaign,
        earliestStartDate:
          campaign.earliestStartDate &&
          formatToBrandsTimezone(campaign.earliestStartDate, utcString),
        latestEndDate:
          campaign.latestEndDate && formatToBrandsTimezone(campaign.latestEndDate, utcString),
      };
    });

    return {
      ...response.data,
      data: transformedData,
    };

    // const sortedByDate = response.data.data
    //   .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
    //   .map((campaign) => ({
    //     ...campaign,
    //     spendingLimit: campaign.spendingLimit ? campaign.spendingLimit : 0,
    //     budget: campaign.budget ? campaign.budget : 0,
    //     dailyBudget: campaign.dailyBudget ? campaign.dailyBudget : 0,
    //     conversions: '-',
    //     clickThrough: Object.keys(campaign).includes('clickThrough') ? campaign.clickThrough : '-',
    //     deliveryStatus: campaign.deliveryStatus ? processReceivedText(campaign.deliveryStatus) : '',
    //     moderationStatus: campaign.moderationStatus
    //       ? processReceivedText(campaign.moderationStatus)
    //       : 'Unknown',
    //   }));

    // return {
    //   ...response.data,
    //   data: sortedByDate,
    // };
  };

  searchSponsorshipSets = async ({
    offset = 0,
    limit,
    search = '',
    isArchive,
    campaignId = '',
    utcString,
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v4/brand/campaigns/sponsorshipsets/search?offset=${offset}&limit=${limit}&search=${search}&isArchive=${isArchive}&campaignId=${campaignId}`,
      method: 'GET',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    const transformedData = response.data.data.map((set) => {
      return {
        ...set,
        startDate: set.startDate && formatToBrandsTimezone(set.startDate, utcString),
        endDate: set.endDate && formatToBrandsTimezone(set.endDate, utcString),
      };
    });

    return {
      ...response.data,
      data: transformedData,
    };

    // const sortedByDate = response.data.data
    //   .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
    //   .map((campaign) => ({
    //     ...campaign,
    //     startDate:
    //       campaign.startDate &&
    //       addTimezoneOffset(campaign.startDate, campaign.timezoneInfo?.offset).toISOString(),
    //     endDate:
    //       campaign.endDate &&
    //       addTimezoneOffset(campaign.endDate, campaign.timezoneInfo?.offset).toISOString(),
    //     spendingLimit: campaign.spendingLimit ? campaign.spendingLimit : 0,
    //     budget: campaign.budget ? campaign.budget : 0,
    //     dailyBudget: campaign.dailyBudget ? campaign.dailyBudget : 0,
    //     conversions: '-',
    //     clickThrough: Object.keys(campaign).includes('clickThrough') ? campaign.clickThrough : '-',
    //     deliveryStatus: campaign.deliveryStatus ? processReceivedText(campaign.deliveryStatus) : '',
    //     moderationStatus: campaign.moderationStatus
    //       ? processReceivedText(campaign.moderationStatus)
    //       : 'Unknown',
    //   }));

    // return {
    //   ...response.data,
    //   data: sortedByDate,
    // };
  };

  searchSponsorships = async ({
    offset = 0,
    limit,
    search = '',
    isArchive,
    campaignId = '',
    adSetId = '',
  }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns/sponsorships/search?offset=${offset}&limit=${limit}&search=${search}&isArchive=${isArchive}&campaignId=${campaignId}&sponsorshipSetId=${adSetId}`,
      method: 'GET',
      newVersionApi: '3.0',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;

    // const sortedByDate = response.data.data
    //   .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
    //   .map((campaign) => ({
    //     ...campaign,
    //     spendingLimit: campaign.spendingLimit ? campaign.spendingLimit : 0,
    //     budget: campaign.budget ? campaign.budget : 0,
    //     dailyBudget: campaign.dailyBudget ? campaign.dailyBudget : 0,
    //     conversions: '-',
    //     clickThrough: Object.keys(campaign).includes('clickThrough') ? campaign.clickThrough : '-',
    //     deliveryStatus: campaign.deliveryStatus ? processReceivedText(campaign.deliveryStatus) : '',
    //     moderationStatus: campaign.moderationStatus
    //       ? processReceivedText(campaign.moderationStatus)
    //       : 'Unknown',
    //   }));

    // return {
    //   ...response.data,
    //   data: sortedByDate,
    // };
  };

  getSponsorshipSets = async ({ offset = 0, limit }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns/sponsorshipsets?offset=${offset}&limit=${limit}`,
      method: 'GET',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    const transformedData = response.data.data.map((set) => ({
      ...set,
      startDate: set.startDate && addTimezoneOffset(set.startDate).toISOString(),
      endDate: set.endDate && addTimezoneOffset(set.endDate).toISOString(),
      budgetTotal: +set.budgetTotal ? +set.budgetTotal.toFixed(2) : 0,
      budgetDaily: +set.budgetDaily ? +set.budgetDaily.toFixed(2) : 0,
      budgetAllocation: {
        charityName: set.charityName ? set.charityName : '-',
        platformFee: set.weAre8Fee ? set.weAre8Fee : 0,
        netCashReward: set.netCashReward ? set.weAre8Fee : 0,
        pricePerMessage: set.pricePerMessage ? set.pricePerMessage : 0,
        consumers:
          set.budgetTotal && set.pricePerMessage
            ? Math.ceil((+set.budgetTotal / set.pricePerMessage) * set.netCashReward)
            : 0,
      },
      deliveryStatus: set.deliveryStatus ? processReceivedText(set.deliveryStatus) : '',
      moderationStatus: set.moderationStatus
        ? processReceivedText(set.moderationStatus)
        : 'Unknown',
    }));

    return {
      ...response.data,
      data: transformedData,
    };
  };

  getSponsorships = async ({ offset = 0, limit }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns/sponsorships?offset=${offset}&limit=${limit}`,
      method: 'GET',
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    const transformedData = response.data.data.map((ad) => ({
      ...ad,
      smsMessage: ad.smsMessage ? ad.smsMessage : '-',
      questions: ad.questions ? processQuestionsFromList(ad.questions) : [],
      imageCreativeUri: ad.ctaImageUri ? ad.ctaImageUri : '',
      ctaLink: ad.ctaWebLink || '-',
      deliveryStatus: ad.deliveryStatus ? processReceivedText(ad.deliveryStatus) : '',
      moderationStatus: ad.moderationStatus ? processReceivedText(ad.moderationStatus) : 'Unknown',
    }));

    return {
      ...response.data,
      data: transformedData,
    };
  };

  getNotifications = async ({ offset = 0, limit = 20 }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns-notifications?offset=${offset}&limit=${limit}`,
      method: 'GET',
    });

    return response.data.data;
  };

  dismissNotification = async ({ notificationId, campaignId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns-notifications/dismiss`,
      method: 'POST',
      body: {
        notificationId,
        campaignId,
      },
    });

    return response.data;
  };
}
