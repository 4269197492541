import * as yup from 'yup';
import url from 'valid-url';
import { t } from '@transifex/native';
import { brandConstants } from '../../../../constants';
import { inRange } from 'lodash';
import { convertVideoLengthInSeconds } from '../../../../utils/media';

yup.addMethod(yup.array, 'unique', function (field, message) {
  return this.test('unique', message, function (array) {
    const uniqueData = Array.from(new Set(array.map((row) => row[field]?.toLowerCase())));
    const isUnique = array.length === uniqueData.length;
    if (isUnique) {
      return true;
    }
    const index = array.findIndex((row, i) => row[field]?.toLowerCase() !== uniqueData[i]);
    if (array[index][field] === '') {
      return true;
    }
    return this.createError({
      path: `${this.path}.${index}.${field}`,
      message,
    });
  });
});

const fourMB = 4194304;

const isValidDoubleClickTrackingScript = (value) => {
  const formattedValue = value.toLowerCase().trim();

  //check if it starts and ends with script tags
  const validScriptStart = formattedValue.startsWith(
    `<script language='javascript1.1' src="https://ad.doubleclick.net/ddm/trackimpj/`
  );
  const validScriptEnd = formattedValue.endsWith('</script>');
  if (!validScriptStart || !validScriptEnd) {
    return false;
  }

  // check if src contains specific parameters
  const includesDc_trc_aid = formattedValue.includes('dc_trk_aid=');
  if (!includesDc_trc_aid) return false;

  const includesDc_trc_cid = formattedValue.includes('dc_trk_cid=');
  if (!includesDc_trc_cid) return false;

  const includesDc_timestamp = formattedValue.includes('ord=[timestamp];');
  if (!includesDc_timestamp) return false;

  const includesDc_lat = formattedValue.includes('dc_lat=');
  if (!includesDc_lat) return false;

  const includesDc_rdid = formattedValue.includes('dc_rdid=');
  if (!includesDc_rdid) return false;

  const includesTag_for_child_directed_treatment = formattedValue.includes(
    'tag_for_child_directed_treatment='
  );
  if (!includesTag_for_child_directed_treatment) return false;

  return true;
};

const isValidImageTag = (value) => {
  const imgTagPattern = /^<img\s+[^>]*src\s*=\s*["'][^"']+["'][^>]*>$/i;
  if (!imgTagPattern.test(value)) {
    return false;
  }

  // Use DOMParser to parse the string and ensure it's a valid <img> element
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'text/html');
  const imgElement = doc.querySelector('img');

  // Ensure that the parsed element is an <img> tag and has a valid src attribute
  if (!imgElement || !imgElement.src) {
    return false;
  }

  // Additional checks can be added here if needed (e.g., checking for alt attribute, etc.)
  return true;
};

const optionsSchema = {
  responsesOptions: yup.array().of(
    yup.object().shape({
      text: yup.string().min(2, t('2 or more symbols are acceptable for response field')),
    })
  ),
};
const questionSchema = {
  text: yup
    .string()
    .min(10, t('Minimum length should be 10 characters'))
    .max(100, t('Text must not be longer than 100 characters')),
};

export const validationSchema = yup.object().shape({
  videoLength: yup.string(),
  minLength: yup.number(),
  maxLength: yup.number(),

  adVideo: yup
    .string()
    .required(t('Video Ad is a required field'))
    .when(
      ['videoLength', '$brandRole', '$minLength', '$maxLength'],
      (videoLength, brandRole, minLength, maxLength, schema) => {
        const duration = convertVideoLengthInSeconds(videoLength);

        const maximumVideoLength =
          brandRole === 'WeAre8'
            ? brandConstants.weAre8VideoLength.maximumVideoLength
            : brandConstants.brandVideoLength.maximumVideoLength;
        const minimumVideoLength =
          brandRole === 'WeAre8'
            ? brandConstants.weAre8VideoLength.minimumVideoLength
            : brandConstants.brandVideoLength.minimumVideoLength;

        if (duration > 0 && duration < minimumVideoLength) {
          return (
            duration &&
            schema.test(
              `is-duration-less-than-${minimumVideoLength}`,
              t(`Video length can not be less than {minimumVideoLength} seconds`, {
                minimumVideoLength,
              }),
              (value) => {
                if (!value) {
                  return true;
                } else {
                  return videoLength && videoLength >= 5;
                }
              }
            )
          );
        }

        if (duration > maximumVideoLength) {
          return schema.test(
            `is-duration-more-than-${maximumVideoLength}`,
            t(`Video length should not exceed {maximumVideoLength} seconds`, {
              maximumVideoLength,
            }),
            (value) => {
              if (!value) {
                return true;
              } else {
                return duration && duration <= maximumVideoLength;
              }
            }
          );
        }

        if (!inRange(duration, minLength, maxLength + 1)) {
          return schema.test(
            `is-duration-out-of-range-${minLength}-${maxLength}`,
            t(`Video length should be between {minLength} and {maxLength} seconds`, {
              minLength,
              maxLength,
            }),
            (value) => {
              if (!value) {
                return true;
              } else {
                return duration && inRange(duration, minLength, maxLength);
              }
            }
          );
        }
      }
    ),
  name: yup
    .string()
    .min(3, t('Minimum length should be 3 chars'))
    .max(100, t('Maximum length should not exceed 100 chars'))
    .required(t('Ad Name is a required field')),
  questions: yup.array().of(yup.object().shape({ ...questionSchema, ...optionsSchema })),
  ctaCover: yup
    .string()
    .required(t('Call To Action Image is a required field'))
    .when('ctaCoverFile', (ctaCoverFile, schema) => {
      if (!ctaCoverFile?.size) return schema;

      return schema.test(
        'is-more-4MB',
        t('Cropped file should not be more than 4MB'),
        () => ctaCoverFile.size < fourMB
      );
    }),
  ctaLink: yup
    .string()
    .test('if-valid-link', t('Link should be valid and have https:// protocol'), (value) => {
      if (value) {
        return url.isHttpsUri(value);
      }
      return false;
    }),
  // thirdPartyTracking: yup
  //   .string()
  //   .test('if-valid-script', 'Script structure is incorrect', (value) => {
  //     if (value) {
  //       return isValidDoubleClickTrackingScript(value);
  //     }
  //     if (value === '' || !value) return true;
  //     return false;
  //   }),
  thirdPartyTracking: yup
    .array()
    .of(
      yup.object().shape({
        provider: yup.string().required(t('3rd party provider is required')),
        script: yup.string().when('provider', (provider, schema) => {
          if (provider === 'doubleClick') {
            return schema.test('is-valid-script', t('Script structure is incorrect'), (value) =>
              value ? isValidDoubleClickTrackingScript(value) : false
            );
          }
          if (provider === 'adFormCtaTracking') {
            return schema
              .url('Please enter a valid URL starting with https://')
              .required('Please enter your brand website');
          }

          if (provider === 'adFormCompletionsTracking') {
            return schema.test('is-valid-img-tag', t('Image tag structure is incorrect'), (value) =>
              value ? isValidImageTag(value) : false
            );
          }
          return schema; // Ensure the schema is returned if no conditions match
        }),
      })
    )
    .test(
      'unique',
      t('Third party tracking option cannot be used twice for the same Ad'),
      (items) => {
        if (!items) return true;
        const arrayWithNames = items.map((item) => item?.provider);
        const uniqueData = [...new Set(arrayWithNames)];
        const isUnique = items.length === uniqueData.length;
        if (isUnique) return true;
        return false;
      }
    ),
});
