export const findEnvironment = () => {
  let environmentString = '';
  switch (process.env.REACT_APP_CUSTOM_ENV) {
    case 'test':
      environmentString = 'test';
      break;
    case 'development':
      environmentString = 'test';
      break;
    case 'uat':
      environmentString = 'uat';
      break;
    case 'production':
      environmentString = 'production';
      break;
    default:
      break;
  }
  return environmentString;
};
