import React from 'react';
import ContentLoader from 'react-content-loader';
import { SectionTitlePlaceholder } from '../SectionTitlePlaceholder.js';

import s from './ObjectivePlaceholder.module.scss';

const ObjectivePlaceholder = ({ items, ...props }) => (
  <div className={s['objectives']}>
    <SectionTitlePlaceholder />
    <div className={s['wrapper']}>
      {items.map((item, index) => {
        return (
          <ContentLoader
            key={index}
            speed={2}
            width={410}
            height={129}
            viewBox="0 0 410 129"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="0" y="0" rx="3" ry="3" width="391" height="118" />
          </ContentLoader>
        );
      })}
    </div>
  </div>
);

export default ObjectivePlaceholder;
