import React from 'react';
import ContentLoader from 'react-content-loader';

const MenuPlaceholder = ({ ...props }) => {
  return (
    <ContentLoader
      speed={2}
      width={200}
      height={400}
      viewBox="0 0 200 400"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="15" rx="5" ry="5" width="196" height="53" />
      <rect x="0" y="93" rx="5" ry="5" width="156" height="26" />
      <rect x="0" y="126" rx="5" ry="5" width="124" height="12" />
      <rect x="0" y="147" rx="5" ry="5" width="124" height="12" />
      <rect x="0" y="170" rx="5" ry="5" width="124" height="12" />
      <rect x="0" y="205" rx="5" ry="5" width="156" height="26" />
      <rect x="0" y="240" rx="5" ry="5" width="124" height="12" />
      <rect x="0" y="260" rx="5" ry="5" width="124" height="12" />
      <rect x="0" y="280" rx="5" ry="5" width="124" height="12" />
      <rect x="0" y="315" rx="5" ry="5" width="156" height="26" />
      <rect x="0" y="350" rx="5" ry="5" width="124" height="12" />
      <rect x="0" y="370" rx="5" ry="5" width="124" height="12" />
      <rect x="0" y="390" rx="5" ry="5" width="124" height="12" />
    </ContentLoader>
  );
};

export default MenuPlaceholder;
