import React from 'react';
import { SectionTitlePlaceholder } from '../SectionTitlePlaceholder.js';
import { SingleCheckboxPlaceholder } from './SingleCheckboxPlaceholder.js';

import s from './CheckboxesPlaceholder.module.scss';

const CheckboxesPlaceholder = ({ items, ...props }) => (
  <div className={s['container']}>
    <SectionTitlePlaceholder />
    <div className={s['wrapper']}>
      {items.map((item, index) => {
        return <SingleCheckboxPlaceholder key={index} />;
      })}
    </div>
  </div>
);

export default CheckboxesPlaceholder;
