import React from 'react';
import s from './MakeItBlurry.module.scss';

function MakeItBlurry({ text, active, children }) {
  return active ? (
    <div className={s['blurry-container']}>
      <div className={s['backdrop']}></div>
      <span className={s['message']}>{text}</span>
      {children}
    </div>
  ) : (
    children
  );
}

export default MakeItBlurry;
