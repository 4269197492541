import { createAction, createAsyncAction } from 'eight.js.store-common';

// ASYNC

export const activateSponsorshipSetFromCreationAsync = createAsyncAction(
  '@@sponsorshipSet/ACTIVATE_SPONSORSHIP_SET_FROM_CREATION'
);

export const deactivateSponsorshipSetFromCreationAsync = createAsyncAction(
  '@@sponsorshipSet/DEACTIVATE_SPONSORSHIP_SET_FROM_CREATION'
);

export const updateSponsorshipSetAsync = createAsyncAction(
  '@@sponsorshipSet/UPDATE_SPONSORSHIP_SET'
);

export const fetchAdSetPricingOptionsAsync = createAsyncAction(
  '@@sponsorshipSet/FETCH_AD_SET_PRICING_OPTIONS'
);

// SYNC

export const clearSponsorshipSet = createAction('@@sponsorshipSet/CLEAR_SPONSORSHIP_SET');

export const toggleActivateSponsorshipSet = createAction(
  '@@sponsorshipSet/TOGGLE_SPONSORSHIP_SET_ACTIVE'
);

export const setSponsorshipSetIsReadOnly = createAction('@@sponsorshipSet/SET_READ_ONLY');

export const setDataArray = createAction('@@sponsorshipSet/SET_DATA_IN_ARRAY');

export const setSponsorshipSetInternalFetching = createAction('@@sponsorshipSet/SET_IS_FETCHING');

export const setSponsorshipSetData = createAction('@@sponsorshipSet/SET_SPONSORSHIP_SET_DATA');

export const setCustomAudiencesTags = createAction('@@sponsorshipSet/UPDATE_CUSTOM_AUDIENCES_TAGS');

export const setActiveAdSet = createAction('@@sponsorshipSet/SET_ACTIVE_AD_SET');

export const setSponsorshipSetName = createAction('@@sponsorshipSet/SET_SPONSORSHIP_SET_NAME');
