import React from 'react';

import s from '../ReportingContent.module.scss';
import { useT } from '@transifex/react';

const ReportingEmpty = ({ campaign, reportingEmptyImage }) => {
  const t = useT();
  return (
    <div className={s['reporting__content']}>
      <div className={s['reporting__content--inner']}>
        <div className={s['reporting__header-container']}>
          <h1 className={s['reporting__header']}>{campaign?.name}</h1>
        </div>
      </div>

      <div className={s['reporting__empty']}>
        <img
          alt="reporting-empty"
          className={s['reporting__empty-image']}
          src={reportingEmptyImage}
        />

        <div className={s['reporting__empty-title']}>
          {t('This campaign does not have any metrics yet')}
        </div>

        <div className={s['reporting__empty-subtitle']}>
          {t('If your campaign is live, check back soon!')}
        </div>
      </div>
    </div>
  );
};

export default ReportingEmpty;
