import { createSelector } from 'reselect';

const uiStateSelector = (state) => state.ui;

export const stickyMenuItemSelector = createSelector(
  uiStateSelector,
  (state) => state.stickyMenuItem
);

export const defaultModalContentSelector = createSelector(
  uiStateSelector,
  (state) => state.modals.default.modalContent
);

export const modalsSelector = createSelector(uiStateSelector, (state) => state.modals);

const summaryModalIsOpen = createSelector(modalsSelector, (state) => state.summary.isOpen);

const defaultModalIsOpen = createSelector(modalsSelector, (state) => state.default.isOpen);

const charityModalIsOpen = createSelector(modalsSelector, (state) => state.charity.isOpen);

const budgetAllocationModalIsOpen = createSelector(
  modalsSelector,
  (state) => state.budgetAllocation.isOpen
);

const notificationModalIsOpen = createSelector(
  modalsSelector,
  (state) => state.notification.isOpen
);

const questionsModalIsOpen = createSelector(modalsSelector, (state) => state.questions.isOpen);

const errorsModalIsOpen = createSelector(modalsSelector, (state) => state.errors.isOpen);

const customAudiencesModalIsOpen = createSelector(
  modalsSelector,
  (state) => state.customAudiences.isOpen
);

const billingModalIsOpen = createSelector(modalsSelector, (state) => state.billing.isOpen);

const requestInvoiceModalIsOpen = createSelector(
  modalsSelector,
  (state) => state.requestInvoice.isOpen
);

const cropModalIsOpen = createSelector(modalsSelector, (state) => state.crop.isOpen);

const fundsModalIsOpen = createSelector(modalsSelector, (state) => state.funds.isOpen);

const campaignActivationModalIsOpen = createSelector(
  modalsSelector,
  (state) => state.campaignActivation.isOpen
);

const stripeModalIsOpen = createSelector(modalsSelector, (state) => state.stripe.isOpen);

const videoLengthNotificationIsOpen = createSelector(
  modalsSelector,
  (state) => state.videoLengthNotification.isOpen
);

const audienceTagsWarningIsOpen = createSelector(
  modalsSelector,
  (state) => state.audienceTagsWarning.isOpen
);

const unitOpeningWarningIsOpen = createSelector(
  modalsSelector,
  (state) => state.unitOpeningWarning.isOpen
);

export const modalIsOpenSelector = createSelector(
  summaryModalIsOpen,
  defaultModalIsOpen,
  charityModalIsOpen,
  budgetAllocationModalIsOpen,
  notificationModalIsOpen,
  questionsModalIsOpen,
  errorsModalIsOpen,
  customAudiencesModalIsOpen,
  billingModalIsOpen,
  cropModalIsOpen,
  fundsModalIsOpen,
  requestInvoiceModalIsOpen,
  videoLengthNotificationIsOpen,
  campaignActivationModalIsOpen,
  stripeModalIsOpen,
  audienceTagsWarningIsOpen,
  unitOpeningWarningIsOpen,
  (
    summaryModalIsOpen,
    defaultModalIsOpen,
    charityModalIsOpen,
    budgetAllocationModalIsOpen,
    notificationModalIsOpen,
    questionsModalIsOpen,
    errorsModalIsOpen,
    customAudiencesModalIsOpen,
    billingModalIsOpen,
    cropModalIsOpen,
    fundsModalIsOpen,
    requestInvoiceModalIsOpen,
    videoLengthNotificationIsOpen,
    unitOpeningWarningIsOpen,
    campaignActivationModalIsOpen,
    stripeModalIsOpen,
    audienceTagsWarningIsOpen
  ) =>
    summaryModalIsOpen ||
    defaultModalIsOpen ||
    charityModalIsOpen ||
    budgetAllocationModalIsOpen ||
    notificationModalIsOpen ||
    questionsModalIsOpen ||
    errorsModalIsOpen ||
    customAudiencesModalIsOpen ||
    billingModalIsOpen ||
    cropModalIsOpen ||
    fundsModalIsOpen ||
    requestInvoiceModalIsOpen ||
    videoLengthNotificationIsOpen ||
    campaignActivationModalIsOpen ||
    stripeModalIsOpen ||
    audienceTagsWarningIsOpen ||
    unitOpeningWarningIsOpen
);

export const summaryModalContentSelector = createSelector(
  uiStateSelector,
  (state) => state.modals.summary.modalContent
);

export const sidebarOpenSelector = createSelector(uiStateSelector, (state) => state.sideBarOpen);

export const stateIsEqualSelector = createSelector(uiStateSelector, (state) => state.stateIsEqual);

export const savingToastSelector = createSelector(uiStateSelector, (state) => state.savingToast);

export const uploadingToastSelector = createSelector(
  uiStateSelector,
  (state) => state.uploadingToast
);
