import React, { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';

import { DisabledNavLink } from '../../common/DisabledNavLink';

import { campaignMenu } from '../../../constants';

import s from './NewSponsorshipSetMenu.module.scss';
import { MenuOptions } from '../../common/MenuOptions';
import { SvgIcon } from '../../common/SvgIcon';
import { NewSponsorshipMenu } from '../NewSponsorshipMenu';
import { useSelector } from 'react-redux';

import {
  adSetsSelector,
  campaignSelector,
  createNewAd,
  deleteAdSet,
  duplicateAdSet,
} from '../../../store/data';
import classNames from 'classnames';
import useActiveMenuItem from '../../../hooks/useActiveMenuItem/useActiveMenuItem';
import { AnimatedMenu } from '../AnimatedMenu';

const NewSponsorshipSetMenu = ({
  handleSponsorshipSetActivation,
  sponsorshipSet,
  sponsorshipSetId,
  campaignId,
  sponsorshipSetMode,
  sponsorshipEditId,
  sponsorshipMode,
  stickyMenuItem,
  adSet,
  activeSponsorshipSet,
  isArchived,
  unitIsApproved,
}) => {
  const sponsorshipSetNav = campaignMenu.menuItems.find((menuItem) => menuItem.name === 'Ad Set');
  const sponsorshipSets = useSelector(adSetsSelector);
  const campaign = useSelector(campaignSelector);

  const isDisabled = useMemo(
    () => !campaign.isCreated || isArchived,
    [campaign.isCreated, isArchived]
  );

  const { activeMenuItem } = useActiveMenuItem({
    type: 'adSet',
    adSetId: adSet.id,
  });
  const [showSubMenu, setShowSubmenu] = useState(true);

  useEffect(() => {
    setShowSubmenu(activeMenuItem);
  }, [activeMenuItem]);

  const adSetName = useMemo(() => {
    return activeSponsorshipSet.id === adSet.id ? activeSponsorshipSet.name : adSet.name;
  }, [activeSponsorshipSet.id, adSet.id, adSet.name, activeSponsorshipSet.name]);

  // // The 'timing' and 'budget' menu options depend on sponsorshipSet id creation.
  // const isMenuItemDisabled = (item) =>
  //   item === 'timing' || item === 'budget' || item === 'charity' ? !sponsorshipSet?.id : isDisabled;

  return (
    <div className={s['sponsorshipset-menu']}>
      <div className={s['sponsorshipset-menu__item']}>
        <button
          className={
            activeMenuItem && !isDisabled
              ? classNames(s['sponsorshipset-menu__item-title'], s['active'])
              : isDisabled
              ? classNames(s['sponsorshipset-menu__item-title'], s['disabled'])
              : s['sponsorshipset-menu__item-title']
          }
        >
          <DisabledNavLink
            to={generatePath(sponsorshipSetNav.editPath, {
              cid: campaignId,
              ssid: adSet.id,
            })}
            isDisabled={isDisabled}
            isOpen={activeMenuItem}
            fragment="adset-name"
          >
            <div className={s['expand-icon']}>
              <div
                className={showSubMenu ? s['open'] : s['closed']}
                onClick={() => setShowSubmenu((prevState) => !prevState)}
              >
                <SvgIcon name="expand" />
              </div>
            </div>
            {adSet.isDirty && <SvgIcon name="warning" />}
            <div className={s['adset-icon']}>
              <SvgIcon name="adset" />
            </div>
            <span>{adSetName || 'New Ad Set'}</span>
          </DisabledNavLink>
        </button>
        <MenuOptions
          name="Ad Set"
          adSetId={adSet.id}
          items={sponsorshipSets}
          createFunction={createNewAd}
          deleteFunction={deleteAdSet}
          duplicateFunction={duplicateAdSet}
          type="adSet"
          campaignId={campaignId}
          isDisabled={isDisabled}
          isOpen={activeMenuItem && !isDisabled}
          unitIsApproved={unitIsApproved}
        />
      </div>

      <AnimatedMenu
        show={showSubMenu}
        menuItems={sponsorshipSetNav.submenuItems}
        campaignId={campaignId}
        adSetId={adSet.id}
        isDisabled={isDisabled}
        stickyMenuItem={stickyMenuItem}
      >
        {adSet.ads.map((ad) => (
          <NewSponsorshipMenu
            ad={ad}
            key={ad.id}
            adSet={adSet}
            sponsorshipMode={sponsorshipMode}
            campaignId={campaignId}
            sponsorshipSetId={sponsorshipSetId}
            sponsorshipEditId={sponsorshipEditId}
            isArchived={isArchived}
            stickyMenuItem={stickyMenuItem}
            unitIsApproved={ad.moderationStatus.toLowerCase() === 'approved' || !ad.isDraft}
          />
        ))}
      </AnimatedMenu>
    </div>
  );
};

export default NewSponsorshipSetMenu;
