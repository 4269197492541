import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useT } from '@transifex/react';

import { TabButton } from '../../common/TabButton';
import { Table } from '../../Table';

import { manageConstants } from '../../../constants';
import s from './ManageContent.module.scss';
import { SvgIcon } from '../../common/SvgIcon';
import TextInput from '../../FormElements/TextInput/TextInput';
import { constructTestId } from '../../../utils/test-ids';
import { MANAGE } from '../../../constants/test-ids/pages';
import { FormGroup } from '../../FormElements/FormGroup';
import { CustomSelect } from '../../FormElements/Selects/CustomSelect';
import {
  fetchCampaignsAsync,
  fetchSponsorshipSetsAsync,
  fetchSponsorshipsAsync,
  setIsArchive,
} from '../../../store/manage';
import { useDispatch } from 'react-redux';
import { usePagination } from '../../../hooks/usePagination';
import { ManagePlaceholder } from '../../../containers/Placeholders/ManagePlaceholder';
import { Controller } from 'react-hook-form';
import { fetchCampaignNamesAsync, fetchSponsorshipSetNamesAsync } from '../../../store/brand';
import { SuggestionsTextInput } from '../../FormElements/SuggestionsTextInput';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';

const PAGE = MANAGE;

const ManageContent = ({
  brandInfo,
  campaigns,
  sponsorshipSets,
  sponsorships,
  handleSearch,
  isArchive,
  isSearch,
  handleArchive,
  handleUnarchive,
  handleRejectionReasonClick,
  handlePerformanceClick,
  handleCampaignActivation,
  handleSponsorshipSetActivation,
  handleSponsorshipActivation,
  handleQuestionsClick,
  duplicateCampaign,
  campaignNames,
  adSetNames,
  register,
  setValue,
  control,
  getValues,
  handleSubmit,
  watchAdSets,
  watchCampaigns,
  watchStatus,
  watchSearch,
  watchLimit,
  options,
  campaignsFetching,
  adSetsFetching,
  adsFetching,
  watchTab,
}) => {
  const t = useT();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (watchTab) {
      case 1:
        if (!campaignNames.length) dispatch(fetchCampaignNamesAsync.request());
        break;
      case 2:
        if (!adSetNames.length) dispatch(fetchSponsorshipSetNamesAsync.request());
        break;
      default:
        break;
    }
  }, [adSetNames.length, campaignNames.length, dispatch, watchTab]);

  const formRef = useRef(null);

  const getPaginationData = useMemo(() => {
    if (watchTab === 0) {
      return campaigns?.total;
    }
    if (watchTab === 1) {
      return sponsorshipSets?.total;
    }
    if (watchTab === 2) {
      return sponsorships?.total;
    }
  }, [watchTab, campaigns, sponsorshipSets, sponsorships]);

  const getPaginationMethod = useMemo(() => {
    if (watchTab === 0) {
      return (value) => dispatch(fetchCampaignsAsync.request(value));
    }

    if (watchTab === 1) {
      return (value) => dispatch(fetchSponsorshipSetsAsync.request(value));
    }

    if (watchTab === 2) {
      return (value) => dispatch(fetchSponsorshipsAsync.request(value));
    }
  }, [watchTab, dispatch]);

  const getStartingPage = useMemo(() => {
    return (options.offset - options.limit) / options.limit + 2;
  }, [options.offset, options.limit]);

  const { pages, pagination, prevPage, nextPage, changePage, currentPage } = usePagination({
    data: getPaginationData,
    startFrom: getStartingPage,
    getPage: getPaginationMethod,
    options,
    setValue,
  });

  const {
    moreToFetch: moreCampaignNamesToFetch,
    fetchMore: fetchMoreCampaignNames,
    setKeyword: setCampaignNamesKeyword,
    fetchInitials: fetchInitialCampaignNames,
  } = useInfiniteScroll(fetchCampaignNamesAsync.request, campaignNames);

  const {
    moreToFetch: moreSponsorshipSetNamesToFetch,
    fetchMore: fetchMoreSponsorshipSetNames,
    setKeyword: setSponsorshipSetNamesKeyword,
    fetchInitials: fetchInitialSponsorshipSetNames,
  } = useInfiniteScroll(fetchSponsorshipSetNamesAsync.request, adSetNames, false, 'campaignId');

  const tabsItems = [
    {
      id: 0,
      title: t('Campaigns'),
      content: campaigns?.data?.length ? (
        <Table
          brandInfo={brandInfo}
          isArchive={watchStatus.value === 'archived'}
          tableHeader={
            isArchive
              ? manageConstants.campaigns.archivedCampaignsTableHeaders
              : manageConstants.campaigns.campaignsTableHeaders
          }
          campaignData={campaigns}
          handleArchive={handleArchive}
          handleUnarchive={handleUnarchive}
          handleRejectionReasonClick={handleRejectionReasonClick}
          handlePerformanceClick={handlePerformanceClick}
          handleCampaignActivation={handleCampaignActivation}
          duplicateCampaign={duplicateCampaign}
        />
      ) : (
        <div className={s['no-data__info']}>{manageConstants.campaigns.noSearchData}</div>
      ),
    },
    {
      id: 1,
      title: t('Ad Sets'),
      content: sponsorshipSets?.data?.length ? (
        <Table
          brandInfo={brandInfo}
          isArchive={watchStatus.value === 'archived'}
          tableHeader={manageConstants.adSets.adSetsTableHeaders}
          adSetsData={sponsorshipSets}
          handleArchive={handleArchive}
          handleUnarchive={handleUnarchive}
          handleRejectionReasonClick={handleRejectionReasonClick}
          handlePerformanceClick={handlePerformanceClick}
          handleSponsorshipSetActivation={handleSponsorshipSetActivation}
        />
      ) : (
        <div className={s['no-data__info']}>{manageConstants.adSets.noSearchData}</div>
      ),
    },
    {
      id: 2,
      title: t('Ads'),
      content: sponsorships?.data?.length ? (
        <Table
          tableHeader={manageConstants.ads.adsTableHeaders}
          isArchive={watchStatus.value === 'archived'}
          adsData={sponsorships}
          handleArchive={handleArchive}
          handleUnarchive={handleUnarchive}
          handleRejectionReasonClick={handleRejectionReasonClick}
          handlePerformanceClick={handlePerformanceClick}
          handleSponsorshipActivation={handleSponsorshipActivation}
          handleQuestionsClick={handleQuestionsClick}
        />
      ) : (
        <div className={s['no-data__info']}>{manageConstants.ads.noSearchData}</div>
      ),
    },
  ];

  const handleShowSelect = useCallback(() => {
    const archived = getValues('status')?.value;
    setIsArchive(archived === 'archived');
    setValue('offset', 0);
    handleSubmit(handleSearch)();
  }, [handleSubmit, getValues, handleSearch, setValue]);

  const handleClearClick = useCallback(() => {
    setValue('search', '');
    if (isSearch) {
      handleSubmit(handleSearch)();
    }
  }, [isSearch, setValue, handleSearch, handleSubmit]);

  const handleCampaignSelect = useCallback(
    (value) => {
      setValue('adsets', { label: '', value: '' });
      setValue('search', '');
      setValue('offset', 0);
      fetchInitialCampaignNames(value.label);
      fetchInitialSponsorshipSetNames('', value.value);
      handleSubmit(handleSearch)();
    },
    [
      setValue,
      fetchInitialCampaignNames,
      fetchInitialSponsorshipSetNames,
      handleSubmit,
      handleSearch,
    ]
  );

  const handleAdSetSelect = useCallback(
    (value) => {
      const campaignName = campaignNames.find(
        (campaign) => campaign.value === value.campaignId
      ).label;
      setValue('campaigns', { label: campaignName, value: value.campaignId });
      setValue('offset', 0);
      setValue('search', '');
      handleSubmit(handleSearch)();
    },
    [campaignNames, setValue, handleSubmit, handleSearch]
  );

  const handleClearAllFields = useCallback(() => {
    setValue('campaigns', '');
    setValue('adsets', '');
    setValue('status', manageConstants.statusItems[0]);
    setValue('offset', 0);
    handleSubmit(handleSearch)();
  }, [handleSubmit, handleSearch, setValue]);

  const handleSelectItemsPerPage = useCallback(() => {
    setValue('offset', 0);
    handleSubmit(handleSearch)();
  }, [handleSearch, handleSubmit, setValue]);

  const renderDoublePagination = () => {
    return document.body.getBoundingClientRect().height > window.innerHeight;
  };

  const getFetching = useCallback(
    (title) => {
      switch (title) {
        case 'Ad Sets':
          return adSetsFetching;
        case 'Ads':
          return adsFetching;
        default:
          return campaignsFetching;
      }
    },
    [adSetsFetching, adsFetching, campaignsFetching]
  );

  return (
    <div className={s['manage-content']}>
      <div className={'manage-content__toast-notifications'}>
        <ToastContainer
          className="Toastify__toast-container-notifications"
          position="top-right"
          autoClose={false}
          hideProgressBar={true}
          closeOnClick={false}
          draggable={false}
          enableMultiContainer
        />
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          closeOnClick={false}
          draggable={false}
          autoClose={5000}
          enableMultiContainer
          containerId={'top-center'}
        />
      </div>
      <h1 className={s['manage-content__title']}>{t('Your Campaigns')}</h1>
      <form
        onSubmit={handleSubmit((value) => handleSearch(value))}
        className={s['manage-content__form']}
        ref={formRef}
      >
        <div className={s['manage-content__form-tabs-section']}>
          <Controller
            control={control}
            name="tab"
            render={({ field: { onChange, value } }) =>
              tabsItems.map((tab) => (
                <div className={s['tab-wrapper']} key={tab.id}>
                  <TabButton
                    title={tab.title}
                    isActive={value === tab.id}
                    handleSelectTab={() => onChange(tab.id)}
                  />
                </div>
              ))
            }
          />
        </div>

        <div className={s['manage-content__form-search-area']}>
          <div className={s['filters']}>
            <div className={s['dropdown-select']}>
              <FormGroup title={t('Status')}>
                <CustomSelect
                  inputId={constructTestId(PAGE, 'status')}
                  options={manageConstants.statusItems}
                  isMulti={false}
                  handleSelect={handleShowSelect}
                  control={control}
                  name="status"
                  watchValue={watchStatus}
                />
              </FormGroup>
            </div>
            {(watchTab === 1 || watchTab === 2) && (
              <div className={s['dropdown-select']}>
                <FormGroup title={t('Campaign')}>
                  <SuggestionsTextInput
                    isSelect
                    control={control}
                    name="campaigns"
                    data-testid={constructTestId(PAGE, 'campaign-names')}
                    onInputChange={setCampaignNamesKeyword}
                    onChange={(value, event) => {
                      event.action === 'select-option' && handleCampaignSelect(value);
                    }}
                    data={campaignNames}
                    fetchMoreData={fetchMoreCampaignNames}
                    hasMoreData={moreCampaignNamesToFetch}
                    watchValue={watchCampaigns}
                  />
                </FormGroup>
              </div>
            )}
            {watchTab === 2 && (
              <div className={s['dropdown-select']}>
                <FormGroup title={t('Ad Set')}>
                  <SuggestionsTextInput
                    isSelect
                    control={control}
                    name="adsets"
                    data-testid={constructTestId(PAGE, 'adsets-names')}
                    onInputChange={setSponsorshipSetNamesKeyword}
                    onChange={(value, event) => {
                      event.action === 'select-option' && handleAdSetSelect(value);
                    }}
                    data={adSetNames}
                    fetchMoreData={fetchMoreSponsorshipSetNames}
                    hasMoreData={moreSponsorshipSetNamesToFetch}
                    watchValue={watchAdSets}
                  />
                </FormGroup>
              </div>
            )}
            {(watchTab === 1 || watchTab === 2) && (
              <button className={s['clear-filters']} type="button" onClick={handleClearAllFields}>
                {t('Clear all filters')}
              </button>
            )}
          </div>

          <div className={s['search-bar']}>
            <div className={s['input-container']}>
              <TextInput
                data-testid={constructTestId(PAGE, 'search')}
                type="text"
                id="search"
                name="search"
                label={t('CAMPAIGN NAME')}
                register={register}
                watchValue={watchSearch}
              />
              {watchSearch && (
                <button type="button" onClick={handleClearClick}>
                  <SvgIcon name="x" />
                </button>
              )}
            </div>

            <button type="submit" className={s['search-button']}>
              {t('Search')}
              <SvgIcon name="search" />
            </button>
          </div>
        </div>

        {renderDoublePagination && (
          <div className={s['manage-content__form-pagination-area']}>
            <div className={s['results-select']}>
              <CustomSelect
                options={manageConstants.itemsPerPage}
                handleSelect={handleSelectItemsPerPage}
                control={control}
                name="limit"
                error={null}
                styleType="gray"
                watchValue={watchLimit}
              />
            </div>
            {pages > 1 && (
              <div className={s['pagination']}>
                <div className={s['pagination-inner']}>
                  {currentPage > 1 && (
                    <div className={s['pagination-inner__arrow']} onClick={prevPage}>
                      <SvgIcon name="pagination-left" />
                    </div>
                  )}

                  {pagination.map((page) => {
                    if (!page.ellipsis) {
                      return (
                        <div
                          className={
                            s[
                              page.current
                                ? 'pagination-inner__button-active'
                                : 'pagination-inner__button'
                            ]
                          }
                          key={page.id}
                          onClick={changePage(page.id)}
                        >
                          {page.id}
                        </div>
                      );
                    } else {
                      return <div key={page.id}>&hellip;</div>;
                    }
                  })}

                  {currentPage !== pages && (
                    <div className={s['pagination-inner__arrow']} onClick={nextPage}>
                      <SvgIcon name="pagination-right" />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <div className={s['manage-content__form-table-area']}>
          <>
            {tabsItems.map((tab, index) => {
              if (watchTab === tab.id)
                return getFetching(tab?.title) ? (
                  <ManagePlaceholder key={tab.id} containerWidth={formRef?.current?.clientWidth} />
                ) : (
                  <div className={s['panel-section']} key={index}>
                    {tab.content}
                  </div>
                );

              return '';
            })}
          </>
        </div>
        <div className={s['manage-content__form-pagination-area']}>
          <div className={s['results-select']}>
            <CustomSelect
              options={manageConstants.itemsPerPage}
              handleSelect={handleSelectItemsPerPage}
              control={control}
              name="limit"
              error={null}
              styleType="gray"
              watchValue={watchLimit}
            />
          </div>
          {pages > 1 && (
            <div className={s['pagination']}>
              <div className={s['pagination-inner']}>
                {currentPage > 1 && (
                  <div className={s['pagination-inner__arrow']} onClick={prevPage}>
                    <SvgIcon name="pagination-left" />
                  </div>
                )}

                {pagination.map((page) => {
                  if (!page.ellipsis) {
                    return (
                      <div
                        className={
                          s[
                            page.current
                              ? 'pagination-inner__button-active'
                              : 'pagination-inner__button'
                          ]
                        }
                        key={page.id}
                        onClick={changePage(page.id)}
                      >
                        {page.id}
                      </div>
                    );
                  } else {
                    return <div key={page.id}>&hellip;</div>;
                  }
                })}

                {currentPage !== pages && (
                  <div className={s['pagination-inner__arrow']} onClick={nextPage}>
                    <SvgIcon name="pagination-right" />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ManageContent;
