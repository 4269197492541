export const PRICE_PER_CARBON_TONE = {
  GB: 9.45,
  US: 13.4,
  AU: 18.1,
  NZ: 18.1,
  IN: 1025.4,
  FR: 11.25,
  IE: 11.25,
  ES: 11.25,
  DE: 11.25,
};
export const PRICE_PER_TREE = 0.124;
export const CARBON_OFFSET_IMPACT_PERCENTAGE = 0.01;
