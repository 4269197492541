import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useT } from '@transifex/react';

import { Title } from '../../common/Titles/Title';
import { Tile } from '../../common/Tile';

import s from './HomeContent.module.scss';

import { constructTestId } from '../../../utils/test-ids';
import { HOME } from '../../../constants/test-ids/pages';
import PopUp from '../../common/PopUp/PopUp';

const SECTION = HOME;

const HomeContent = ({ brand, navItems }) => {
  const t = useT();

  const showReminderPopUp = useMemo(
    () => brand.accountStatus === 'unverified',
    [brand.accountStatus]
  );

  return (
    <div className={s['home__content']}>
      <div className={s['home__content--inner']}>
        <div className={s['home__content--inner__title']}>
          <Title className={s['home__content--inner__title-element']}>
            {t('Welcome')}, {brand.brandName}
          </Title>
        </div>

        <div className={s['home__content--inner__nav']}>
          {navItems.map((navItem) => {
            return (
              <Link
                key={navItem.name}
                to={navItem.path}
                className={s['home__content--inner__nav-item']}
                data-testid={constructTestId(SECTION, navItem.name)}
              >
                {navItem.name === 'Business Settings' ? (
                  <PopUp
                    customClass={s['home__content--inner__nav-pop-up']}
                    alwaysVisible={showReminderPopUp}
                    position="right"
                    primary
                    message={t('Complete your business settings to publish campaigns')}
                    noButtons
                  >
                    <Tile
                      iconName={navItem.icon}
                      title={t(navItem.name)}
                      subtitle={t(navItem.subtitle)}
                    />
                  </PopUp>
                ) : (
                  <Tile
                    iconName={navItem.icon}
                    title={t(navItem.name)}
                    subtitle={t(navItem.subtitle)}
                  />
                )}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
