import { createAction, createAsyncAction } from 'eight.js.store-common';

export const demographicsFilterRequest = createAction('@@analytics/DEMOGRAPHICS_FILTER_REQUEST');

export const fetchAcceptanceOverTimeAsync = createAsyncAction(
  '@@analytics/FETCH_ACCEPTANCE_OVER_TIME'
);

export const fetchFilteredAcceptanceOverTimeAsync = createAsyncAction(
  '@@analytics/FETCH_ACCEPTANCE_OVER_TIME_FILTERED'
);

export const fetchAnalyticsCampaignAsync = createAsyncAction(
  '@@analytics/FETCH_ANALYTICS_CAMPAIGN'
);

export const fetchAnalyticsSponsorshipAsync = createAsyncAction(
  '@@analytics/FETCH_ANALYTICS_SPONSORSHIP'
);

export const fetchAnalyticsSponsorshipSetAsync = createAsyncAction(
  '@@analytics/FETCH_ANALYTICS_SPONSORSHIP_SET'
);

export const fetchDemographicsAsync = createAsyncAction('@@analytics/FETCH_DEMOGRAPHICS');

export const fetchFilteredDemographicsAsync = createAsyncAction(
  '@@analytics/FETCH_DEMOGRAPHICS_FILTERED'
);

export const fetchHeatMapAsync = createAsyncAction('@@analytics/FETCH_HEAT_MAP');

export const fetchResponsesAsync = createAsyncAction('@@analytics/FETCH_RESPONSES');

export const fetchFilteredResponsesAsync = createAsyncAction(
  '@@analytics/FETCH_RESPONSES_FILTERED'
);

export const fetchShortAnswerResponsesAsync = createAsyncAction(
  '@@analytics/FETCH_SHORT_ANSWER_RESPONSES'
);

export const fetchFilteredShortAnswerResponsesAsync = createAsyncAction(
  '@@analytics/FETCH_SHORT_ANSWER_RESPONSES_FILTERED'
);

export const fetchTotalsAsync = createAsyncAction('@@analytics/FETCH_TOTALS');

export const fetchFilteredTotalsAsync = createAsyncAction('@@analytics/FETCH_TOTALS_FILTERED');

export const overviewFilterRequest = createAction('@@analytics/OVERVIEW_FILTER_REQUEST');

export const setOverviewFilterOption = createAction('@@analytics/SET_OVERVIEW_FILTER_OPTION');

export const setInitialFetchedData = createAction('@@analytics/SET_INITIAL_FETCHED_DATA');

export const setDemographicsFilterOption = createAction(
  '@@analytics/SET_DEMOGRAPHIC_FILTER_OPTION'
);

export const getCSVDataAsync = createAsyncAction('@@analytics/GET_CSV_DATA');

export const toggleIsLoadingCSVData = createAction('@@analytics/TOGGLE_IS_LOADING_CSV_DATA');

export const updateDownloadPercentage = createAction('@@analytics/UPDATE_DOWNLOAD_PERCENTAGE');

export const setCSVDataName = createAction('@@analytics/SET_CSV_DATA_NAME');

export const setCSVDataDefault = createAction('@@analytics/SET_CSV_DATA_DEFAULT');

export const clearCSVDataForDownload = createAction('@@analytics/CLEAR_CSV_DATA_FOR_DOWNLOAD');

export const setCSVRequestId = createAction('@@analytics/SET_CSV_REQUEST_ID');

export const checkForCSVCompletionAsync = createAsyncAction('@@analytics/CHECK_FOR_CSV_COMPLETION');

export const createPerformanceReportingCSVAsync = createAsyncAction(
  '@@analytics/CREATE_PERFORMANCE_REPORTING_CSV'
);

export const setIsResponsesCSVLoading = createAction('@@analytics/IS_RESPONSES_CSV_LOADING');
