import { useState, useCallback, useEffect } from 'react';

const usePagination = (initialState) => {
  const { data, startFrom, getPage, options = {}, setValue } = initialState;
  const perPage = options?.limit;
  const pages = Math.ceil(data / perPage);
  const pagination = [];
  const [currentPage, setCurrentPage] = useState(startFrom);

  let ellipsisLeft = false;
  let ellipsisRight = false;

  for (let i = 1; i <= pages; i++) {
    if (i === currentPage) {
      pagination.push({ id: i, current: true, ellipsis: false });
    } else {
      if (i < 2 || i > pages - 1 || i === currentPage - 1 || i === currentPage + 1) {
        pagination.push({ id: i, current: false, ellipsis: false });
      } else if (i > 1 && i < currentPage && !ellipsisLeft) {
        pagination.push({ id: i, current: false, ellipsis: true });

        ellipsisLeft = true;
      } else if (i < pages && i > currentPage && !ellipsisRight) {
        pagination.push({ id: i, current: false, ellipsis: true });
        ellipsisRight = true;
      }
    }
  }

  const changePage = useCallback(
    (page) => () => {
      if (page !== currentPage) {
        setCurrentPage(page);

        const offset = page * perPage - perPage;

        setValue('offset', offset);

        getPage({ ...options, offset });
      }
    },
    [setCurrentPage, getPage, currentPage, perPage, options, setValue]
  );

  const goToPrevPage = useCallback(() => {
    setCurrentPage((prevVal) => (prevVal - 1 === 0 ? prevVal : prevVal - 1));

    const offset = perPage * (currentPage - 2 < 0 ? 0 : currentPage - 2);

    setValue('offset', offset);

    getPage({ ...options, offset });
  }, [setCurrentPage, getPage, perPage, currentPage, options, setValue]);

  const goToNextPage = useCallback(() => {
    setCurrentPage((prevVal) => (prevVal === pages ? prevVal : prevVal + 1));

    const offset = perPage * (currentPage >= pages ? pages - 1 : currentPage);

    setValue('offset', offset);

    getPage({ ...options, offset });
  }, [setCurrentPage, pages, getPage, perPage, currentPage, options, setValue]);

  useEffect(() => {
    if (options.offset === 0) setCurrentPage(1);
  }, [options.offset]);

  return {
    pages,
    pagination,
    currentPage,
    prevPage: goToPrevPage,
    nextPage: goToNextPage,
    changePage,
  };
};

export default usePagination;
