import { routes } from '../constants';

const mainMenu = Object.freeze({
  items: [
    {
      name: 'Home',
      subtitle: '',
      path: routes.HOME,
      icon: 'home',
    },
    {
      name: 'Create Campaign',
      subtitle: 'Start a new campaign',
      path: routes.CAMPAIGN_CREATE,
      icon: 'create-campaign',
    },
    {
      name: 'Manage Campaigns',
      subtitle: 'Manage your drafted and live campaigns',
      path: routes.MANAGE,
      icon: 'manage-campaign',
    },
    {
      name: 'Business Settings',
      subtitle: 'Manage your account and payments',
      path: routes.SETTINGS,
      icon: 'settings',
    },
  ],
});

export default mainMenu;
