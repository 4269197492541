import { getTimezoneUTCText } from './dateHelpers';
export const addTextUtcStringField = (data) => {
  if (data)
    if (Array.isArray(data)) {
      // For all fetched timezones

      return data.map((obj) => {
        return formatTextUtcString(obj);
      });
    } else {
      // For brand info timezone, which is a single object
      if (Object.keys(data).length) {
        return formatTextUtcString(data);
      } else return {};
    }
};

export const removeTextUtcStringField = (data) => {
  if (Array.isArray(data)) {
    data = data.map(({ textUtcString, id, ...att }) => att);
    return data;
  } else {
    const { textUtcString, id, ...timezone } = data;
    return timezone;
  }
};

const formatTextUtcString = (obj) => {
  if (obj.utcString.includes('/')) {
    const [continent, ...rest] = obj.utcString.split('/');
    let city = rest.join('/');
    city = city.replaceAll('_', ' ');

    return {
      ...obj,
      textUtcString: getTimezoneUTCText(obj.offset) + ' ' + city + ', ' + continent,
    };
  } else
    return {
      ...obj,
      textUtcString: getTimezoneUTCText(obj.offset) + ' ' + obj.utcString + '',
    };
};
