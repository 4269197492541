import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useT } from '@transifex/react';
import LogoAndTitle from '../../../LogoAndTitle/LogoAndTitle';
import s from './ResetPasswordEmailSent.module.scss';
import { useDispatch } from 'react-redux';
import { clearData, sendResetPasswordEmailAsync } from '../../../../store/brand';
import { useRouter } from '../../../../hooks/useRouter';
import { eightAuth } from '../../../../store/eightauth';
import { ToastContainer } from 'react-toastify';
import { Button } from '../../../common/Button';
import { routes } from '../../../../constants';

const RESEND_CODE_WAITING_TIME = 30;

const ResetPasswordEmailSent = () => {
  const [resendTimer, setResendTimer] = useState(0);

  const { machineToken } = eightAuth.tokenService.getTokens();

  const t = useT();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!machineToken) {
      dispatch(clearData());
      dispatch(eightAuth.fetchMachineTokenAsync.request());
    }
  }, [dispatch, machineToken]);

  const { query, push } = useRouter();

  const sendPasswordResetEmail = useCallback(() => {
    dispatch(sendResetPasswordEmailAsync.request(query?.email));
    setResendTimer(RESEND_CODE_WAITING_TIME);

    let interval = setInterval(() => {
      setResendTimer((seconds) => {
        if (seconds) return seconds - 1;
        else return clearInterval(interval);
      });
    }, 1000);
  }, [dispatch, query?.email]);

  const handleLogin = useCallback(() => {
    push(routes.LOGIN);
  }, [push]);

  const resendTimerText = useMemo(() => (resendTimer ? `${resendTimer}s` : ''), [resendTimer]);

  return (
    <>
      <div className={s['reset-password-email-sent__container']}>
        <div className={s['reset-password-email-sent__inner']}>
          <LogoAndTitle />
          <div className={s['reset-password-email-sent__inner-title']}>
            {t('Please check your inbox')}
          </div>
          <div className={s['reset-password-email-sent__inner-body']}>
            {t('If account exists, an email will be sent with further instructions.')}
          </div>

          {query?.email && (
            <div className={s['reset-password-email-sent__inner-resend']}>
              {t('You didn’t get an email?')}
              <button
                className={
                  resendTimer
                    ? s['reset-password-email-sent__inner-resend__link-disabled']
                    : s['reset-password-email-sent__inner-resend__link']
                }
                onClick={() => sendPasswordResetEmail(query?.email)}
                disabled={resendTimer}
              >
                {t('Resend')}
              </button>
              {resendTimerText}
            </div>
          )}
          <div className={s['reset-password-email-sent__inner-button']}>
            <Button
              fullwidth
              type="button"
              styling="primary"
              callback={handleLogin}
              customText={t('Login')}
            />
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
      />
    </>
  );
};

export default ResetPasswordEmailSent;
