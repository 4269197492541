import React from 'react';

import { SvgIcon } from '../../common/SvgIcon';

import s from './NotificationsCloseButton.module.scss';

const NotificationsCloseButton = ({ dismiss, closeToast }) => (
  <div onClick={closeToast} className={s['close']}>
    <div className={s['close-btn']} onClick={dismiss}>
      <SvgIcon name="notifications-close" />
    </div>
  </div>
);

export default NotificationsCloseButton;
