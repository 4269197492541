import React from 'react';
import ContentLoader from 'react-content-loader';

const SectionTitlePlaceholder = (props) => (
  <ContentLoader
    speed={2}
    width={450}
    height={60}
    viewBox="0 0 450 60"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="12" rx="3" ry="3" width="174" height="22" />
    <rect x="0" y="43" rx="3" ry="3" width="374" height="10" />
  </ContentLoader>
);

export default SectionTitlePlaceholder;
