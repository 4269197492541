import React, { useMemo } from 'react';

import { NavHashLink } from 'react-router-hash-link';

import classNames from 'classnames';
import s from './DisabledNavLink.module.scss';

const cx = classNames.bind(s);

const DisabledNavLink = ({ isDisabled, isOpen, fragment, stickyMenuItem, id, ...props }) => {
  const getLinkClassName = useMemo(() => {
    return cx({
      [s['link']]: true,
      [s['link--active']]: stickyMenuItem === fragment && isOpen,
    });
  }, [fragment, stickyMenuItem, isOpen]);

  return (
    <>
      {isDisabled ? (
        <div className={s['disabled']}>{props.children}</div>
      ) : (
        <NavHashLink
          smooth
          to={fragment ? `${props.to}#${fragment}` : props.to}
          className={getLinkClassName}
          name-menu={fragment}
          id={id}
        >
          {props.children}
        </NavHashLink>
      )}
    </>
  );
};

export default DisabledNavLink;
