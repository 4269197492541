import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from '../../../components/Modal';

import { modalsSelector, setModalText, toggleModalByName } from '../../../store/ui';

const WithErrorModalContainer = ({ children }) => {
  const modals = useSelector(modalsSelector);
  const dispatch = useDispatch();

  const handleModalClose = useCallback(() => {
    dispatch(toggleModalByName({ name: 'errors', value: false }));
    dispatch(setModalText({ name: 'errors', text: '' }));
  }, [dispatch]);

  return (
    <>
      {children}
      {modals.errors.isOpen && (
        <Modal
          title={modals.errors.modalContent.title}
          closeModal={handleModalClose}
          noButtons
          withCloseIcon
          styling="error"
        >
          {modals.errors.modalContent.text}
        </Modal>
      )}
    </>
  );
};

export default WithErrorModalContainer;
