import { useT } from '@transifex/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../constants';

import s from './NotFoundError.module.scss';
import b from '../common/Button/Button.module.scss';
import classNames from 'classnames';

const NotFoundError = ({ text }) => {
  const t = useT();

  return (
    <div className={s['error-container']}>
      <div className={s['inner-container']}>
        <h1 className={s['header']}>{text || t('Oops! This page does not exist.')}</h1>
        <Link
          to={routes.HOME}
          className={classNames(b['btn'], b['btn__primary'], s['home-button'])}
        >
          {t('Back to Home')}
        </Link>
      </div>
    </div>
  );
};

export default NotFoundError;
