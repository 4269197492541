import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginContent } from '../../components/PagesContent/LoginContent';
import { Spinner } from '../../components/Spinner';
import { eightAuth } from '../../store/eightauth';

const Login = () => {
  const dispatch = useDispatch();

  const isLoginLoading = useSelector(eightAuth.loginLoadingSelector);

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const handleEmailChange = useCallback(
    (e) => {
      setLoginEmail(e);
    },
    [setLoginEmail]
  );

  const handlePasswordChange = useCallback(
    (e) => {
      setLoginPassword(e);
    },
    [setLoginPassword]
  );

  const handleLoginClick = useCallback(
    (form) => {
      dispatch(
        eightAuth.loginAsync.request({
          username: form.loginEmail,
          password: form.loginPassword,
        })
      );
    },
    [dispatch]
  );

  return (
    <>
      {isLoginLoading && <Spinner />}
      <LoginContent
        loginEmail={loginEmail}
        loginPassword={loginPassword}
        handleEmailChange={handleEmailChange}
        handlePasswordChange={handlePasswordChange}
        handleLoginClick={handleLoginClick}
      />
    </>
  );
};

export default Login;
