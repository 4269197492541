import React, { useEffect, useRef, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useT } from '@transifex/react';

import { Title } from '../../common/Titles/Title';
import { HomeLink } from '../../common/HomeLink';
import { FileSpecifications } from '../../common/FileSpecifications';
import { Button } from '../../common/Button';
import { UploadZone } from '../../common/UploadZone';
import { PasswordHints } from '../../common/PasswordHints';
import { Checkbox } from '../../FormElements/Checkbox';

import { FormGroup } from '../../FormElements/FormGroup';
import { TextInput } from '../../FormElements/TextInput';
import { CustomSelect } from '../../FormElements/Selects/CustomSelect';

import { ModalCrop } from '../../ModalCrop';

import { routes } from '../../../constants';

import { base64StringToFile, extractImageFileExtension } from '../../../utils/media';

import { campaignConstants } from '../../../constants';

import { validationSchema } from './validationSchema';

import s from './SignUpContent.module.scss';
import { SvgIcon } from '../../common/SvgIcon';
import { passwordRequirements } from '../../../constants/signup/passwordRequirements';
import { useDispatch } from 'react-redux';
import { signupAsync } from '../../../store/auth';
import { EMAIL_REGEXP } from '../../../utils/validate';

import { ToastContainer } from 'react-toastify';
import { sendVerificationCodeAsync } from '../../../store/brand';
import { PasswordStrengthBar } from '../../common/PasswordStrengthBar';

const RESEND_CODE_WAITING_TIME = 59;

const SignUpContent = ({ brandInfo, handleSelectField, modals, toggleModalByName }) => {
  const t = useT();
  const dispatch = useDispatch();

  const [processingBrandLogo, setProccessingBrandLogo] = useState('');
  const [currentBrandLogoUri, setCurrentBrandLogoUri] = useState('');

  const [resendTimer, setResendTimer] = useState(0);

  const [isPasswordShown, setIsPasswordShown] = useState(false);

  const [requirementsFulfilled, setRequirementsFulfilled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
  } = useForm({
    defaultValues: {
      logoUri: brandInfo.logoUri,
      logoFile: brandInfo.logoFile,
      brandName: brandInfo.brandName,
      website: brandInfo.website,
      country: brandInfo?.country,
      emailAddress: brandInfo.emailAddress,
      password: brandInfo.password,
      confirmPassword: brandInfo.confirmPassword,
      signupCode: brandInfo.signupCode,
      acceptedTermsOfUse: false,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const watchBrandName = useWatch({ control, name: 'brandName' });
  const watchEmailAddress = useWatch({ control, name: 'emailAddress' });
  const watchWebsite = useWatch({ control, name: 'website' });
  const watchPassword = useWatch({ control, name: 'password' });
  const watchSignUpCode = useWatch({ control, name: 'signupCode' });
  const watchConfirmPassword = useWatch({ control, name: 'confirmPassword' });
  const watchCountry = useWatch({ control, name: 'country' });
  const watchLogoUri = useWatch({ control, name: 'logoUri' });
  const watchAcceptedTermsOfUse = useWatch({ control, name: 'acceptedTermsOfUse' });

  const cropperRef = useRef(null);
  const isDisabledButton = useMemo(() => {
    return (
      watchLogoUri?.length > 0 &&
      watchBrandName?.length > 0 &&
      watchWebsite?.length > 0 &&
      getValues('country')?.name?.length > 0 &&
      watchEmailAddress?.length > 0 &&
      watchPassword?.length > 0 &&
      watchConfirmPassword?.length > 0 &&
      watchSignUpCode?.length > 0 &&
      watchAcceptedTermsOfUse
    );
  }, [
    getValues,
    watchBrandName,
    watchLogoUri,
    watchWebsite,
    watchEmailAddress,
    watchPassword,
    watchConfirmPassword,
    watchSignUpCode,
    watchAcceptedTermsOfUse,
  ]);

  useEffect(() => {
    reset({
      logoUri: brandInfo.logoUri,
      logoFile: brandInfo.logoFile,
      brandName: brandInfo.brandName,
      website: brandInfo.website,
      country: brandInfo?.country,
      emailAddress: brandInfo.emailAddress,
      password: brandInfo.password,
      confirmPassword: brandInfo.confirmPassword,
      signupCode: brandInfo.signupCode,
    });
  }, [
    reset,
    brandInfo.logoUri,
    brandInfo.logoFile,
    brandInfo.brandName,
    brandInfo.website,
    brandInfo.country,
    brandInfo.emailAddress,
    brandInfo.password,
    brandInfo.confirmPassword,
    brandInfo.signupCode,
  ]);

  const handleSubmitSignUpData = useCallback(() => {
    // Fix on internationalization
    const form = getValues();

    dispatch(signupAsync.request({ form }));
  }, [dispatch, getValues]);

  const handleSetBrandLogo = useCallback(
    (acceptedFiles) => {
      const logoUri = URL.createObjectURL(acceptedFiles[0]);

      setProccessingBrandLogo(logoUri);

      dispatch(toggleModalByName({ name: 'crop', value: true }));

      URL.revokeObjectURL(acceptedFiles[0]);
    },
    [dispatch, toggleModalByName]
  );

  const handleCropImage = useCallback(() => {
    const cropper = cropperRef?.current.cropper;

    const cropperData = cropper.getCroppedCanvas().toDataURL();
    const fileExtension = extractImageFileExtension(cropperData);
    const fileName = `brandLogo.${fileExtension}`;
    const croppedFile = base64StringToFile(cropperData, fileName);
    const croppedUrl = URL.createObjectURL(croppedFile);

    setValue('logoFile', croppedFile);
    setValue('logoUri', croppedUrl);

    setCurrentBrandLogoUri(croppedUrl);

    toggleModalByName({ name: 'crop', value: false });
  }, [cropperRef, toggleModalByName, setValue]);

  const handleCloseCropModal = useCallback(() => {
    toggleModalByName({ name: 'crop', value: false });
  }, [toggleModalByName]);

  const sendVerificationCode = useCallback(() => {
    dispatch(sendVerificationCodeAsync.request({ emailAddress: watchEmailAddress }));
    setResendTimer(RESEND_CODE_WAITING_TIME);

    let interval = setInterval(() => {
      setResendTimer((seconds) => {
        if (seconds) return seconds - 1;
        else return clearInterval(interval);
      });
    }, 1000);
  }, [dispatch, watchEmailAddress]);

  const verificationCodeButtonText = useMemo(
    () =>
      resendTimer
        ? t('RESEND VERIFICATION IN {timer}s', { timer: resendTimer })
        : t('SEND VERIFICATION CODE'),
    [resendTimer, t]
  );

  const requirements = useMemo(() => {
    const temp = passwordRequirements;
    temp.minLength.fulfilled = watchPassword?.length >= 6 ? true : false;
    temp.uppercase.fulfilled = watchPassword !== watchPassword?.toUpperCase() ? true : false;
    temp.lowercase.fulfilled = watchPassword !== watchPassword?.toLowerCase() ? true : false;
    temp.digit.fulfilled = /\d/.test(watchPassword) ? true : false;
    const fulfilledValue = Object.keys(temp).filter((index) => !temp[index].fulfilled).length === 0;
    setRequirementsFulfilled(fulfilledValue ? true : false);
    return temp;
  }, [watchPassword]);

  const validEmail = useMemo(
    () => new RegExp(EMAIL_REGEXP).test(watchEmailAddress),
    [watchEmailAddress]
  );

  const eyeIcon = useMemo(
    () => (isPasswordShown ? <SvgIcon name="eye" /> : <SvgIcon name="eye-off" />),
    [isPasswordShown]
  );

  return (
    <div className={s['signup']}>
      <div className={s['signup-side']}>
        <div className={s['signup-side__title']}>
          <HomeLink
            name={t('Back')}
            path={routes.LOGIN}
            style={{ padding: 0, marginBottom: '1rem' }}
          />
          <Title>{t('Create a WeAre8 Brand Account')}</Title>
          <div className={s['message']}>
            {t('Already have an account?')}
            <Link className={s['login-link']} to={routes.LOGIN}>
              {t('Login')}
            </Link>
          </div>
        </div>
      </div>
      <div className={s['signup-main']}>
        <form onSubmit={handleSubmit(handleSubmitSignUpData)}>
          <div className={s['signup-main__logo']}>
            <FormGroup
              title={t('Brand Logo')}
              subtitle={t('Upload or edit your brand logo')}
              required
            >
              <div className={s['logo-inner']}>
                <div className={s['logo-inner__upload']}>
                  <Controller
                    control={control}
                    name="logoUri"
                    render={({ field: { ref } }) => {
                      return (
                        <UploadZone
                          inputRef={ref}
                          styleType="circle"
                          name="logoUri"
                          control={control}
                          type="image"
                          error={errors.logoUri}
                          handleChange={handleSetBrandLogo}
                          contentType="image/jpeg, image/png"
                          fileUrl={currentBrandLogoUri}
                        />
                      );
                    }}
                  />
                </div>
                <div className={s['logo-inner__specs']}>
                  <FileSpecifications fileSpecs={campaignConstants.brandLogoFileSpecs} />
                </div>
              </div>
            </FormGroup>
          </div>
          <div className={s['signup-main__section']}>
            <FormGroup title={t('Brand Name')} required>
              <TextInput
                type="text"
                id="brand-name"
                name="brandName"
                label={t('ENTER YOUR BRAND NAME')}
                // formValue={brandInfo.brandName}
                // onChange={handleChangeBrandData}
                register={register}
                error={errors.brandName}
                watchValue={watchBrandName}
              />
            </FormGroup>
          </div>

          <div className={s['signup-main__section']}>
            <FormGroup title={t('Website')} required>
              <TextInput
                type="text"
                id="account-website"
                name="website"
                label={t('ENTER YOUR WEBSITE')}
                watchValue={watchWebsite}
                register={register}
                error={errors.website}
              />
            </FormGroup>
          </div>

          <div className={s['signup-main__section']}>
            <FormGroup
              title={t('Country')}
              subtitle={
                <div className={s['subtitle']}>
                  {t('The brand cannot change it’s country in the settings after sign up')}
                  <div className={s['currency']}>
                    {t('Currency')}:
                    <span className={s['currency-code']}>
                      {watchCountry?.currencySymbol
                        ? `${watchCountry.currencySymbol}${watchCountry.currencyCode}`
                        : ''}
                    </span>
                  </div>
                </div>
              }
              required
            >
              <CustomSelect
                control={control}
                name="country"
                watchValue={watchCountry}
                options={brandInfo?.fetchedCountries}
                placeholder={t('Select your country')}
                error={errors.country?.value}
              />
            </FormGroup>
          </div>

          {/* TODO Hide in task 4345
              maybe in future it will need
          */}
          {/* <div className={s['signup-main__section']}>
            <FormGroup
              title='Time Zone'
              required
            >
              <CustomSelect
                control={control}
                name='timeZone'
                defaultValue={brandInfo.timeZone}
                options={brandInfo?.country?.timezones}
                handleSelect={handleSelectField('timeZone')}
                placeholder={'Select your time zone'}
                error={errors.timeZone?.value}
              />

            </FormGroup>
          </div> */}

          <div className={s['signup-main__section']}>
            <FormGroup title={t('Email')} required>
              <TextInput
                type="email"
                id="account-email"
                name="emailAddress"
                label="EMAIL@EXAMPLE.COM"
                watchValue={watchEmailAddress}
                register={register}
                error={errors.emailAddress}
                role="presentation"
                autocomplete="off"
              />
            </FormGroup>
          </div>

          <div className={s['signup-main__section']}>
            <FormGroup title={t('Password')} required>
              <TextInput
                type={isPasswordShown ? 'text' : 'password'}
                id="account-password"
                name="password"
                label={t('CREATE A PASSWORD')}
                watchValue={watchPassword}
                register={register}
                error={errors.password}
                withIcon
                icon={eyeIcon}
                handleIconClick={() => setIsPasswordShown((prevValue) => !prevValue)}
              />
            </FormGroup>
            <PasswordHints requirements={requirements} />
            {requirementsFulfilled && <PasswordStrengthBar password={watchPassword} />}
          </div>

          <div className={s['signup-main__section']}>
            <FormGroup title={t('Confirm Password')} required>
              <TextInput
                type={isPasswordShown ? 'text' : 'password'}
                id="account-confirm-password"
                name="confirmPassword"
                label={t('RETYPE YOUR PASSWORD')}
                watchValue={watchConfirmPassword}
                register={register}
                error={errors.confirmPassword}
                withIcon
                icon={eyeIcon}
                handleIconClick={() => setIsPasswordShown((prevValue) => !prevValue)}
              />
            </FormGroup>
          </div>

          <div className={s['signup-main__section']}>
            <FormGroup title={t('Verification code')} required>
              <TextInput
                type="text"
                id="signup-code"
                name="signupCode"
                label={t('ENTER THE VERIFICATION CODE')}
                watchValue={watchSignUpCode}
                register={register}
                error={errors.signupCode}
                withActionButton
                actionButtonDisabled={!validEmail || resendTimer}
                actionButtonCallback={sendVerificationCode}
                actionButtonText={verificationCodeButtonText}
              />
            </FormGroup>
          </div>

          <div className={s['signup-main__section']}>
            <Controller
              control={control}
              name="acceptedTermsOfUse"
              render={({ field: { onChange, value } }) => (
                <FormGroup>
                  <Checkbox
                    label={
                      <div>
                        {t('By creating an account you are accepting our ')}
                        <a
                          className={s['link']}
                          href="https://www.weare8.com/legal"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('Terms & Conditions')}
                        </a>
                      </div>
                    }
                    name="acceptedTermsOfUse"
                    id="acceptedTermsOfUse"
                    value="acceptedTermsOfUse"
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                </FormGroup>
              )}
            />
          </div>

          <div className={s['signup-main__button']}>
            <Button
              customText={t('Create account')}
              type="submit"
              styling={isDisabledButton ? 'primary' : 'gray'}
              disabled={!isDisabledButton}
            />
          </div>
        </form>
      </div>
      {modals.crop.isOpen && (
        <ModalCrop
          imageUri={processingBrandLogo}
          cropperRef={cropperRef}
          actionCallback={handleCropImage}
          closeCallback={handleCloseCropModal}
        />
      )}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        draggable={false}
        limit={1}
      />
    </div>
  );
};

export default SignUpContent;
