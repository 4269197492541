const reportingSelectStyles = (disabled) => ({
  container: (provided) => ({
    ...provided,
    border: 'none',
    '&:focus, &:active': {
      border: 'none',
    },
    opacity: '1',
  }),
  control: (base, state) => ({
    ...base,
    minHeight: '50px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    color: '#fff',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomLeftRadius: '3px',
    borderBottomRightRadius: '3px',
    boxShadow: state.isSelected && '#6672fb',
    '&:hover': {
      borderColor: '#6672fb',
    },
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    color: '#fff',
    height: '40px',
    '& input': {
      height: '40px',
    },
  }),
  option: () => ({
    margin: '0',
    padding: '15px 20px',
    fontSize: '0.75em',
    letterSpacing: '0.3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      background: '#6672fb',
      color: '#fff',
    },
  }),
});

export default reportingSelectStyles;
