import { useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import querystring from 'query-string';

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(
    () => ({
      push: history.push,
      go: history.go,
      replace: history.replace,
      pathname: location.pathname,
      params: { ...params },
      query: {
        ...querystring.parse(location.search),
        ...params,
      },
      match,
      location,
      history,
    }),
    [params, match, location, history]
  );
};

export default useRouter;
