const analyticsConstants = Object.freeze({
  csvDataTypes: [
    'accepted',
    'viewedInFeed',
    'videoViewed',
    'clickThrough',
    'completed',
    'userEngagement',
    'fullReport',
  ],
  urlFragments: {
    ACCEPTED: '/accepted',
    VIEWED_IN_FEED: '/viewed-in-feed',
    VIDEO_VIEWED: '/video-viewed',
    CLICK_THROUGH: '/click-through',
    COMPLETED: '/completed',
    ANSWERED_QIESTION: `/answered-question/`,
  },
  urlFragmentsInfluencers: {
    INFLUENCERS_DATA: 'influencers-data',
    INFLUENCERSDATA: 'influencersdata',
  },
  csvDataNames: {
    ACCEPTED: 'accepted',
    VIEWED_IN_FEED: 'viewedInFeed',
    VIDEO_VIEWED: 'videoViewed',
    CLICK_THROUGH: 'clickThrough',
    COMPLETED: 'completed',
    USER_ENGAGEMENT: 'userEngagement',
    FULL_REPORT: 'fullReport',
  },
});

export default analyticsConstants;
