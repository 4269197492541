import React from 'react';

import { ReportingBox } from '../../ReportingBox';

import { Tooltip } from '../../../../common/Tooltip';
import { Countup } from '../../../../common/Countup';

import s from './ReportingOverviewTotal.module.scss';
import { isNumber } from 'lodash';

const DEFAULT = '--';

const ReportingOverviewTotal = ({
  withTooltip,
  currency,
  countup = true,
  downloadable,
  title,
  number,
  counters,
  csvDataType,
  handleDownloadCSV,
  disabled,
  percentage,
}) => (
  <ReportingBox
    downloadable={downloadable}
    csvDataType={csvDataType}
    handleDownloadCSV={handleDownloadCSV}
    disabled={disabled}
  >
    <div className={s['total__content']}>
      <div className={s['total__content-header']}>
        <div className={s['total__content-header__title']}>{title}</div>
        {withTooltip && (
          <Tooltip content="Each impression is guaranteed one second of human impression." />
        )}
      </div>

      <div className={s['total__content-number']}>
        {number ? (
          countup ? (
            <Countup currencySymbol={currency} number={number} percentage={percentage} />
          ) : (
            <>
              {percentage && `${percentage}%`}
              {(!percentage && currency) || ''}
              {!percentage && number}
            </>
          )
        ) : (
          DEFAULT
        )}
      </div>
      {counters?.length && (
        <div className={s['total__content-counter']}>
          {counters.map(({ title: counterTitle, number: counterNumber }, i) => (
            <div key={i} className={s['total__content-counter__container']}>
              <div className={s['total__content-counter__title']}>{counterTitle}</div>
              <div className={s['total__content-counter__number']}>
                {counterNumber ? (
                  countup && isNumber(counterNumber) ? (
                    <Countup currencySymbol={currency} number={counterNumber} />
                  ) : (
                    <>
                      {currency || ''}
                      {counterNumber}
                    </>
                  )
                ) : (
                  DEFAULT
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  </ReportingBox>
);

export default ReportingOverviewTotal;
