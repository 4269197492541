import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import { addBrandInfoValidationSchema } from './validationSchema';
import { TextInput } from '../../FormElements/TextInput';
import { Checkbox } from '../../FormElements/Checkbox';
import { Button } from '../../common/Button';
import { ErrorMessage } from '../../common/ErrorMessage';
import { FormGroup } from '../../FormElements/FormGroup';
import s from './AddBrandInfo.module.scss';
import { useT } from '@transifex/react';

const cx = classNames.bind(s);

const AddBrandInfo = ({ brandInfo, handleChangeBrandData, handleApproveBrandInfo }) => {
  const t = useT();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      billingEmail: brandInfo?.billingEmail,
      legalName: brandInfo?.legalName,
      brandType: brandInfo?.brandType,
      addressLineFirst: brandInfo?.addressLineFirst,
      addressLineSecond: brandInfo?.addressLineSecond,
      city: brandInfo?.city,
      postCode: brandInfo?.postCode,
      brandCountryCode: brandInfo?.brandCountryCode,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(addBrandInfoValidationSchema),
  });

  useEffect(() => {
    reset({
      billingEmail: brandInfo?.billingEmail,
      legalName: brandInfo?.legalName,
      brandType: brandInfo?.brandType,
      addressLineFirst: brandInfo?.addressLineFirst,
      addressLineSecond: brandInfo?.addressLineSecond,
      city: brandInfo?.city,
      postCode: brandInfo?.postCode,
      brandCountryCode: brandInfo?.brandCountryCode,
    });
  }, [reset, brandInfo]);

  const watchBrandCountryCode = useWatch({ control, name: 'brandCountryCode' });
  const watchBillingEmail = useWatch({ control, name: 'billingEmail' });
  const watchLegalName = useWatch({ control, name: 'legalName' });
  const watchAddressLineFirst = useWatch({ control, name: 'addressLineFirst' });
  const watchAddressLineSecond = useWatch({ control, name: 'addressLineSecond' });
  const watchCity = useWatch({ control, name: 'city' });
  const watchPostCode = useWatch({ control, name: 'postCode' });
  const watchBrandType = useWatch({ control, name: 'brandType' });

  return (
    <form
      onSubmit={handleSubmit(() => handleApproveBrandInfo(getValues()))}
      className={s['form-brand-info']}
    >
      <div
        className={cx(s['form-brand-info__section-checkboxes'], {
          [s['form-brand-info__section-checkboxes--error']]: errors?.brandType?.message,
        })}
      >
        <Checkbox
          label="Business"
          shape="circ"
          name="brandType"
          value={'business'}
          checked={watchBrandType === 'business'}
          onChange={(e) => setValue('brandType', e.target.value)}
          register={register}
        />
        <Checkbox
          label="Individual"
          shape="circ"
          name="brandType"
          value={'individual'}
          checked={watchBrandType === 'individual'}
          onChange={(e) => setValue('brandType', e.target.value)}
          register={register}
        />
        {errors.brandType && <ErrorMessage error={errors.brandType.message} />}
      </div>
      <div className={s['form-brand-info__section']}>
        <FormGroup title={t('Legal Name')}>
          <TextInput
            type="text"
            id="brand-legal-name"
            name="legalName"
            label={'Legal Name'.toUpperCase()}
            register={register}
            error={errors.legalName}
            watchValue={watchLegalName}
          />
        </FormGroup>
      </div>
      <div className={s['form-brand-info__section']}>
        <FormGroup title={t('Billing Email Address')}>
          <TextInput
            type="email"
            id="brand--biiling-email"
            name="billingEmail"
            label={t('BILLING EMAIL ADDRESS')}
            register={register}
            error={errors.billingEmail}
            watchValue={watchBillingEmail}
          />
        </FormGroup>
      </div>
      <div className={s['form-brand-info__section']}>
        <FormGroup title={t('Brand Address')}>
          <TextInput
            type="text"
            id="brand-addressLineFirst"
            name="addressLineFirst"
            label={t('ADDRESS LINE 1')}
            register={register}
            error={errors.addressLineFirst}
            watchValue={watchAddressLineFirst}
          />
          <TextInput
            type="text"
            id="brand-addressLineSecond"
            name="addressLineSecond"
            label={t('ADDRESS LINE 2 (OPTIONAL)')}
            register={register}
            error={errors.addressLineSecond}
            watchValue={watchAddressLineSecond}
            customInputFieldClass={s['form-brand-info__section-input']}
          />
          <TextInput
            type="text"
            id="brand-city"
            name="city"
            label={t('TOWN/CITY')}
            register={register}
            customInputFieldClass={s['form-brand-info__section-input']}
            error={errors.city}
            watchValue={watchCity}
          />
          <TextInput
            type="text"
            id="brand-postcode"
            name="postCode"
            label="POSTCODE"
            register={register}
            customInputFieldClass={s['form-brand-info__section-input']}
            error={errors.postCode}
            watchValue={watchPostCode}
          />
          <TextInput
            type="text"
            id="brand-country-code"
            name="brandCountryCode"
            label="COUNTRY CODE (US, GB, etc.)"
            register={register}
            customInputFieldClass={s['form-brand-info__section-input']}
            error={errors.brandCountryCode}
            watchValue={watchBrandCountryCode}
          />
        </FormGroup>
      </div>

      <Button
        customText={t('Save & Continue')}
        type="submit"
        styling="primary"
        customClass={s['form-brand-info__btn']}
      />
    </form>
  );
};

export default AddBrandInfo;
