import { useT } from '@transifex/react';
import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useTrackingScript } from '../../hooks/useTrackingScript';
import { constructTestId } from '../../utils/test-ids';
import { CustomSelect } from '../FormElements/Selects/CustomSelect';
import { TextArea } from '../FormElements/TextArea';
import { DeleteButton } from '../common/QuestionsButtons/DeleteButton';

import s from './ThirdParty.module.scss';
import { sponsorshipConstants } from '../../constants';
import { Controller, useWatch } from 'react-hook-form';

const ThirdParty = ({
  control,
  register,
  adIsLocked,
  section,
  errors,
  withDeleteButton,
  onDelete,
  index,
  setValue,
  sponsorshipCreate,
}) => {
  const t = useT();
  const scriptProvider = useWatch({ control, name: `thirdPartyTracking.${index}.provider` });
  const scriptValue = useWatch({ control, name: `thirdPartyTracking.${index}.script` });
  const [newRequest, setNewRequest] = useState(0);
  const handleToastMessage = useCallback(() => {
    toast.success(t('Tracking event sent.'));
  }, [t]);
  const handleEventTest = useCallback(() => {
    localStorage.setItem('tracking-script', JSON.stringify(scriptValue));
    window.open('/tracking-test');
  }, [scriptValue]);

  useTrackingScript(scriptValue, newRequest, handleToastMessage);

  const handleTextAreaChange = useCallback(
    (e) => {
      const value = e.target.value;
      setValue(`thirdPartyTracking.${index}`, { provider: scriptProvider, script: value });
      setNewRequest(0);
    },
    [index, scriptProvider, setValue]
  );

  const handleSelectChange = useCallback(
    (e) => {
      const optionValue = e.value;
      setValue(`thirdPartyTracking.${index}`, { provider: optionValue, script: scriptValue });
    },
    [index, scriptValue, setValue]
  );

  return (
    <div className={s['thirdParty']}>
      <div className={s['title']}>{t('CHOOSE 3RD PARTY')}</div>
      <CustomSelect
        error={errors.thirdPartyTracking ? errors.thirdPartyTracking[index]?.provider : ''}
        inputId={constructTestId(section, 'third-party-tracking-select')}
        control={control}
        name={`thirdPartyTracking.${index}`}
        watchValue={sponsorshipConstants.thirdPartyScriptOptions.find(
          (option) => option.value === scriptProvider
        )}
        handleSelect={handleSelectChange}
        options={sponsorshipConstants.thirdPartyScriptOptions}
        customOptionLabelField={'name'}
        placeholder={t('CHOOSE 3RD PARTY')}
        disabled={adIsLocked}
      />
      <div className={s['title']} style={{ marginTop: '1rem' }}>
        {t('3RD PARTY TRACKING CODE')}
      </div>
      <Controller
        render={({ field }) => (
          <TextArea
            label={t('INPUT YOUR 3RD PARTY TRACKING CODE HERE')}
            onChange={handleTextAreaChange}
            id={field.name}
            name={field.name}
            styling="multirows"
            defaultValue={field?.value?.script}
            rows={10}
            error={errors.thirdPartyTracking ? errors.thirdPartyTracking[index]?.script : ''}
            data-testid={constructTestId(section, 'third-party-tracking-script')}
            disabled={adIsLocked}
          />
        )}
        name={`thirdPartyTracking.${index}`}
        control={control}
      />
      {scriptProvider === 'doubleClick' && (
        <div
          className={s['popup']}
          onClick={handleEventTest}
          data-testid={constructTestId(section, 'third-party-tracking-script-button-test')}
          style={
            (errors.thirdPartyTracking && errors.thirdPartyTracking[index]?.script) ||
            !sponsorshipCreate.thirdPartyTrackingScripts
              ? { color: 'GrayText', pointerEvents: 'none' }
              : {}
          }
        >
          {t('Click here to emulate a test tracking event')}
        </div>
      )}

      {withDeleteButton && (
        <DeleteButton
          id={scriptProvider}
          callback={onDelete}
          disabled={adIsLocked}
          data-testid={constructTestId(section, 'delete')}
        />
      )}
    </div>
  );
};

export default ThirdParty;
