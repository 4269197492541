import axios from 'axios';
import APILockService from '../apiLock/service';

const instance = axios.create();
instance.interceptors.request.use(
  async function (config) {
    if (APILockService.isLocked(config)) {
      const prevAccessToken = config.headers.Authorization.replace('Bearer ', '');
      const { newAccessToken, tokenType } = await APILockService.waitTillUnlocked();
      if (config.tokenType === tokenType)
        config.headers = newAccessToken
          ? { ...config.headers, Authorization: `Bearer ${newAccessToken}` }
          : config.headers;
      if (prevAccessToken === newAccessToken) throw new axios.Cancel('Requests canceled');
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
