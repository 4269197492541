import React from 'react';
import ContentLoader from 'react-content-loader';

const UploadPlaceholder = ({ type, ...props }) => {
  if (type === 'circle')
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <ContentLoader
          speed={2}
          width={210}
          height={210}
          viewBox="0 0 210 210"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <circle cx="103" cy="108" r="102" />
        </ContentLoader>
        <ContentLoader
          speed={2}
          width={220}
          height={210}
          viewBox="0 0 220 210"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="0" y="12" rx="3" ry="3" width="174" height="22" />
          <rect x="0" y="40" rx="3" ry="3" width="220" height="8" />
          <rect x="0" y="61" rx="0" ry="0" width="174" height="12" />
          <rect x="0" y="81" rx="3" ry="3" width="220" height="35" />
          <rect x="0" y="125" rx="3" ry="3" width="220" height="32" />
          <rect x="0" y="167" rx="3" ry="3" width="220" height="35" />
        </ContentLoader>
      </div>
    );
  if (type === 'square')
    return (
      <ContentLoader
        speed={2}
        width={378}
        height={465}
        viewBox="0 0 378 465"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="12" rx="3" ry="3" width="174" height="22" />
        <rect x="0" y="40" rx="3" ry="3" width="269" height="8" />
        <rect x="0" y="61" rx="0" ry="0" width="197" height="12" />
        <rect x="0" y="81" rx="3" ry="3" width="308" height="35" />
        <rect x="0" y="140" rx="3" ry="3" width="308" height="32" />
        <rect x="0" y="197" rx="3" ry="3" width="308" height="35" />
        <rect x="0" y="260" rx="0" ry="0" width="320" height="320" />
      </ContentLoader>
    );
};

export default UploadPlaceholder;
