import { createReducer, initialAsyncState } from 'eight.js.store-common';

import acceptanceJSON from './acceptance.json';
import onboardingJSON from './demographics.json';
import heatMapJSON from './heatmap.json';
import responsesJSON from './responses.json';
import sponsorshipJSON from './sponsorship.json';
import sponsorshipSetJSON from './sponsorshipset.json';
import totalsJSON from './totals.json';

import { removeFromLocalStorage, writeToLocalStorage } from '../../utils/localStorage/localStorage';
import {
  checkForCSVCompletionAsync,
  clearCSVDataForDownload,
  createPerformanceReportingCSVAsync,
  fetchAcceptanceOverTimeAsync,
  fetchAnalyticsCampaignAsync,
  fetchAnalyticsSponsorshipAsync,
  fetchAnalyticsSponsorshipSetAsync,
  fetchDemographicsAsync,
  fetchFilteredAcceptanceOverTimeAsync,
  fetchFilteredDemographicsAsync,
  fetchFilteredResponsesAsync,
  fetchFilteredShortAnswerResponsesAsync,
  fetchFilteredTotalsAsync,
  fetchHeatMapAsync,
  fetchResponsesAsync,
  fetchShortAnswerResponsesAsync,
  fetchTotalsAsync,
  setCSVDataDefault,
  setCSVDataName,
  setCSVRequestId,
  setDemographicsFilterOption,
  setInitialFetchedData,
  setOverviewFilterOption,
  toggleIsLoadingCSVData,
  setIsResponsesCSVLoading,
  updateDownloadPercentage,
  getCSVDataAsync,
} from './actions';

const initialState = {
  ...initialAsyncState,
  initialFetchedData: {
    initialTotals: null,
    responses: [],
    responsesOptions: [],
    acceptanceOptions: [],
    agesOptions: [],
    osOptions: [],
    citiesOptions: [],
    gendersOptions: [],
  },
  csvDataForDownload: [],
  csvDataName: null,
  csvRequestId: null,
  csvStatus: 'pending',
  csvFileURL: null,
  isLoadingCSVData: false,
  isResponsesCSVLoading: false,
  progress: 0,
  acceptanceOverTime: null,
  analyticsCampaign: null,
  analyticsSponsorship: null,
  analyticsSponsorshipSet: null,
  demographics: null,
  heatMap: null,
  responses: null,
  shortAnswerResponses: null,
  totals: null,

  acceptanceOverTimeFilteredFetching: false,
  demographicsFilteredFetching: false,
  responsesFilteredFetching: false,
  shortAnswerFilteredResponsesFetching: false,
  totalsFilteredFetching: false,

  acceptanceOverTimeFetching: true,
  analyticsCampaignFetching: false,
  analyticsSponsorshipFetching: false,
  analyticsSponsorshipSetFetching: false,
  demographicsFetching: true,
  heatMapFetching: true,
  responsesFetching: true,
  shortAnswerResponsesFetching: true,
  totalsFetching: true,

  filterOptions: {
    overview: {
      selectedGender: [],
      selectedDevice: [],
      selectedCity: [],
      ageFrom: [],
      ageTo: [],
      allAges: {
        id: 'allAges',
        value: false,
      },
      initialDateFrom: '',
      initialDateTo: '',
      dateFrom: '',
      dateTo: '',
    },
    demographics: {
      demographicsCity: [],
      demographicsGender: [],
      demographicsDevice: [],
      demographicsAgeFrom: [],
      demographicsAgeTo: [],
      demographicsAllAges: {
        id: 'demographicsAllAges',
        value: false,
      },
      demographicsActivity: [],
      demographicsQuestion: [],
      demographicsResponse: [],
      initialDemographicsDateFrom: '',
      initialDemographicsDateTo: '',
      demographicsDateFrom: '',
      demographicsDateTo: '',
      dynamicQuestionOptions: [],
    },
  },

  dummyData: false,
};

export const analyticsReducer = createReducer('@@analytics', initialState, {
  [clearCSVDataForDownload]: ({ state }) => {
    state.csvDataForDownload = initialState.csvDataForDownload;
    state.csvDataName = null;
    state.csvRequestId = null;
    state.csvStatus = 'pending';
    state.csvFileURL = null;
  },
  [setCSVDataDefault]: ({ state }) => {
    state.csvDataName = initialState.csvDataName;
    state.csvDataForDownload = initialState.csvDataForDownload;
  },
  [setCSVDataName]: ({ state, action }) => {
    state.csvDataName = action.payload;
  },
  [updateDownloadPercentage]: ({ state, action }) => {
    state.progress = action.payload;
  },
  [toggleIsLoadingCSVData]: ({ state, action }) => {
    state.isLoadingCSVData = action.payload;
  },
  [createPerformanceReportingCSVAsync.success]: ({ state, action }) => {
    state.csvRequestId = action.payload.requestId;
    writeToLocalStorage('csvRequestId', action.payload.requestId);
  },

  [setCSVRequestId]: ({ state, action }) => {
    state.csvRequestId = action.payload;
    if (!action.payload) removeFromLocalStorage('csvRequestId');
  },

  [setInitialFetchedData]: ({ state, action }) => {
    const { name, value } = action.payload;

    state.initialFetchedData = {
      ...state.initialFetchedData,
      [name]: value,
    };
  },

  [fetchAcceptanceOverTimeAsync.request]: ({ state }) => {
    state.acceptanceOverTimeFetching = true;
  },

  [fetchAcceptanceOverTimeAsync.failure]: ({ state }) => {
    state.acceptanceOverTimeFetching = false;
  },

  [fetchAcceptanceOverTimeAsync.success]: ({ state, action }) => {
    action = state.dummyData ? { payload: acceptanceJSON } : action;

    state.acceptanceOverTime = action.payload;
    state.acceptanceOverTimeFetching = false;
  },

  [fetchFilteredAcceptanceOverTimeAsync.request]: ({ state }) => {
    state.acceptanceOverTimeFilteredFetching = true;
  },

  [fetchFilteredAcceptanceOverTimeAsync.failure]: ({ state }) => {
    state.acceptanceOverTimeFilteredFetching = false;
  },

  [fetchFilteredAcceptanceOverTimeAsync.success]: ({ state, action }) => {
    state.acceptanceOverTime = action.payload;
    state.acceptanceOverTimeFilteredFetching = false;
  },

  [fetchAnalyticsCampaignAsync.request]: ({ state }) => {
    state.analyticsCampaignFetching = true;
  },

  [fetchAnalyticsCampaignAsync.success]: ({ state, action }) => {
    state.analyticsCampaign = action.payload;
    state.analyticsCampaignFetching = false;
  },

  [fetchAnalyticsCampaignAsync.failure]: ({ state }) => {
    state.analyticsCampaignFetching = false;
  },

  [fetchAnalyticsSponsorshipAsync.request]: ({ state }) => {
    state.analyticsSponsorshipFetching = true;
  },

  [fetchAnalyticsSponsorshipAsync.failure]: ({ state }) => {
    state.analyticsSponsorshipFetching = false;
  },

  [fetchAnalyticsSponsorshipAsync.success]: ({ state, action }) => {
    action = state.dummyData ? { payload: sponsorshipJSON } : action;

    state.analyticsSponsorship = action.payload;
    state.analyticsSponsorshipFetching = false;
  },

  [fetchAnalyticsSponsorshipSetAsync.request]: ({ state }) => {
    state.analyticsSponsorshipSetFetching = true;
  },

  [fetchAnalyticsSponsorshipSetAsync.failure]: ({ state }) => {
    state.analyticsSponsorshipSetFetching = false;
  },

  [fetchAnalyticsSponsorshipSetAsync.success]: ({ state, action }) => {
    action = state.dummyData ? { payload: sponsorshipSetJSON } : action;

    state.analyticsSponsorshipSet = action.payload;
    state.analyticsSponsorshipSetFetching = false;
  },

  [fetchDemographicsAsync.request]: ({ state }) => {
    state.demographicsFetching = true;
  },

  [fetchDemographicsAsync.failure]: ({ state }) => {
    state.demographicsFetching = false;
  },

  [fetchDemographicsAsync.success]: ({ state, action }) => {
    action = state.dummyData
      ? {
          payload: onboardingJSON,
        }
      : action;

    state.demographics = action.payload;
    state.demographicsFetching = false;
  },

  [fetchFilteredDemographicsAsync.request]: ({ state }) => {
    state.demographicsFilteredFetching = true;
  },

  [fetchFilteredDemographicsAsync.failure]: ({ state }) => {
    state.demographicsFilteredFetching = false;
  },

  [fetchFilteredDemographicsAsync.success]: ({ state, action }) => {
    state.demographics = action.payload;
    state.demographicsFilteredFetching = false;
  },

  [fetchHeatMapAsync.request]: ({ state }) => {
    state.heatMapFetching = true;
  },

  [fetchHeatMapAsync.failure]: ({ state }) => {
    state.heatMapFetching = false;
  },

  [fetchHeatMapAsync.success]: ({ state, action }) => {
    action = state.dummyData
      ? {
          payload: heatMapJSON,
        }
      : action;

    state.heatMap = action.payload;
    state.heatMapFetching = false;
  },

  [fetchResponsesAsync.request]: ({ state }) => {
    state.responsesFetching = true;
  },

  [fetchResponsesAsync.failure]: ({ state }) => {
    state.responsesFetching = false;
  },

  [fetchResponsesAsync.success]: ({ state, action }) => {
    action = state.dummyData ? { payload: responsesJSON } : action;

    state.responses = action.payload;
    state.responsesFetching = false;
  },

  [fetchFilteredResponsesAsync.request]: ({ state }) => {
    state.responsesFilteredFetching = true;
  },

  [fetchFilteredResponsesAsync.failure]: ({ state }) => {
    state.responsesFilteredFetching = false;
  },

  [fetchFilteredResponsesAsync.success]: ({ state, action }) => {
    state.responses = action.payload;
    state.responsesFilteredFetching = false;
  },

  [fetchShortAnswerResponsesAsync.request]: ({ state }) => {
    state.shortAnswerResponsesFetching = true;
  },

  [fetchShortAnswerResponsesAsync.failure]: ({ state }) => {
    state.shortAnswerResponsesFetching = false;
  },

  [fetchShortAnswerResponsesAsync.success]: ({ state, action }) => {
    state.shortAnswerResponses = action.payload;

    state.shortAnswerResponsesFetching = false;
  },

  [fetchFilteredShortAnswerResponsesAsync.request]: ({ state }) => {
    state.shortAnswerFilteredResponsesFetching = true;
  },

  [fetchFilteredShortAnswerResponsesAsync.request]: ({ state }) => {
    state.shortAnswerFilteredResponsesFetching = false;
  },

  [fetchFilteredShortAnswerResponsesAsync.success]: ({ state, action }) => {
    state.shortAnswerResponses = action.payload;
    state.shortAnswerFilteredResponsesFetching = false;
  },

  [fetchTotalsAsync.request]: ({ state }) => {
    state.totalsFetching = true;
  },

  [fetchTotalsAsync.failure]: ({ state }) => {
    state.totalsFetching = false;
  },

  [fetchTotalsAsync.success]: ({ state, action }) => {
    action = state.dummyData ? { payload: totalsJSON } : action;

    state.totals = action.payload;
    state.totalsFetching = false;
  },

  [fetchFilteredTotalsAsync.request]: ({ state }) => {
    state.totalsFilteredFetching = true;
  },

  [fetchFilteredTotalsAsync.failure]: ({ state }) => {
    state.totalsFilteredFetching = false;
  },

  [fetchFilteredTotalsAsync.success]: ({ state, action }) => {
    state.totals = action.payload;
    state.totalsFilteredFetching = false;
  },

  [setOverviewFilterOption]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.filterOptions = {
      ...state.filterOptions,
      overview: {
        ...state.filterOptions.overview,
        [name]: value,
      },
    };
  },

  [setDemographicsFilterOption]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.filterOptions = {
      ...state.filterOptions,
      demographics: {
        ...state.filterOptions.demographics,
        [name]: value,
      },
    };
  },

  [getCSVDataAsync.success]: ({ state, action }) => {
    state.csvDataForDownload = action.payload;
  },

  [checkForCSVCompletionAsync.success]: ({ state, action }) => {
    state.csvStatus = action.payload.status?.toLowerCase();
    state.csvFileURL = action.payload.url;
  },

  [checkForCSVCompletionAsync.failure]: ({ state, action }) => {
    state.csvStatus = 'failed';
  },

  [setIsResponsesCSVLoading]: ({ state, action }) => {
    state.isResponsesCSVLoading = action.payload;
  },
});
