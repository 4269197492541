import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';

import { Logo } from '../../../components/Logo';
import { GeneralSidebar } from '../../../components/Sidebars/GeneralSidebar';
import { MainMenu } from '../../../components/Menus/MainMenu';

import { Home } from '../../Home';
import { Manage } from '../../Manage';
import { Reporting } from '../../Reporting';

import { modalIsOpenSelector, sidebarOpenSelector, toggleSideBar } from '../../../store/ui';

import { routes, mainMenu } from '../../../constants';
import { NotFound } from '../../NotFound';
import { CampaignNotFound } from '../../CampaignNotFound';
import { AltPageWrapper } from '../../../components/wrappers/AltPageWrapper';

const WithSidebarContainer = () => {
  const isOpen = useSelector(sidebarOpenSelector);
  const modalIsOpen = useSelector(modalIsOpenSelector);
  const dispatch = useDispatch();
  return (
    <>
      <GeneralSidebar
        modalIsOpen={modalIsOpen}
        isOpen={isOpen}
        toggleSidebar={() => dispatch(toggleSideBar())}
      >
        <Logo isOpen={isOpen} />
        <MainMenu isOpen={isOpen} items={mainMenu.items} />
      </GeneralSidebar>
      <AltPageWrapper isOpen={isOpen}>
        <Switch>
          <Route exact path={routes.HOME} component={Home} />
          <Route exact path={routes.MANAGE} component={Manage} />
          <Route exact path={`${routes.REPORTING}${routes.CAMPAIGN_ROUTE}`} component={Reporting} />
          <Route
            exact
            path={`${routes.REPORTING}${routes.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}`}
            component={Reporting}
          />
          <Route
            path={`${routes.REPORTING}${routes.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}${routes.SPONSORSHIP_ROUTE}`}
            component={Reporting}
          />
          <Route path={routes.CAMPAIGN_NOT_FOUND} component={CampaignNotFound} />
          <Route path={routes.NOT_FOUND} component={NotFound} />
          <Redirect to={routes.NOT_FOUND} />
        </Switch>
      </AltPageWrapper>
    </>
  );
};

export default WithSidebarContainer;
