import * as yup from 'yup';
import config from '../../../config';
import { numberToCurrency } from '../../../utils/budget';
import { t } from '@transifex/native';

export const addFundsValidationSchema = yup.object().shape({
  funds: yup
    .number()
    .typeError(t('The funds should contain numbers only'))
    .moreThan(
      config.stripeMinDeposit - 0.01,
      t('Sorry, minimum deposit is {minDeposit}', {
        minDeposit: numberToCurrency(config.stripeMinDeposit),
      })
    )
    .lessThan(
      1000000,
      t('Sorry, maximun deposit must be less than {maxDeposit}', {
        maxDeposit: numberToCurrency(1000000, true),
      })
    ),
});
