import React, { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useT } from '@transifex/react';

import { PaymentCard } from '../../PaymentCard';
import { SvgIcon } from '../../common/SvgIcon';

import s from './BillingPaymentCards.module.scss';

const BillingPaymentCards = ({
  handleAddCard,
  makeMethodPrimary,
  paymentMethods,
  removePaymentCard,
  handleAddBacsPayment,
  bacsDirectDebitData,
  setStripeFormError,
  countryCode,
}) => {
  const t = useT();
  const stripe = useStripe();
  const [miniModalOpen, setMiniModalOpen] = useState('');

  useEffect(() => {
    if (bacsDirectDebitData.checkoutSessionId) {
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as argument here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: bacsDirectDebitData.checkoutSessionId,
        })
        .then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          if (result.error.message) {
            setStripeFormError({
              name: 'general',
              value: result.error.message,
            });
          } else {
            setStripeFormError({ name: 'general', value: null });
          }
        });
    }
  }, [bacsDirectDebitData.checkoutSessionId, setStripeFormError, stripe]);

  return (
    <>
      <div className={s['cards-battery']}>
        {paymentMethods.length > 0 &&
          paymentMethods.map((card, index) => (
            <div key={index} className={s['payment-card']}>
              <PaymentCard
                miniModalOpen={miniModalOpen}
                setMiniModalOpen={setMiniModalOpen}
                makeMethodPrimary={makeMethodPrimary}
                removePaymentCard={removePaymentCard}
                cardBrand={card?.cardBrand}
                lastFourDigits={card?.cardLast4 || card?.bacsDebitLast4}
                expiration={`${card?.cardExpMonth}/${card?.cardExpYear}`}
                primary={card.isDefault}
                paymentMethodId={card.paymentMethodId}
                isDefault={card.isDefault}
                cardType={card?.type}
              />
            </div>
          ))}
      </div>
      {paymentMethods.length < 3 && (
        <div className={s['payment-methods']}>
          <div className={s['payment-methods__card-add']} onClick={handleAddCard}>
            <div className={s['payment-methods__card-add-central']}>
              <SvgIcon name="plus-big" />
              <div className={s['title']}>{t('add card')}</div>
            </div>
          </div>

          {countryCode === 'GB' && (
            <div className={s['payment-methods__card-add-bacs']} onClick={handleAddBacsPayment}>
              <div className={s['payment-methods__card-add-central']}>
                <SvgIcon name="plus-big" />
                <div className={s['title']}>{t('add bank account')}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BillingPaymentCards;
