import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../../../../constants';

import s from './BillingInfoBox.module.scss';

const cx = classNames.bind(s);

const BillingInfoBox = ({
  title,
  isDisabled,
  hoverText,
  hoverMessage,
  accountStatus,
  children,
}) => {
  const boxClasses = useMemo(
    () =>
      cx({
        [s['box']]: true,
        [s['disabled']]: isDisabled,
      }),
    [isDisabled]
  );

  return (
    <div className={boxClasses}>
      <div className={s['box-title']}>{title}</div>
      <div className={s['box-content']}>{children}</div>
      {(hoverText || hoverMessage) && isDisabled && (
        <div className={s['box-disabled-content']}>
          <span className={s['title']}>{hoverText}</span>
          {accountStatus === 'pending' ? (
            <span className={s['message-pending']}>{hoverMessage}</span>
          ) : (
            <Link className={s['message-unverified']} to={routes.SETTINGS_ACCOUNT_INFO}>
              {hoverMessage}
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default BillingInfoBox;
