import { store } from '../../store';

export const currencyCodeState = () => store?.getState()?.brand.brandInfo.currencyCode;
export const countryCodeState = () => store?.getState()?.brand.brandInfo.countryCode;
export const bcp47TagState = () => store?.getState()?.brand.brandInfo.bcP47LanguageTag;

export const defaultCurrencyCode = 'GBP';
export const defaultCountryCode = 'GB';
export const defaultBcp47Tag = 'en-GB';

export const currenciesSymbol = Object.freeze({
  EUR: '€',
  USD: '$',
  GBP: '£',
  AUD: '$',
  NZD: '$',
  INR: '₹',
});
