import { createReducer, initialAsyncState } from 'eight.js.store-common';

import {
  clearSummary,
  publishCampaignAsync,
  setAllModulesActivated,
  setIsPublishing,
} from './actions';

const initialState = {
  ...initialAsyncState,
  summaryState: {
    allModulesActivated: false,
  },
  fetching: false,
  error: '',
};

export const summaryReducer = createReducer('@@summary', initialState, {
  [publishCampaignAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [clearSummary]: ({ state }) => {
    state.summaryState = initialState.summaryState;
  },

  [setAllModulesActivated]: ({ state, action }) => {
    state.summaryState.allModulesActivated = action.payload;
  },

  [setIsPublishing]: ({ state, action }) => {
    state.fetching = action.payload;
  },
});
