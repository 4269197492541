import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { pdf } from '@react-pdf/renderer';
import { useT } from '@transifex/react';

import config from '../../../config';
import { Invoice } from '../../../components/Invoice';
import { addFundsValidationSchema } from './addFundsValidationSchema';
import { TextInput } from '../../FormElements/TextInput';
import { Button } from '../../common/Button';
import { useDebounce } from '../../../hooks/useDebounce';
import { FormGroup } from '../../FormElements/FormGroup';
import { CustomSelect } from '../../FormElements/Selects/CustomSelect';
import { DIGITS_AND_COMAS } from '../../../utils/validate';
import { hasExpiredCard } from '../../../utils/settingsBilling';
import s from './AddFunds.module.scss';
import { useDispatch } from 'react-redux';
import invoiceConstants from '../../../constants/invoice/invoice-data';

const AddFunds = ({
  handleApproveFunds,
  brandInfo,
  handleChangeFundsSum,
  stripeChargeError,
  setStripeChargeError,
  handleSelectPaymentMethod,
  getVatAndFee,
  vatAndFee,
  invoiceData,
}) => {
  const t = useT();

  const dispatch = useDispatch();
  const handleDownloadClick = async () => {
    if (invoiceData) {
      const Doc = <Invoice data={invoiceData} brandInfo={brandInfo} isInvoicePartner />;
      const blob = await pdf(Doc).toBlob();
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = `Invoice-${invoiceData.invoiceId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    return () => dispatch(setStripeChargeError({}));
  }, [dispatch, setStripeChargeError]);

  const stripeError = useMemo(
    () => (stripeChargeError.message ? stripeChargeError.message : ''),
    [stripeChargeError.message]
  );

  const defaultPaymentMethod = useMemo(() => {
    const defaultMethod = brandInfo.paymentMethods.find((item) => item.isDefault);
    if (defaultMethod) {
      return defaultMethod;
    }
    return [];
  }, [brandInfo.paymentMethods]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm({
    defaultValues: {
      funds: brandInfo?.funds,
      paymentMethod: defaultPaymentMethod,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: brandInfo?.isInvoicePartner ? null : yupResolver(addFundsValidationSchema),
  });

  useEffect(() => {
    reset({
      funds: brandInfo?.funds,
      paymentMethod: defaultPaymentMethod,
    });
  }, [reset, brandInfo?.funds, defaultPaymentMethod]);

  const watchFunds = watch('funds');
  const watchPaymentMethod = watch('paymentMethod');

  const debouncedFunds = useDebounce(watchFunds, 1000);

  useEffect(() => {
    if (debouncedFunds) {
      const isAmountCorrect = brandInfo.isInvoicePartner
        ? brandInfo.isInvoicePartner
        : debouncedFunds.match(DIGITS_AND_COMAS) &&
          +debouncedFunds >= config.stripeMinDeposit &&
          +debouncedFunds < 1000000;
      const activeMethod = brandInfo?.paymentMethods.find((el) => el.isDefault);
      const isPaymentMethodDefault =
        activeMethod && activeMethod.isDefault ? activeMethod.isDefault : null;

      isAmountCorrect &&
        isPaymentMethodDefault &&
        dispatch(getVatAndFee({ amount: +debouncedFunds }));
    }
  }, [
    dispatch,
    getVatAndFee,
    debouncedFunds,
    brandInfo?.paymentMethods,
    brandInfo.isInvoicePartner,
  ]);

  const isAddFundsButtonDisabled = useMemo(() => {
    const isCardExpired = hasExpiredCard(defaultPaymentMethod);
    const isAmountCorrect =
      watchFunds.match(DIGITS_AND_COMAS) &&
      +watchFunds >= config.stripeMinDeposit &&
      +watchFunds < 1000000;

    return brandInfo.isInvoicePartner ? isCardExpired : !isAmountCorrect || isCardExpired;
  }, [defaultPaymentMethod, watchFunds, brandInfo.isInvoicePartner]);

  const title = brandInfo?.isInvoicePartner ? t('Pay Now') : t('Add funds');
  const linkText = brandInfo?.isInvoicePartner ? t('View invoice') : '';

  const handleClick = () => {
    if (brandInfo?.isInvoicePartner) handleDownloadClick();
  };

  return (
    <form onSubmit={handleSubmit(handleApproveFunds)} className={s['form-funds']}>
      <div className={s['form-funds__title']}>
        {title}
        {linkText && (
          <p className={s['invoice_link']} onClick={handleClick}>
            {linkText}
          </p>
        )}
      </div>
      <div className={s['form-funds__sum']}>
        <FormGroup title={t('How much would you like to deposit?')} required>
          <TextInput
            type="text"
            id="card-funds"
            name="funds"
            label={`${brandInfo?.country?.currencyCode} ${brandInfo?.country?.currencySymbol}`}
            watchValue={watchFunds}
            error={errors.funds}
            register={register}
            disabled={brandInfo?.isInvoicePartner}
          />
        </FormGroup>
      </div>
      <div className={s['form-funds__method']}>
        <FormGroup title={t('Payment Method')}>
          <CustomSelect
            control={control}
            name="paymentMethod"
            options={brandInfo?.paymentMethods}
            handleSelect={handleSelectPaymentMethod}
            watchValue={watchPaymentMethod}
          />
        </FormGroup>
      </div>
      {stripeError.length ? (
        <span className={s['form-funds__charge-error']}>{t(stripeError)}</span>
      ) : null}
      <p className={s['form-funds__divider']} />
      <p className={s['form-funds__subtotal']}>
        {t('Subtotal')}:<span className={s['form-funds__subtotal-sum']}>{vatAndFee.subTotal}</span>
      </p>
      {brandInfo?.countryCode !== 'US' && brandInfo?.countryCode !== 'NZ' && (
        <p className={s['form-funds__vat']}>
          {invoiceConstants[brandInfo?.countryCode]?.taxType}:
          <span className={s['form-funds__vat-sum']}>{vatAndFee.vatAmount}</span>
        </p>
      )}
      <p className={s['form-funds__stripe-fee']}>
        {t('CC processing fee')}:
        <span className={s['form-funds__stripe-fee-sum']}>{vatAndFee.stripeFee}</span>
      </p>
      <p className={s['form-funds__total']}>
        {t('Total')}:<span className={s['form-funds__total-sum']}>{vatAndFee.total}</span>
      </p>
      <p className={s['form-funds__time']}>
        {t('Processing time')}: {vatAndFee.paymentProcessingTime}
      </p>
      <Button
        customText={brandInfo.isInvoicePartner ? t('Pay now') : t('Add')}
        type="submit"
        styling="primary"
        disabled={isAddFundsButtonDisabled}
      />
    </form>
  );
};

export default AddFunds;
