export const pageLoadSnippet = `
<!-- Start Eight Pixel Snippet -->
<script>
  !(function (e, t, i, n, s, c, a) {
    e[n] ||
      (((s = e[n] =
        function () {
          s.process ? s.process.apply(s, arguments) : s.queue.push(arguments);
        }).queue = []),
      (s.t = +new Date()),
      ((c = t.createElement(i)).async = 1),
      (c.src =
        "${process.env.REACT_APP_PIXEL_DOMAIN}?t=" +
        864e5 * Math.ceil(new Date() / 864e5)),
      (a = t.getElementsByTagName(i)[0]).parentNode.insertBefore(c, a));
  })(window, document, "script", "eight"),
    eight("init", "INSERT_BRAND_ID"),
    eight("event", "pageView");
</script>
<!-- End Eight Pixel Snippet -->
`;

export const pageViewTestEvent = `
eight('event', 'pageView', { testMode: true });
`;

export const htmlExampleSnippet = `
<!-- Example -->
<!DOCTYPE html>
<html lang="en">
  <head>
    put_pixel_code_here
  </head>
...
`;

export const purchaseSnippet = `
<script>
    eight('event', 'purchase', { purchaseAmount: PURCHASE_AMOUNT });
</script>
`;

export const purchaseEvent = `
eight('event', 'purchase', { purchaseAmount: PURCHASE_AMOUNT });
`;

export const purchaseTextEvent = `
eight('event', 'purchase', { purchaseAmount: PURCHASE_AMOUNT, testMode: true });
`;

export const onClickPurchase = `
<‍button onclick="eight('event', 'purchase', { purchaseAmount: PURCHASE_AMOUNT });">Purchase</button‍>
`;

export const functionPurchase = `
function onPurchaseClick() {
    eight('event', 'purchase', { purchaseAmount: PURCHASE_AMOUNT });
};
`;

export const subscriptionSnippet = `
<script>
    eight('event', 'subscription', { customerEmail: CUSTOMER_EMAIL });
</script>
`;

export const subscriptionEvent = `
eight('event', 'subscription', { customerEmail: CUSTOMER_EMAIL });
`;

export const subscriptionTestEvent = `
eight('event', 'subscription', { customerEmail: CUSTOMER_EMAIL, testMode: true });
`;

export const onClickSubscription = `
<‍button onclick="eight('event', 'subscription', { customerEmail: CUSTOMER_EMAIL });">Subscribe</button‍>
`;

export const functionSubscription = `
function onSubscriptionClick() {
    eight('event', 'subscription', { customerEmail: CUSTOMER_EMAIL });
};
`;

export const pixelTestingEventsHeader = ['Event name', 'Data', 'Date'];
