import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useT } from '@transifex/react';

import { routes } from '../../../../constants';

import { useRouter } from '../../../../hooks/useRouter';

import { Modal } from '../../../../components/Modal';
import { Layout } from '../../../../components/Layout';
import { ScrollToTopWrapper } from '../../../../components/wrappers/ScrollToTopWrapper';
import { Summary } from '../../../../components/PagesContent/CreateContent/Summary';
import { AddBrandInfo } from '../../../../components/ModalsContent/AddBrandInfo';

import {
  campaignIsPublishingSelector,
  publishCampaignAsync,
  summaryFetchingSelector,
  updateBrandInfoFromCampaignSummaryAsync,
} from '../../../../store/campaign/summary';

import {
  modalsSelector,
  setModalContent,
  summaryModalContentSelector,
  toggleModalByName,
  setModalDisplayMode,
  setShowModalFunds,
  uploadingToastSelector,
} from '../../../../store/ui';

import {
  brandInfoSelector,
  setBrandData,
  setDefaultBrandInfo,
  toggleIsBrandInfoSaved,
  isBrandInfoSavedSelector,
} from '../../../../store/brand';
import { SummaryPlaceholder } from '../../../Placeholders/SummaryPlaceholder';
import { Spinner } from '../../../../components/Spinner';
import {
  campaignDataFetchingSelector,
  campaignSummarySelector,
  unpublishedChangesSelector,
} from '../../../../store/data';
import invoiceConstants from '../../../../constants/invoice/invoice-data';
import { budgetParser } from '../../../../utils/budget';

const CampaignSummaryForm = () => {
  const t = useT();
  const dispatch = useDispatch();

  const brandInfo = useSelector(brandInfoSelector);
  const campaignSummary = useSelector(campaignSummarySelector);
  const modals = useSelector(modalsSelector);
  const summaryModalContent = useSelector(summaryModalContentSelector);
  const summaryIsFetching = useSelector(summaryFetchingSelector);
  const isBrandInfoSaved = useSelector(isBrandInfoSavedSelector);
  const fetching = useSelector(campaignDataFetchingSelector);
  const isProcessing = useSelector(uploadingToastSelector);
  const campaignIsPublishing = useSelector(campaignIsPublishingSelector);
  const unpublishedChanges = useSelector(unpublishedChangesSelector);

  const { isArchive } = campaignSummary;

  const { push, location } = useRouter();

  useEffect(() => {
    return () => dispatch(toggleModalByName({ name: 'campaignActivation', value: false }));
  }, [dispatch]);

  const handleSummaryModalClose = useCallback(() => {
    dispatch(toggleModalByName({ name: 'summary', value: false }));
    dispatch(setModalContent({ name: 'summary', content: { title: 'Warning', text: '' } }));
  }, [dispatch]);

  const campaignBudget = useMemo(() => {
    const allBudgets = campaignSummary?.adSets.map((adSet) => budgetParser(adSet.budget)) || [];
    const budgetSum = allBudgets.length ? allBudgets.reduce((a, b) => a + b) : 0;
    return budgetSum;
  }, [campaignSummary.adSets]);

  const isNotEnoughFunds = useMemo(() => {
    return (
      !brandInfo.isInvoicePartner && parseFloat(brandInfo.balance) < parseFloat(campaignBudget)
    );
  }, [brandInfo.balance, brandInfo.isInvoicePartner, campaignBudget]);

  const handleCampaignActivationModalClose = useCallback(() => {
    dispatch(toggleModalByName({ name: 'campaignActivation', value: false }));
    !modals.campaignActivation.displayMode && dispatch(setDefaultBrandInfo());
    !modals.campaignActivation.displayMode &&
      dispatch(setModalDisplayMode({ name: 'campaignActivation', value: true }));
  }, [dispatch, modals.campaignActivation.displayMode]);

  const handleAddFunds = useCallback(() => {
    if (!brandInfo.paymentMethods.length) {
      brandInfo.countryCode !== 'GB' &&
        dispatch(setModalDisplayMode({ name: 'billing', value: false }));
      dispatch(toggleModalByName({ name: 'billing', value: true }));
      dispatch(setShowModalFunds({ name: 'billing', value: true }));
    } else {
      dispatch(toggleModalByName({ name: 'funds', value: true }));
    }
    push(`${routes.SETTINGS_BILLING}?returnTo=${location.pathname}`);
  }, [brandInfo.paymentMethods, brandInfo.countryCode, push, location.pathname, dispatch]);

  const modalTitle = useMemo(
    () =>
      modals.campaignActivation.displayMode ? t('Not enough funds') : t('Add Company address'),
    [modals.campaignActivation.displayMode, t]
  );

  const modalSubTitle = useMemo(
    () =>
      modals.campaignActivation.displayMode
        ? t(`Sorry, not enough available funds to cover the specified campaign budget.`)
        : t(
            `WeAre8 is required by law to charge {taxType}. Please provide information below so we could display it on Invoices/Receipts and you could get {taxType} back.`,
            {
              taxType: invoiceConstants[brandInfo?.countryCode]?.taxType,
            }
          ),
    [modals.campaignActivation.displayMode, t, brandInfo.countryCode]
  );

  const handleApproveBrandInfo = useCallback(
    (payload) => {
      dispatch(updateBrandInfoFromCampaignSummaryAsync.request(payload));
    },
    [dispatch]
  );

  useEffect(() => {
    if (isBrandInfoSaved) {
      if (isNotEnoughFunds) {
        dispatch(setModalDisplayMode({ name: 'campaignActivation', value: true }));
      } else if (!brandInfo.paymentMethods.length && isNotEnoughFunds) {
        handleAddFunds();
      }
    }

    return () => dispatch(toggleIsBrandInfoSaved(false));
  }, [isBrandInfoSaved, brandInfo.paymentMethods, handleAddFunds, dispatch, isNotEnoughFunds]);

  const handleChangeBrandData = useCallback(
    (e) => {
      const { name, value } = e.target;

      if (!/^\s+$/.test(value)) {
        //string contains not only whitespace
        dispatch(setBrandData({ name, value }));
      }
    },
    [dispatch]
  );

  return (
    <Layout>
      <ScrollToTopWrapper>
        {campaignIsPublishing && <Spinner />}
        {fetching ? (
          <SummaryPlaceholder />
        ) : (
          <Summary
            isArchive={isArchive}
            brandInfo={brandInfo}
            campaign={campaignSummary}
            publishCampaign={publishCampaignAsync.request}
            toggleModalByName={toggleModalByName}
            isNotEnoughFunds={isNotEnoughFunds}
            setModalDisplayMode={setModalDisplayMode}
            isProcessing={isProcessing.isOpen}
            unpublishedChanges={unpublishedChanges}
          />
        )}
      </ScrollToTopWrapper>
      {modals.summary.isOpen && (
        <Modal
          title={modals.summary.modalContent.title}
          closeModal={handleSummaryModalClose}
          customButtonText={t('Got It')}
          actionCallback={handleSummaryModalClose}
          withoutCancel={modals.summary.modalContent.withoutCancel}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {summaryModalContent.text}
            <span style={{ fontSize: '0.8rem', marginTop: '2rem' }}>
              {summaryModalContent.details}
            </span>
          </div>
        </Modal>
      )}
      {modals.campaignActivation.isOpen && (
        <>
          {summaryIsFetching && <Spinner />}
          <Modal
            title={modalTitle}
            closeModal={handleCampaignActivationModalClose}
            customButtonText={t('Add funds')}
            actionCallback={handleAddFunds}
            withSubmit={!modals.campaignActivation.displayMode}
          >
            {modalSubTitle}
            {!modals.campaignActivation.displayMode && (
              <AddBrandInfo
                handleApproveBrandInfo={handleApproveBrandInfo}
                brandInfo={brandInfo}
                handleChangeBrandData={handleChangeBrandData}
                loading={summaryIsFetching}
              />
            )}
          </Modal>
        </>
      )}
    </Layout>
  );
};

export default CampaignSummaryForm;
