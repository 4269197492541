import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouter } from '../../../../hooks/useRouter';

import { yupResolver } from '@hookform/resolvers/yup';

import { Layout } from '../../../../components/Layout';
import { CampaignAndBrand } from '../../../../components/PagesContent/CreateContent/CampaignAndBrand';
import { ScrollToTopWrapper } from '../../../../components/wrappers/ScrollToTopWrapper';

import { campaignConstants, fragments, routes } from '../../../../constants';

import {
  activateCampaignFromCreationAsync,
  campaignCreateSelector,
  campaignFetchingSelector,
  campaignIdSelector,
  deactivateCampaignFromCreationAsync,
  toggleActivateCampaign,
  updateCampaignAsync,
} from '../../../../store/campaign/campaign';

import {
  modalsSelector,
  savingToastSelector,
  setStickyMenuItem,
  toggleModalByName,
} from '../../../../store/ui';

import { DevTool } from '@hookform/devtools';
import { useT } from '@transifex/react';
import { useForm } from 'react-hook-form';
import { generatePath } from 'react-router';
import { useUnmount } from 'react-use';
import { Modal } from '../../../../components/Modal';
import { NewCharity } from '../../../../components/ModalsContent/NewCharity';
import { useActiveForm } from '../../../../hooks/useActiveForm';
import { useDirtyFields } from '../../../../hooks/useDirtyFields';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';
import {
  brandInfoSelector,
  fetchUniqueCampaignNamesAsync,
  previousUniqueCampaignNamesSelector,
} from '../../../../store/brand';
import {
  adSetsSelector,
  campaignDataFetchingSelector,
  setCampaignIsDirty,
} from '../../../../store/data';
import { getDefaultCharityByCountryCode } from '../../../../utils/defaultCampaignData';
import preventSubmitOnEnter from '../../../../utils/preventSubmitOnEnter';
import { CampaignPlaceholder } from '../../../Placeholders/CampaignPlaceholder';
import { validationSchema } from './validationSchema';

const CampaignForm = () => {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const t = useT();

  const { brandName, logoUri, countryCode } = useSelector(brandInfoSelector);
  const campaignDraft = useSelector(campaignCreateSelector);
  const campaignId = useSelector(campaignIdSelector);
  const fetching = useSelector(campaignDataFetchingSelector);
  const modals = useSelector(modalsSelector);
  const previousCampaignNames = useSelector(previousUniqueCampaignNamesSelector);
  const campaignFetching = useSelector(campaignFetchingSelector);
  const allAdSets = useSelector(adSetsSelector);
  const saving = useSelector(savingToastSelector).isOpen;

  const campaignIsLocked = !campaignDraft.isDraft;

  useActiveForm({ type: 'campaign', allAdSets });

  const firstAdSetId = useMemo(() => allAdSets[0]?.id, [allAdSets]);

  useEffect(() => () => dispatch(setStickyMenuItem(null)), [dispatch]);

  const [processingBrandLogo, setProccessingBrandLogo] = useState('');
  const [currentBrandLogoUri, setCurrentBrandLogoUri] = useState('');

  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      name: campaignDraft.name,
      campaignBrandName: campaignDraft?.campaignBrandName || brandName,
      campaignBrandLogoUri: campaignDraft?.campaignBrandLogoUri || logoUri,
      category: campaignDraft?.category || campaignConstants.objectives[0].value,
      campaignBrandLogoFile: campaignDraft?.campaignBrandLogoFile,
      selectedCharity:
        campaignDraft?.selectedCharity ?? getDefaultCharityByCountryCode(countryCode),
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const { shouldSave, dirtyFieldsList } = useDirtyFields({
    storedDirtyFields: campaignDraft?.dirtyFields,
    fieldNames: campaignConstants.fieldNames,
    isDirty,
    formDirtyFields: dirtyFields,
  });

  useUnmount(() => {
    shouldSave &&
      dispatch(
        setCampaignIsDirty({
          isDirty: true,
          form: getValues(),
          dirtyFields: { ...dirtyFields, ...campaignDraft.dirtyFields },
        })
      );
  });

  useEffect(() => {
    reset(
      {
        name: campaignDraft.name,
        campaignBrandName: campaignDraft?.campaignBrandName || brandName,
        campaignBrandLogoUri: campaignDraft?.campaignBrandLogoUri || logoUri,
        category: campaignDraft?.category || campaignConstants.objectives[0].value,
        campaignBrandLogoFile: campaignDraft?.campaignBrandLogoFile,
        selectedCharity:
          campaignDraft?.selectedCharity ?? getDefaultCharityByCountryCode(countryCode),
      },
      { keepDirtyValues: true, keepDirty: true }
    );

    if (!dirtyFields?.campaignBrandLogoUri)
      setCurrentBrandLogoUri(campaignDraft?.campaignBrandLogoUri || logoUri);
  }, [reset, campaignDraft, dirtyFields?.campaignBrandLogoUri, brandName, logoUri, countryCode]);

  const handleSubmitForm = useCallback(
    (form) => {
      if (shouldSave || !campaignId) {
        dispatch(updateCampaignAsync.request(form));
      } else {
        push(
          generatePath(routes.SPONSORSHIP_SET_EDIT, {
            cid: campaignId,
            ssid: firstAdSetId,
          }) + fragments.AD_SET_NAME
        );
      }
    },
    [shouldSave, dispatch, push, campaignId, firstAdSetId]
  );

  const handleCloseCharityModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'charity', value: false }));
  }, [dispatch]);

  const handleCampaignActivation = useCallback(
    (e) => {
      const checked = e.target.checked;
      dispatch(toggleActivateCampaign(checked));
      dispatch(
        checked
          ? activateCampaignFromCreationAsync.request()
          : deactivateCampaignFromCreationAsync.request()
      );
    },
    [dispatch]
  );

  const {
    moreToFetch: moreNamesToFetch,
    fetchMore: fetchMoreNames,
    setKeyword: setNamesKeyword,
  } = useInfiniteScroll(fetchUniqueCampaignNamesAsync.request, previousCampaignNames);

  return (
    <Layout>
      <ScrollToTopWrapper>
        {fetching ? (
          <CampaignPlaceholder />
        ) : (
          <form onKeyDown={preventSubmitOnEnter} onSubmit={handleSubmit(handleSubmitForm)}>
            <CampaignAndBrand
              fetchMoreCampaignNames={fetchMoreNames}
              moreCampaignNamesToFetch={moreNamesToFetch}
              setNamesKeyword={setNamesKeyword}
              handleSubmitForm={handleSubmitForm}
              campaignDraft={campaignDraft}
              campaignConstants={campaignConstants}
              modals={modals}
              previousCampaignNames={previousCampaignNames}
              toggleModalByName={toggleModalByName}
              setValue={setValue}
              watch={watch}
              register={register}
              control={control}
              errors={errors}
              trigger={trigger}
              currentBrandLogoUri={currentBrandLogoUri}
              processingBrandLogo={processingBrandLogo}
              setCurrentBrandLogoUri={setCurrentBrandLogoUri}
              setProccessingBrandLogo={setProccessingBrandLogo}
              saving={saving}
              shouldSave={shouldSave || !campaignId}
              handleCampaignActivation={handleCampaignActivation}
              campaignIsLocked={campaignIsLocked}
              dirtyFieldsList={dirtyFieldsList}
            />
          </form>
        )}
      </ScrollToTopWrapper>
      {modals.charity.isOpen && (
        <Modal title={t('Add a Preferred Charity')} closeModal={handleCloseCharityModal} withSubmit>
          <NewCharity campaignDraft={campaignDraft} campaignFetching={campaignFetching} />
        </Modal>
      )}
    </Layout>
  );
};

export default CampaignForm;
