export const unitSearch = (id, data, unitTitle) => {
  switch (unitTitle) {
    case 'Campaign':
      return data.filter((unit) => unit.id === id)[0];
    case 'SponsorshipSet':
      return data.filter((unit) => unit.sponsorshipSetId === id)[0];
    case 'Sponsorship':
      return data.filter((unit) => unit.sponsorshipId === id)[0];
    default:
      return null;
  }
};

export const waitForDispatch = (action, dispatch) =>
  new Promise((resolve) => {
    dispatch(action);
    resolve();
  });

export const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));
