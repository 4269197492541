import { createAsyncAction, createAction } from 'eight.js.store-common';

// ASYNC

export const activateCampaignAsync = createAsyncAction('@@manage/ACTIVATE_CAMPAIGN');

export const activateSponsorshipSetAsync = createAsyncAction('@@manage/ACTIVATE_SPONSORSHIP_SET');

export const activateSponsorshipAsync = createAsyncAction('@@manage/ACTIVATE_SPONSORSHIP');

export const dismissNotificationAsync = createAsyncAction('@@manage/DISMISS_NOTIFICATION');

export const deactivateSponsorshipAsync = createAsyncAction('@@manage/DEACTIVATE_SPONSORSHIP');

export const deactivateSponsorshipSetAsync = createAsyncAction(
  '@@manage/DEACTIVATE_SPONSORSHIP_SET'
);
export const deactivateCampaignAsync = createAsyncAction('@@manage/DEACTIVATE_CAMPAIGN');

export const fetchCampaignsAsync = createAsyncAction('@@manage/FETCH_CAMPAIGNS_LIST');

export const fetchSponsorshipSetsAsync = createAsyncAction('@@manage/FETCH_SPONSORSHIP_SETS_LIST');

export const fetchSponsorshipsAsync = createAsyncAction('@@manage/FETCH_SPONSORSHIPS_LIST');

export const fetchNotificationsAsync = createAsyncAction('@@manage/FETCH_NOTIFICATIONS');

// SYNC

export const clearReceivedNotifications = createAction('@@manage/CLEAR_RECEIVED_NOTIFICATIONS');

export const fetchAllUnits = createAction('@@manage/FETCH_ALL_UNITS');

export const removeNotificationFromArray = createAction('@@manage/REMOVE_NOTIFICATION_FROM_ARRAY');

export const setCampaignIsActive = createAction('@@manage/SET_CAMPAIGN_IS_ACTIVE');

export const setCampaignIsArchive = createAction('@@manage/SET_CAMPAIGN_IS_ARCHIVE');

export const setCampaignIsUnarchive = createAction('@@manage/SET_CAMPAIGN_IS_UNARCHIVE');

export const setCampaignDeliveryStatus = createAction('@@manage/SET_CAMPAIGN_DELIVERY_STATUS');

export const setSponsorshipSetDeliveryStatus = createAction(
  '@@manage/SET_SPONSORSHIP_SET_DELIVERY_STATUS'
);
export const setSponsorshipSetsDeliveryStatus = createAction(
  '@@manage/SET_SPONSORSHIP_SETS_DELIVERY_STATUS'
);

export const setIsArchive = createAction('@@manage/SET_IS_ARCHIVE');

export const setIsSearch = createAction('@@manage/SET_IS_SEARCH');

export const setSponsorshipSetIsActive = createAction('@@manage/SET_SPONSORSHIP_SET_IS_ACTIVE');

export const setSponsorshipSetsAreActive = createAction('@@manage/SET_SPONSORSHIP_SETS_ARE_ACTIVE');

export const setSponsorshipIsActive = createAction('@@manage/SET_SPONSORSHIP_IS_ACTIVE');

export const setSponsorshipsAreActive = createAction('@@manage/SET_SPONSORSHIPS_ARE_ACTIVE');

export const setSponsorshipDeliveryStatus = createAction(
  '@@manage/SET_SPONSORSHIP_DELIVERY_STATUS'
);
export const setSponsorshipsDeliveryStatus = createAction(
  '@@manage/SET_SPONSORSHIPS_DELIVERY_STATUS'
);

export const setManageIsFetching = createAction('@@manage/SET_IS_FETCHING');

export const setCampaignsAreFetching = createAction('@@manage/SET_CAMPAIGNS_ARE_FETCHING');

export const setAdSetsAreFetching = createAction('@@manage/SET_AD_SETS_ARE_FETCHING');

export const setAdsAreFetching = createAction('@@manage/SET_ADS_ARE_FETCHING');

export const setDataArray = createAction('@@manage/SET_DATA_ARRAY');
