import React, { useCallback } from 'react';

import ReactPlayer from 'react-player';
import { useT } from '@transifex/react';

import { DeliveryStatus } from '../DeliveryStatus';
import { SvgIcon } from '../common/SvgIcon';

import { Switch } from '../FormElements/Switch';
import PopUp from './../common/PopUp/PopUp';

import { budgetFormatter, calculateDailyBudget, numberToCurrency } from '../../utils/budget';

import { routes } from '../../constants';
import s from './Table.module.scss';
import { Link } from 'react-router-dom';
import { generatePath } from 'react-router';
// import { useSelector } from 'react-redux';
// import { receivedCampaignsSelector } from '../../store/manage';
import classNames from 'classnames';
import Tooltip from '../common/Tooltip/Tooltip';
import { calculateCampaignDuration } from '../../utils/date/dateHelpers';
import {
  formatFrequency,
  formatRate,
  processReceivedText,
  setBrandDateFormat,
} from '../../utils/receivedData';
import { FeatureFlag } from '../common/FeatureFlag';

const Table = ({
  brandInfo,
  tableHeader,
  campaignData,
  adSetsData,
  adsData,
  isArchive,
  handleArchive,
  handleRejectionReasonClick,
  handleCampaignActivation,
  handleSponsorshipSetActivation,
  handleSponsorshipActivation,
  handleQuestionsClick,
  duplicateCampaign,
}) => {
  const t = useT();

  const getNumberToCurrency = (value, noDecimals) =>
    value ? numberToCurrency(value, noDecimals) : '-';

  // const campaigns = useSelector(receivedCampaignsSelector);

  // const hasStats = useCallback((accepted, completed, clickThrough, isActive) => {
  //   return completed || accepted || clickThrough || isActive;
  // }, []);

  const dailyBudget = (startDate, endDate, budget) =>
    calculateDailyBudget(calculateCampaignDuration(startDate, endDate), budget);

  const tableContainerRef = useCallback((node) => {
    function DoubleScroll(element) {
      var scrollbar = document.createElement('div');
      scrollbar.appendChild(document.createElement('div'));
      scrollbar.style.overflow = 'auto';
      scrollbar.style.overflowY = 'hidden';
      scrollbar.firstChild.style.width = element.scrollWidth + 'px';
      scrollbar.firstChild.style.paddingTop = '1px';
      scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
      scrollbar.onscroll = function () {
        element.scrollLeft = scrollbar.scrollLeft;
      };
      element.onscroll = function () {
        scrollbar.scrollLeft = element.scrollLeft;
      };
      element.parentNode.insertBefore(scrollbar, element);
    }

    if (node) DoubleScroll(node);
  }, []);

  const calculatedStatus = (deliveryStatus, moderationStatus) => {
    if (moderationStatus !== 'approved') return moderationStatus;
    return deliveryStatus;
  };

  return (
    <>
      <div className={s['table-wrap']} ref={tableContainerRef}>
        <div className={s['table']}>
          <div className={s['table-head']}>
            {tableHeader.map((cell, index) => (
              <div key={index} className={s['table-head__cell']}>
                {t(cell?.title)}
              </div>
            ))}
          </div>
          <div className={s['table-body']}>
            {campaignData &&
              campaignData?.data.map((campaign, index) => {
                // const { completed, accepted, clickThrough } =
                //   campaigns.data.find((camp) => camp.id === campaign.id) || {};
                return (
                  <div key={index} className={s['table-row']}>
                    <div className={s['table-cell']}>
                      <div className={s['table-cell__switch']}>
                        <Switch
                          id={campaign?.id}
                          value={campaign?.id}
                          checked={campaign?.isActive}
                          name={campaign?.name}
                          onChange={handleCampaignActivation}
                          disabled={isArchive}
                        />
                      </div>
                    </div>
                    <div className={s['table-cell__link']}>
                      <Link
                        className={s['name']}
                        to={
                          isArchive
                            ? generatePath(routes.CAMPAIGN_SUMMARY, { cid: campaign?.id })
                            : generatePath(routes.CAMPAIGN_EDIT, {
                                cid: campaign?.id,
                              })
                        }
                      >
                        {campaign?.name}
                      </Link>
                      <div className={s['reporting']}>
                        {/* {!hasStats(accepted, completed, clickThrough, campaign?.isActive) ? (
                          <div className={classNames(s['reporting-performance'], s['disabled'])}>
                            <SvgIcon name="performance" />
                            <span className={s['link']} id={campaign?.id}>
                              Performance
                            </span>
                          </div>
                        ) :  */}

                        <Link
                          to={
                            generatePath(`${routes.REPORTING}${routes.CAMPAIGN_ROUTE}`, {
                              cid: campaign?.id,
                            }) + '?type=overview'
                          }
                          className={classNames(s['reporting-performance'])}
                        >
                          <SvgIcon name="performance" />
                          <span className={s['link']} id={campaign?.id}>
                            {t('Performance')}
                          </span>
                        </Link>
                        {/* } */}
                        <div className={classNames(s['reporting-duplicate'], s['disabled'])}>
                          <PopUp
                            okText={t('Duplicate')}
                            cancelText={t('Cancel')}
                            message={t('Are you sure you want to duplicate this campaign?')}
                            onConfirm={() => {
                              duplicateCampaign(campaign?.id);
                            }}
                            position="right"
                            disabled
                          >
                            <div className={s['reporting-duplicate__content']}>
                              <SvgIcon name="copy" />
                              <span className={s['link']} id={campaign?.id}>
                                {t('Duplicate')}
                              </span>
                            </div>
                          </PopUp>
                        </div>
                      </div>
                    </div>
                    <div className={s['table-cell__status']}>
                      <div className={s['table-cell__status-inner']}>
                        <div className={s['status-info']}>
                          <DeliveryStatus
                            status={calculatedStatus(
                              campaign.deliveryStatus,
                              campaign.moderationStatus
                            )}
                          />
                          <div className={s['status-text']}>
                            {processReceivedText(
                              calculatedStatus(campaign.deliveryStatus, campaign.moderationStatus)
                            )}
                          </div>
                        </div>
                        {campaign?.moderationReason ? (
                          <div
                            id={campaign?.id}
                            className={s['reason-link']}
                            onClick={handleRejectionReasonClick('campaign')}
                          >
                            {t('Details')}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className={s['table-cell']}>
                      {setBrandDateFormat(campaign?.earliestStartDate, brandInfo.countryCode)}
                    </div>
                    <div className={s['table-cell']}>
                      {setBrandDateFormat(campaign?.latestEndDate, brandInfo.countryCode)}
                    </div>
                    <div className={s['table-cell']}>
                      {numberToCurrency(parseFloat(campaign?.budget), false, false, 6)}
                    </div>
                    <div className={s['table-cell']}>
                      {numberToCurrency(parseFloat(campaign?.moneySpent), false, false, 6)}
                    </div>
                    {/* <div className={s['table-cell']}>{campaign?.reach}</div> */}
                    <div className={s['table-cell']}>{campaign?.accepted || '-'}</div>
                    <div className={s['table-cell']}>{campaign?.completed || '-'}</div>
                    <div className={s['table-cell']}>{campaign?.clickThrough || '-'}</div>
                    <div className={s['table-cell']}>{formatRate(campaign?.clickThroughRate)}</div>
                    {/* <div className={s['table-cell']}>{campaign?.conversions}</div> */}
                    <div className={s['table-cell']}>{formatRate(campaign?.conversionRate)}</div>
                    <FeatureFlag name="WA8-2359">
                      <div className={s['table-cell']}>
                        {formatFrequency(campaign?.sponsorshipFrequency)}
                      </div>
                    </FeatureFlag>

                    <div className={s['table-cell__actions']}>
                      {!campaign?.isArchive ? (
                        <div className={s['archive-section']}>
                          <button
                            className={s['archive']}
                            type="button"
                            onClick={() => handleArchive(campaign?.id)}
                          >
                            <Tooltip
                              content={t('Archive')}
                              customIcon={<SvgIcon name="archive" />}
                              customClass="action"
                            />
                          </button>
                        </div>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                );
              })}
            {adSetsData &&
              adSetsData?.data.map((adSet, index) => {
                const { completed, accepted, clickThrough } = adSet;

                return (
                  <div key={index} className={s['table-row']}>
                    <div className={s['table-cell']}>
                      <div className={s['table-cell__switch']}>
                        <Switch
                          id={adSet?.sponsorshipSetId}
                          value={adSet?.sponsorshipSetId}
                          checked={adSet?.isActive}
                          name={adSet?.sponsorshipSetName}
                          onChange={handleSponsorshipSetActivation}
                          disabled={isArchive}
                        />
                      </div>
                    </div>
                    <div className={s['table-cell__link']}>
                      <Link
                        className={s['name']}
                        to={
                          isArchive
                            ? generatePath(routes.CAMPAIGN_SUMMARY, { cid: adSet?.campaignId })
                            : generatePath(routes.SPONSORSHIP_SET_EDIT, {
                                cid: adSet?.campaignId,
                                ssid: adSet?.sponsorshipSetId,
                              })
                        }
                      >
                        {adSet?.sponsorshipSetName}
                      </Link>
                      <div className={s['reporting']}>
                        {/* {!hasStats(accepted, completed, clickThrough, adSet?.isActive) ? (
                          <>
                            <div className={classNames(s['reporting-performance'], s['disabled'])}>
                              <SvgIcon name="performance" />
                              <span className={s['link']} id={adSet?.campaignId}>
                                {t('Performance')}
                              </span>
                            </div>
                            <div className={classNames(s['reporting-demographics'], s['disabled'])}>
                              <SvgIcon name="demographics" />
                              <span className={s['link']} id={adSet?.campaignId}>
                                {t('Demographics')}
                              </span>
                            </div>
                          </>
                        ) : ( */}
                        <>
                          <Link
                            to={
                              generatePath(
                                `${routes.REPORTING}${routes.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}`,
                                {
                                  cid: adSet?.campaignId,
                                  ssid: adSet?.sponsorshipSetId,
                                }
                              ) + '?type=overview'
                            }
                            className={classNames(s['reporting-performance'])}
                          >
                            <SvgIcon name="performance" />
                            <span className={s['link']} id={adSet?.campaignId}>
                              {t('Performance')}
                            </span>
                          </Link>
                          <Link
                            to={
                              generatePath(
                                `${routes.REPORTING}${routes.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}`,
                                {
                                  cid: adSet?.campaignId,
                                  ssid: adSet?.sponsorshipSetId,
                                }
                              ) + '?type=demographics'
                            }
                            className={classNames(s['reporting-demographics'])}
                          >
                            <SvgIcon name="demographics" />
                            <span className={s['link']} id={adSet?.campaignId}>
                              {t('Demographics')}
                            </span>
                          </Link>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                    <div className={s['table-cell__status']}>
                      <div className={s['table-cell__status-inner']}>
                        <div className={s['status-info']}>
                          <DeliveryStatus
                            status={calculatedStatus(adSet.deliveryStatus, adSet.moderationStatus)}
                          />
                          <div className={s['status-text']}>
                            {processReceivedText(
                              calculatedStatus(adSet.deliveryStatus, adSet.moderationStatus)
                            )}
                          </div>
                        </div>
                        {adSet?.moderationReason ? (
                          <div
                            id={adSet?.sponsorshipSetId}
                            className={s['reason-link']}
                            onClick={handleRejectionReasonClick('adset')}
                          >
                            {t('Details')}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    {/* <div className={s['table-cell__link']} id={adSet?.campaignId}>
                      <div className={s['table-cell__line-clamp']}>{adSet?.campaignName}</div>
                    </div> */}
                    <div className={s['table-cell']}>
                      {setBrandDateFormat(adSet?.startDate, brandInfo.countryCode, true)}
                    </div>
                    <div className={s['table-cell']}>
                      {setBrandDateFormat(adSet?.endDate, brandInfo.countryCode, true)}
                    </div>
                    <div className={s['table-cell']}>
                      {getNumberToCurrency(adSet?.budgetTotal, true)}
                    </div>
                    <div className={s['table-cell']}>
                      {numberToCurrency(parseFloat(adSet?.moneySpent), false, false, 6)}
                    </div>
                    <div className={s['table-cell']}>
                      {getNumberToCurrency(
                        dailyBudget(adSet?.startDate, adSet?.endDate, adSet?.budgetTotal),
                        false
                      )}
                    </div>{' '}
                    <div className={s['table-cell']}>{accepted || '-'}</div>
                    <div className={s['table-cell']}>{completed || '-'}</div>
                    <div className={s['table-cell']}>{clickThrough || '-'}</div>
                    <div className={s['table-cell']}>{formatRate(adSet?.clickThroughRate)}</div>
                    <FeatureFlag name="WA8-2359">
                      <div className={s['table-cell']}>
                        {formatFrequency(adSet?.sponsorshipFrequency)}
                      </div>
                    </FeatureFlag>
                  </div>
                );
              })}
            {adsData &&
              adsData?.data.map((ad, index) => {
                const { completed, accepted, clickThrough } = ad;
                return (
                  <div key={index} className={s['table-row']}>
                    <div className={s['table-cell']}>
                      <div className={s['table-cell__switch']}>
                        <Switch
                          id={ad?.sponsorshipId}
                          value={ad?.sponsorshipId}
                          checked={ad?.isActive}
                          name={ad?.sponsorshipName}
                          onChange={handleSponsorshipActivation}
                          disabled={isArchive}
                        />
                      </div>
                    </div>
                    <div className={s['table-cell__link']}>
                      <Link
                        className={s['name']}
                        to={
                          isArchive
                            ? generatePath(routes.CAMPAIGN_SUMMARY, { cid: ad?.campaignId })
                            : generatePath(routes.SPONSORSHIP_EDIT, {
                                cid: ad?.campaignId,
                                ssid: ad?.sponsorshipSetId,
                                sid: ad?.sponsorshipId,
                              })
                        }
                      >
                        {ad?.sponsorshipName}
                      </Link>
                      <div className={s['reporting']}>
                        {/* {!hasStats(accepted, completed, clickThrough, ad?.isActive) ? (
                          <>
                            <div className={classNames(s['reporting-performance'], s['disabled'])}>
                              <SvgIcon name="performance" />
                              <span className={s['link']} id={ad?.campaignId}>
                                {t('Performance')}
                              </span>
                            </div>
                            <div className={classNames(s['reporting-demographics'], s['disabled'])}>
                              <SvgIcon name="demographics" />
                              <span className={s['link']} id={ad?.campaignId}>
                                {t('Demographics')}
                              </span>
                            </div>
                          </>
                        ) : ( */}
                        <>
                          <Link
                            to={
                              generatePath(
                                `${routes.REPORTING}${routes.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}${routes.SPONSORSHIP_ROUTE}`,
                                {
                                  cid: ad?.campaignId,
                                  ssid: ad?.sponsorshipSetId,
                                  sid: ad?.sponsorshipId,
                                }
                              ) + '?type=overview'
                            }
                            className={classNames(s['reporting-performance'])}
                          >
                            <SvgIcon name="performance" />
                            <span className={s['link']} id={ad?.campaignId}>
                              {t('Performance')}
                            </span>
                          </Link>
                          <Link
                            to={
                              generatePath(
                                `${routes.REPORTING}${routes.CAMPAIGN_ROUTE}${routes.SPONSORSHIP_SET_ROUTE}${routes.SPONSORSHIP_ROUTE}`,
                                {
                                  cid: ad?.campaignId,
                                  ssid: ad?.sponsorshipSetId,
                                  sid: ad?.sponsorshipId,
                                }
                              ) + '?type=demographics'
                            }
                            className={classNames(s['reporting-demographics'])}
                          >
                            <SvgIcon name="demographics" />
                            <span className={s['link']} id={ad?.campaignId}>
                              {t('Demographics')}
                            </span>
                          </Link>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                    <div className={s['table-cell__status']}>
                      <div className={s['table-cell__status-inner']}>
                        <div className={s['status-info']}>
                          <DeliveryStatus
                            status={calculatedStatus(ad.deliveryStatus, ad.moderationStatus)}
                          />
                          <div className={s['status-text']}>
                            {processReceivedText(
                              calculatedStatus(ad.deliveryStatus, ad.moderationStatus)
                            )}
                          </div>
                        </div>
                        {ad?.moderationReason ? (
                          <div
                            id={ad?.sponsorshipId}
                            className={s['reason-link']}
                            onClick={handleRejectionReasonClick('ad')}
                          >
                            {t('Details')}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className={s['table-cell']}>
                      <div className={s['cell-media']}>
                        {ad.videoHlsCreativeUri ? (
                          <ReactPlayer
                            className={s['player']}
                            volume={1}
                            controls={true}
                            height="100%"
                            width="100%"
                            url={ad.videoHlsCreativeUri || ''}
                          />
                        ) : (
                          <div className={s['no-content']}>{t('No content')}</div>
                        )}
                      </div>
                    </div>
                    <div className={s['table-cell']}>
                      <div className={s['table-cell__questions']}>
                        {ad?.questions?.length ? (
                          <div
                            className={s['link']}
                            onClick={handleQuestionsClick}
                            id={ad?.sponsorshipId}
                          >
                            {t('View questions')}
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                    <div className={s['table-cell']}>
                      <div className={s['cell-media']}>
                        {ad.ctaImageUri ? (
                          <img
                            className={s['cell-media__image']}
                            src={ad.ctaImageUri}
                            alt="cover-tile"
                          />
                        ) : (
                          <div className={s['no-content']}>{t('No content')}</div>
                        )}
                      </div>
                    </div>
                    <div className={s['table-cell']}>
                      <div className={s['table-cell__line-clamp']}>{ad.ctaWebLink}</div>
                    </div>
                    <div className={s['table-cell']}>{accepted || '-'}</div>
                    <div className={s['table-cell']}>{completed || '-'}</div>
                    <div className={s['table-cell']}>{clickThrough || '-'}</div>
                    <div className={s['table-cell']}>{formatRate(ad?.clickThroughRate)}</div>
                    <div className={s['table-cell']}>{formatRate(ad?.conversionRate)}</div>
                    <FeatureFlag name="WA8-2359">
                      <div className={s['table-cell']}>
                        {formatFrequency(ad?.sponsorshipFrequency)}
                      </div>
                    </FeatureFlag>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
