const fragments = Object.freeze({
  CAMPAIGN_NAME: '#campaign-name',
  OBJECTIVE: '#objective',
  AD_SET_NAME: '#adset-name',
  AUDIENCE: '#audience',
  TIMING: '#timing',
  BUDGET: '#budget',
  CHARITY: '#charity',
  AD_NAME: '#ad-name',
  CREATIVE: '#creative',
  QUESTIONS: '#questions',
  ACTION: '#action',
  PIXEL: '#8pixel',
});

export default fragments;
