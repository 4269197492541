import { analyticsConstants } from '../../../../constants';

export const getCSVFileName = (csvDataName) => {
  if (csvDataName === analyticsConstants.csvDataNames.ACCEPTED) {
    return 'Accepted_offer_report.csv';
  }

  if (csvDataName === analyticsConstants.csvDataNames.VIEWED_IN_FEED) {
    return 'Viewed_offer_report.csv';
  }

  if (csvDataName === analyticsConstants.csvDataNames.VIDEO_VIEWED) {
    return 'Video_views_report.csv';
  }

  if (csvDataName === analyticsConstants.csvDataNames.CLICK_THROUGH) {
    return 'Clicked_through_report.csv';
  }

  if (csvDataName === analyticsConstants.csvDataNames.COMPLETED) {
    return 'Completed_offer_report.csv';
  }

  if (csvDataName === analyticsConstants.csvDataNames.USER_ENGAGEMENT) {
    return 'User_engagement_report.csv';
  }

  if (csvDataName === analyticsConstants.csvDataNames.FULL_REPORT) {
    return 'WeAre8_full_report.csv';
  }

  if (csvDataName && !analyticsConstants.csvDataTypes.includes(csvDataName)) {
    return 'Question_report.csv';
  }

  return null;
};
