import * as yup from 'yup';
import { CURRENCY_WITH_CENTS } from '../../../utils/validate';
import { t } from '@transifex/native';

export const newBudgetAllocationSchema = yup.object().shape({
  netCashReward: yup
    .string()
    .matches(CURRENCY_WITH_CENTS, t('Cash Reward value should be in digits format XX.XX only'))
    .test(
      'is-greater-than-zero',
      t('Cash Reward should be greater than 0'),
      (value) => value && +value > 0
    )
    .required(t('Cash Reward is a required field')),
  weAre8Fee: yup
    .string()
    .matches(CURRENCY_WITH_CENTS, t('WeAre8 fee value should be in digits format XX.XX only'))
    .test(
      'is-greater-than-zero',
      t('WeAre8 fee should be greater than 0'),
      (value) => value && +value > 0
    )
    .required(t('WeAre8 fee is a required field')),
  charityDonation: yup
    .string()
    .matches(CURRENCY_WITH_CENTS, t('Charity donation value should be in digits format XX.XX only'))
    .test(
      'is-greater-than-zero',
      t('Charity donation should be greater than 0'),
      (value) => value && +value > 0
    )
    .required(t('Charity donation is a required field')),
});
