import React from 'react';

import s from './QuestionsModalContent.module.scss';
import { useT } from '@transifex/react';

const QuestionsModalContent = ({ questions }) => {
  const t = useT();

  return (
    <div className={s['questions']}>
      {questions.map((question, index) => (
        <div key={index} className={s['questions-block']}>
          <div className={s['questions-block__header']}>
            <div className={s['title']}>{`${t('Question')} ${index + 1}`}</div>
            <div className={s['category']}>{question.kind}</div>
          </div>
          <div className={s['question-block__body']}>{question.text}</div>
        </div>
      ))}
    </div>
  );
};

export default QuestionsModalContent;
