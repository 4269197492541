import { useT } from '@transifex/react';
import React, { useCallback } from 'react';
import { SectionTitle } from '../../../common/Titles/SectionTitle';

import { useSelector } from 'react-redux';
import {
  functionPurchase,
  functionSubscription,
  htmlExampleSnippet,
  onClickPurchase,
  onClickSubscription,
  pageLoadSnippet,
  purchaseSnippet,
  subscriptionSnippet,
} from '../../../../constants/pixel';
import { brandInfoSelector } from '../../../../store/brand';
import PixelTesting from './PixelTesting';
import Copy from '../../../common/Copy/Copy';
import SecondaryTitle from '../../../common/Titles/SecondaryTitle/SecondaryTitle';
import s from './PixelSetupContent.module.scss';

function PixelSetupContent() {
  const t = useT();
  const brandInfo = useSelector(brandInfoSelector);

  const insertBrandId = useCallback(
    (string) => {
      return string.replace('INSERT_BRAND_ID', brandInfo.id);
    },
    [brandInfo.id]
  );

  return (
    <div className={s['container']}>
      <SectionTitle>8Pixel {t('Setup')}</SectionTitle>
      <section>
        <SecondaryTitle className={s['title']}>{t('Implementation instructions')}</SecondaryTitle>
        <div className={s['description']}>
          {t('To set up your')} 8Pixel{' '}
          {t(
            'in your website you have to follow some simple steps. The basic code with Page Load event has to be placed properly in order to fire all the conversion events when needed.'
          )}
          <div className={s['base-code']}>
            <div className={s['title']}>
              <strong>{t(`1. Find you website's header section`)}</strong>
            </div>
            <div className={s['content']}>
              <span>{t(`Find the <head></head> tags near the top of your website.`)}</span>
              <small>
                {t(`If you are using a CMS or web platform try looking for header template.`)}
              </small>
              <pre>
                <code>{htmlExampleSnippet}</code>
              </pre>
            </div>
            <div className={s['title']}>
              <strong>{t(`2. Add Pixel code`)}</strong>
            </div>
            <div className={s['content']}>
              <span>{t(`Copy the Pixel snippet.`)}</span>
              <span>{t(`Paste it into the <head></head> of your website.`)}</span>
              <pre>
                <Copy textToCopy={pageLoadSnippet} />
                <code>{insertBrandId(pageLoadSnippet)}</code>
              </pre>
            </div>
          </div>
          <div className={s['events']}>
            <div className={s['title']}>
              <strong>{t(`3. Conversion tracking`)}</strong>
            </div>
            <div className={s['content']}>
              <span>
                {t('The next step after implementing the')} 8Pixel{' '}
                {t(
                  'base code is to decide which of the following conversion events you want to track. Currently we support tracking for the following events: Purchases and Subscriptions. Please follow the instructions below to properly implement conversion tracking in your website. Besides the technical implementation, you also need to do the following when setting up your campaign:'
                )}
              </span>
              <ul>
                <li>{t('Choose conversions as your campaign objective ')}</li>
                <li>
                  {t('Enable')} 8Pixel {t('and pick a conversion event on the ad set level')}
                </li>
              </ul>
              <span className={s['explanation']}>
                {t(
                  `You have to previously implement the first step with the basic configuration in order to trigger those events successfully.`
                )}
              </span>
            </div>
          </div>
          <div className={s['purchase']}>
            <div className={s['title']}>
              <strong>{t(`Purchase`)}</strong>
            </div>
            <div className={s['content']}>
              <span>
                {t(
                  `To track purchases in your website you have to add the following code to the page shown after a successful purchase (or last step of purchase if you don't have a separate Confirmation page).
                  Find the closing </body> tag. Then paste the code just before the tag.
                  `
                )}
              </span>
              <span className={s['explanation']}>
                {t(
                  `Make sure to replace the variables in code with the correct values when running the script on the page.`
                )}
              </span>
              <pre>
                <Copy textToCopy={purchaseSnippet} />
                <code>{purchaseSnippet}</code>
              </pre>
              <span>
                {t(
                  `Otherwise you can trigger the following event by binding it to a button's click event directly in the HTML page.`
                )}
              </span>
              <pre>
                <Copy textToCopy={onClickPurchase} />
                <code>{onClickPurchase}</code>
              </pre>
              <span>{t(`Or with a custom JS event.`)}</span>
              <pre>
                <Copy textToCopy={functionPurchase} />
                <code>{functionPurchase}</code>
              </pre>
            </div>
          </div>
          <div className={s['subscription']}>
            <div className={s['title']}>
              <strong>{t(`Subscription`)}</strong>
            </div>
            <div className={s['content']}>
              <span>
                {t(
                  `To track subscriptions in your website you have to add the following code to the page shown after a successful subscription to a newsletter for example (or last step of subscription if you don't have a separate Confirmation page).
                  Find the closing </body> tag. Then paste the code just before the tag.
                  `
                )}
              </span>
              <span className={s['explanation']}>
                {t(
                  `Make sure to replace the variables in code with the correct values when running the script on the page.`
                )}
              </span>
              <pre>
                <Copy textToCopy={subscriptionSnippet} />
                <code>{subscriptionSnippet}</code>
              </pre>
              <span>
                {t(
                  `Otherwise you can trigger the following event by binding it to a button's click event directly in the HTML page.`
                )}
              </span>
              <pre>
                <Copy textToCopy={onClickSubscription} />
                <code>{onClickSubscription}</code>
              </pre>
              <span>{t(`Or with a custom JS event.`)}</span>
              <pre>
                <Copy textToCopy={functionSubscription} />
                <code>{functionSubscription}</code>
              </pre>
            </div>
          </div>
        </div>
      </section>
      <PixelTesting />
    </div>
  );
}

export default PixelSetupContent;
