import React from 'react';

import s from './Tag.module.scss';

const Tag = ({ id, text, callback }) => {
  return (
    <div className={s['tag']}>
      <div className={s['tag__text']}>{text}</div>
      <div id={id} onClick={callback} className={s['tag__button']}>
        x
      </div>
    </div>
  );
};

export default Tag;
