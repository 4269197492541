import React, { useEffect } from 'react';

import { ReportingChart } from '../../ReportingChart';

import { Bar } from '../../../../Charts/Bar';
import { Donut } from '../../../../Charts/Donut';
import { List } from '../../../../Charts/List';
import { Map } from '../../../../Charts/Map';

import s from './ReportingDemographicsContent.module.scss';
import { useT } from '@transifex/react';

const ReportingDemographicsContent = ({
  demographics,
  brandInfo,
  influencersByGender,
  heatMap,
  setDemographicsNode,
}) => {
  const node = document.getElementById('reporting-demographics-section-1');
  const node2 = document.getElementById('reporting-demographics-section-2');
  const node3 = document.getElementById('reporting-demographics-section-3');
  const t = useT();

  useEffect(() => {
    setDemographicsNode([node, node2, node3]);
  }, [setDemographicsNode, node, node2, node3]);

  return (
    <>
      <div className={s['demographics-content']}>
        <div id="reporting-demographics-section-1">
          <div className={s['demographics-content__flex--medium']}>
            <div className={s['flex-item']}>
              <ReportingChart title={t('By Location')} titleStyle={{ marginBottom: 0 }}>
                {demographics?.influencersByRegionCode?.data?.length > 0 ? (
                  <Map
                    country={brandInfo?.country?.countryCode}
                    joinBy={'hc-a2'}
                    series={heatMap?.data}
                    mapWithCities
                  />
                ) : (
                  <div className={s['no-data']}>{t('No data available')}</div>
                )}
              </ReportingChart>
            </div>
            <div className={s['flex-item']}>
              <ReportingChart title={t('By Region')} chartStyle={{ alignItems: 'flex-start' }}>
                {demographics?.influencersByRegionName?.data?.length > 0 ? (
                  <List series={demographics?.influencersByRegionName} />
                ) : (
                  <div className={s['no-data']}>{t('No data available')}</div>
                )}
              </ReportingChart>
            </div>
          </div>
        </div>

        <div id="reporting-demographics-section-2">
          <div className={s['demographics-content__flex--medium']}>
            <div className={s['flex-item']}>
              <ReportingChart title={t('By City')} chartStyle={{ alignItems: 'flex-start' }}>
                {demographics?.influencersByCityName?.data?.length > 0 ? (
                  <List series={demographics?.influencersByCityName} />
                ) : (
                  <div className={s['no-data']}>{t('No data available')}</div>
                )}
              </ReportingChart>
            </div>
            <div className={s['flex-item']}>
              <ReportingChart title={t('By Age')} chartStyle={{ alignItems: 'flex-start' }}>
                {demographics?.influencerByAgeGrouped?.data?.length > 0 ? (
                  <Bar series={demographics?.influencerByAgeGrouped} />
                ) : (
                  <div className={s['no-data']}>{t('No data available')}</div>
                )}
              </ReportingChart>
            </div>
          </div>
        </div>

        <div id="reporting-demographics-section-3">
          <div className={s['demographics-content__flex--medium']}>
            <div className={s['flex-item']}>
              <ReportingChart title={t('By Gender')} titleStyle={{ marginBottom: 0 }}>
                {influencersByGender?.data?.length > 0 ? (
                  <Donut dataType="gender" series={influencersByGender} />
                ) : (
                  <div className={s['no-data']}>{t('No data available')}</div>
                )}
              </ReportingChart>
            </div>
            <div className={s['flex-item']}>
              <ReportingChart title={t('By Device')} titleStyle={{ marginBottom: 0 }}>
                {demographics?.influencersByPlatform?.data?.length > 0 ? (
                  <Donut dataType="device" series={demographics?.influencersByPlatform} />
                ) : (
                  <div className={s['no-data']}>{t('No data available')}</div>
                )}
              </ReportingChart>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportingDemographicsContent;
