import { createAction, createAsyncAction } from 'eight.js.store-common';

export const clearState = createAction('@@data/CLEAR_STATE');
export const createNewAdSet = createAction('@@data/CREATE_NEW_AD_SET');
export const duplicateAdSet = createAction('@@data/DUPLICATE_AD_SET');
export const deleteAdSet = createAction('@@data/DELETE_AD_SET');
export const createNewAd = createAction('@@data/CREATE_NEW_AD');
export const duplicateAd = createAction('@@data/DUPLICATE_AD');
export const deleteAd = createAction('@@data/DELETE_AD');
export const campaignDataFetching = createAction('@@data/CAMPAIGN_DATA_FETCHING');
export const setFetchedNameAndLogo = createAction('@@campaign/SET_FETCHED_NAME_AND_LOGO');
export const setCampaignIsDirty = createAction('@@data/SET_CAMPAIGN_IS_DIRTY');
export const setAdSetIsDirty = createAction('@@data/SET_AD_SET_IS_DIRTY');
export const setAdIsDirty = createAction('@@data/SET_AD_IS_DIRTY');
export const setUnpublishedDifferences = createAction('@@data/SET_UNPUBLISHED_DIFFERENCES');
export const setCampaignIsPublished = createAction('@@data/SET_CAMPAIGN_IS_PUBLISHED');

export const fetchCampaignAsync = createAsyncAction('@@data/FETCH_CAMPAIGN');
export const fetchCampaignDifferencesAsync = createAsyncAction('@@data/FETCH_CAMPAIGN_DIFFERENCES');
