import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import s from './CustomCheckbox.module.scss';

const cx = classNames.bind(s);

const CustomCheckbox = ({
  'data-testid': testId,
  onChange,
  name,
  shape,
  label,
  checked,
  disabled,
  value,
  register,
  dashStyle,
}) => {
  const labelClasses = useMemo(
    () =>
      cx({
        [s['checkbox-label']]: true,
        [s['checkbox-label--disabled']]: disabled,
        [s['checkbox-label__rect']]: shape === 'rect',
        [s['checkbox-label__circ']]: shape === 'circ' && !dashStyle,
        [s['checkbox-label__dash']]: dashStyle,
      }),
    [shape, disabled, dashStyle]
  );

  return (
    <div className={s['checkbox']}>
      <input
        {...register}
        className={s['checkbox-input']}
        onChange={onChange}
        type="checkbox"
        id={value}
        data-testid={testId}
        value={value}
        name={name}
        checked={checked}
        disabled={disabled}
      />
      <label className={labelClasses} htmlFor={value}>
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
