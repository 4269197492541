import React, { useMemo } from 'react';

import { useT } from '@transifex/react';

import classNames from 'classnames/bind';
import s from './DeleteButton.module.scss';

const cx = classNames.bind(s);

const DeleteButton = ({ disabled, callback, id, 'data-testid': testId }) => {
  const t = useT();

  const buttonsClassNames = useMemo(
    () =>
      cx({
        [s['delete-button']]: true,
        [s['delete-button__disabled']]: disabled,
      }),
    [disabled]
  );

  return (
    <button
      className={buttonsClassNames}
      type="button"
      id={id}
      onClick={callback}
      disabled={disabled}
      data-testid={testId}
    >
      {t('Delete')}
    </button>
  );
};

export default DeleteButton;
