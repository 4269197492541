import * as yup from 'yup';
import { EMAIL_REGEXP, NUMBER_REGEXP } from '../../../utils/validate';
import { t } from '@transifex/native';

export const invoiceRequestValidationSchema = yup.object().shape({
  estimatedMonthlySpend: yup
    .number()
    .integer(t('The number is not an integer'))
    .typeError(t('The amount should contain numbers only'))
    .moreThan(9999, t('Sorry, minimum amount is £10000'))
    .lessThan(1000001, t('Sorry, maximun amount is £1.000.000')),
  contactName: yup
    .string()
    .required(t('Name is a required field'))
    .min(3, t('Minimum length should be 3 chars'))
    .max(100, t('Maximum length should be 100 chars')),
  contactNumber: yup
    .string()
    .required(t('Contact Number is a required field'))
    .matches(NUMBER_REGEXP, t('Incorrect input format. Only numbers accepted'))
    .min(3, t('Minimum Contact number length should be 3 chars'))
    .max(100, t('Maximum Contact number length should be 100 chars')),
  contactEmail: yup
    .string()
    .matches(EMAIL_REGEXP, t('Email must be in e-mail format'))
    .required(t('Email is a required field')),
});
