import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useT } from '@transifex/react';

import { TextInput } from '../../FormElements/TextInput';
import { Button } from '../../common/Button';

import { routes } from '../../../constants';

import { validationSchema } from './validationSchema';

import s from './LoginContent.module.scss';

import { constructTestId } from '../../../utils/test-ids';
import { LOGIN } from '../../../constants/test-ids/pages';

import LogoAndTitle from '../../LogoAndTitle/LogoAndTitle';
import weAre8Logo from '../../../assets/images/rebrand-logo.png';

const SECTION = LOGIN;

const LoginContent = ({
  loginEmail,
  loginPassword,
  handleEmailChange,
  handlePasswordChange,
  handleLoginClick,
}) => {
  const t = useT();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      loginEmail,
      loginPassword,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  return (
    <div className={s['login']} tabIndex="0">
      <div className={s['login-inner']}>
        <LogoAndTitle />

        <form
          className={s['login-inner__section-form']}
          onSubmit={handleSubmit(() => handleLoginClick(getValues()))}
        >
          <div className={s['form-input']}>
            <TextInput
              type="text"
              id="login-email"
              name="loginEmail"
              label={t('EMAIL')}
              onChange={handleEmailChange}
              register={register}
              error={errors.loginEmail}
              data-testid={constructTestId(SECTION, 'email')}
              watchValue={watch('loginEmail')}
            />
          </div>
          <div className={s['form-input-2']}>
            <TextInput
              type="password"
              id="login-password"
              name="loginPassword"
              label={t('PASSWORD')}
              onChange={handlePasswordChange}
              register={register}
              error={errors.loginPassword}
              data-testid={constructTestId(SECTION, 'password')}
              watchValue={watch('loginPassword')}
            />
          </div>
          <Link className={s['login-inner__section-reset']} to={routes.RESET_PASSWORD_EMAIL}>
            {t('Reset password')}
          </Link>
          <div className={s['form-button']}>
            <Button
              data-testid={constructTestId(SECTION, 'login-btn')}
              type="submit"
              styling="primary"
              customText={t('LOGIN')}
              fullwidth
            />
          </div>
        </form>
        <div className={s['login-inner__section-signup']}>
          {t(`Don't have an account? `)}
          &nbsp;
          <Link className={s['login-inner__section-signup__link']} to={routes.SIGN_UP}>
            {t('Sign up now!')}
          </Link>
        </div>
        <div className={s['login-inner__contact']}>
          {t('Trouble with your account?')}
          &nbsp;
          <a className={s['login-inner__contact-link']} href="mailto:sam-i@weare8.com">
            {t('Contact us')}
          </a>
        </div>
        <div className={s['login-inner__logo']}>
          <img src={weAre8Logo} />
        </div>
      </div>
    </div>
  );
};

export default LoginContent;
