import { createReducer } from 'eight.js.store-common';
import { campaignConstants, sponsorshipConstants, sponsorshipSetConstants } from '../../constants';
import {
  campaignDataFetching,
  clearState,
  createNewAd,
  createNewAdSet,
  deleteAd,
  deleteAdSet,
  duplicateAd,
  duplicateAdSet,
  fetchCampaignAsync,
  setAdIsDirty,
  setAdSetIsDirty,
  setCampaignIsDirty,
  setCampaignIsPublished,
  setFetchedNameAndLogo,
  setUnpublishedDifferences,
} from './actions';
import { v4 as uuidv4 } from 'uuid';
import {
  activateCampaignFromCreationAsync,
  deactivateCampaignFromCreationAsync,
  updateCampaignAsync,
} from '../campaign/campaign';
import {
  activateSponsorshipSetFromCreationAsync,
  deactivateSponsorshipSetFromCreationAsync,
  updateSponsorshipSetAsync,
} from '../campaign/sponsorshipSet';
import {
  activateSponsorshipFromCreationAsync,
  deactivateSponsorshipFromCreationAsync,
  updateSponsorshipAsync,
  uploadVideoAsync,
} from '../campaign/sponsorship';
import { getAdAllDirtyFields, getAdSetAllDirtyFields } from '../../utils/form/formHelpers';
const initialState = {
  fetching: true,
  error: '',
  campaign: campaignConstants.initialState,
  campaignDifferences: [],
  campaignSummary: { adSets: [] },
};

export const dataReducer = createReducer('@@data', initialState, {
  [clearState]: ({ state }) => {
    state.campaign = {
      ...campaignConstants.initialState,
      id: uuidv4(),
      adSets: [
        {
          ...sponsorshipSetConstants.initialState,
          id: uuidv4(),
          ads: [{ ...sponsorshipConstants.initialState, id: uuidv4() }],
        },
      ],
    };
    state.campaignSummary = { adSets: [] };
    state.fetching = true;
  },
  [campaignDataFetching]: ({ state, action }) => {
    state.fetching = action.payload;
  },
  [createNewAdSet]: ({ state, action }) => {
    state.campaign.adSets = [
      ...state.campaign.adSets,
      {
        ...sponsorshipSetConstants.initialState,
        id: action.payload.newId,
        ads: [{ ...sponsorshipConstants.initialState, id: uuidv4() }],
      },
    ];
  },
  [duplicateAdSet]: ({ state, action }) => {
    let adSetToDuplicate = {
      ...state.campaign.adSets.find((set) => set.id === action.payload.adSetId),
    };

    adSetToDuplicate.ads = adSetToDuplicate.ads.map((ad) => {
      return {
        ...ad,
        id: uuidv4(),
        isDirty: true,
        isDraft: true,
        dirtyFields: getAdAllDirtyFields(),
        isCreated: false,
        ctaCover: '',
        adVideo: '',
        videoUploadStatus: '',
        isVideoUploaded: false,
        moderationStatus: 'draft',
        isActive: false,
        deliveryStatus: 'inactive',
      };
    });

    state.campaign.adSets = [
      ...state.campaign.adSets,
      {
        ...adSetToDuplicate,
        id: action.payload.newId,
        name: `Copy of ${adSetToDuplicate.name}`,
        isCreated: false,
        isDirty: true,
        isDraft: true,
        dirtyFields: getAdSetAllDirtyFields(),
        moderationStatus: 'draft',
        isActive: false,
        deliveryStatus: 'inactive',
        samiPricingId: '',
        ads: adSetToDuplicate?.ads?.map((adToDuplicate) => ({
          ...adToDuplicate,
          questions: adToDuplicate?.questions?.map((question) => ({
            ...question,
            id: uuidv4(),
          })),
        })),
      },
    ];
  },
  [deleteAdSet]: ({ state, action }) => {
    if (state.campaign.adSets.length > 1) {
      state.campaign.adSets = state.campaign.adSets.filter(
        (set) => set.id !== action.payload.adSetId
      );
      state.campaignSummary.adSets = state.campaignSummary.adSets.filter(
        (set) => set.id !== action.payload.adSetId
      );
    }
  },
  [createNewAd]: ({ state, action }) => {
    const setIndex = state.campaign.adSets.findIndex((set) => set.id === action.payload.adSetId);
    state.campaign.adSets[setIndex].ads = [
      ...state.campaign.adSets[setIndex].ads,
      { ...sponsorshipConstants.initialState, id: action.payload.newId },
    ];
  },
  [duplicateAd]: ({ state, action }) => {
    const selectedAdSetIndex = state.campaign.adSets.findIndex(
      (set) => set.id === action.payload.adSetId
    );
    const adToDuplicate = state.campaign.adSets[selectedAdSetIndex].ads.find(
      (ad) => ad.id === action.payload.adId
    );

    state.campaign.adSets[selectedAdSetIndex].ads = [
      ...state.campaign.adSets[selectedAdSetIndex].ads,
      {
        ...adToDuplicate,
        id: action.payload.newId,
        name: `Copy of ${adToDuplicate.name}`,
        isDirty: true,
        dirtyFields: getAdAllDirtyFields(),
        isCreated: false,
        isDraft: true,
        ctaCover: '',
        adVideo: '',
        videoWidth: '',
        videoHeight: '',
        videoUploadStatus: '',
        isVideoUploaded: false,
        moderationStatus: 'draft',
        isActive: false,
        deliveryStatus: 'inactive',
        questions: adToDuplicate.questions.map((question) => ({
          ...question,
          id: uuidv4(),
        })),
      },
    ];
  },
  [deleteAd]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.adSetId
    );
    if (state.campaign.adSets[adSetIndex].ads.length > 1) {
      state.campaign.adSets[adSetIndex].ads = state.campaign.adSets[adSetIndex].ads.filter(
        (ad) => ad.id !== action.payload.adId
      );
      state.campaignSummary.adSets[adSetIndex].ads = state.campaignSummary.adSets[
        adSetIndex
      ].ads.filter((ad) => ad.id !== action.payload.adId);
    }
  },
  [fetchCampaignAsync.success]: ({ state, action }) => {
    state.campaign = action.payload;
    state.campaignSummary = action.payload;
  },
  [setUnpublishedDifferences]: ({ state, action }) => {
    const campaignDifferences = action.payload;

    const modifiedSummaryAdSets = state.campaignSummary.adSets.map((adSet) => {
      const adSetDifferences = campaignDifferences
        .find((diff) => diff.id === adSet.id)
        ?.differences.map((item) => item.modifiedField);
      const modifiedAds = adSet.ads.map((ad) => {
        const adDifferences = campaignDifferences
          .find((diff) => diff.id === ad.id)
          ?.differences.map((item) => item.modifiedField);
        return {
          ...ad,
          differences: adDifferences,
        };
      });
      return {
        ...adSet,
        differences: adSetDifferences,
        ads: modifiedAds,
      };
    });

    const modifiedAdSets = state.campaign.adSets.map((adSet) => {
      const adSetDifferences = campaignDifferences
        .find((diff) => diff.id === adSet.id)
        ?.differences.map((item) => ({
          modifiedField: item.modifiedField,
          publishedValue: item.publishedValue,
        }));
      const modifiedAds = adSet.ads.map((ad) => {
        const adDifferences = campaignDifferences
          .find((diff) => diff.id === ad.id)
          ?.differences.map((item) => ({
            modifiedField: item.modifiedField,
            publishedValue: item.publishedValue,
          }));
        return {
          ...ad,
          differences: adDifferences,
        };
      });
      return {
        ...adSet,
        differences: adSetDifferences,
        ads: modifiedAds,
      };
    });

    state.campaignDifferences = action.payload;
    state.campaignSummary = { ...state.campaignSummary, adSets: modifiedSummaryAdSets };
    state.campaign = { ...state.campaign, adSets: modifiedAdSets };
  },
  [updateCampaignAsync.success]: ({ state, action }) => {
    Object.keys(action.payload).map((key) => (state.campaign[key] = action.payload[key]));
    state.campaign.isCreated = true;
    state.campaign.isActive = true;

    Object.keys(action.payload).map((key) => (state.campaignSummary[key] = action.payload[key]));
    state.campaignSummary.isCreated = true;
    state.campaignSummary.isActive = true;
  },

  [updateSponsorshipSetAsync.success]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.sponsorshipSetId
    );
    Object.keys(action.payload.form).map(
      (key) => (state.campaign.adSets[adSetIndex][key] = action.payload.form[key])
    );
    state.campaign.adSets[adSetIndex].isCreated = true;
    state.campaign.adSets[adSetIndex].isActive = true;

    const adSetExistsInSummary = state.campaignSummary.adSets?.some(
      (adSet) => adSet.id === state.campaign.adSets[adSetIndex].id
    );

    if (adSetExistsInSummary) {
      Object.keys(action.payload.form).map(
        (key) => (state.campaignSummary.adSets[adSetIndex][key] = action.payload.form[key])
      );
      state.campaignSummary.adSets[adSetIndex].isCreated = true;
      state.campaignSummary.adSets[adSetIndex].isActive = true;
    } else
      state.campaignSummary.adSets = [
        ...state.campaignSummary.adSets,
        { ...state.campaign.adSets[adSetIndex], isCreated: true, isActive: true },
      ];
  },

  [updateSponsorshipAsync.success]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.sponsorshipSetId
    );
    const adIndex = state.campaign.adSets[adSetIndex].ads.findIndex(
      (ad) => ad.id === action.payload.sponsorshipId
    );
    Object.keys(action.payload.form).map(
      (key) => (state.campaign.adSets[adSetIndex].ads[adIndex][key] = action.payload.form[key])
    );
    state.campaign.adSets[adSetIndex].ads[adIndex].isCreated = true;
    state.campaign.adSets[adSetIndex].ads[adIndex].isActive = true;
    state.campaign.adSets[adSetIndex].ads[adIndex].isDirty = false;

    const adExistsInSummary = state.campaignSummary.adSets[adSetIndex].ads?.some(
      (ad) => ad.id === state.campaign.adSets[adSetIndex].ads[adIndex].id
    );

    if (adExistsInSummary) {
      Object.keys(action.payload.form).map(
        (key) =>
          (state.campaignSummary.adSets[adSetIndex].ads[adIndex][key] = action.payload.form[key])
      );
      state.campaignSummary.adSets[adSetIndex].ads[adIndex].isCreated = true;
      state.campaignSummary.adSets[adSetIndex].ads[adIndex].isActive = true;
      state.campaignSummary.adSets[adSetIndex].ads[adIndex].isDirty = action.payload.isDirty;
    } else
      state.campaignSummary.adSets[adSetIndex].ads = [
        ...state.campaignSummary.adSets[adSetIndex].ads,
        {
          ...state.campaign.adSets[adSetIndex].ads[adIndex],
          isCreated: true,
          isActive: true,
          isDirty: action.payload.isDirty,
        },
      ];
  },

  [setFetchedNameAndLogo]: ({ state, action }) => {
    const { campaignBrandName, campaignBrandLogoUri } = action.payload;
    state.campaign.campaignBrandName = campaignBrandName;
    state.campaign.campaignBrandLogoUri = campaignBrandLogoUri;
  },

  [setCampaignIsDirty]: ({ state, action }) => {
    Object.keys(action.payload.form).map((key) => (state.campaign[key] = action.payload.form[key]));
    state.campaign.isDirty = action.payload.isDirty;
    state.campaign.dirtyFields = action.payload.dirtyFields;
  },

  [setAdSetIsDirty]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.adSetId
    );

    if (adSetIndex !== -1) {
      Object.keys(action.payload.form).map(
        (key) => (state.campaign.adSets[adSetIndex][key] = action.payload.form[key])
      );
      state.campaign.adSets[adSetIndex].isDirty = action.payload.isDirty;
      state.campaign.adSets[adSetIndex].dirtyFields = action.payload.dirtyFields;
    }
  },

  [setAdIsDirty]: ({ state, action }) => {
    const adSetIndex = state.campaign.adSets.findIndex(
      (adSet) => adSet.id === action.payload.adSetId
    );
    if (adSetIndex !== -1) {
      const adIndex = state.campaign.adSets[adSetIndex].ads.findIndex(
        (ad) => ad.id === action.payload.adId
      );

      if (adIndex !== -1) {
        Object.keys(action.payload.form).map(
          (key) => (state.campaign.adSets[adSetIndex].ads[adIndex][key] = action.payload.form[key])
        );
        state.campaign.adSets[adSetIndex].ads[adIndex].isDirty = false;

        state.campaign.adSets[adSetIndex].ads[adIndex].dirtyFields = action.payload.dirtyFields;
      }
    }
  },

  [activateCampaignFromCreationAsync.success]: ({ state }) => {
    state.campaign.isActive = true;
  },

  [deactivateCampaignFromCreationAsync.success]: ({ state }) => {
    state.campaign.isActive = false;
    state.campaign.adSets = state.campaign.adSets.map((adSet) => {
      const formattedAds = adSet.ads.map((ad) => {
        return { ...ad, isActive: false };
      });
      adSet.isActive = false;
      return {
        ...adSet,
        ads: formattedAds,
      };
    });
  },

  [activateSponsorshipSetFromCreationAsync.success]: ({ state, action }) => {
    const adSetId = action.payload;
    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);

    state.campaign.isActive = true;
    state.campaign.adSets[adSetIndex].isActive = true;
  },

  [deactivateSponsorshipSetFromCreationAsync.success]: ({ state, action }) => {
    const adSetId = action.payload;
    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);
    state.campaign.adSets[adSetIndex].isActive = false;

    state.campaign.adSets[adSetIndex].ads = state.campaign.adSets[adSetIndex].ads.map((ad) => {
      return {
        ...ad,
        isActive: false,
      };
    });
  },

  [activateSponsorshipFromCreationAsync.success]: ({ state, action }) => {
    const adSetId = action.payload.adSetId;
    const adId = action.payload.adId;

    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);
    const adIndex = state.campaign.adSets[adSetIndex].ads.findIndex((ad) => ad.id === adId);

    state.campaign.isActive = true;
    state.campaign.adSets[adSetIndex].isActive = true;
    state.campaign.adSets[adSetIndex].ads[adIndex].isActive = true;
  },

  [deactivateSponsorshipFromCreationAsync.success]: ({ state, action }) => {
    const adSetId = action.payload.adSetId;
    const adId = action.payload.adId;

    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);
    const adIndex = state.campaign.adSets[adSetIndex].ads.findIndex((ad) => ad.id === adId);

    state.campaign.adSets[adSetIndex].ads[adIndex].isActive = false;
  },

  [uploadVideoAsync.success]: ({ state, action }) => {
    const adSetId = action.payload.adSetId;
    const adId = action.payload.adId;

    const adSetIndex = state.campaign.adSets.findIndex((adSet) => adSet.id === adSetId);
    const adIndex = state.campaign.adSets[adSetIndex].ads.findIndex((ad) => ad.id === adId);

    state.campaign.adSets[adSetIndex].ads[adIndex].isVideoUploaded = true;
  },

  [setCampaignIsPublished]: ({ state }) => {
    state.campaign = {
      ...state.campaign,
      isDraft: false,
      adSets: state.campaign.adSets.map((adSet) => ({
        ...adSet,
        isDraft: !adSet.isCreated || (adSet.ads.length === 1 && !adSet.ads[0].isCreated),
        ads: adSet.ads.map((ad) => ({
          ...ad,
          isDraft: !ad.isCreated,
        })),
      })),
    };

    state.campaignSummary = {
      ...state.campaignSummary,
      isDraft: false,
      adSets: state.campaignSummary.adSets.map((adSet) => ({
        ...adSet,
        isDraft: !adSet.isCreated || (adSet.ads.length === 1 && !adSet.ads[0].isCreated),
        ads: adSet.ads.map((ad) => ({
          ...ad,
          isDraft: !ad.isCreated,
        })),
      })),
    };
  },
});
