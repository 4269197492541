import React from 'react';
import ContentLoader from 'react-content-loader';
import InputPlaceholder from '../InputPlaceholder/InputPlaceholder';
import { SingleLinePlaceholder } from '../SingleLinePlaceholder';

import s from './InputWithTextPlaceholder.module.scss';

const InputWithTextPlaceholder = ({ rows, ...props }) => (
  <div className={s['container']}>
    <ContentLoader
      speed={2}
      width={450}
      height={15}
      viewBox="0 0 450 15"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="178" height="15" />
    </ContentLoader>
    <div className={s['wrapper']}>
      {[...Array(rows)].map((row, index) => {
        return <SingleLinePlaceholder key={index} />;
      })}
    </div>
    <InputPlaceholder />
  </div>
);

export default InputWithTextPlaceholder;
