import { useT } from '@transifex/react';
import React, { useCallback, useMemo } from 'react';
import { useRouter } from '../../../hooks/useRouter';

import { SvgIcon } from '../../common/SvgIcon';

import { routes } from '../../../constants';

import {
  getBalanceSum,
  getBalanceTitle,
  getVerificationIcon,
  getVerificationText,
} from '../../../utils/settingsBilling';

import { TopBarPlaceholder } from '../../../containers/Placeholders/TopBarPlaceholder';
import { amountToCurrency } from '../../../utils/budget';
import Tooltip from '../../common/Tooltip/Tooltip';
import s from './AdminPanel.module.scss';

const AdminPanel = ({
  amount,
  brandId,
  brandEmail,
  currencySymbol,
  logoUri,
  logout,
  name,
  isInvoicePartner,
  accountStatus,
  fetching,
}) => {
  const t = useT();
  const { push, location } = useRouter();

  const handleModalActions = useCallback(() => {
    logout();
    push(`${routes.LOGIN}`);
  }, [logout, push]);

  const balanceTitle = useMemo(() => {
    return getBalanceTitle(isInvoicePartner);
  }, [isInvoicePartner]);

  const balanceSum = useMemo(() => {
    return getBalanceSum(isInvoicePartner, amount);
  }, [isInvoicePartner, amount]);

  return (
    <div className={s['admin-panel']}>
      <a
        className={s['admin-panel__feedback-link']}
        href={`mailto:sam-i@weare8.com?subject=Feedback%20on%20page&body=%0D%0A%0D%0A
        %0D%0ABrand Name: ${name},
        %0D%0ABrand email: ${brandEmail},
        %0D%0AFeedback page: https://sam-i.weare8.com${location.pathname},
        %0D%0AMeta: ${brandId}`}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {t('SAM-i Help Centre')}
      </a>

      {/* <Link to="/" className={s['admin-panel__default-logo']}>
        <img src={logoUri} alt="" />
      </Link> */}
      {fetching ? (
        <TopBarPlaceholder />
      ) : (
        <>
          <div className={s['admin-panel__default-logo']}>
            <img className={s['admin-panel__default-logo--image']} src={logoUri} alt="" />
            <div className={s['admin-panel__default-logo--status']}>
              <Tooltip
                customClass="action"
                content={getVerificationText(accountStatus)}
                customIcon={getVerificationIcon(accountStatus)}
              ></Tooltip>
            </div>
          </div>

          <div className={s['admin-panel__brand-name']}>{name}</div>

          <div className={s['admin-panel__balance']}>
            <div className={s['admin-panel__balance-name']}>{balanceTitle}:</div>
            <div className={s['admin-panel__balance-amount']}>
              {amountToCurrency(parseFloat(balanceSum))}
            </div>
          </div>
        </>
      )}

      {/*<div className={s['admin-panel__notifications']}>*/}
      {/*  <SvgIcon name={'notification'}/>*/}
      {/*</div>*/}

      <div className={s['admin-panel__logout']} onClick={handleModalActions}>
        <span>{t('Logout')}</span>
        <SvgIcon name="logout" />
      </div>
    </div>
  );
};

export default AdminPanel;
