import React, { useMemo } from 'react';

import { SvgIcon } from '../SvgIcon';

import classNames from 'classnames/bind';
import s from './Tile.module.scss';

const cx = classNames.bind(s);

const Tile = ({ disabled, iconName, title, subtitle, isChecked }) => {
  const tileClassName = useMemo(() => {
    return cx({
      [s['tile__inner']]: true,
      [s['tile__inner--checked']]: isChecked,
      [s['tile__inner--disabled']]: disabled,
      [s['tile__inner--disabled-checked']]: isChecked && disabled,
    });
  }, [isChecked, disabled]);

  return (
    <div className={s['tile']}>
      <div className={tileClassName}>
        <div className={s['tile__inner-icon__wrapper']}>
          <div className={s['tile__inner-icon__wrapper-icon']}>
            <SvgIcon name={iconName} />
          </div>
        </div>
        <div className={s['tile__inner-content']}>
          <div className={s['tile__inner-content__title']}>{title}</div>
          <div className={s['tile__inner-content__subtitle']}>{subtitle}</div>
        </div>
      </div>
    </div>
  );
};

export default Tile;
