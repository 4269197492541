import { call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { eightAuth } from './../eightauth';
import { t } from '@transifex/native';

import { processReceivedText } from '../../utils/receivedData';

import { routes } from '../../constants';

import { container } from '../../container';

import { setAuthInternalFetching, signupAsync } from './actions';

import { sendVerificationCodeAsync } from '../brand';
import { setModalText, toggleModalByName } from '../ui';
import { toast } from 'react-toastify';

const brandService = container.get('BrandService');

function* signUp({ payload }) {
  try {
    const {
      brandName,
      emailAddress,
      website,
      password,
      country,
      logoUri,
      logoFile,
      signupCode,
      acceptedTermsOfUse,
    } = payload.form;

    yield put(setAuthInternalFetching(true));

    const brandTimezone = country.defaultTimezoneId;

    const response = yield call(brandService.signUpBrand, {
      name: brandName,
      countryCode: country.countryCode,
      password,
      emailAddress,
      timezoneId: brandTimezone,
      signupCode,
      website,
      logoFileName: logoFile?.name,
      BCP47LanguageTag: country.languages[0].bcp47Tag,
      acceptedTermsOfUse,
    });

    const {
      id,
      name,
      countryCode,
      currencyCode,
      timezone,
      timezoneId,
      isInvoicePartner,
      brandType,
      emailAccountStatus,
      bcP47LanguageTag,
      accountStatus,
      accountType,
    } = response?.brand;

    const brandInfo = {
      id,
      countryCode,
      currencyCode,
      emailAddress,
      logoUri,
      brandName: name,
      signupCode: '',
      website,
      timezoneId,
      isInvoicePartner,
      brandType,
      bcP47LanguageTag,
      timezone,
      emailAccountStatus,
      accountStatus,
      accountType,
    };

    yield put(signupAsync.success(brandInfo));

    yield call(loginHelper, { email: emailAddress, password });

    yield call(brandService.uploadBrandMedia, {
      media: logoFile,
      mediaType: logoFile.type,
      logoUri,
    });

    yield put(push(routes.HOME, { from: 'signup' }));

    yield put(setAuthInternalFetching(false));
  } catch (error) {
    yield put(signupAsync.failure(error));
    yield put(setAuthInternalFetching(false));
    if (error?.response?.data?.message) {
      yield put(
        setModalText({
          name: 'errors',
          text: error?.response?.data?.message,
        })
      );
      yield put(toggleModalByName({ name: 'errors', value: 'true' }));
    }
  }
}

function* loginHelper({ email, password }) {
  for (let i = 1; i <= 10; i++) {
    yield put(eightAuth.loginAsync.request({ username: email, password }));

    const { success, failure } = yield race({
      success: take(eightAuth.loginAsync.success.type),
      failure: take(eightAuth.loginAsync.failure.type),
    });

    if (success) {
      return success.payload;
    }

    if (i === 10) {
      throw failure.payload;
    }

    yield delay(1000);
  }
}

function* refresh({ type }) {
  const { pathname } = window.location;
  if (type === eightAuth.refreshAsync.failure.type && !pathname.includes(routes.LOGIN)) {
    const { search } = window.location;
    const query = window.location.pathname.includes(routes.LOGIN)
      ? ''
      : `?returnTo=${window.location.pathname}`;
    const customQuery = search ? search : query;

    yield put(push(`${routes.LOGIN}${customQuery}`));
  }
}

function* login({ payload, type }) {
  if (type === eightAuth.loginAsync.success.type) {
    const { search } = window.location;
    const urlParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlParams);
    const returnTo = params.returnTo ?? routes.HOME;

    yield put(push(returnTo));
  } else if (type === eightAuth.loginAsync.failure.type) {
    const err = payload;

    console.log(err);

    yield put(
      setModalText({
        name: 'errors',
        text: err?.response?.data['error_description']
          ? processReceivedText(err?.response?.data['error_description'])
          : err?.response?.data?.message,
      })
    );
    yield put(toggleModalByName({ name: 'errors', value: true }));
  }
}

function* sendVerificationCode({ payload }) {
  try {
    const { emailAddress } = payload;

    yield call(brandService.sendVerificationCode, {
      emailAddress,
    });

    toast.info(t('Verification code sent'));
  } catch (error) {
    const errorCode = error.response.data.code;

    toast.error(
      errorCode === 11
        ? t(
            `We're sorry, this email is already in use. Please enter a different email or log in to your account.`
          )
        : t('Unable to send verification code')
    );
  }
}
export function* authActionWatcher() {
  yield takeLatest(signupAsync.request.type, signUp);
  yield takeLatest(sendVerificationCodeAsync.request.type, sendVerificationCode);
  yield takeLatest([eightAuth.loginAsync.success.type, eightAuth.loginAsync.failure.type], login);
  yield takeLatest([eightAuth.refreshAsync.failure.type], refresh);
}
