import React from 'react';
import { Layout } from '../../../components/Layout';
import { BrandOnboardingContent } from '../../../components/PagesContent/SettingsContent/BrandOnboardingContent';

function SettingsBrandOnboarding() {
  return (
    <Layout>
      <BrandOnboardingContent />
    </Layout>
  );
}

export default SettingsBrandOnboarding;
