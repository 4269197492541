export const LETTERS_REGEXP = /^([^0-9]*)$/;
export const NUMBER_REGEXP = /^\d*(\.\d*)?$/;
export const CARD_NUMBERS_REGEX = /^(\d{4}\s)(\d{4}\s)(\d{4}\s)\d{4}$/gi;
export const CURRENCY_WITH_CENTS = /^[0-9]+(\.[0-9]{1,2})?$/gm;
export const DATE_REGEXP = /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/;
export const DIGITS_AND_COMAS = /^[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]+)?$/;
export const DIGITS_AND_COMAS_AND_EMPTY = /^$|[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]+)?$/;
export const EMAIL_REGEXP =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
export const PASSWORD_REGEXP =
  /(?=^.{6,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/gm;
export const EMOJIS_REGEXP = /<a?:.+?:\d{18}>|\p{Extended_Pictographic}/gu;
// eslint-disable-next-line no-control-regex
export const UNICODE_REGEXP = /[^\u0000-\u007F]+/gm;

export const summaryCampaignValidation = (campaign) => {
  const schema = {
    'Campaign Name': (value) => value.length >= 3,
    'Brand Name': (value) => value.length >= 3,
    'Brand Logo': (value) => value.length > 2,
  };

  const info = {
    'Campaign Name': campaign?.name,
    'Brand Name': campaign?.campaignBrandName,
    'Brand Logo': campaign?.campaignBrandLogoUri,
  };

  const validate = (ad, schema) =>
    Object.keys(schema)
      .filter((key) => !schema[key](ad[key]))
      .map((key) => ({
        errorName: `${key} is missing`,
      }));

  const errors = validate(info, schema);

  return {
    campaignIsValid: !errors.length,
    campaignErrors: errors,
  };
};

export const summarySponsorshipSetValidation = (adSet) => {
  // FIX ME!
  const schema = {
    'AdSet Name': (value) => value?.length > 2,
    'Start Date': (value) => value,
    Budget: (value) => value > 0,
    Charity: (value) => value?.length > 2,
  };

  const info = {
    'AdSet Name': adSet.name,
    'Start Date': adSet.startDate,
    Charity: adSet.selectedCharity.name,
    Budget: adSet.budget,
  };

  const validate = (ad, schema) =>
    Object.keys(schema)
      .filter((key) => !schema[key](ad[key]))
      .map((key) => ({
        errorName: `${key} is invalid or missing`,
      }));

  const errors = validate(info, schema);

  return {
    adSetIsValid: !errors.length,
    adSetErrors: errors,
  };
};

export const summaryUniteSponsorshipSetValidation = (adSet) => {
  const schema = {
    'AdSet Name': (value) => value?.length > 2,
    'Start Date': (value) => value?.length > 2,
  };

  const info = {
    'AdSet Name': adSet.name,
    'Start Date': adSet.startDate,
  };

  const validate = (ad, schema) =>
    Object.keys(schema)
      .filter((key) => !schema[key](ad[key]))
      .map((key) => ({
        errorName: `${key} is missing`,
      }));

  const errors = validate(info, schema);

  return {
    adSetIsValid: !errors.length,
    adSetErrors: errors,
  };
};

export const summarySponsorshipValidation = (ad) => {
  const schema = {
    'Ad Name': (value) => value?.length > 2,
    // 'Message Cover Tile': value => value?.length > 2,
    'Video Message': (value) => value?.length > 2,
    // 'SMS Message': value => value?.length > 2,
    Questions: (value) => !!value,
    'Call To Action Image': (value) => value?.length > 2,
  };

  const info = {
    'Ad Name': ad.name,
    // 'Message Cover Tile': ad.adCover,
    'Video Message': ad.adVideo,
    // 'SMS Message': ad.smsMessage,
    Questions: ad.receivedQuestions,
    'Call To Action Image': ad.ctaCover,
  };

  const validate = (ad, schema) =>
    Object.keys(schema)
      .filter((key) => !schema[key](ad[key]))
      .map((key) => ({
        errorName: `${key} is missing`,
      }));

  const errors = validate(info, schema);

  return {
    adIsValid: !errors.length,
    adErrors: errors,
  };
};

export const isSecondDateMoreThanFirst = (initialDate, comparedDate) => {
  const [startYear, startMonth, startDay] = initialDate.split('-');
  const [endYear, endMonth, endDay] = comparedDate.split('-');

  if (comparedDate) {
    if (+endYear < +startYear) {
      return false;
    }

    if (+endYear > +startYear && +endDay < +startDay) {
      return true;
    }

    if (+endYear === +startYear && +endMonth < +startMonth) {
      return false;
    }

    if (+endYear === +startYear && +endMonth > +startMonth) {
      return true;
    }

    return +endDay >= +startDay;
  } else {
    return true;
  }
};

export const isDateFormat = (value) => (value ? value.match(DATE_REGEXP) : true);
