import { createSelector } from 'reselect';

const authStateSelector = (state) => state.auth;

export const adminAccessTokenSelector = createSelector(
  authStateSelector,
  (state) => state.adminAccessToken
);

export const authInternalFetchingSelector = createSelector(
  authStateSelector,
  (state) => state.internalFetching
);
