import React from 'react';

import { T, useT } from '@transifex/react';

import { Modal } from '../../../components/Modal';
import { Button } from '../../common/Button';
import s from './StripeCancel.module.scss';

const StripeCancel = ({ modals, handleCloseModal, handleRepeat }) => {
  const t = useT();

  return (
    <>
      {modals.stripe.isOpen && (
        <Modal closeModal={handleCloseModal} withSubmit customButtonCancelText="Back">
          <div className={s['stripe-cancel-content']}>
            <p className={s['stripe-cancel-content__title']}>
              {t('Sorry! Your bank account verification failed.')}
              {t('Please try again.')}
              <T
                _str="If it keeps happening - email us at {emailLink}."
                emailLink={
                  <a
                    className={s['stripe-cancel-content__support-link']}
                    href={`mailto:brand.support@weare8.com`}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    brand.support@weare8.com
                  </a>
                }
              />
            </p>
            <Button
              customText={t('Try Again')}
              styling="primary"
              type="button"
              callback={handleRepeat}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default StripeCancel;
