import React, { useState, useRef, useEffect } from 'react';

import s from './ProgressCircle.module.scss';
import { useT } from '@transifex/react';

const ProgressCircle = ({ title, progress, closeModal }) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);
  const t = useT();

  const size = 150;
  const center = size / 2;
  const strokeWidth = 5;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const circleOneStroke = '#f7f7f7';
  const circleTwoStroke = '#6672fb';

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, progress, offset, circumference]);

  return (
    <div className={s['progress']}>
      <div className={s['progress-box']}>
        <div className={s['progress-box__title']}>{title}</div>
        <svg className={s['progress-box__svg']} width={size} height={size}>
          <circle
            className={s['progress-box__svg-circle-bg']}
            stroke={circleOneStroke}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
          />
          <circle
            className={s['progress-box__svg-circle']}
            ref={circleRef}
            stroke={circleTwoStroke}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={offset}
          />
          <text className={s['progress-box__svg-circle-text']} x={`${78}`} y={`${80}`}>
            {progress}%
          </text>
        </svg>
        <div className={s['progress-box__close']} onClick={closeModal}>
          {t('Cancel')}
        </div>
      </div>
    </div>
  );
};

export default ProgressCircle;
