import React from 'react';

import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { useT } from '@transifex/react';

import s from './ReportingSelect.module.scss';
import reportingSelectStyles from '../reportingSelectStyles';

const ReportingSelect = ({
  options,
  placeholder,
  defaultValue,
  name,
  handleSelect,
  control,
  disabled,
  questionOptionsSelect,
}) => {
  const t = useT();

  const noOptionsMessage = () => {
    return <div>{t('Select Question first')}</div>;
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return (
          <div className={s['select']}>
            <Select
              closeMenuOnSelect={true}
              cacheOptions={true}
              options={options}
              styles={reportingSelectStyles(disabled)}
              noOptionsMessage={questionOptionsSelect && noOptionsMessage}
              placeholder={placeholder}
              isMulti={false}
              isDisabled={disabled}
              onChange={(v) => {
                onChange(v);
                handleSelect && handleSelect(v);
              }}
              value={value}
            />
          </div>
        );
      }}
    />
  );
};

export default ReportingSelect;
