import { t } from '@transifex/native';
import { v4 as uuidv4 } from 'uuid';

export const defaultQuestions = {
  multipleChoiceQuestions: [
    {
      optionTexts: {
        1: 'Every Day',
        2: 'Special Occasions/Sometimes',
        3: 'Future Me',
        4: "Something I'll Try",
        5: 'Not sure it does',
      },
      optionTags: {},
      id: uuidv4(),
      questionText: 'How does {{brand}} fit into your life? ',
      questionOrder: 1,
    },
    {
      optionTexts: {
        1: 'Unforgettable',
        2: 'A faint memory',
        3: 'Vanished instantly',
        4: 'Stuck in my mind',
      },
      optionTags: {},
      id: uuidv4(),
      questionText: 'How memorable was this ad',
      questionOrder: 2,
    },
  ],
  viewerSentimentQuestions: [
    {
      optionTags: {},
      id: uuidv4(),
      questionText: 'How did this ad from {{brand}} make you feel?',
      questionOrder: 3,
    },
  ],
};

const sponsorshipConstants = Object.freeze({
  initialState: {
    id: uuidv4(),
    name: 'New Ad',
    isDirty: false,
    isCreated: false,
    isDraft: true,
    isActive: false,
    isReadOnly: false,
    moderationStatus: 'Unknown',
    deliveryStatus: 'Inactive',
    smsMessage: '',
    processingAdCover: '',
    processingAdCoverFile: {},
    thirdPartyTrackingScripts: [],
    adCover: '',
    adCoverFile: {},
    adVideo: '',
    adVideoFile: {},
    videoLength: '0:00:00',
    isVideoUploaded: false,
    progress: 0,
    videoUploadStatus: '',
    videoKey: '',
    processingCtaCover: '',
    processingCtaCoverFile: {},
    ctaCover: '',
    ctaCoverFile: {},
    ctaLink: '',
    ctaButtonText: '',
    ctaDiscount: '',
    trackingUrl: '',
    receivedQuestions: false,
    appToTrack: { name: 'Any', label: 'Any', value: 'any' },
    questions: null,
    questionToAddTags: '',
    localTags: [],
    impressionsUrl: '',
    completionUrl: '',
    internalFetching: false,
    videoWidth: '',
    videoHeight: '',
    previousSponsorshipNames: [],
  },
  adCoverFileSpecs: [
    {
      name: 'File Type',
      values: '.jpg, .png',
      icon: 'file-type',
    },
    {
      name: 'Aspect Ratio',
      values: '16:9, 1:1, 4:3',
      icon: 'aspect-ratio',
    },
  ],

  videoAdFileSpecs: [
    {
      name: 'File Type',
      values: '.Mp4, .MOV',
      icon: 'file-type',
    },
    {
      name: 'Resolution',
      values: 'minimum width: 600p',
      icon: 'resolution',
    },
    {
      name: 'Aspect Ratio',
      values: '9:16, 1:1, 4:3, 16:9, 3:4',
      icon: 'aspect-ratio',
    },
  ],

  ctaImageFileSpecs: [
    {
      name: 'File Type',
      values: '.jpg, .png',
      icon: 'file-type',
    },
    {
      name: 'Resolution',
      values: 'minimum width: 600p',
      icon: 'resolution',
    },
    {
      name: 'Aspect Ratio',
      values: '1:1',
      icon: 'aspect-ratio',
    },
  ],
  defaultOption: {
    id: uuidv4(),
    name: 'Option',
    text: '',
    tags: [],
  },
  defaultQuestion: {
    id: uuidv4(),
    name: 'Question',
    text: '',
    saved: false,
    questionOrder: 1,
    type: { label: 'Yes/No', name: 'yesno', value: 'YesNo' },
    options: [],
    responsesOptions: [
      {
        id: uuidv4(),
        name: 'Yes',
        text: 'Yes',
        tags: [],
        tagsOptions: [],
      },
      {
        id: uuidv4(),
        name: 'No',
        text: 'No',
        tags: [],
        tagsOptions: [],
      },
    ],
    tagsCount: 0,
  },
  defaultQuestions: [
    {
      id: uuidv4(),
      name: 'Question',
      saved: false,
      text: '',
      questionOrder: 1,
      type: { label: 'Yes/No', name: 'yesno', value: 'YesNo' },
      options: [],
      responsesOptions: [
        {
          id: uuidv4(),
          name: 'Yes',
          text: 'Yes',
          tags: [],
        },
        {
          id: uuidv4(),
          name: 'No',
          text: 'No',
          tags: [],
        },
      ],
    },
  ],

  questionsTypes: [
    { label: t('Yes/No'), name: 'yesno', value: 'YesNo' },
    { label: t('Viewer Sentiment'), name: 'viewersentiment', value: 'ViewerSentiment' },
    { label: t('Multiple Choice'), name: 'multiplechoice', value: 'MultipleChoice' },
    { label: t('Select All That Apply'), name: 'selectallthatapply', value: 'SelectAllThatApply' },
    { label: t('Short Answer'), name: 'shortanswer', value: 'ShortAnswer' },
  ],

  addToTrackOptions: [
    { label: t('Any'), name: 'Any', value: 'any' },
    { label: 'iOS', name: 'iOS', value: 'iOS' },
    { label: 'Android', name: 'Android', value: 'Android' },
  ],

  conversionTracking: [
    {
      sectionName: 'Tracking pixel status',
      iconName: 'status-warning',
      text: t('No tracking pixel'),
      linkClass: 'link-red',
      linkText: 'Set up tracking pixel',
      linkUrl: 'https://www.weare8.com/',
    },
    {
      sectionName: 'Tracking pixel status',
      iconName: 'status-ok',
      text: t('Tracking pixel active'),
      linkClass: 'link-violet',
      linkText: 'See tracking pixel',
      linkUrl: 'https://www.weare8.com/',
    },
    {
      sectionName: 'Tracking pixel status',
      iconName: 'status-warning',
      text: t('No tracking pixel'),
      linkClass: 'link-red',
      linkText: 'Test tracking pixel',
      linkUrl: 'https://www.weare8.com/',
    },
  ],
  viewerSentimentAnswers: ['0x1F620', '0x1F641', '0x1F610', '0x1F642', '0x1F601'],
  yesNoTagsOptions: [
    {
      id: uuidv4(),
      name: 'Yes',
      text: t('Yes'),
      tags: [],
    },
    {
      id: uuidv4(),
      name: 'No',
      text: t('No'),
      tags: [],
    },
  ],
  viewerSentimentTagsOptions: [
    {
      id: uuidv4(),
      name: '1',
      text: '0x1F620',
      tags: [],
    },
    {
      id: uuidv4(),
      name: '2',
      text: '0x1F641',
      tags: [],
    },
    {
      id: uuidv4(),
      name: '3',
      text: '0x1F610',
      tags: [],
    },
    {
      id: uuidv4(),
      name: '4',
      text: '0x1F642',
      tags: [],
    },
    {
      id: uuidv4(),
      name: '5',
      text: '0x1F601',
      tags: [],
    },
  ],
  fieldNames: [
    {
      value: 'name',
      label: 'Ad Name',
    },
    {
      value: 'adVideo',
      label: 'Ad Video',
    },
    {
      value: 'questions',
      label: 'Questions',
    },
    {
      value: 'ctaCover',
      label: 'Call To Action Image',
    },
    {
      value: 'ctaLink',
      label: 'Call To Action Link',
    },
    {
      value: 'ctaButtonText',
      label: 'Custom CTA button text',
    },
    {
      value: 'ctaDiscount',
      label: 'Discount Code',
    },
    {
      value: 'ctaTrackingService',
      label: 'Third Party Tracking Service',
    },
    {
      value: 'ctaTrackingScript',
      label: 'Third Party Tracking Code',
    },
  ],
  thirdPartyScriptOptions: [
    { name: 'DoubleClick', value: 'doubleClick' },
    { name: 'DoubleVerify', value: 'doubleVerify' },
    { name: 'Ad Form (Track CTA Link Clicks)', value: 'adFormCtaTracking' },
    { name: 'Ad Form (Track Completions)', value: 'adFormCompletionsTracking' },
  ],
  canvaUrl: 'https://www.canva.com/create/ads/',
});

export default sponsorshipConstants;
