import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { useRouter } from '../../../hooks/useRouter';

import classNames from 'classnames/bind';
import s from './SettingsMenu.module.scss';
import { useT } from '@transifex/react';

const cx = classNames.bind(s);

const SettingsMenu = ({ items = [] }) => {
  const { pathname } = useRouter();
  const t = useT();
  const getLinkClassName = useCallback(
    (path) => {
      return cx({
        [s['settings-menu__list-item-link']]: true,
        [s['settings-menu__list-item-link--active']]: pathname.includes(path),
      });
    },
    [pathname]
  );

  return (
    <div className={s['settings-menu']}>
      <ul className={s['settings-menu__list']}>
        {items.map((item) => (
          <li key={item.path} className={s['settings-menu__list-item']}>
            <NavLink
              exact
              to={item.path}
              activeClassName={s['active-name']}
              className={getLinkClassName(item.path)}
            >
              {t(item.name)}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingsMenu;
