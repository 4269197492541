import { useT } from '@transifex/react';
import React, { useCallback, useState } from 'react';
import { SvgIcon } from '../SvgIcon';
import s from './Copy.module.scss';

function Copy({ textToCopy, style }) {
  const [copied, setCopied] = useState('');
  const t = useT();
  const handleCopy = useCallback((text) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, []);
  return (
    <div className={s['copy-container']} style={style}>
      {copied && <small>{t(`Copied!`)}</small>}
      <div className={s['copy-icon']} onClick={() => handleCopy(textToCopy)}>
        <SvgIcon name="copy" />
      </div>
    </div>
  );
}

export default Copy;
