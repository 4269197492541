import React from 'react';

import { Tile } from '../../common/Tile';

import s from './RadioWithTile.module.scss';

const RadioWithTile = ({ 'data-testid': testId, checked, onChange, value, register, ...props }) => {
  return (
    <div className={s['radio']}>
      <label className={s['radio__label']}>
        <input
          data-testid={testId}
          hidden
          type={props.type}
          name={props.name}
          value={value}
          id={props.title}
          onChange={onChange}
          disabled={props.disabled}
          {...register(props.name)}
        />
        <Tile
          iconName={props.icon}
          title={props.title}
          subtitle={props.subtitle}
          isChecked={checked === value}
          disabled={props.disabled}
        />
      </label>
    </div>
  );
};

export default RadioWithTile;
