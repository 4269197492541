import { createSelector } from 'reselect';

const summaryStateSelector = (state) => state.summary;

const summaryInternalSelector = createSelector(summaryStateSelector, (state) => state.summaryState);

export const allModulesActivatedSelector = createSelector(
  summaryInternalSelector,
  (state) => state.allModulesActivated
);

export const summaryFetchingSelector = createSelector(
  summaryStateSelector,
  (state) => state.fetching
);

export const campaignIsPublishingSelector = createSelector(
  summaryStateSelector,
  (state) => state.fetching
);
