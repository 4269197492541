import React from 'react';
import { Layout } from '../../../components/Layout';
import { PixelSetupContent } from '../../../components/PagesContent/SettingsContent/PixelSetupContent';

function SettingsPixelSetup() {
  return (
    <Layout>
      <PixelSetupContent />
    </Layout>
  );
}

export default SettingsPixelSetup;
