import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Layout } from '../../../../components/Layout';
import { NameAndAudience } from '../../../../components/PagesContent/CreateContent/NameAndAudience';
import { TimingAndBudget } from '../../../../components/PagesContent/CreateContent/TimingAndBudget';
import { ScrollToTopWrapper } from '../../../../components/wrappers/ScrollToTopWrapper';

import { fragments, routes, sponsorshipSetConstants } from '../../../../constants';

import { budgetFormatter } from '../../../../utils/budget';

import preventSubmitOnEnter from '../../../../utils/preventSubmitOnEnter';

import { campaignIdSelector } from '../../../../store/campaign/campaign';

import { modalsSelector, savingToastSelector, setStickyMenuItem } from '../../../../store/ui';

import {
  sponsorshipSetCreateSelector,
  updateSponsorshipSetAsync,
  sponsorshipSetEditIdSelector,
  setSponsorshipSetData,
  activateSponsorshipSetFromCreationAsync,
  deactivateSponsorshipSetFromCreationAsync,
  toggleActivateSponsorshipSet,
} from '../../../../store/campaign/sponsorshipSet';

import {
  brandInfoSelector,
  fetchTagsAsync,
  fetchUniqueSponsorshipSetNamesAsync,
  incentivesSelector,
  locationsSelector,
  previousUniqueSponsorshipSetsNamesSelector,
} from '../../../../store/brand';
import { useRouter } from '../../../../hooks/useRouter';
import { SponsorshipSetPlaceholder } from '../../../Placeholders/SponsorshipSetPlaceholder';
import { useForm } from 'react-hook-form';
import { createModeValidationSchema, editModeValidationSchema } from './validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { StepButtons } from '../../../../components/StepButtons';
import { constructTestId } from '../../../../utils/test-ids';
import { generatePath } from 'react-router';
import { CREATE_CAMPAIGN_PAGE } from '../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import { useActiveForm } from '../../../../hooks/useActiveForm';
import {
  adSetsSelector,
  campaignDataFetchingSelector,
  campaignSelector,
  setAdSetIsDirty,
} from '../../../../store/data';
import { useUnmount } from 'react-use';
import { useDirtyFields } from '../../../../hooks/useDirtyFields';
import { useInfiniteScroll } from '../../../../hooks/useInfiniteScroll';
import { sponsorshipCreateSelector } from '../../../../store/campaign/sponsorship';
import { comparingIncentivePrice } from '../../../../utils/comparingIncentivePrice';

const SECTION = CREATE_CAMPAIGN_PAGE.NAME_AND_AUDIENCE;

const SponsorshipSetForm = () => {
  const dispatch = useDispatch();

  const [clickedOnSave, setClickedOnSave] = useState(false);

  const brandInfo = useSelector(brandInfoSelector);
  const campaignId = useSelector(campaignIdSelector);
  const fetching = useSelector(campaignDataFetchingSelector);
  const modals = useSelector(modalsSelector);
  const sponsorshipSetDraft = useSelector(sponsorshipSetCreateSelector);
  const sponsorshipCreate = useSelector(sponsorshipCreateSelector);
  const sponsorshipSetId = useSelector(sponsorshipSetEditIdSelector);
  const previousSponsorshipSetsNames = useSelector(previousUniqueSponsorshipSetsNamesSelector);
  const campaignCreate = useSelector(campaignSelector);
  const saving = useSelector(savingToastSelector).isOpen;
  const regionsAndCities = useSelector(locationsSelector);
  const allAdSets = useSelector(adSetsSelector);
  const incentives = useSelector(incentivesSelector);

  const adSetIsLocked = !sponsorshipSetDraft.isDraft;

  // // a legacy pricing table, meaning that brand's samiPricingId changed
  // // at some point through admin
  // useEffect(() => {
  //   const pricingIsFetched = sponsorshipSetDraft?.videoAdPricingData?.length;

  //   if (
  //     sponsorshipSetDraft?.samiPricingId &&
  //     sponsorshipSetDraft?.samiPricingId !== brandInfo?.samiPricingId
  //   ) {
  //     if (pricingIsFetched) {
  //       setAdSetPricingOptions(sponsorshipSetDraft?.videoAdPricingData);
  //     } else {
  //       if (sponsorshipSetDraft?.samiPricingId)
  //         dispatch(
  //           fetchAdSetPricingOptionsAsync.request({
  //             samiPricingId: sponsorshipSetDraft?.samiPricingId,
  //           })
  //         );
  //     }
  //   } else if (brandInfo?.videoAdPricingData?.length) {
  //     setAdSetPricingOptions(brandInfo?.videoAdPricingData);
  //   }
  // }, [
  //   brandInfo?.samiPricingId,
  //   brandInfo.videoAdPricingData,
  //   dispatch,
  //   sponsorshipSetDraft?.samiPricingId,
  //   sponsorshipSetDraft?.videoAdPricingData,
  // ]);

  const { query, push } = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isDirty },
    control,
    reset,
    getValues,
    watch,
    trigger,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: sponsorshipSetDraft.name,
      gender: sponsorshipSetDraft.gender,
      age: sponsorshipSetDraft.age,
      locations: sponsorshipSetDraft.locations.length > 0 ? sponsorshipSetDraft.locations : [],
      locationsStatus: sponsorshipSetDraft.locations.length > 0,
      interests: sponsorshipSetDraft.interests,
      interestsStatus: sponsorshipSetDraft.interests.length > 0,
      passions: sponsorshipSetDraft.passions,
      passionsStatus: sponsorshipSetDraft.passions.length > 0,
      eightPixelAudiences: sponsorshipSetDraft.eightPixelAudiences,
      eightPixelAudiencesStatus: sponsorshipSetDraft.eightPixelAudiences.length > 0,
      includeTags: sponsorshipSetDraft.includeTags,
      excludeTags: sponsorshipSetDraft.excludeTags,
      deviceType: sponsorshipSetDraft.deviceType,
      frequencyCap: sponsorshipSetDraft.frequencyCap ?? 1,
      frequencyCapStatus: Boolean(sponsorshipSetDraft.frequencyCap),
      isEightPixelEnabled: sponsorshipSetDraft.isEightPixelEnabled,
      pixelConverstionEvent: sponsorshipSetDraft.pixelConversionEvent || 'purchase',
      customAudiences: sponsorshipSetDraft.customAudiencesSelected,
      startDate: sponsorshipSetDraft.startDate,
      endDate: sponsorshipSetDraft.endDate,
      budget: sponsorshipSetDraft.budget
        ? budgetFormatter(sponsorshipSetDraft.budget)
        : sponsorshipSetDraft.budget,
      charitiesAreas: sponsorshipSetDraft.charitiesAreas,
      selectedCharity: sponsorshipSetDraft.selectedCharity,
      incentive:
        comparingIncentivePrice(
          sponsorshipSetDraft.incentive,
          sponsorshipSetDraft.videoDurationAllowedRange,
          sponsorshipSetDraft.isDraft,
          incentives
        ) ?? brandInfo.incentive,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    context: {
      brandUtcString: brandInfo.brandTimezone.utcString,
      isEdit: !sponsorshipSetDraft.isDraft,
    },
    resolver:
      sponsorshipSetDraft.isReadOnly || sponsorshipSetId
        ? yupResolver(editModeValidationSchema)
        : yupResolver(createModeValidationSchema),
  });

  useEffect(() => {
    reset(
      {
        name: sponsorshipSetDraft.name,
        gender: sponsorshipSetDraft.gender,
        age: sponsorshipSetDraft.age,
        locations: sponsorshipSetDraft.locations.length > 0 ? sponsorshipSetDraft.locations : [],
        locationsStatus: sponsorshipSetDraft.locations.length > 0,
        interests: sponsorshipSetDraft.interests,
        interestsStatus: sponsorshipSetDraft.interests.length > 0,
        passions: sponsorshipSetDraft.passions,
        passionsStatus: sponsorshipSetDraft.passions.length > 0,
        eightPixelAudiences: sponsorshipSetDraft.eightPixelAudiences,
        eightPixelAudiencesStatus: sponsorshipSetDraft.eightPixelAudiences.length > 0,
        includeTags: sponsorshipSetDraft.includeTags,
        excludeTags: sponsorshipSetDraft.excludeTags,
        deviceType: sponsorshipSetDraft.deviceType,
        frequencyCap: sponsorshipSetDraft.frequencyCap,
        frequencyCapStatus: Boolean(sponsorshipSetDraft.frequencyCap),
        isEightPixelEnabled: Boolean(sponsorshipSetDraft.isEightPixelEnabled),
        pixelConversionEvent:
          (sponsorshipSetDraft.pixelConversionEvent === 'unknown'
            ? 'purchase'
            : sponsorshipSetDraft.pixelConversionEvent) ||
          (campaignCreate.category === 'conversions' ? 'purchase' : 'unknown'),
        customAudiences: sponsorshipSetDraft.customAudiencesSelected,
        startDate: sponsorshipSetDraft.startDate,
        endDate: sponsorshipSetDraft.endDate,
        budget: sponsorshipSetDraft.budget
          ? budgetFormatter(sponsorshipSetDraft.budget)
          : sponsorshipSetDraft.budget,
        charitiesAreas: sponsorshipSetDraft.charitiesAreas,
        selectedCharity: sponsorshipSetDraft.selectedCharity,
        incentive:
          comparingIncentivePrice(
            sponsorshipSetDraft.incentive,
            sponsorshipSetDraft.videoDurationAllowedRange,
            sponsorshipSetDraft.isDraft,
            incentives
          ) ?? brandInfo.incentive,
      },
      { keepDirtyValues: true, keepDirty: true }
    );
  }, [
    reset,
    sponsorshipSetDraft.name,
    sponsorshipSetDraft.isActive,
    sponsorshipSetDraft.regionsAndCities,
    sponsorshipSetDraft.locations,
    sponsorshipSetDraft.interests,
    sponsorshipSetDraft.passions,
    sponsorshipSetDraft.excludeTags,
    sponsorshipSetDraft.deviceType,
    sponsorshipSetDraft.customAudiencesSelected,
    sponsorshipSetDraft.age,
    sponsorshipSetDraft.gender,
    sponsorshipSetDraft.startDate,
    sponsorshipSetDraft.endDate,
    sponsorshipSetDraft.budget,
    sponsorshipSetDraft.charitiesAreas,
    sponsorshipSetDraft.selectedCharity,
    sponsorshipSetDraft.isUnite,
    sponsorshipSetDraft.incentive,
    brandInfo.videoAdPricingData,
    sponsorshipSetDraft.isEightPixelEnabled,
    sponsorshipSetDraft.pixelConversionEvent,
    sponsorshipSetDraft.includeTags,
    campaignCreate.category,
    sponsorshipSetDraft.eightPixelAudiences,
    brandInfo.incentive,
    sponsorshipSetDraft.videoDurationAllowedRange,
    incentives,
    sponsorshipSetDraft.isDraft,
    sponsorshipSetDraft.frequencyCap,
  ]);

  const { dirtyFieldsList, shouldSave } = useDirtyFields({
    storedDirtyFields: sponsorshipSetDraft?.dirtyFields,
    formDirtyFields: dirtyFields,
    fieldNames: sponsorshipSetConstants.fieldNames,
    isDirty,
  });

  // This runs when we switch to another entity or unmount (the component does unmount)
  useUnmount(() => {
    if (shouldSave && !clickedOnSave) {
      dispatch(
        setAdSetIsDirty({
          isDirty: true,
          dirtyFields: { ...dirtyFields, ...sponsorshipSetDraft.dirtyFields },
          form: getValues(),
          adSetId: sponsorshipSetDraft?.id || query.ssid,
        })
      );
      dispatch(setSponsorshipSetData({ name: 'id', value: '' }));
    }
    dispatch(setSponsorshipSetData({ name: 'dirtyFields', value: {} }));
  });

  const timingRef = useRef();

  // HACK! Scroll on startDate field when it's invalid. Works for now, should fix later!!!
  const handleError = (err) => {
    (err.startDate || err.endDate) && timingRef.current.scrollIntoView();
  };

  const handleFormSubmit = () => {
    setClickedOnSave(true);
    if (shouldSave)
      dispatch(
        updateSponsorshipSetAsync.request({
          form: getValues(),
          dirtyFields: { ...dirtyFields, ...sponsorshipSetDraft.dirtyFields },
          sponsorshipSetId: sponsorshipSetDraft.id,
        })
      );
    else {
      push(
        generatePath(routes.SPONSORSHIP_EDIT, {
          cid: campaignId,
          ssid: sponsorshipSetDraft.id,
          sid: sponsorshipSetDraft.ads[0].id,
        }) + fragments.AD_NAME
      );
    }
  };

  useActiveForm({
    type: 'adSet',
    campaignId: query.cid,
    adSetId: query.ssid,
    adId: query.sid,
    allAdSets,
  });

  useEffect(() => () => dispatch(setStickyMenuItem(null)), [dispatch]);

  const handlePreviousStep = useCallback(() => {
    push(
      generatePath(routes.CAMPAIGN_EDIT, {
        cid: campaignId,
      }) + fragments.OBJECTIVE
    );
  }, [campaignId, push]);

  const handleAdSetActivation = useCallback(
    (e) => {
      const checked = e.target.checked;
      dispatch(toggleActivateSponsorshipSet(checked));
      dispatch(
        checked
          ? activateSponsorshipSetFromCreationAsync.request(sponsorshipSetDraft.id || query.ssid)
          : deactivateSponsorshipSetFromCreationAsync.request(sponsorshipSetDraft.id || query.ssid)
      );
    },
    [dispatch, sponsorshipSetDraft.id, query.ssid]
  );

  const {
    moreToFetch: moreNamesToFetch,
    fetchMore: fetchMoreNames,
    setKeyword: setNamesKeyword,
  } = useInfiniteScroll(fetchUniqueSponsorshipSetNamesAsync.request, previousSponsorshipSetsNames);

  const {
    moreToFetch: moreTagsToFetch,
    fetchMore: fetchMoreTags,
    setKeyword: setTagsKeyword,
    fetchInitials: fetchInitialTags,
  } = useInfiniteScroll(fetchTagsAsync.request, brandInfo.fetchedTags, true);

  return (
    <Layout>
      <form onSubmit={handleSubmit(handleFormSubmit, handleError)} onKeyDown={preventSubmitOnEnter}>
        <ScrollToTopWrapper>
          {fetching ? (
            <SponsorshipSetPlaceholder />
          ) : (
            <>
              <NameAndAudience
                fetchMoreSponsorshipSetNames={fetchMoreNames}
                moreSponsorshipSetNamesToFetch={moreNamesToFetch}
                setNamesKeyword={setNamesKeyword}
                fetchInitialTags={fetchInitialTags}
                fetchMoreTags={fetchMoreTags}
                moreTagsToFetch={moreTagsToFetch}
                setTagsKeyword={setTagsKeyword}
                brandInfo={brandInfo}
                sponsorshipSetDraft={sponsorshipSetDraft}
                previousSponsorshipSetsNames={previousSponsorshipSetsNames}
                objective={campaignCreate.category}
                control={control}
                errors={errors}
                watch={watch}
                register={register}
                setValue={setValue}
                trigger={trigger}
                handleAdSetActivation={handleAdSetActivation}
                regionsAndCities={regionsAndCities}
                clearErrors={clearErrors}
                adSetIsLocked={adSetIsLocked}
                dirtyFieldsList={dirtyFieldsList}
              />
              <TimingAndBudget
                brandInfo={brandInfo}
                modals={modals}
                sponsorshipSetDraft={sponsorshipSetDraft}
                videoAdPricingCheckboxes={brandInfo.videoAdPricingData}
                control={control}
                errors={errors}
                register={register}
                setValue={setValue}
                trigger={trigger}
                timingRef={timingRef}
                campaignCreate={campaignCreate}
                adSetIsLocked={adSetIsLocked}
                clearErrors={clearErrors}
              />
            </>
          )}
        </ScrollToTopWrapper>
        <StepButtons
          data-testid={constructTestId(SECTION, 'continue-button')}
          handlePreviousStep={handlePreviousStep}
          saving={saving}
          isSubmitDisabled={!shouldSave}
        />
      </form>
    </Layout>
  );
};

export default SponsorshipSetForm;
