import { createReducer, initialAsyncState } from 'eight.js.store-common';
import { campaignConstants } from '../../../constants';
import {
  activateCampaignFromCreationAsync,
  clearCampaignDraft,
  setCampaignData,
  setFetching,
  setCampaignEditId,
  setCampaignIsReadOnly,
  toggleActivateCampaign,
  updateCampaignAsync,
  deactivateCampaignFromCreationAsync,
  archiveCampaignAsync,
  unarchiveCampaignAsync,
  setActiveCampaign,
  setCampaignName,
} from './actions';

const initialState = {
  ...initialAsyncState,
  campaignCreate: campaignConstants.initialState,
  campaignEdit: {
    id: '',
  },
  fetching: false,
  error: '',
};

export const campaignReducer = createReducer('@@campaign', initialState, {
  [setActiveCampaign]: ({ state, action }) => {
    state.campaignCreate = { ...state.campaignCreate, ...action.payload };
  },

  [activateCampaignFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [clearCampaignDraft]: ({ state }) => {
    state.campaignCreate = initialState.campaignCreate;
    state.campaignEdit = initialState.campaignEdit;
  },

  [deactivateCampaignFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [setCampaignData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.campaignCreate[name] = value;
  },

  [updateCampaignAsync.failure]: ({ state, action }) => {
    state.campaignEdit.error = action.payload;
  },

  [setFetching]: ({ state, action }) => {
    state.fetching = action.payload;
  },

  [setCampaignEditId]: ({ state, action }) => {
    state.campaignEdit.id = action.payload;
  },

  [setCampaignIsReadOnly]: ({ state, action }) => {
    state.campaignCreate.isReadOnly = action.payload;
  },

  [toggleActivateCampaign]: ({ state, action }) => {
    state.campaignCreate.isActive = action.payload;
  },

  [archiveCampaignAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [unarchiveCampaignAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [setCampaignName]: ({ state, action }) => {
    state.campaignCreate.name = action.payload;
  },
});
