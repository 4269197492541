import React from 'react';

import classNames from 'classnames';
import s from './Title.module.scss';

const Title = ({ children, className }) => {
  const combinedClassName = classNames({
    [s['title']]: true,
    [className]: !!className,
  });

  return (
    <div className={combinedClassName}>
      <h1 className={s['title__content']}>{children}</h1>
    </div>
  );
};

export default Title;
