import React, { useMemo, createRef, useCallback, useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Controller } from 'react-hook-form';
import { Button } from '../Button';
import Dropzone from 'react-dropzone';
import { useT } from '@transifex/react';

import { SvgIcon } from '../SvgIcon';
import { ErrorMessage } from '../ErrorMessage';

import classNames from 'classnames/bind';
import s from './UploadZone.module.scss';

const cx = classNames.bind(s);

const UploadZone = ({
  error,
  control,
  name,
  type,
  styleType,
  handleChange,
  contentType,
  fileUrl,
  disabled,
  videoWidth,
  videoHeight,
  hasFile = false,
  'data-testid': testId,
  inputRef,
}) => {
  const videoPlayerRef = createRef();
  const videoElementRef = useRef(null);

  const [computedHeight, setComputedHeight] = useState('100%');
  const [computedWidth, setComputedWidth] = useState('100%');
  // const [videoAdjust, setVideoAdjust] = useState(null);

  const videoContainerRef = useCallback(
    (node) => {
      if (node && videoWidth && videoHeight && !hasFile) {
        const videoRatio = videoWidth / videoHeight;
        const containerRatio = node.clientWidth / node.clientHeight;
        if (videoRatio >= containerRatio) {
          setComputedHeight((node.clientWidth * (videoHeight - 45)) / videoWidth);
          setComputedWidth('100%');
        } else {
          setComputedHeight(node.clientHeight - 45);
          setComputedWidth((node.clientHeight * videoWidth) / (videoHeight - 45));
        }
      }
    },
    [videoWidth, videoHeight, hasFile]
  );

  function calcContainerHeight(videoHeight, ContainerW, videoWidth) {
    const x = (ContainerW / videoHeight) * videoWidth;
    return x;
  }

  const t = useT();

  const zoneClassNames = useMemo(
    () =>
      cx({
        [s['zone__inner']]: true,
        [s['zone__inner--error']]: error,
        [s['zone__inner--circle']]: styleType === 'circle',
        [s['zone__inner--rect']]: styleType === 'rect',
        [s['zone__inner--square']]: styleType === 'square',
        [s['zone__inner--portrait']]: styleType === 'portrait',
        [s['zone__inner--small-square']]: styleType === 'small-square',
        [s['zone__inner--disabled']]: disabled,
      }),
    [styleType, error, disabled]
  );

  const setVideoObjectFit = useCallback(() => {
    if (videoElementRef?.current?.getInternalPlayer()) {
      if (videoWidth < videoHeight) {
        // setVideoAdjust(calcContainerHeight(videoHeight, 234, videoWidth));
        videoElementRef.current.getInternalPlayer().style.objectFit = 'cover';
      } else {
        videoElementRef.current.getInternalPlayer().style.objectFit = 'contain';
      }
    }
  }, [videoHeight, videoWidth]);

  useEffect(() => {
    if (videoElementRef?.current?.getInternalPlayer()) {
      setVideoObjectFit();
    }
  }, [setVideoObjectFit]);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={fileUrl}
      render={({ field: { onChange, onBlur } }) => {
        const handleDrop = (files) => {
          if (files && files[0]) {
            onChange(files[0].path);
            handleChange(files);
          }
        };

        return (
          <Dropzone accept={contentType} multiple={false} onDrop={handleDrop} ref={videoPlayerRef}>
            {({ getRootProps, getInputProps }) => (
              <div className={s['zone']}>
                <div className={zoneClassNames} ref={videoContainerRef}>
                  <div {...getRootProps({ className: s['dropzone'] })} ref={inputRef}>
                    <input
                      {...getInputProps({
                        'data-testid': testId,
                        disabled,
                        hidden: true,
                        name,
                        onBlur,
                      })}
                    />
                    {type === 'video' && fileUrl ? (
                      <>
                        <div className={s['video-wrapper']}>
                          <ReactPlayer
                            ref={videoElementRef}
                            className={s['player']}
                            volume={1}
                            controls={true}
                            height={'100%'}
                            width={hasFile ? '100%' : computedWidth}
                            url={fileUrl}
                            onReady={setVideoObjectFit}
                          />
                        </div>
                        <Button
                          type="button"
                          customText={t('Select a different video file...')}
                          styling="black"
                          fullwidth
                          customClass={s['select-file-btn']}
                        />
                      </>
                    ) : type === 'image' && fileUrl ? (
                      <>
                        <div className={s['image-wrapper']}>
                          <img className={s['image']} src={fileUrl} alt={''} />
                        </div>
                        {styleType === 'square' && (
                          <Button
                            type="button"
                            customText={t('Select a different image file...')}
                            styling="black"
                            fullwidth
                            customClass={s['select-file-btn']}
                          />
                        )}
                      </>
                    ) : type === 'file' && fileUrl ? (
                      <>
                        <div className={s['file-wrapper']}>
                          <div className={s['file-icon']}>
                            <SvgIcon name="document" />
                          </div>
                        </div>
                        {(styleType === 'square' || styleType === 'small-square') && (
                          <Button
                            type="button"
                            customText={t('Select a different file...')}
                            styling="black"
                            fullwidth
                            customClass={s['select-file-btn']}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <div className={s['icon']}>
                          <SvgIcon name="upload" />
                        </div>
                        <div className={s['text']}>
                          {t('Drag and drop a file or click to browse')}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {error && <ErrorMessage error={t(error.message)} />}
              </div>
            )}
          </Dropzone>
        );
      }}
    />
  );
};

export default UploadZone;
