import React from 'react';

import { useT } from '@transifex/react';

import { Modal } from '../../../components/Modal';
import { Button } from '../../common/Button';
import s from './StripeSuccess.module.scss';

const StripeSuccess = ({ modals, handleCloseModal }) => {
  const t = useT();

  return (
    <>
      {modals.stripe.isOpen && (
        <Modal noButtons>
          <div className={s['stripe-success-content']}>
            <p className={s['stripe-success-content__title']}>{t('Success!')}</p>
            <p className={s['stripe-success-content__text']}>{t('Payment method is added.')}</p>
            <Button
              customText={t('Got it!')}
              styling="primary"
              type="button"
              callback={handleCloseModal}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default StripeSuccess;
