import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';

import { createRootReducer } from './root-reducer';
import { rootSaga } from './root-saga';
// import { container } from '../container';

export const configureStore = (history) => {
  const routerMiddleware = createRouterMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [routerMiddleware, sagaMiddleware];
  const enhancers = [];

  if (process.env.NODE_ENV === 'development') {
    // middleware.push(logger);

    // eslint-disable-next-line no-underscore-dangle
    if (window?.__REDUX_DEVTOOLS_EXTENSION__) {
      // eslint-disable-next-line no-underscore-dangle
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  // const tokenService = container.get('TokenService');
  // const { accessToken, refreshToken } = tokenService.retrieveTokens();

  const store = createStore(
    createRootReducer(history),
    // {
    //   auth: {
    //     accessToken,
    //     refreshToken,
    //   },
    // },
    compose(applyMiddleware(...middleware), ...enhancers)
  );

  sagaMiddleware.run(rootSaga);

  return store;
};
