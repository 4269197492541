import { createSelector } from 'reselect';

const sponsorshipSetStateSelector = (state) => state.sponsorshipSet;

export const sponsorshipSetCreateSelector = createSelector(
  sponsorshipSetStateSelector,
  (state) => state.sponsorshipSetCreate
);

export const sponsorshipSetEditSelector = createSelector(
  sponsorshipSetStateSelector,
  (state) => state.sponsorshipSetEdit
);

export const sponsorshipSetEditIdSelector = createSelector(
  sponsorshipSetEditSelector,
  (state) => state.id
);
