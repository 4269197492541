import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setStickyMenuItem } from '../../store/ui';

const useScroll = (refsArray, threshold = 1, rootMargin = '0px') => {
  const observer = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: rootMargin,
      threshold: threshold,
    };

    observer.current = new IntersectionObserver(([target]) => {
      if (target.isIntersecting) {
        const navElement = document.querySelector(`a[name-menu="${target.target.id}"]`);

        if (navElement && !navElement.classList.contains('link--active')) {
          dispatch(setStickyMenuItem(target.target.id));
        } else if (navElement && navElement.classList.contains('link--active')) {
          dispatch(setStickyMenuItem(null));
        }
      }
    }, options);

    refsArray.forEach((element) => {
      observer.current.observe(element.current);
    });

    return () => {
      refsArray.forEach((element) => {
        observer.current.unobserve(element.current);
      });
    };
  }, [refsArray, threshold, dispatch, rootMargin]);
};

export default useScroll;
