import React, { useMemo } from 'react';

import { useT } from '@transifex/react';

import s from './BuyingSystemNotification.module.scss';
import { Link } from 'react-router-dom';

const BuyingSystemNotification = ({ notification, clickOnLink }) => {
  const t = useT();

  const messageFirstPart = useMemo(
    () => notification.message?.split('<u>')[0],
    [notification.message]
  );
  const messageSecondPart = useMemo(
    () => notification.message?.split('</u>')[1],
    [notification.message]
  );

  const trimLinkMessage = useMemo(
    () => notification.message?.split('<u>')[1]?.split('</u>')[0],
    [notification.message]
  );

  const location = useMemo(() => {
    var el = document.createElement('a');
    el.href = notification.url;
    return el.pathname;
  }, [notification.url]);

  return (
    <>
      <div className={s['message-row']}>
        {messageFirstPart && t(`{message}`, { message: messageFirstPart })}
        {trimLinkMessage && (
          <>
            {!notification.url ? (
              <span onClick={clickOnLink} className={s['link']}>
                {t(`{linkMessage}`, { linkMessage: trimLinkMessage })}
              </span>
            ) : (
              <Link to={location} className={s['link']}>
                {t(`{linkMessage}`, { linkMessage: trimLinkMessage })}
              </Link>
            )}
          </>
        )}
        {messageSecondPart && t(`{message}`, { message: messageSecondPart })}
      </div>
    </>
  );
};

export default BuyingSystemNotification;
