import React, { useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames/bind';
import { useT } from '@transifex/react';

import { usePagination } from '../../hooks/usePagination';
import { SvgIcon } from '../common/SvgIcon';
import { CustomSelect } from '../FormElements/Selects/CustomSelect';

import { budgetFormatter, numberToCurrency } from '../../utils/budget';
import { pdf } from '@react-pdf/renderer';
import { Invoice } from '../Invoice';
import { setPaymentName } from '../../utils/settingsBilling';
import s from './TableBilling.module.scss';
import { Link } from 'react-router-dom';
import { billingConstants, routes } from '../../constants';
import { generatePath } from 'react-router';
import { useDispatch } from 'react-redux';

const cx = classNames.bind(s);

const TableBilling = ({
  headers,
  tableData,
  content,
  isInvoicePartner,
  currencySymbol,
  getBrandBillsAsync,
  setDataArray,
  itemsPerPageOptions,
  handleBillingReport,
  brandInfo,
  handleApproveInvoice,
  handlePerformanceClick,
  getBrandTransactionsAsync,
  brandBills,
}) => {
  const t = useT();
  const dispatch = useDispatch();

  const handleDownloadClick = (invoiceData, docName, isReceipt) => async () => {
    const invoiceDataForPartner =
      content === 'transactions history' && isInvoicePartner
        ? brandBills.data.find((item) => item.invoiceId === invoiceData.invoiceId)
        : {};

    const data =
      content === 'transactions history' && isInvoicePartner ? invoiceDataForPartner : invoiceData;

    if (data) {
      const Doc = (
        <Invoice
          data={data}
          brandInfo={brandInfo}
          isReceipt={isReceipt}
          isInvoicePartner={isInvoicePartner}
        />
      );
      const blob = await pdf(Doc).toBlob();
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);

      const docNumber = docName === 'Receipt' ? invoiceData.transactionId : data.invoiceId;

      link.download = `${docName}-${docNumber}.pdf`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const { control, watch } = useForm({
    defaultValues: {
      itemsPerPage: t(billingConstants.itemsPerPage[0]),
    },
  });

  const watchItemsPerPage = watch('itemsPerPage');

  const handleSelectItemsPerPage = useCallback(
    (item) => {
      if (content === 'monthly spend') {
        dispatch(getBrandBillsAsync({ offset: 0, limit: item.value }));
      }

      if (content === 'transactions history') {
        dispatch(getBrandTransactionsAsync({ offset: 0, limit: item.value }));
      }
    },
    [content, getBrandBillsAsync, getBrandTransactionsAsync, dispatch]
  );

  const getPaginationData = useMemo(() => {
    if (content === 'monthly spend') {
      return tableData?.totalBrandBills;
    }

    if (content === 'transactions history') {
      return tableData?.totalBrandTransactions;
    }
  }, [tableData, content]);

  const getPaginationMethod = useMemo(() => {
    if (content === 'monthly spend') {
      return getBrandBillsAsync;
    }

    if (content === 'transactions history') {
      return getBrandTransactionsAsync;
    }
  }, [content, getBrandBillsAsync, getBrandTransactionsAsync]);

  const { pages, pagination, prevPage, nextPage, changePage } = usePagination({
    itemsPerPage: t(watchItemsPerPage.value),
    data: getPaginationData,
    getPage: getPaginationMethod,
  });

  return (
    <>
      <div className={s['table-wrap']}>
        <div className={s['table']}>
          <div className={s['table-head']}>
            {headers.map((cell, index) => (
              <div className={s['table-head__cell']} key={index}>
                {t('{title}', { title: t(cell.title) })}
              </div>
            ))}
          </div>
          <div className={s['table-body']}>
            {content === 'monthly spend' &&
              isInvoicePartner &&
              tableData?.data.length > 0 &&
              tableData?.data.map((item, index) => (
                <div key={index} className={s['table-row']}>
                  <div className={s['table-cell']}>{item?.billedMonth}</div>
                  <Link
                    to={generatePath(routes.SETTINGS_BILLING + routes.SETTINGS_BILLING_REPORT, {
                      year: item?.billedMonth.split(', ')[1],
                      month: item?.billedMonth.split(',')[0],
                    })}
                    className={s['table-cell__link']}
                  >
                    {t('view report')}
                  </Link>
                  <div className={s['table-cell']}>
                    {numberToCurrency(item?.billAmount, false, false, 4)}
                  </div>
                  <div
                    className={cx(s['table-cell'], s['table-cell__capital'], {
                      [s['table-cell__past-due']]: item?.billStatus === 'pastDue',
                    })}
                  >
                    {item?.billStatus}
                  </div>
                  <div className={s['table-cell']}>
                    {(item?.billStatus === 'unpaid' || item?.billStatus === 'pastDue') &&
                      item?.dueDate}
                  </div>
                  <div
                    className={cx(s['table-cell'], {
                      [s['table-cell__link']]:
                        item?.billStatus === 'due' || item?.billStatus === 'unpaid',
                    })}
                    onClick={
                      item?.billStatus === 'due' || item?.billStatus === 'unpaid'
                        ? handleDownloadClick(item, 'Invoice')
                        : undefined
                    }
                  >
                    {item?.invoiceId}
                  </div>
                  <div
                    className={cx(s['table-cell'], {
                      [s['table-cell__link']]:
                        item?.billStatus === 'due' || item?.billStatus === 'unpaid',
                    })}
                    onClick={
                      item?.billStatus === 'due' || item?.billStatus === 'unpaid'
                        ? handleApproveInvoice(item.id)
                        : undefined
                    }
                  >
                    {(item?.billStatus === 'due' || item?.billStatus === 'unpaid') && `Pay now`}
                  </div>
                </div>
              ))}
            {content === 'monthly spend' &&
              !isInvoicePartner &&
              tableData?.data?.length > 0 &&
              tableData?.data.map((item, index) => (
                <div key={index} className={s['table-row']}>
                  <div className={s['table-cell']}>{item?.billedMonth}</div>
                  <Link
                    to={generatePath(routes.SETTINGS_BILLING + routes.SETTINGS_BILLING_REPORT, {
                      year: item?.billedMonth.split(', ')[1],
                      month: item?.billedMonth.split(',')[0],
                    })}
                    className={s['table-cell__link']}
                  >
                    {t('view report')}
                  </Link>
                  <div className={s['table-cell']}>
                    {numberToCurrency(item?.billAmount, false, false, 6)}
                  </div>
                  {item?.billStatus === 'ongoing' ? (
                    <div className={s['table-cell']}>-</div>
                  ) : (
                    <div
                      className={s['table-cell__link']}
                      onClick={handleDownloadClick(item, 'Invoice')}
                    >
                      {item?.invoiceId}
                    </div>
                  )}
                </div>
              ))}
            {content === 'transactions history' &&
              tableData?.data?.length > 0 &&
              tableData?.data.map((item, index) => (
                <div key={item.transactionId} className={s['table-row']}>
                  <div className={s['table-cell']}>{item?.date}</div>
                  <div className={s['table-cell']}>{numberToCurrency(item?.total)}</div>
                  <div className={s['table-cell']}>{item?.transactionId}</div>
                  {isInvoicePartner && (
                    <div
                      className={s['table-cell__link']}
                      onClick={handleDownloadClick(item, 'Invoice')}
                    >
                      {item?.invoiceId}
                    </div>
                  )}
                  <div className={s['table-cell']}>{setPaymentName(item?.paymentMethod)}</div>
                  <div className={s['table-cell']}>{item?.status}</div>
                  <div
                    className={cx(s['table-cell'], {
                      [s['table-cell__link']]: item?.status === 'completed' && item?.paymentMethod,
                    })}
                    onClick={
                      item?.status === 'completed' && item?.paymentMethod
                        ? handleDownloadClick(item, 'Receipt', !isInvoicePartner)
                        : undefined
                    }
                  >
                    {item?.status === 'completed' && item?.paymentMethod && t('download')}
                  </div>
                </div>
              ))}
            {content === 'billing report' &&
              tableData?.length > 0 &&
              tableData?.map((item, index) => (
                <div key={index} className={s['table-row']}>
                  <div className={s['table-cell']}>{item?.campaignId}</div>
                  <div className={s['table-cell']}>{item?.campaignName}</div>

                  <div id={item?.campaignId} className={s['table-cell__link']}>
                    <Link
                      to={
                        generatePath(`${routes.REPORTING}${routes.CAMPAIGN_ROUTE}`, {
                          cid: item?.campaignId,
                        }) + '?type=overview'
                      }
                      id={item?.campaignId}
                    >
                      {t('performance')}
                    </Link>
                  </div>
                  <div className={s['table-cell']}>
                    <p className={s['table-cell__date']}>{item?.fromDate}</p>
                    <p className={s['table-cell__date']}>{item?.toDate}</p>
                  </div>
                  <div className={s['table-cell']}>{budgetFormatter(item?.participants)}</div>
                  <div className={s['table-cell']}>
                    {numberToCurrency(item?.totalCampaignCostAmount)}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {content !== 'billing report' && (
        <div className={s['table-footer']}>
          <div className={s['results-select']}>
            <CustomSelect
              options={itemsPerPageOptions}
              handleSelect={handleSelectItemsPerPage}
              defaultValue={billingConstants.itemsPerPage[0]}
              control={control}
              name="itemsPerPage"
              watchValue={watchItemsPerPage}
              error={null}
              styleType="gray"
            />
          </div>
          <div className={s['pagination']}>
            {pages > 1 && (
              <div className={s['pagination-inner']}>
                <div className={s['pagination-inner__arrow']} onClick={prevPage}>
                  <SvgIcon name="pagination-left" />
                </div>

                {pagination.map((page) => {
                  if (!page.ellipsis) {
                    return (
                      <div
                        className={
                          s[
                            page.current
                              ? 'pagination-inner__button-active'
                              : 'pagination-inner__button'
                          ]
                        }
                        key={page.id}
                        onClick={changePage(page.id)}
                      >
                        {page.id}
                      </div>
                    );
                  } else {
                    return <div key={page.id}>&hellip;</div>;
                  }
                })}

                <div className={s['pagination-inner__arrow']} onClick={nextPage}>
                  <SvgIcon name="pagination-right" />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TableBilling;
