import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { routes } from '../../constants';
import { adSetsSelector } from '../../store/data';

function useActiveMenuItem({ type, adSetId, adId }) {
  const [activeMenuItem, setActiveMenuItem] = useState(false);
  const allAdSets = useSelector(adSetsSelector);

  const match = useRouteMatch(
    type === 'adSet' ? routes.SPONSORSHIP_SET_EDIT : routes.SPONSORSHIP_EDIT
  );
  const activeAdSetId = useMemo(() => match?.params.ssid, [match]);
  const activeAdId = useMemo(() => match?.params.sid, [match]);

  useEffect(() => {
    if (type === 'adSet') {
      setActiveMenuItem(activeAdSetId === adSetId || allAdSets.length === 1);
    } else if (type === 'ad') {
      const selectedAdSet = allAdSets.find((adSet) => adSet.id === adSetId);
      setActiveMenuItem(activeAdId === adId || selectedAdSet?.ads.length === 1);
    }
  }, [activeAdSetId, activeAdId, adSetId, adId, type, allAdSets]);
  return { activeMenuItem };
}

export default useActiveMenuItem;
