import React from 'react';
import CheckboxesPlaceholder from '../../../components/Placeholders/CheckboxesPlaceholder.js/CheckboxesPlaceholder.js';
import InputPlaceholder from '../../../components/Placeholders/InputPlaceholder/InputPlaceholder.js';
import InputWithTextPlaceholder from '../../../components/Placeholders/InputWithTextPlaceholder/InputWithTextPlaceholder.js';
import { SectionTitlePlaceholder } from '../../../components/Placeholders/SectionTitlePlaceholder.js';
import SingleLinePlaceholder from '../../../components/Placeholders/SingleLinePlaceholder/SingleLinePlaceholder.js';
import { sponsorshipSetConstants } from '../../../constants';
import Placeholder from '../Placeholder.js';

function SponsorshipSetPlaceholder({ firstStep, secondStep }) {
  return (
    <>
      <Placeholder gap="45px">
        <SectionTitlePlaceholder />
        <InputPlaceholder />
        <SectionTitlePlaceholder />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
          <CheckboxesPlaceholder items={sponsorshipSetConstants.genderData} />
          <CheckboxesPlaceholder items={sponsorshipSetConstants.ageData} />
        </div>
        <InputWithTextPlaceholder rows={6} />
        <InputWithTextPlaceholder rows={4} />
        <InputWithTextPlaceholder rows={4} />
      </Placeholder>
      <Placeholder gap="25px">
        <InputWithTextPlaceholder rows={2} />
        <InputPlaceholder />
        <InputPlaceholder />
        <InputPlaceholder />
        <CheckboxesPlaceholder items={sponsorshipSetConstants.videoAdPricingCheckboxes} />
        <InputWithTextPlaceholder rows={3} />
        <InputPlaceholder />
        <SectionTitlePlaceholder />
        <div>
          {[...Array(4)].map((item, index) => (
            <SingleLinePlaceholder key={index} />
          ))}
        </div>
        <SingleLinePlaceholder />
        <div>
          {[...Array(10)].map((item, index) => (
            <SingleLinePlaceholder key={index} />
          ))}
        </div>
      </Placeholder>
    </>
  );
}

export default SponsorshipSetPlaceholder;
