import { T } from '@transifex/react';
import React from 'react';
import { pixelTestingEventsHeader } from '../../constants/pixel';
import s from './TablePixelTestingEvents.module.scss';
import { formatToBrandsTimezone } from '../../utils/date/dateHelpers';
import { useSelector } from 'react-redux';
import { brandInfoSelector } from '../../store/brand';
import { setBrandDateFormat } from '../../utils/receivedData';

const TablePixelTestingEvents = ({ isPixelTestInProgress, data }) => {
  const tableName = isPixelTestInProgress ? 'Incoming test events' : 'Previous test events';
  const { countryCode, timezoneId } = useSelector(brandInfoSelector);

  const getEventDataContent = (eventData) => {
    if (!eventData.purchaseAmount && !eventData.customerEmail) return '-';

    if (eventData.purchaseAmount)
      return (
        <>
          purchase amount: <strong>{eventData.purchaseAmount}</strong>
        </>
      );
    if (eventData.customerEmail)
      return (
        <>
          customer email: <strong>{eventData.customerEmail}</strong>
        </>
      );
  };

  return (
    <>
      <div className={s['table-wrap']}>
        <h4>{tableName}</h4>
        <div className={s['table']}>
          <div className={s['table-head']}>
            {pixelTestingEventsHeader.map((title, index) => (
              <div key={index} className={s['table-head__cell']}>
                <T _str="{title}" title={title} />
              </div>
            ))}
          </div>
          <div className={s['table-body']}>
            {data?.map((event, index) => (
              <div className={s['table-row']} key={index}>
                <div className={s['table-cell']}>{event.ev}</div>
                <div className={s['table-cell']}>{getEventDataContent(event.ed)}</div>
                <div className={s['table-cell']}>
                  {setBrandDateFormat(
                    formatToBrandsTimezone(new Date(event.ts), timezoneId),
                    countryCode,
                    true
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {!data.length && (
        <div className={s['empty-body']}>
          <h4>
            {isPixelTestInProgress ? (
              <T _str="Waiting for test events..." />
            ) : (
              <T _str="No recorded events" />
            )}
          </h4>
        </div>
      )}
      <small className={s['alert']}>
        * <T _str="Dates are displayed in brand's timezone" />
      </small>
    </>
  );
};

export default TablePixelTestingEvents;
