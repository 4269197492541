import { call, put, takeLatest, select } from 'redux-saga/effects';

import { unitSearch } from '../../utils/manage';

import { container } from '../../container';

import {
  activateCampaignAsync,
  activateSponsorshipAsync,
  activateSponsorshipSetAsync,
  deactivateCampaignAsync,
  deactivateSponsorshipAsync,
  deactivateSponsorshipSetAsync,
  dismissNotificationAsync,
  fetchAllUnits,
  fetchCampaignsAsync,
  fetchNotificationsAsync,
  fetchSponsorshipSetsAsync,
  fetchSponsorshipsAsync,
  removeNotificationFromArray,
  setCampaignDeliveryStatus,
  setCampaignIsActive,
  setSponsorshipDeliveryStatus,
  setSponsorshipIsActive,
  setSponsorshipSetDeliveryStatus,
  setSponsorshipSetIsActive,
  setSponsorshipSetsAreActive,
  setSponsorshipsAreActive,
  setSponsorshipsDeliveryStatus,
  setSponsorshipSetsDeliveryStatus,
  setCampaignsAreFetching,
  setAdSetsAreFetching,
  setAdsAreFetching,
  setManageIsFetching,
} from './actions';

import {
  receivedCampaignsSelector,
  receivedSponsorshipSetsSelector,
  receivedSponsorshipsSelector,
} from './selectors';

import { brandInfoSelector } from '../brand';
import { setModalContent, toggleModalByName } from '../ui';
import { t } from '@transifex/native';
import { archiveCampaignAsync } from '../campaign/campaign';

const manageService = container.get('ManageService');
const campaignService = container.get('CampaignService');
const sponsorshipSetService = container.get('SponsorshipSetService');
const sponsorshipService = container.get('SponsorshipService');

function* fetchInitialUnitsSaga({ payload }) {
  try {
    yield put(
      fetchCampaignsAsync.request({
        offset: payload.offset,
        limit: payload.limit,
        search: payload.search,
        archived: payload.archived,
      })
    );
    yield put(
      fetchSponsorshipSetsAsync.request({
        offset: payload.initialCall ? 0 : payload.offset,
        limit: payload.initialCall ? 20 : payload.limit,
        search: payload.search,
        archived: payload.archived,
        campaignId: payload.campaignId,
      })
    );
    yield put(
      fetchSponsorshipsAsync.request({
        offset: payload.initialCall ? 0 : payload.offset,
        limit: payload.initialCall ? 20 : payload.limit,
        search: payload.search,
        archived: payload.archived,
        campaignId: payload.campaignId,
        adSetId: payload.adSetId,
      })
    );
  } catch (error) {
    console.log(error);
  }
}

function* fetchCampaignsSaga({ payload }) {
  try {
    yield put(setCampaignsAreFetching(true));

    const { timezoneId } = yield select(brandInfoSelector);

    const response = yield call(manageService.searchCampaigns, {
      offset: payload.offset,
      limit: payload.limit,
      search: payload.search,
      isArchive: payload.archived,
      utcString: timezoneId,
    });

    yield put(fetchCampaignsAsync.success(response));
    yield put(setCampaignsAreFetching(false));
  } catch (error) {
    console.log(error);
    yield put(fetchCampaignsAsync.failure());
  }
}

function* fetchSponsorshipSetsSaga({ payload }) {
  try {
    yield put(setAdSetsAreFetching(true));

    const { timezoneId } = yield select(brandInfoSelector);

    const data = yield call(manageService.searchSponsorshipSets, {
      offset: payload.offset,
      limit: payload.limit,
      search: payload.search,
      isArchive: payload.archived,
      campaignId: payload.campaignId,
      utcString: timezoneId,
    });

    yield put(fetchSponsorshipSetsAsync.success(data));
    yield put(setAdSetsAreFetching(false));
  } catch (error) {
    yield put(fetchSponsorshipSetsAsync.failure(error));
    yield put(setAdSetsAreFetching(false));
  }
}

function* fetchSponsorshipsSaga({ payload }) {
  try {
    yield put(setAdsAreFetching(true));
    const data = yield call(manageService.searchSponsorships, {
      offset: payload.offset,
      limit: payload.limit,
      search: payload.search,
      isArchive: payload.archived,
      campaignId: payload.campaignId,
      adSetId: payload.adSetId,
    });

    yield put(fetchSponsorshipsAsync.success(data));
    yield put(setAdsAreFetching(false));
  } catch (error) {
    yield put(fetchSponsorshipsAsync.failure(error));
    yield put(setAdsAreFetching(false));
  }
}

function* fetchNotificationsSaga() {
  try {
    const response = yield call(manageService.getNotifications, {
      offset: 0,
      limit: 20,
    });
    yield put(fetchNotificationsAsync.success(response));
  } catch (error) {
    yield put(fetchNotificationsAsync.failure(error));
  }
}

function* activateCampaignSaga({ payload }) {
  try {
    yield put(setManageIsFetching(true));

    const receivedCampaigns = yield select(receivedCampaignsSelector);

    const targetedCampaign = unitSearch(payload.id, receivedCampaigns.data, 'Campaign');

    yield call(campaignService.activateCampaign, { campaignId: payload.id });
    yield put(activateCampaignAsync.success());
    yield put(setCampaignIsActive({ id: payload.id, isActive: true }));

    if (targetedCampaign.moderationStatus === 'approved')
      yield put(
        setCampaignDeliveryStatus({
          id: payload.id,
          deliveryStatus: 'active',
        })
      );

    yield put(toggleModalByName({ name: 'unitOpeningWarning', value: true }));

    yield put(setManageIsFetching(false));
  } catch (error) {
    yield put(setManageIsFetching(false));
    yield put(activateCampaignAsync.failure(error));
  }
}

function* deactivateCampaignSaga({ payload }) {
  try {
    yield put(setManageIsFetching(true));

    yield call(campaignService.deactivateCampaign, { campaignId: payload.id });
    yield put(deactivateCampaignAsync.success());

    yield put(setCampaignIsActive({ id: payload.id, isActive: false }));

    yield put(
      setCampaignDeliveryStatus({
        id: payload.id,
        deliveryStatus: 'inactive',
      })
    );

    yield put(
      setSponsorshipSetsAreActive({ sponsorshipSetIds: payload.sponsorshipSetIds, isActive: false })
    );

    yield put(
      setSponsorshipSetsDeliveryStatus({
        sponsorshipSetIds: payload.sponsorshipSetIds,
        deliveryStatus: 'inactive',
      })
    );

    yield put(
      setSponsorshipsAreActive({ sponsorshipIds: payload.sponsorshipIds, isActive: false })
    );

    yield put(
      setSponsorshipsDeliveryStatus({
        sponsorshipIds: payload.sponsorshipIds,
        deliveryStatus: 'inactive',
      })
    );

    if (payload.archiveNext) {
      const content = {
        title: t('Warning'),
        text: t('You are about to archive a campaign. Are you sure?'),
        buttonText: t('Archive'),
        dispatchActionCallback: archiveCampaignAsync.request({ id: payload.id }),
      };
      yield put(setModalContent({ name: 'default', content }));
      yield put(toggleModalByName({ name: 'default', value: true }));
    }

    yield put(setManageIsFetching(false));
  } catch (error) {
    yield put(setManageIsFetching(false));
    yield put(deactivateCampaignAsync.failure(error));
  }
}

function* activateSponsorshipSetSaga({ payload }) {
  try {
    yield put(setManageIsFetching(true));

    const receivedAdSets = yield select(receivedSponsorshipSetsSelector);
    const targetedAdSet = unitSearch(
      payload.sponsorshipSetId,
      receivedAdSets.data,
      'SponsorshipSet'
    );

    yield call(sponsorshipSetService.activateSponsorshipSet, {
      campaignId: payload.campaignId,
      sponsorshipSetId: payload.sponsorshipSetId,
    });
    yield put(activateSponsorshipSetAsync.success());

    yield put(setCampaignIsActive({ id: payload.campaignId, isActive: true }));
    yield put(
      setSponsorshipSetIsActive({ sponsorshipSetId: payload.sponsorshipSetId, isActive: true })
    );

    if (targetedAdSet.moderationStatus === 'approved') {
      yield put(
        setCampaignDeliveryStatus({
          id: payload.campaignId,
          deliveryStatus: 'active',
        })
      );
      yield put(
        setSponsorshipSetDeliveryStatus({
          sponsorshipSetId: payload.sponsorshipSetId,
          deliveryStatus: 'active',
        })
      );
    }

    yield put(toggleModalByName({ name: 'unitOpeningWarning', value: true }));

    yield put(setManageIsFetching(false));
  } catch (error) {
    yield put(setManageIsFetching(false));
    yield put(activateSponsorshipSetAsync.failure(error));
  }
}

function* deactivateSponsorshipSetSaga({ payload }) {
  try {
    yield put(setManageIsFetching(true));

    yield call(sponsorshipSetService.deactivateSponsorshipSet, {
      campaignId: payload.campaignId,
      sponsorshipSetId: payload.sponsorshipSetId,
    });
    yield put(deactivateSponsorshipSetAsync.success());

    yield put(
      setSponsorshipSetIsActive({ sponsorshipSetId: payload.sponsorshipSetId, isActive: false })
    );

    yield put(
      setSponsorshipsAreActive({ sponsorshipIds: payload.sponsorshipIds, isActive: false })
    );

    yield put(
      setSponsorshipSetDeliveryStatus({
        sponsorshipSetId: payload.sponsorshipSetId,
        deliveryStatus: 'inactive',
      })
    );
    yield put(
      setSponsorshipsDeliveryStatus({
        sponsorshipIds: payload.sponsorshipIds,
        deliveryStatus: 'inactive',
      })
    );

    yield put(setManageIsFetching(false));
  } catch (error) {
    yield put(setManageIsFetching(false));
    yield put(deactivateSponsorshipSetAsync.failure(error));
  }
}

function* activateSponsorshipSaga({ payload }) {
  try {
    yield put(setManageIsFetching(true));
    const receivedAds = yield select(receivedSponsorshipsSelector);
    const targetedAd = unitSearch(payload.sponsorshipId, receivedAds.data, 'Sponsorship');

    yield call(sponsorshipService.activateSponsorship, {
      campaignId: payload.campaignId,
      sponsorshipSetId: payload.sponsorshipSetId,
      sponsorshipId: payload.sponsorshipId,
    });

    yield put(activateSponsorshipAsync.success());

    yield put(setCampaignIsActive({ id: payload.campaignId, isActive: true }));

    yield put(
      setSponsorshipSetIsActive({ sponsorshipSetId: payload.sponsorshipSetId, isActive: true })
    );

    yield put(
      setSponsorshipIsActive({
        sponsorshipId: payload.sponsorshipId,
        isActive: true,
      })
    );

    if (targetedAd.moderationStatus === 'approved') {
      yield put(
        setCampaignDeliveryStatus({
          id: payload.campaignId,
          deliveryStatus: 'active',
        })
      );
      yield put(
        setSponsorshipSetDeliveryStatus({
          sponsorshipSetId: payload.sponsorshipSetId,
          deliveryStatus: 'active',
        })
      );
      yield put(
        setSponsorshipDeliveryStatus({
          sponsorshipId: payload.sponsorshipId,
          deliveryStatus: 'active',
        })
      );
    }
    yield put(setManageIsFetching(false));
  } catch (error) {
    yield put(setManageIsFetching(false));
    yield put(activateSponsorshipAsync.failure(error));
  }
}

function* deactivateSponsorshipSaga({ payload }) {
  try {
    yield put(setManageIsFetching(true));

    yield call(sponsorshipService.deactivateSponsorship, {
      campaignId: payload.campaignId,
      sponsorshipSetId: payload.sponsorshipSetId,
      sponsorshipId: payload.sponsorshipId,
    });
    yield put(deactivateSponsorshipAsync.success());

    yield put(
      setSponsorshipIsActive({
        sponsorshipId: payload.sponsorshipId,
        isActive: false,
      })
    );
    yield put(
      setSponsorshipDeliveryStatus({
        sponsorshipId: payload.sponsorshipId,
        deliveryStatus: 'inactive',
      })
    );
    yield put(setManageIsFetching(false));
  } catch (error) {
    yield put(setManageIsFetching(false));
    yield put(deactivateSponsorshipAsync.failure(error));
  }
}

function* dismissNotificationSaga({ payload }) {
  try {
    yield call(manageService.dismissNotification, {
      notificationId: payload.notificationId,
      campaignId: payload.campaignId,
    });
    yield put(dismissNotificationAsync.success());
    yield put(removeNotificationFromArray({ notificationId: payload.notificationId }));
  } catch (error) {
    yield put(dismissNotificationAsync.failure(error));
  }
}

export function* manageActionWatcher() {
  yield takeLatest(fetchAllUnits, fetchInitialUnitsSaga);
  yield takeLatest(fetchCampaignsAsync.request.type, fetchCampaignsSaga);
  yield takeLatest(fetchSponsorshipSetsAsync.request.type, fetchSponsorshipSetsSaga);
  yield takeLatest(fetchSponsorshipsAsync.request.type, fetchSponsorshipsSaga);
  yield takeLatest(fetchNotificationsAsync.request.type, fetchNotificationsSaga);
  yield takeLatest(activateCampaignAsync.request.type, activateCampaignSaga);
  yield takeLatest(deactivateCampaignAsync.request.type, deactivateCampaignSaga);
  yield takeLatest(activateSponsorshipSetAsync.request.type, activateSponsorshipSetSaga);
  yield takeLatest(deactivateSponsorshipSetAsync.request.type, deactivateSponsorshipSetSaga);
  yield takeLatest(activateSponsorshipAsync.request.type, activateSponsorshipSaga);
  yield takeLatest(deactivateSponsorshipAsync.request.type, deactivateSponsorshipSaga);
  yield takeLatest(dismissNotificationAsync.request.type, dismissNotificationSaga);
}
