import React, { useEffect, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { T, useT } from '@transifex/react';
import { useDispatch } from 'react-redux';

import { TextInput } from '../../FormElements/TextInput';
import { Button } from '../../common/Button';
import { Spinner } from '../../../components/Spinner';
import { submitNewCharityAsync } from '../../../store/campaign/campaign';

import { newCharityValidationSchema } from './validationSchema';

import s from './NewCharity.module.scss';
import preventSubmitOnEnter from '../../../utils/preventSubmitOnEnter';

const NewCharity = ({ campaignDraft, campaignFetching }) => {
  const t = useT();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
  } = useForm({
    defaultValues: {
      newCharityName: campaignDraft.newCharityName,
      newCharityWebsite: campaignDraft.newCharityWebsite,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(newCharityValidationSchema),
  });

  const watchCharityName = useWatch({ control, name: 'newCharityName' });
  const watchCharityWebsite = useWatch({ control, name: 'newCharityWebsite' });

  useEffect(() => {
    reset({
      newCharityName: campaignDraft.newCharityName,
      newCharityWebsite: campaignDraft.newCharityWebsite,
    });
  }, [reset, campaignDraft.newCharityName, campaignDraft.newCharityWebsite]);

  const handleFormSubmit = useCallback(() => {
    dispatch(submitNewCharityAsync.request(getValues()));
  }, [dispatch, getValues]);

  return (
    <>
      {campaignFetching && <Spinner />}
      <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={preventSubmitOnEnter}>
        <div className={s['modal-text']}>
          <T _str="Please provide the name and a URL link to your selected charity. Please note: your chosen charity must be registered to be a donation partner. We will message you as soon as your charity has been reviewed." />
        </div>
        <div className={s['modal-input__title']}>{t('Name of Charity')}</div>
        <TextInput
          type="text"
          id="newCharityName"
          name="newCharityName"
          label={t('ENTER NAME OF CHARITY')}
          register={register}
          error={errors.newCharityName}
          watchValue={watchCharityName}
        />

        <div className={s['modal-input__title']}>{t('Charity Website')}</div>
        <TextInput
          type="text"
          id="newCharityWebsite"
          name="newCharityWebsite"
          label="WWW.EXAMPLE.COM"
          register={register}
          error={errors.newCharityWebsite}
          watchValue={watchCharityWebsite}
        />

        <Button
          type="submit"
          styling="primary"
          customText={t('Submit Charity')}
          customClass={s['modal-button']}
        />
      </form>
    </>
  );
};

export default NewCharity;
