import React from 'react';

import classNames from 'classnames';
import s from './Logo.module.scss';

import logo from '../../assets/images/logo.svg';
import mobileLogo from '../../assets/images/logo-mobile.svg';

const Logo = ({ isOpen }) => {
  const logoClassName = classNames({
    [s['logo-wrapper']]: true,
    [s['logo-wrapper--mobile']]: !isOpen,
  });
  return (
    <div className={logoClassName}>
      {isOpen ? <img src={logo} alt="logo" /> : <img src={mobileLogo} alt="mobile logo" />}
    </div>
  );
};

export default Logo;
