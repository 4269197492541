import { createAction } from 'eight.js.store-common';

export const clearModalContent = createAction('@@ui/CLEAR_MODAL_CONTENT');

export const setModalContent = createAction('@@ui/SET_MODAL_CONTENT');

export const setModalText = createAction('@@ui/SET_MODAL_TEXT');

export const setModalDisplayMode = createAction('@@ui/SET_MODAL_DISPLAY_MODE');

export const setModalTitle = createAction('@@ui/SET_MODAL_TITLE');

export const setModalQuestionsContent = createAction('@@ui/SET_MODAL_QUESTIONS_CONTENT');

export const setStateStatus = createAction('@@ui/SET_STATE_STATUS');

export const toggleSideBar = createAction('@@ui/TOGGLE_SIDEBAR');

export const toggleModalByName = createAction('@@ui/TOGGLE_MODAL_BY_NAME');

export const toggleSavingToast = createAction('@@ui/TOGGLE_SAVING_TOAST');

export const toggleUploadingToast = createAction('@@ui/TOGGLE_UPLOADING_TOAST');

export const setShowModalFunds = createAction('@@ui/SET_SHOW_MODAL_FUNDS');

export const setStickyMenuItem = createAction('@@ui/SET_STICKY_MENU_ITEM');
