import React, { useMemo } from 'react';

import { SvgIcon } from '../../SvgIcon';

import classNames from 'classnames/bind';
import s from './SquareButton.module.scss';

const cx = classNames.bind(s);

const SquareButton = ({ disabled, callback, styling, 'data-testid': testId }) => {
  const buttonsClassNames = useMemo(
    () =>
      cx({
        [s['button']]: true,
        [s['button-disabled']]: disabled,
      }),
    [disabled]
  );

  return (
    <button
      className={buttonsClassNames}
      type="button"
      onClick={callback}
      disabled={disabled}
      data-testid={testId}
    >
      <SvgIcon name={styling === 'plus' ? 'plus' : 'minus'} />
    </button>
  );
};

export default SquareButton;
