import config from '../../../config';
import { eightAuth } from '../../eightauth';

export class CampaignService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  activateCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/activate`,
      method: 'POST',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res;
  };

  deactivateCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/adset/publish/${campaignId}/manually-deactivate`,
      method: 'POST',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res;
  };

  archiveCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/archive`,
      method: 'POST',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res;
  };

  unarchiveCampaign = async ({ campaignId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/unarchive`,
      method: 'POST',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res;
  };

  updateCampaign = async (payload) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v5/watchandrespond/`,
      method: 'POST',
      bodyType: 'json',
      body: {
        campaignId: payload.campaignId,
        name: payload.name,
        brandId: payload.brandId,
        brandName: payload.campaignBrandName,
        brandLogoOriginalFileName: payload.media.name,
        brandCountryCode: payload.countryCode,
        category: payload.category,
        spendingLimit: 1000,
        brandLogo: payload.campaignBrandLogoUri,
        charityInfo: payload.charity,
        isActive: payload.isActive,
      },
    });

    const preSignedUrl = res.data?.brandLogoImagePreSignedUrlDetail?.preSignedUrl;

    if (preSignedUrl) {
      await fetch(preSignedUrl, {
        method: 'PUT',
        body: payload.media,
      });
    }

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };

  duplicateCampaign = async ({ campaignId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/watchandrespond/duplicate-request`,
      method: 'POST',
      bodyType: 'json',
      body: {
        campaignId,
      },
    });

    return response.data;
  };

  getDuplicationStatus = async ({ duplicateRequestId }) => {
    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/campaigns/duplicate-status/${duplicateRequestId}`,
      method: 'GET',
      bodyType: 'json',
    });

    return response.data;
  };

  submitNewCharity = async ({ newCharityName, newCharityWebsite }) => {
    const res = await this.fetchService.fetch({
      url: `${config.usersAPI}/brands/me/add-charity-request`,
      method: 'POST',
      bodyType: 'json',
      body: {
        charityName: newCharityName,
        charityWebsite: newCharityWebsite,
      },
    });

    if (res.status !== 200 && res.status !== 201) {
      throw new Error('Unable to send request for new Charity');
    }

    return res.data;
  };
}
