import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { CustomCheckbox } from '../../CustomCheckbox';

import s from './DropdownSelect.module.scss';

function DropdownNestedOption({ data, parentValue, setValue, value, options }) {
  const [currentValue, setCurrentValue] = useState(
    value?.find((val) => val.value === data.value)?.value
  );

  useEffect(() => {
    setCurrentValue(value?.find((val) => val.value === data.value)?.value);
  }, [value, data.value]);

  const isChecked = useMemo(() => {
    return (
      (currentValue === data.value && value.some((val) => val.value === currentValue)) ||
      value.some((val) => val.value === data.region)
    );
  }, [currentValue, data.value, value, data.region]);

  const handleValueChange = useMemo(() => {
    let finalValue = [];

    const parentExists = value?.some((val) => val.value === data.region);
    const parent = options.find((option) => option.value === data.region);

    if (parentExists) {
      finalValue = value?.filter((val) => val.value !== data.region);
      finalValue = [...finalValue, ...parent.cities];
      finalValue = finalValue.filter((val) => val.value !== data.value);
      return finalValue;
    }
    const valueAlreadyExists = value?.some((val) => val.value === data.value);
    if (valueAlreadyExists) {
      return value?.filter((val) => val.value !== data.value);
    }
    return [...value, data];
  }, [data, options, value]);

  const handleClick = (e) => {
    setCurrentValue(value);

    setValue(handleValueChange);
  };
  return (
    <div className={classNames(s['data'], s['child'])} onClick={handleClick}>
      <div className={s['checkbox']}>
        <CustomCheckbox checked={isChecked} />
      </div>
      <span>{data.label}</span>
    </div>
  );
}

export default DropdownNestedOption;
