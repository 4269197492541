import React, { useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';

import { DisabledNavLink } from '../../common/DisabledNavLink';

import { campaignMenu } from '../../../constants';

import s from './NewSponsorshipMenu.module.scss';
import { SvgIcon } from '../../common/SvgIcon';
import { MenuOptions } from '../../common/MenuOptions';
import { createNewAd, deleteAd, duplicateAd } from '../../../store/data';
import { sponsorshipCreateSelector } from '../../../store/campaign/sponsorship';
import { useSelector } from 'react-redux';
import useActiveMenuItem from '../../../hooks/useActiveMenuItem/useActiveMenuItem';
import classNames from 'classnames';
import { AnimatedMenu } from '../AnimatedMenu';

const SponsorshipMenu = ({
  sponsorshipEditId,
  campaignId,
  sponsorshipSetId,
  sponsorshipMode,
  stickyMenuItem,
  ad,
  adSet,
  isArchived,
  unitIsApproved,
}) => {
  const sponsorshipNav = campaignMenu.menuItems.find((menuItem) => menuItem.name === 'Ad');
  const [showSubMenu, setShowSubmenu] = useState(true);

  const activeSponsorship = useSelector(sponsorshipCreateSelector);

  const isDisabled = useMemo(() => !adSet.isCreated || isArchived, [adSet.isCreated, isArchived]);

  const { activeMenuItem } = useActiveMenuItem({
    type: 'ad',
    adId: ad.id,
    adSetId: adSet.id,
  });

  useEffect(() => {
    setShowSubmenu(activeMenuItem);
  }, [activeMenuItem]);

  const adName = useMemo(() => {
    return activeSponsorship.id === ad.id ? activeSponsorship.name : ad.name;
  }, [activeSponsorship.id, ad.id, ad.name, activeSponsorship.name]);

  // The 'questions' , 'action' menu options depend on sponsorship id creation.
  // const isMenuItemDisabled = (item) =>
  //   item === 'questions' || item === 'action' ? !ad?.id : isDisabled;

  return (
    <div className={s['sponsorship-menu']}>
      <div className={s['sponsorship-menu__item']}>
        <button
          className={
            activeMenuItem && !isDisabled
              ? classNames(s['sponsorship-menu__item-title'], s['active'])
              : isDisabled
              ? classNames(s['sponsorship-menu__item-title'], s['disabled'])
              : s['sponsorship-menu__item-title']
          }
        >
          <DisabledNavLink
            to={generatePath(sponsorshipNav.editPath, {
              cid: campaignId,
              ssid: adSet.id,
              sid: ad.id,
            })}
            isDisabled={isDisabled}
            fragment="ad-name"
          >
            <div
              className={s['expand-icon']}
              onClick={() => setShowSubmenu((prevState) => !prevState)}
            >
              <div className={showSubMenu ? s['open'] : s['closed']}>
                <SvgIcon name="expand" />
              </div>
            </div>
            {ad.isDirty && <SvgIcon name="warning" />}
            <div className={s['ad-icon']}>
              <SvgIcon name="ad" />
            </div>
            <span>{adName || 'New Ad'}</span>
          </DisabledNavLink>
        </button>
        <MenuOptions
          name="Ad"
          adId={ad.id}
          adSetId={adSet.id}
          items={adSet.ads}
          createFunction={createNewAd}
          deleteFunction={deleteAd}
          duplicateFunction={duplicateAd}
          type="ad"
          campaignId={campaignId}
          isDisabled={isDisabled}
          isOpen={activeMenuItem && !isDisabled}
          unitIsApproved={unitIsApproved}
        />
      </div>
      <AnimatedMenu
        show={showSubMenu}
        menuItems={sponsorshipNav.submenuItems}
        campaignId={campaignId}
        adSetId={adSet.id}
        adId={ad.id}
        isDisabled={isDisabled}
        stickyMenuItem={stickyMenuItem}
      />
    </div>
  );
};

export default SponsorshipMenu;
