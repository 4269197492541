import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { T, useT } from '@transifex/react';

import { Button } from '../../common/Button';

import { TextInput } from '../../FormElements/TextInput';

import { invoiceRequestValidationSchema } from '../ConfirmSuccess/validationSchema';

import s from './InvoiceRequest.module.scss';
import { numberToThousandCurrency } from '../../../utils/budget';

const InvoiceRequest = ({
  handleInvoicePartnerRequestData,
  invoicePartnerData,
  handleSubmitInvoicePartnerRequest,
}) => {
  const t = useT();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      estimatedMonthlySpend: invoicePartnerData?.estimatedMonthlySpend,
      contactName: invoicePartnerData?.contactName,
      contactNumber: invoicePartnerData?.contactNumber,
      contactEmail: invoicePartnerData?.contactEmail,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(invoiceRequestValidationSchema),
  });

  useEffect(() => {
    reset({
      estimatedMonthlySpend: invoicePartnerData?.estimatedMonthlySpend,
      contactName: invoicePartnerData?.contactName,
      contactNumber: invoicePartnerData?.contactNumber,
      contactEmail: invoicePartnerData?.contactEmail,
    });
  }, [reset, invoicePartnerData]);

  const watchEstimatedMonthlySpend = watch('estimatedMonthlySpend');
  const watchContactName = watch('contactName');
  const watchContactNumber = watch('contactNumber');
  const watchContactEmail = watch('contactEmail');

  return (
    <div className={s['invoice-request']}>
      <form onSubmit={handleSubmit(handleSubmitInvoicePartnerRequest)}>
        <div className={s['invoice-request__subheader']}>
          <strong>{t('Please note')}</strong>. {t("It's only available for")}{' '}
          {numberToThousandCurrency(10000)}
          {t('+ campaigns, additional brand verification is required')}
        </div>
        <div className={s['invoice-request__input-title']}>{t('Estimated monthly spend')}</div>
        <TextInput
          type="text"
          id="estimatedAmount"
          name="estimatedMonthlySpend"
          label={t('AMOUNT')}
          register={register}
          error={errors.estimatedMonthlySpend}
          watchValue={watchEstimatedMonthlySpend}
        />

        <div className={s['invoice-request__input-title']}>{t('Contact name')}</div>
        <TextInput
          type="text"
          id="name"
          name="contactName"
          label={t('NAME')}
          register={register}
          error={errors.contactName}
          watchValue={watchContactName}
        />

        <div className={s['invoice-request__input-title']}>{t('Contact phone number')}</div>
        <TextInput
          type="text"
          id="phoneNumber"
          name="contactNumber"
          label={t('PHONE NUMBER')}
          register={register}
          error={errors.contactNumber}
          watchValue={watchContactNumber}
        />

        <div className={s['invoice-request__input-title']}>{t('Contact email')}</div>
        <TextInput
          type="text"
          id="email"
          name="contactEmail"
          label={t('EMAIL')}
          register={register}
          error={errors.contactEmail}
          watchValue={watchContactEmail}
        />

        <div className={s['invoice-request__buttons']}>
          <Button type="submit" customText={t('Send request')} styling="primary" />
        </div>
      </form>
    </div>
  );
};

export default InvoiceRequest;
