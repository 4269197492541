import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import s from './PopUp.module.scss';

const cx = classNames.bind(s);

const PopUp = ({
  title,
  message,
  okText,
  cancelText,
  children,
  onConfirm,
  position,
  noButtons,
  alwaysVisible = false,
  primary,
  customClass,
  disabled,
}) => {
  const [visible, setVisible] = useState(false);

  const popUpContainerClassNames = useMemo(
    () =>
      cx(customClass, {
        [s['pop-up-container']]: true,
        [s[`${position}-primary`]]: primary,
        [s[`${position}-default`]]: !primary,
      }),
    [position, primary, customClass]
  );

  return (
    <>
      <div className={[s['children']]} onClick={() => !visible && !disabled && setVisible(true)}>
        {children}
        {(visible || alwaysVisible) && (
          <div className={popUpContainerClassNames} style={{ pointerEvents: noButtons && 'none' }}>
            <div className={s['pop-up-content']}>
              <div>{title}</div>
              <div className={s['message']}>{message}</div>
              {!noButtons && (
                <div className={s['buttons-container']}>
                  <button
                    type="button"
                    className={s['ok-btn']}
                    onClick={() => {
                      onConfirm();
                      return setVisible(false);
                    }}
                  >
                    {okText || 'Ok'}
                  </button>
                  <button
                    type="button"
                    className={classNames(s['cancel-btn'])}
                    onClick={() => setVisible(false)}
                  >
                    {cancelText || 'Cancel'}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PopUp;
