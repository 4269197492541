import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { T, useT } from '@transifex/react';

import { Question } from '../../../Question';
import { Button } from '../../../common/Button';
import { InfoCard } from '../../../common/InfoCard';

import { sponsorshipConstants } from '../../../../constants';

import s from './Questions.module.scss';

import { constructTestId } from '../../../../utils/test-ids/';
import { CREATE_CAMPAIGN_PAGE } from '../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';
import {
  addOption,
  addQuestion,
  changeQuestionCatagory,
  getUpdatedQuestions,
  moveOption,
  moveQuestion,
  removeOption,
  removeQuestion,
} from '../../../../utils/form/formHelpers';
import { FormGroup } from '../../../FormElements/FormGroup';
import CustomAudienceTagsModal from '../../../ModalsContent/CustomAudienceTags/CustomAudienceTagsModal';
import { useScroll } from '../../../../hooks/useScroll';

const SECTION = CREATE_CAMPAIGN_PAGE.NAME_AND_AUDIENCE;
const Questions = ({
  brand,
  modals,
  isDisabled,
  sponsorshipCreate,
  toggleModalByName,
  setStickyMenuItem,
  control,
  errors,
  questions,
  onQuestionsChange,
  questionsRef,
  inputRef,
  localTags,
}) => {
  const t = useT();
  const dispatch = useDispatch();

  const [selectedQuestion, setSelectedQuestion] = useState('');

  useScroll([questionsRef], 0.2);

  useEffect(() => {
    dispatch(setStickyMenuItem('questions'));
  }, [setStickyMenuItem, dispatch]);

  const onQuestionChange = useCallback(
    (updatedQuestion) => {
      const updatedQuestions = getUpdatedQuestions(updatedQuestion, questions);
      onQuestionsChange(updatedQuestions);
    },
    [onQuestionsChange, questions]
  );

  const handleQuestionType = useCallback(
    (id) => (value) => {
      changeQuestionCatagory(value, id, questions, onQuestionChange);
    },
    [questions, onQuestionChange]
  );

  const handleAddOption = useCallback(
    (id) => {
      addOption(id, questions, onQuestionChange);
    },
    [questions, onQuestionChange]
  );

  const handleDeleteOption = useCallback(
    (questionId, idx) => {
      removeOption(idx, questionId, questions, onQuestionChange);
    },
    [questions, onQuestionChange]
  );

  const handleAddQuestion = () => {
    addQuestion(questions, onQuestionsChange);
  };

  const handleDeleteQuestion = useCallback(
    (e) => {
      const { id } = e.target;
      removeQuestion(id, questions, onQuestionsChange);
    },
    [questions, onQuestionsChange]
  );

  const handleQuestionDragEnd = (props, questions, onQuestionsChange) => {
    const sourceIndex = props.source.index;
    const destinationIndex = props.destination?.index;

    moveQuestion(sourceIndex, destinationIndex, questions, onQuestionsChange);
  };

  const handleOptionDragEnd = useCallback(
    (questionId) => (props) => {
      const sourceIndex = props.source.index;
      const destinationIndex = props.destination?.index;

      moveOption(sourceIndex, destinationIndex, questionId, questions, onQuestionChange);
    },
    [questions, onQuestionChange]
  );

  const setAddQuestionDisabledProp = useMemo(() => {
    return brand?.role === 'WeAre8'
      ? questions?.length > 9 || isDisabled
      : questions?.length > 4 || isDisabled;
  }, [brand?.role, isDisabled, questions?.length]);

  const handleAudiencesModalOpen = useCallback(
    (questionId) => () => {
      const targetedQuestion = questions.find((question) => question.updateId === questionId);

      setSelectedQuestion(targetedQuestion);

      dispatch(toggleModalByName({ name: 'customAudiences', value: true }));
    },
    [dispatch, questions, toggleModalByName]
  );

  useEffect(() => {
    const selectedQuestionId = selectedQuestion?.updateId;

    const targetedQuestion = questions?.find(
      (question) => question.updateId === selectedQuestionId
    );

    setSelectedQuestion(targetedQuestion);
  }, [questions, selectedQuestion]);

  return (
    <div id="questions" ref={questionsRef} className={s['questions']}>
      <FormGroup title={t('Questions')} required />
      <div className={s['questions__intro']}>
        <div className={s['questions__intro-header']}>
          <T
            _str="Every brand ad also enables you to ask your target consumer questions. We recommend between 1-3 questions, each with no more than 100 characters. For more on making questions, go {hereLink}."
            hereLink={
              <a
                className={s['questions__intro-header--link']}
                href="https://faq.weare8.com/advertising#question_bank"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {t('here')}
              </a>
            }
          />
        </div>
        <InfoCard>
          <div className={s['content']}>
            <T
              _str="These can be behavioral, lifestyle, product, or ad-related questions. Imagine you were in a conversation with your consumers. {hereText}:"
              hereText={<strong>{t('Here are a few examples to get you started')}</strong>}
            />
          </div>
          <ul className={s['list']}>
            <li className={s['list__item']}>
              {t('How did this video make you feel?')}
              <span className={s['list__item--accented']}>
                ({t('Viewer Sentiment')}:
                {sponsorshipConstants.viewerSentimentAnswers.map((option, index) => (
                  <span key={index} className={s['list__item-emoji']}>
                    {String.fromCodePoint(option)}
                  </span>
                ))}
                )
              </span>
            </li>
            <li className={s['list__item']}>
              {t('Have you purchased from (brand name) before?')}
              <span className={s['list__item--accented']}>( {t('Yes / No')} )</span>
            </li>
            <li className={s['list__item']}>
              {t('Which of the following statements best describes your level of interest in...?')}
              <span className={s['list__item--accented']}>( {t('Multiple Choice')} )</span>
            </li>
            <li className={s['list__item']}>
              {t('What do you like to do with your free time?')}
              <span className={s['list__item--accented']}>( {t('Select All That Apply')} )</span>
            </li>
            <li className={s['list__item']}>
              {t('What makes you happy?')}
              <span className={s['list__item--accented']}>( {t('Short Answer')} )</span>
            </li>
          </ul>
        </InfoCard>
      </div>
      <div className={s['questions__battery']}>
        {isDisabled && (
          <small>
            <i>Note: You cannot edit the questions of an active campaign.</i>
          </small>
        )}
        <DragDropContext
          onDragEnd={(props) => handleQuestionDragEnd(props, questions, onQuestionsChange)}
        >
          <Droppable droppableId={`droppable-questions`}>
            {(provided, snapshot) => (
              <div
                className={s['questions-area']}
                style={{
                  backgroundColor: snapshot.isDraggingOver ? '#ebebeb' : '#ffffff',
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {questions?.map((question, index) => (
                  <Draggable key={index} draggableId={`draggable-questions-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={s['questions-area__question']}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          boxShadow: snapshot.isDragging ? '0 0 .4rem #666' : 'none',
                          backgroundColor: '#ffffff',
                          padding: snapshot.isDragging ? '5px' : 'none',
                        }}
                      >
                        <Question
                          inputRef={inputRef}
                          key={question.id}
                          question={question}
                          control={control}
                          provided={provided}
                          index={index}
                          isReadOnly={isDisabled}
                          handleAudiencesModalOpen={handleAudiencesModalOpen}
                          handleQuestionType={handleQuestionType}
                          handleDeleteQuestion={handleDeleteQuestion}
                          handleAddOption={handleAddOption}
                          handleDeleteOption={handleDeleteOption}
                          handleOptionDragEnd={handleOptionDragEnd}
                          questionsTypes={sponsorshipConstants.questionsTypes}
                          errors={errors}
                          showQuestionDeleteButton={questions?.length > 1}
                          data-testid={constructTestId(SECTION, `question-${index}`)}
                          onQuestionChange={onQuestionChange}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Button
        styling="black"
        type="button"
        callback={handleAddQuestion}
        name={t('Add Question')}
        disabled={setAddQuestionDisabledProp}
        data-testid={constructTestId(SECTION, 'add-question')}
      />

      {modals.customAudiences.isOpen && (
        <CustomAudienceTagsModal
          modals={modals}
          selectedQuestion={selectedQuestion}
          questions={questions}
          update={onQuestionChange}
          localTags={localTags}
        />
      )}
    </div>
  );
};

export default Questions;
