import React from 'react';

import { TabButton } from '../../../common/TabButton';

import { routes } from '../../../../constants';

import s from './ReportingTabs.module.scss';
import { useT } from '@transifex/react';

const ReportingTabs = ({ onSelect, activeTab }) => {
  const t = useT();
  const tabs = [
    {
      key: routes.REPORTING_OVERVIEW,
      title: 'Overview',
    },
    {
      key: routes.REPORTING_DEMOGRAPHICS,
      title: 'Demographics',
    },
  ];

  return (
    <div className={s['tabs__container']}>
      {tabs.map(({ key, title }) => (
        <div key={key} className={s['tabs__tab']}>
          <TabButton
            title={t(title)}
            isActive={activeTab === title.toLowerCase()}
            handleSelectTab={() => onSelect(title)}
          />
        </div>
      ))}
    </div>
  );
};

export default ReportingTabs;
