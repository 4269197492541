import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import { useRouter } from '../../../hooks/useRouter';

import { SvgIcon } from '../../common/SvgIcon';

import classNames from 'classnames/bind';
import s from './MainMenu.module.scss';
import { useT } from '@transifex/react';

const cx = classNames.bind(s);

const MainMenu = ({ isOpen, items = [] }) => {
  const { pathname } = useRouter();
  const t = useT();
  const getLinkClassName = useCallback(
    (path) => {
      return cx({
        [s['main-menu__list-item-link']]: true,
        [s['main-menu__list-item-link--active']]: pathname === path,
      });
    },
    [pathname]
  );

  return (
    <div className={s['main-menu']}>
      <ul className={s['main-menu__list']}>
        {items.map((item) => (
          <li key={item.path} className={s['main-menu__list-item']}>
            <NavLink
              exact
              to={item.path}
              activeClassName={s['active-name']}
              className={getLinkClassName(item.path)}
            >
              <div className={s['main-menu__list-item-icon']}>
                <SvgIcon name={item.icon} />
              </div>
              {isOpen && <div className={s['main-menu__list-item-name']}>{t(item.name)}</div>}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MainMenu;
