const featureFlags = {
  'WA8-2359': true,
  'WA8-2058': true,
  'WA8-2515': false,
  'WA8-2516': false,
  'WA8-2602': false,
  'WA8-2779': false,
  'WA8-2168': false,
};

export default featureFlags;
