import { constructSubname } from '../../../utils/test-ids';

import { CREATE_CAMPAIGN } from '../pages';

export const CREATE_CAMPAIGN_PAGE = Object.freeze({
  AD_CALL_TO_ACTION: constructSubname(CREATE_CAMPAIGN, 'ad-call-to-action'),
  AD_NAME_AND_CREATIVE: constructSubname(CREATE_CAMPAIGN, 'ad-name-and-creative'),
  CAMPAIGN_AND_BRAND: constructSubname(CREATE_CAMPAIGN, 'campaing-and-brand'),
  NAME_AND_AUDIENCE: constructSubname(CREATE_CAMPAIGN, 'name-and-audience'),
  QUESTIONS: constructSubname(CREATE_CAMPAIGN, 'questions'),
  SUMMARY: constructSubname(CREATE_CAMPAIGN, 'summary'),
  TIMING_AND_BUDGET: constructSubname(CREATE_CAMPAIGN, 'timing-and-budget'),
});
