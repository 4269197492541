import { useEffect, useState } from 'react';
import { setAdData } from '../../store/campaign/sponsorship';

function useReportingData({ initialData, type, campaign, sponsorshipSet, sponsorship }) {
  const [data, setData] = useState({});

  useEffect(() => {
    switch (type) {
      case 'campaign':
        setData({
          title: campaign?.name,
          subtitle: 'Campaign Impact',
          typeName: 'Campaign',
        });
        break;
      case 'ad-set':
        setData({
          title: sponsorshipSet?.name,
          subtitle: 'Ad Set Impact',
          typeName: 'Ad Set',
          targeting: {},
        });
        break;
      case 'ad':
        setData({
          title: sponsorship?.name,
          subtitle: 'Ad Impact',
          typeName: 'Ad',
          creativeAndIncentive: {},
          responses: true,
        });
        break;
      default:
        setAdData({});
        break;
    }
  }, [campaign?.name, sponsorship?.name, sponsorshipSet?.name, type]);

  return data;
}

export default useReportingData;
