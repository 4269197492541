import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';
import querystring from 'query-string';

import { setAdminAccessToken } from './../auth';
import { setBrandInfo } from './../brand';

import { eightAuth } from '../eightauth';

const tokenService = eightAuth.tokenService;

function* lookForAdminAccessToken(action) {
  const search = querystring.parse(action.payload.location.search);

  if (search.countryCode && search.currencyCode) {
    yield put(
      setBrandInfo({
        countryCode: search.countryCode,
        currencyCode: search.currencyCode,
        timezoneId: decodeURI(search.timezoneId),
        brandIdFromAdmin: search.brandId,
      })
    );
  }

  if (search.aToken) {
    tokenService.setTokens({ customToken: search.aToken });
    yield put(setAdminAccessToken(search.aToken));
    yield put(replace({ ...action.payload.location, search: `type=${search.type}` }));
  }
}

export function* uiActionWatcher() {
  yield takeLatest(LOCATION_CHANGE, lookForAdminAccessToken);
}
