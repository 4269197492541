import * as yup from 'yup';
import url from 'valid-url';
import { t } from '@transifex/native';

export const newCharityValidationSchema = yup.object().shape({
  newCharityName: yup
    .string()
    .required(t('Name of Charity is a required field'))
    .min(3, t('Minimum length should be 3 chars')),
  newCharityWebsite: yup
    .string()
    .required(t('Charity Website link is a required field'))
    .test(
      'if-valid-link',
      t('Link should be valid and have http:// or https:// protocol'),
      (value) => {
        if (value) {
          return url.isWebUri(value);
        }

        return true;
      }
    ),
});
