import React, { useCallback, useMemo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useT } from '@transifex/react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ToastContainer } from 'react-toastify';

import { Stripe } from '../../../components/Stripe';
import { Modal } from '../../../components/Modal';
import { Layout } from '../../../components/Layout';
import { Spinner } from '../../../components/Spinner';
import { BillingContent } from '../../../components/PagesContent/SettingsContent/BillingContent';
import { AddEditCard } from '../../../components/ModalsContent/AddEditCard';
import { AddFunds } from '../../../components/ModalsContent/AddFunds';
import { BillingPaymentCards } from '../../../components/ModalsContent/BillingPaymentCards';
import { InvoiceRequest } from '../../../components/ModalsContent/InvoiceRequest';
import { ConfirmSuccess } from '../../../components/ModalsContent/ConfirmSuccess';
import { BillingReport } from '../../../components/PagesContent/SettingsContent/BillingContent/BillingReport';
import { BillingTransactionsHistory } from '../../../components/PagesContent/SettingsContent/BillingContent/BillingTransactionsHistory';
import { AddBrandInfo } from '../../../components/ModalsContent/AddBrandInfo';

import { routes } from '../../../constants';

import { useRouter } from '../../../hooks/useRouter';

import {
  modalsSelector,
  setModalDisplayMode,
  toggleModalByName,
  setShowModalFunds,
} from '../../../store/ui';

import {
  brandInfoSelector,
  resetDataToDefault,
  setBrandData,
  setStripeError,
  getPaymentMethodsAsync,
  removePaymentMethodAsync,
  makeMethodPrimaryAsync,
  chargePaymentMethodAsync,
  getBacsCheckoutSessionIdAsync,
  stripeChargeErrorSelector,
  setStripeChargeError,
  getBrandBillsAsync,
  brandBillsSelector,
  setDataArray,
  brandBillDetailsSelector,
  setBrandBillDetails,
  brandTransactionsSelector,
  getBrandTransactionsAsync,
  setDefaultBrandInfo,
  updateBrandInfoFromBillingAsync,
  vatAndFeeSelector,
  getVatAndFeeAsync,
  setDefaultVatAndFee,
  lastStripeChargeSelector,
  setInvoicePartnerRequestData,
  invoicePartnerRequestSelector,
  setDefaultInvoicePartnerRequestData,
  setInvoicePartnerRequestAsync,
  setDefaultTablesData,
  toggleSpinnerForThirdPartyLibs,
  getBrandsLastChargeAsync,
  brandIsFetchingSelector,
} from '../../../store/brand';

import { setStripePublicKey } from '../../../utils/settingsBilling';
import { numberToCurrency } from '../../../utils/budget';
import invoiceConstants from '../../../constants/invoice/invoice-data';

const SettingsBilling = () => {
  const t = useT();
  const { match, query, push } = useRouter();
  const stripeRef = useRef(null);
  const [invoiceData, setInvoiceData] = useState('');

  const modals = useSelector(modalsSelector);
  const brandInfo = useSelector(brandInfoSelector);
  const fetching = useSelector(brandIsFetchingSelector);
  const stripeChargeError = useSelector(stripeChargeErrorSelector);
  const brandBills = useSelector(brandBillsSelector);
  const brandBillDetails = useSelector(brandBillDetailsSelector);
  const brandTransactions = useSelector(brandTransactionsSelector);
  const vatAndFee = useSelector(vatAndFeeSelector);
  const lastStripeCharge = useSelector(lastStripeChargeSelector);
  const invoicePartnerData = useSelector(invoicePartnerRequestSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (brandInfo.countryCode && !stripeRef.current) {
      stripeRef.current = loadStripe(setStripePublicKey(brandInfo.countryCode));
    }
  }, [brandInfo.countryCode]);

  useEffect(() => {
    if (!brandInfo.paymentMethods.length) dispatch(getPaymentMethodsAsync.request());
    if (!lastStripeCharge.length) dispatch(getBrandsLastChargeAsync.request());
    if (!brandBillDetails.length) dispatch(getBrandBillsAsync.request({ offset: 0, limit: 10 }));
    if (!brandTransactions.length)
      dispatch(getBrandTransactionsAsync.request({ offset: 0, limit: 10 }));

    return () => dispatch(setDefaultTablesData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleBillingModalOpen = useCallback(() => {
    dispatch(toggleModalByName({ name: 'billing', value: true }));
  }, [dispatch]);

  const handleBillingModalClose = useCallback(() => {
    dispatch(toggleModalByName({ name: 'billing', value: false }));
    dispatch(setModalDisplayMode({ name: 'billing', value: true }));
    dispatch(resetDataToDefault({ name: 'selectedCard' }));
    modals.billing.isShowModalFunds &&
      dispatch(setShowModalFunds({ name: 'billing', value: false }));
    brandInfo.isInvoicePartner && setInvoiceData('');
  }, [dispatch, setInvoiceData, modals.billing.isShowModalFunds, brandInfo.isInvoicePartner]);

  const handleCancelEditCard = useCallback(() => {
    dispatch(resetDataToDefault({ name: 'selectedCard' }));
    dispatch(setModalDisplayMode({ name: 'billing', value: true }));
    dispatch(setStripeError({ name: 'cardNumber', value: null }));
    dispatch(setStripeError({ name: 'cardCvc', value: null }));
    dispatch(setStripeError({ name: 'cardExpiry', value: null }));
    dispatch(setStripeError({ name: 'general', value: null }));
  }, [dispatch]);

  const handleAddCard = useCallback(() => {
    dispatch(setModalDisplayMode({ name: 'billing', value: false }));
  }, [dispatch]);

  const modalTitle = useMemo(
    () => (modals.billing.displayMode ? t('Manage Credit Cards') : t('Add Credit Card')),
    [modals.billing.displayMode, t]
  );

  const handleRemoveCard = useCallback(
    (paymentMethodId) => {
      dispatch(removePaymentMethodAsync.request(paymentMethodId));
    },
    [dispatch]
  );

  const handleMakeMethodPrimary = useCallback(
    (paymentMethodId) => {
      dispatch(makeMethodPrimaryAsync.request(paymentMethodId));
    },
    [dispatch]
  );

  const handleAddFunds = useCallback(() => {
    if (!brandInfo.paymentMethods.length) {
      brandInfo.countryCode !== 'GB' &&
        dispatch(setModalDisplayMode({ name: 'billing', value: false }));
      dispatch(toggleModalByName({ name: 'billing', value: true }));
      dispatch(setShowModalFunds({ name: 'billing', value: true }));
    } else {
      dispatch(toggleModalByName({ name: 'funds', value: true }));
    }
  }, [dispatch, brandInfo.paymentMethods, brandInfo.countryCode]);

  const handleApproveFunds = useCallback(() => {
    dispatch(toggleModalByName({ name: 'funds', value: false }));
    dispatch(toggleModalByName({ name: 'default', value: true }));
    modals.billing.isShowModalFunds &&
      dispatch(setShowModalFunds({ name: 'billing', value: false }));
  }, [dispatch, modals.billing.isShowModalFunds]);

  const handleChangeFundsSum = useCallback(
    (e) => {
      dispatch(setStripeChargeError({}));
      dispatch(setBrandData({ name: 'funds', value: e.target.value }));
    },
    [dispatch]
  );

  const handleCloseModalFunds = useCallback(() => {
    dispatch(toggleModalByName({ name: 'funds', value: false }));
    dispatch(setBrandData({ name: 'funds', value: '' }));
    dispatch(setShowModalFunds({ name: 'billing', value: false }));
    brandInfo.isInvoicePartner && setInvoiceData('');
    dispatch(setDefaultVatAndFee());
  }, [dispatch, brandInfo.isInvoicePartner, setInvoiceData]);

  const handleAddBacsPayment = useCallback(
    () => dispatch(getBacsCheckoutSessionIdAsync.request()),
    [dispatch]
  );

  const requestInvoiceModalTitle = useMemo(
    () => (modals.requestInvoice.displayMode ? t('Request Invoice-based billing') : t('Success')),
    [modals.requestInvoice.displayMode, t]
  );

  const handleOpenRequestInvoiceModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'requestInvoice', value: true }));
  }, [dispatch]);

  const handleBillingReport = useCallback(
    (id) => () => {
      const index = brandBills.data.findIndex((el) => el.id === id);
      const brandBillDetails = brandBills.data[index].brandBillDetails;
      dispatch(setBrandBillDetails(brandBillDetails));
      push(`${routes.SETTINGS_BILLING}${routes.SETTINGS_BILLING_REPORT}`);
    },
    [dispatch, brandBills.data, push]
  );

  const handlePerformanceClick = useCallback(
    (e) => {
      const { id } = e.target;
      if (id) {
        push(`${routes.REPORTING}/${id}${routes.REPORTING_OVERVIEW}`);
      }
    },
    [push]
  );

  const handleSelectPaymentMethod = useCallback(
    (value) => {
      const { paymentMethodId } = value;
      const selectedMethod = brandInfo.paymentMethods.find(
        (item) => item.paymentMethodId === paymentMethodId
      );

      if (!selectedMethod.isDefault) {
        dispatch(makeMethodPrimaryAsync.request({ paymentMethodId }));
      } else {
        return;
      }
    },
    [dispatch, brandInfo.paymentMethods]
  );

  const handleCampaignActivationModalClose = useCallback(() => {
    dispatch(toggleModalByName({ name: 'campaignActivation', value: false }));
    dispatch(setDefaultBrandInfo());
  }, [dispatch]);

  const handleChangeBrandData = useCallback(
    (e) => {
      const { name, value } = e.target;

      dispatch(setBrandData({ name, value }));
    },
    [dispatch]
  );

  const handleApproveBrandInfo = useCallback(
    (form) => {
      dispatch(updateBrandInfoFromBillingAsync.request(form));
    },
    [dispatch]
  );

  const confirmFundsTitle = useMemo(() => {
    const amount = numberToCurrency(vatAndFee.total);
    return t(`You are about to make a payment of {amount}. Please confirm.`, {
      amount,
    });
  }, [vatAndFee.total, t]);

  const handleCloseConfirmFunds = useCallback(() => {
    dispatch(toggleModalByName({ name: 'default', value: false }));
    dispatch(setBrandData({ name: 'funds', value: '' }));
    brandInfo.isInvoicePartner && setInvoiceData('');
    dispatch(setDefaultVatAndFee());
  }, [dispatch, brandInfo.isInvoicePartner, setInvoiceData]);

  const handleConfirmFunds = useCallback(() => {
    dispatch(toggleModalByName({ name: 'default', value: false }));
    dispatch(
      chargePaymentMethodAsync.request({
        invoiceId: brandInfo.isInvoicePartner && invoiceData.invoiceId,
        returnTo: query.returnTo,
      })
    );
  }, [dispatch, brandInfo.isInvoicePartner, query.returnTo, invoiceData]);

  const handleApproveInvoice = useCallback(
    (id) => () => {
      const invoiceData = brandBills.data.find((item) => item.id === id);

      dispatch(setBrandData({ name: 'funds', value: String(invoiceData.billAmount) }));
      setInvoiceData(invoiceData);
      handleAddFunds();
    },
    [dispatch, handleAddFunds, brandBills.data, setInvoiceData]
  );

  const handleInvoicePartnerRequestData = useCallback(
    (e) => {
      const { name, value } = e.target;

      if (!/^\s+$/.test(value)) {
        //value contains not only whitespace
        dispatch(setInvoicePartnerRequestData({ name, value }));
      }
    },
    [dispatch]
  );

  const handleSubmitInvoicePartnerRequest = useCallback(
    (formData) => {
      dispatch(setInvoicePartnerRequestAsync.request({ formData }));
    },
    [dispatch]
  );

  const handleCloseRequestInvoiceModal = useCallback(() => {
    dispatch(toggleModalByName({ name: 'requestInvoice', value: false }));
    dispatch(setModalDisplayMode({ name: 'requestInvoice', value: true }));
    dispatch(setDefaultInvoicePartnerRequestData());
  }, [dispatch]);

  return (
    <Layout>
      {fetching && <Spinner />}
      <Elements stripe={stripeRef.current}>
        <Switch>
          <Route path={routes.STRIPE} component={Stripe} />
          <Route exact path={routes.SETTINGS_BILLING}>
            <BillingContent
              handleOpenRequestInvoiceModal={handleOpenRequestInvoiceModal}
              handleBillingModalOpen={handleBillingModalOpen}
              brandInfo={brandInfo}
              handleAddFunds={handleAddFunds}
              transactions={brandBills}
              getBrandBillsAsync={getBrandBillsAsync.request}
              handleBillingReport={handleBillingReport}
              handleApproveInvoice={handleApproveInvoice}
              lastStripeCharge={lastStripeCharge}
              hasTransactionHistory={brandTransactions.data.length}
            />
          </Route>
          <Route path={`${match.url}${routes.SETTINGS_BILLING_REPORT}`}>
            <BillingReport
              setDataArray={setDataArray}
              transactions={brandBillDetails}
              brandInfo={brandInfo}
              handlePerformanceClick={handlePerformanceClick}
              brandBills={brandBills}
            />
          </Route>
          <Route path={`${match.url}${routes.SETTINGS_BILLING_TRANSACTIONS_HISTORY}`}>
            <BillingTransactionsHistory
              brandInfo={brandInfo}
              transactions={brandTransactions}
              setDataArray={setDataArray}
              getBrandTransactionsAsync={getBrandTransactionsAsync.request}
              brandBills={brandBills}
            />
          </Route>
        </Switch>
        {modals.default.isOpen && (
          <Modal
            title={confirmFundsTitle}
            closeModal={handleCloseConfirmFunds}
            actionCallback={handleConfirmFunds}
          />
        )}
        {modals.campaignActivation.isOpen && (
          <Modal
            title={t(`Add Company address`)}
            closeModal={handleCampaignActivationModalClose}
            withSubmit
          >
            {t(
              ` WeAre8 is required by law to charge {taxType}. Please provide information below so we
            could display it on Invoices/Receipts and you could get {taxType} back.`,
              {
                taxType: invoiceConstants[brandInfo?.countryCode]?.taxType,
              }
            )}
            <AddBrandInfo
              handleApproveBrandInfo={handleApproveBrandInfo}
              brandInfo={brandInfo}
              handleChangeBrandData={handleChangeBrandData}
            />
          </Modal>
        )}
        {modals.billing.isOpen && (
          <Modal
            title={modalTitle}
            noButtons={modals.billing.displayMode}
            closeModal={handleBillingModalClose}
            cancelCallback={handleCancelEditCard}
            customButtonText={t('Add New Card')}
            styling="billing"
            withSubmit={!modals.billing.displayMode}
            withCloseIcon
          >
            {modals.billing.displayMode ? (
              <BillingPaymentCards
                makeMethodPrimary={handleMakeMethodPrimary}
                removePaymentCard={handleRemoveCard}
                paymentMethods={brandInfo.paymentMethods}
                handleAddCard={handleAddCard}
                handleAddBacsPayment={handleAddBacsPayment}
                bacsDirectDebitData={brandInfo.bacsDirectDebitData}
                countryCode={brandInfo.countryCode}
              />
            ) : (
              <AddEditCard
                customButtonText={t('Add New Card')}
                setModalDisplayMode={setModalDisplayMode}
                setStripeFormError={setStripeError}
                stripeErrors={brandInfo.stripeFormErrors}
                paymentMethods={brandInfo.paymentMethods}
                cardSetupIntentKey={brandInfo.cardSetupIntentKey}
                getPaymentMethodsAsync={getPaymentMethodsAsync.request}
                toggleModalByName={toggleModalByName}
                modals={modals}
                toggleSpinnerForThirdPartyLibs={toggleSpinnerForThirdPartyLibs}
              />
            )}
          </Modal>
        )}
        {modals.funds.isOpen && (
          <Modal closeModal={handleCloseModalFunds} withSubmit>
            <AddFunds
              handleApproveFunds={handleApproveFunds}
              brandInfo={brandInfo}
              handleChangeFundsSum={handleChangeFundsSum}
              stripeChargeError={stripeChargeError}
              setStripeChargeError={setStripeChargeError}
              handleSelectPaymentMethod={handleSelectPaymentMethod}
              vatAndFee={vatAndFee}
              getVatAndFee={getVatAndFeeAsync.request}
              invoiceData={invoiceData}
            />
          </Modal>
        )}
        {modals.requestInvoice.isOpen && (
          <Modal
            title={requestInvoiceModalTitle}
            closeModal={handleCloseRequestInvoiceModal}
            withoutCancel={!modals.requestInvoice.displayMode}
            withSubmit
          >
            {modals.requestInvoice.displayMode ? (
              <InvoiceRequest
                handleInvoicePartnerRequestData={handleInvoicePartnerRequestData}
                invoicePartnerData={invoicePartnerData}
                handleSubmitInvoicePartnerRequest={handleSubmitInvoicePartnerRequest}
              />
            ) : (
              <ConfirmSuccess
                text={t('Your request was sent. Our team will reach out within 2-4 business days.')}
                confirmCallback={handleCloseRequestInvoiceModal}
              />
            )}
          </Modal>
        )}
      </Elements>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick
        draggable={false}
        limit={1}
        style={{ zIndex: '10000' }}
      />
    </Layout>
  );
};

export default SettingsBilling;
