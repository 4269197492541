import { useMemo } from 'react';

function useDirtyFields({ storedDirtyFields, formDirtyFields, fieldNames, isDirty }) {
  // Returns a boolean that indicates if we should enable save/submit form button
  const shouldSave = useMemo(() => {
    let hasDirtyFields = false;

    if (storedDirtyFields) hasDirtyFields = Boolean(Object.keys(storedDirtyFields)?.length > 0);

    if (formDirtyFields && !hasDirtyFields)
      hasDirtyFields = Boolean(Object.keys(formDirtyFields)?.length > 0);

    return hasDirtyFields || isDirty;
  }, [formDirtyFields, storedDirtyFields, isDirty]);

  // Returns a list of <li> elements with the labels of all dirtyFields
  const dirtyFieldsList = useMemo(() => {
    let dirtyFields = storedDirtyFields;

    if (dirtyFields && Object.keys(dirtyFields).length > 0)
      return Object.keys(dirtyFields).map((key, index) => {
        const field = fieldNames.find((field) => {
          return field.value === key && storedDirtyFields[key];
        });
        return field?.label ? <li key={index}>{field.label}</li> : <li key={index}>{key}</li>;
      });
    else return '';
  }, [fieldNames, storedDirtyFields]);

  return { shouldSave, dirtyFieldsList };
}

export default useDirtyFields;
