import React, { useCallback, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useT } from '@transifex/react';

import { SquareButton } from '../common/QuestionsButtons/SquareButton';
import { DeleteButton } from '../common/QuestionsButtons/DeleteButton';

import { TextArea } from '../FormElements/TextArea';
import { CustomSelect } from '../FormElements/Selects/CustomSelect';
import { TextInput } from '../FormElements/TextInput';

import classNames from 'classnames/bind';

import s from './Question.module.scss';
import { constructTestId } from '../../utils/test-ids';
import { useWatch } from 'react-hook-form';
import { cloneDeep } from 'lodash';

const cx = classNames.bind(s);

const Question = ({
  provided,
  control,
  errors,
  index,
  isReadOnly,
  question,
  questionsTypes,
  handleAudiencesModalOpen,
  handleQuestionType,
  handleDeleteQuestion,
  handleDeleteOption,
  handleOptionDragEnd,
  handleAddOption,
  showQuestionDeleteButton,
  'data-testid': SECTION,
  onQuestionChange,
  inputRef,
}) => {
  const t = useT();

  const watchQuestionText = useWatch({ control, name: `questions.${index}.text` });
  const watchQuestionType = useWatch({ control, name: `questions.${index}.type` });
  const watchQuestionOptions = useWatch({ control, name: `questions.${index}.responsesOptions` });

  const responsesOptionsError = useCallback(
    (idx) => {
      if (errors?.questions && errors?.questions[index])
        if (
          errors.questions[index].responsesOptions &&
          errors.questions[index].responsesOptions[idx]
        )
          return errors.questions[index].responsesOptions[idx].text;
    },
    [errors, index]
  );

  const questionTitleClassNames = useMemo(
    () =>
      cx({
        [s['section-title__block-drag']]: true,
        [s['section-title__block-drag--disabled']]: isReadOnly,
      }),
    [isReadOnly]
  );

  const audiencesLinkClassNames = useMemo(
    () =>
      cx({
        [s['section-subtitle__audience-link']]: true,
        [s['section-subtitle__audience-link--disabled']]: isReadOnly,
      }),
    [isReadOnly]
  );

  return (
    <div className={s['section']}>
      <div className={s['section-title']}>
        <div {...provided.dragHandleProps} className={s['section-title__block']}>
          <div className={questionTitleClassNames} />
          <div className={s['section-title__block-text']}>{`${t(question.name)} ${index + 1}`}</div>
        </div>
      </div>
      <div className={s['section-subtitle']}>
        <div className={s['section-subtitle__warning']}>
          {t("Don't forget to set up your custom audiences to utilize first party data.")}
        </div>
        {question?.type?.name !== 'shortanswer' ? (
          <div
            className={audiencesLinkClassNames}
            onClick={!isReadOnly ? handleAudiencesModalOpen(question.updateId) : null}
          >
            {question?.tagsCount > 0
              ? t('Edit custom audiences ({tagsCount})', {
                  tagsCount: question.tagsCount,
                })
              : t('Add audience tags for retargeting')}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className={s['input-area']}>
        <TextArea
          inputRef={inputRef}
          label={t('ENTER YOUR QUESTION')}
          id={question.id}
          name={`questions.${index}.text`}
          defaultValue={question.text}
          disabled={isReadOnly}
          error={errors.questions && errors.questions[index]?.text}
          watchValue={watchQuestionText}
          onChange={(e) => {
            const value = e.target.value;
            let updatedQuestion = { ...question, text: value };
            onQuestionChange(updatedQuestion);
          }}
          styling="no-border"
          data-testid={constructTestId(SECTION, 'name')}
        />
        <div className={s['select-area']}>
          <CustomSelect
            name={`questions.${index}.type`}
            watchValue={watchQuestionType}
            defaultValue={question.type}
            options={questionsTypes}
            handleSelect={handleQuestionType(question.updateId)}
            styleType="no-border"
            disabled={isReadOnly}
            inputId={constructTestId(SECTION, 'type')}
          />
        </div>
      </div>
      <DragDropContext onDragEnd={handleOptionDragEnd(question.updateId)}>
        <Droppable droppableId={`droppable-options-${question.id}`}>
          {(provided, snapshot) => (
            <div
              className={s['options-area']}
              style={{
                backgroundColor: snapshot.isDraggingOver ? '#ebebeb' : '#ffffff',
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {question?.options?.map((option, idx, array) => (
                <Draggable key={idx} draggableId={`draggable-${idx}`} index={idx}>
                  {(provided, snapshot) => (
                    <div
                      className={s['options-area__option']}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        boxShadow: snapshot.isDragging ? '0 0 .4rem #666' : 'none',
                      }}
                    >
                      <TextInput
                        type="text"
                        id={option?.id}
                        name={`questions.${index}.responsesOptions.${idx}.text`}
                        formValue={watchQuestionOptions[idx]?.text}
                        watchValue={watchQuestionOptions[idx]?.text}
                        onChange={(e) => {
                          const value = e.target.value;
                          let updatedQuestion = cloneDeep(question);
                          updatedQuestion.responsesOptions[idx].text = value;
                          updatedQuestion.options[idx].text = value;

                          onQuestionChange(updatedQuestion);
                        }}
                        label={t('ENTER A RESPONSE')}
                        draggable
                        disabled={isReadOnly}
                        error={responsesOptionsError(idx)}
                        data-testid={constructTestId(SECTION, `multiple-response-${option?.id}`)}
                      />
                      <div className={s['options-area__option-buttons']}>
                        <SquareButton
                          styling="plus"
                          callback={() => {
                            handleAddOption(question.updateId);
                          }}
                          disabled={isReadOnly || array.length > 9}
                          data-testid={constructTestId(SECTION, 'multiple-add')}
                        />
                        {question.options.length > 1 && (
                          <SquareButton
                            callback={() =>
                              handleDeleteOption(question.updateId, idx, onQuestionChange)
                            }
                            disabled={isReadOnly}
                            data-testid={constructTestId(SECTION, 'multiple-minus')}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div className={s['section-buttons']}>
        {showQuestionDeleteButton && (
          <DeleteButton
            id={question.id}
            callback={handleDeleteQuestion}
            disabled={isReadOnly}
            data-testid={constructTestId(SECTION, 'delete')}
          />
        )}
      </div>
    </div>
  );
};

export default Question;
