import { nanoid } from 'nanoid';

const brandConstants = Object.freeze({
  profileInfoTypes: {
    BRAND_TYPE: 'brandType',
    BILLING_EMAIL: 'billingEmail',
    LEGAL_NAME: 'legalName',
    ADDRESS: 'address',
  },
  profileUrlFragments: {
    BRAND_TYPE: 'type',
    BILLING_EMAIL: 'billing-email',
    LEGAL_NAME: 'legal-name',
    ADDRESS: 'address',
  },
  paymentMethods: [
    {
      name: 'Credit Card',
      values: 'creditCard',
      label: 'Credit Card',
    },
    {
      name: 'Bank account',
      values: 'bankAccount',
      label: 'Bank account',
    },
  ],
  defaultCard: {
    internalId: nanoid(),
    type: '',
    isPrimary: false,
    cardNumber: '',
    lastFourDigits: '',
    expiration: '',
    cvc: '',
    nameOnCard: '',
  },
  cardTypes: [
    {
      value: 'visa',
      name: 'visa',
      icon: 'visa-radio',
    },
    {
      value: 'mastercard',
      name: 'mastercard',
      icon: 'mastercard-radio',
    },
    {
      value: 'american-express',
      name: 'american-express',
      icon: 'american-express-radio',
    },
  ],
  brandVideoLength: {
    minimumVideoLength: 5,
    maximumVideoLength: 120,
  },
  weAre8VideoLength: {
    minimumVideoLength: 5,
    maximumVideoLength: 180,
  },
  VATReceiptFileSpecs: [
    {
      name: 'File Type',
      values: '.pdf .jpg, .png',
      icon: 'file-type',
    },
  ],
});

export default brandConstants;
