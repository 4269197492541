import React, { useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { Burger } from './Burger';

import { sidebar } from '../../../constants';

import classNames from 'classnames/bind';
import s from './GeneralSidebar.module.scss';

const cx = classNames.bind(s);

const AnimatedContent = ({
  burgerPosition,
  contentWidth,
  toggleSidebar,
  modalIsOpen,
  isOpen,
  children,
}) => {
  const easeTransition = { type: 'linear' };

  const sideBarContentClassNames = useMemo(
    () =>
      cx({
        [s['general-sidebar__content']]: true,
        [s['general-sidebar__content--active']]: modalIsOpen,
      }),
    [modalIsOpen]
  );
  return (
    <motion.div
      initial={{ width: sidebar.CONTENT_WIDTH_OPEN }}
      animate={{ width: contentWidth }}
      transition={easeTransition}
      style={{ height: '100vh' }}
    >
      <div className={sideBarContentClassNames}>{children}</div>
      <div className={s['general-sidebar__burger']} onClick={toggleSidebar}>
        <Burger iconName={isOpen ? 'burgerLeft' : 'burgerRight'} />
      </div>
    </motion.div>
  );
};

const GeneralSidebar = ({ modalIsOpen, children, isOpen, toggleSidebar }) => {
  const contentWidth = isOpen ? sidebar.CONTENT_WIDTH_OPEN : sidebar.CONTENT_WIDTH;
  // const burgerPosition = isOpen ? sidebar.BURGER_OFFSET_OPEN : sidebar.BURGER_OFFSET;

  return (
    <div className={s['general-sidebar']}>
      <AnimatePresence>
        <AnimatedContent
          modalIsOpen={modalIsOpen}
          contentHeight={'100%'}
          contentWidth={contentWidth}
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
        >
          {children}
        </AnimatedContent>
      </AnimatePresence>
    </div>
  );
};

export default GeneralSidebar;
