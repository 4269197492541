import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import s from './Button.module.scss';
import { Link } from 'react-router-dom';

const cx = classNames.bind(s);

const Button = ({
  disabled,
  type,
  callback,
  name,
  styling,
  customText,
  fullwidth,
  customClass,
  icon,
  'data-testid': testId,
  as,
  to,
}) => {
  const buttonClassNames = useMemo(
    () =>
      cx(customClass, {
        [s['btn']]: true,
        [s['btn__disabled']]: disabled,
        [s['btn__primary']]: styling === 'primary',
        [s['btn__secondary']]: styling === 'secondary',
        [s['btn__black']]: styling === 'black',
        [s['btn__gray']]: styling === 'gray',
        [s['btn__filter']]: styling === 'filter',
        [s['btn__filter-disabled']]: styling === 'filter' && disabled,
        [s['fullwidth']]: fullwidth,
        [s['btn__link']]: as,
      }),
    [styling, fullwidth, disabled, customClass, as]
  );

  return (
    <>
      {!as && (
        <button
          className={buttonClassNames}
          onClick={callback}
          type={type}
          disabled={disabled}
          data-testid={testId}
        >
          {icon}
          {customText ? customText : name}
        </button>
      )}
      {as === 'Link' && (
        <Link className={buttonClassNames} to={to}>
          {customText}
        </Link>
      )}
      {as === 'anchor' && (
        <a className={buttonClassNames} href={to}>
          {customText}
        </a>
      )}
    </>
  );
};

export default Button;
