import React from 'react';

import s from './Layout.module.scss';

const Layout = ({ children }) => (
  <div className={s['layout']}>
    <div className={s['layout-inner']}>{children}</div>
  </div>
);

export default Layout;
