import React, { useMemo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { OverviewFilters } from '../ReportingFilters/OverviewFiltes';
import { ReportingOverviewContent } from './ReportingOverviewContent';

import { Bar } from '../../../Charts/Bar';
import { Cloud } from '../../../Charts/Cloud';
import { Donut } from '../../../Charts/Donut';
import { List } from '../../../Charts/List';

import { setOverviewFilterOption } from '../../../../store/analytics';

import { numberToCurrency } from '../../../../utils/budget';

import s from './ReportingOverview.module.scss';

const ReportingOverview = ({
  acceptanceOverTime,
  brandInfo,
  demographics,
  filteringDatesRange,
  filterOptions,
  influencersByGender,
  initialFetchedData,
  overviewFilterRequest,
  responses,
  shortAnswerResponses,
  sponsorship,
  sponsorshipSet,
  totals,
  handleDownloadCSV,
  noReportingData,
  heatMap,
  setOverviewNode,
  setResponsesNode,
  data,
  campaign,
  type,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const { dateFrom, dateTo } = filteringDatesRange;

    dispatch(
      setOverviewFilterOption({
        name: 'initialDateFrom',
        value: dateFrom,
      })
    );

    dispatch(
      setOverviewFilterOption({
        name: 'initialDateTo',
        value: dateTo,
      })
    );
  }, [filteringDatesRange, dispatch]);

  const getCPCV = useMemo(
    () => numberToCurrency(totals?.costPerEngagement.total, false, false, 4),

    [totals?.costPerEngagement.total]
  );

  const getSpent = useMemo(() => {
    const { total, totalDenominator } = totals?.spent || {};

    if (total && totalDenominator) {
      return `${numberToCurrency(total, false, false, 6)} / ${numberToCurrency(
        totalDenominator,
        true,
        true
      )}`;
    }
  }, [totals?.spent]);

  const getChartForQuestion = useCallback(
    (question) => {
      let Chart = null;
      switch (question?.metadata?.questionKind) {
        case 'YesNo':
          Chart = Donut;
          break;
        case 'ViewerSentiment':
          Chart = Bar;
          break;
        case 'MultipleChoice':
          Chart = List;
          break;
        case 'SelectAllThatApply':
          Chart = List;
          break;
        case 'ShortAnswer':
          Chart = Cloud;
          break;
        default:
          return null;
      }

      if (Chart) {
        return (
          <Chart
            question
            series={question}
            isCapital={Chart === List}
            detailQuestions={Chart === List && sponsorship?.detail}
          />
        );
      }
    },
    [sponsorship?.detail]
  );

  const country = useMemo(
    () => brandInfo?.countryCode || brandInfo?.country?.countryCode,
    [brandInfo?.country?.countryCode, brandInfo?.countryCode]
  );

  const clickThroughPercentage = useMemo(
    () => ((totals?.click_through?.total / totals?.completed?.total) * 100).toFixed(),
    [totals?.click_through?.total, totals?.completed?.total]
  );

  const combinedAndOrderedResponses = useMemo(() => {
    if (responses && shortAnswerResponses) {
      const combinedResponses = [...responses, ...shortAnswerResponses];
      // const combinedResponses = [];

      const questionsOrder = Object.values(sponsorship?.detail || {})
        ?.flat()
        ?.sort((a, b) => a.questionOrder - b.questionOrder)
        ?.map((el) => el.id);

      return combinedResponses.sort(
        (a, b) =>
          questionsOrder.indexOf(a.metadata.questionId) -
          questionsOrder.indexOf(b.metadata.questionId)
      );
    }
    return [];
  }, [responses, shortAnswerResponses, sponsorship?.detail]);

  const conversionEventNames = useMemo(() => {
    if (type === 'campaign') {
      const adSets = campaign?.sponsorshipsSets;
      if (adSets) {
        const conversionEventsArray = [
          ...new Set(
            adSets
              .filter(
                (adSet) =>
                  adSet.pixelConversionEvent === 'purchase' ||
                  adSet.pixelConversionEvent === 'subscription'
              )
              .map((adSet) => adSet.pixelConversionEvent)
          ),
        ];
        return conversionEventsArray ? conversionEventsArray.join(', ') : '--';
      }
      return '--';
    } else return sponsorshipSet?.pixelConversionEvent;
  }, [campaign?.sponsorshipsSets, sponsorshipSet?.pixelConversionEvent, type]);

  return (
    <>
      <OverviewFilters
        filterOptions={filterOptions}
        initialFetchedData={initialFetchedData}
        overviewFilterRequest={overviewFilterRequest}
        handleDownloadCSV={handleDownloadCSV}
        countryCode={brandInfo.countryCode}
        type={type}
        filteringDatesRange={filteringDatesRange}
        brandIdFromAdmin={brandInfo.brandIdFromAdmin}
      />
      {noReportingData ? (
        <div className={s['reporting-overview__no-data']}>
          Couldn't find anything for your request. Please change filters and try again.
        </div>
      ) : (
        <ReportingOverviewContent
          sponsorshipSet={sponsorshipSet || {}}
          influencersByGender={influencersByGender}
          acceptanceOverTime={acceptanceOverTime}
          brandInfo={brandInfo}
          totals={totals}
          handleDownloadCSV={handleDownloadCSV}
          clickThroughPercentage={clickThroughPercentage}
          getSpent={getSpent}
          country={country}
          combinedAndOrderedResponses={combinedAndOrderedResponses}
          getChartForQuestion={getChartForQuestion}
          heatMap={heatMap}
          setOverviewNode={setOverviewNode}
          setResponsesNode={setResponsesNode}
          getCPCV={getCPCV}
          data={data}
          responses={responses}
          conversionEventNames={conversionEventNames}
        />
      )}
    </>
  );
};

export default ReportingOverview;
