import React from 'react';
import { SvgIcon } from '../SvgIcon';

import { useT } from '@transifex/react';

import s from './PasswordHints.module.scss';

const PasswordHints = ({ requirements }) => {
  const t = useT();

  return (
    <div className={s['hints-battery']}>
      {Object.keys(requirements).map((index, pos) => (
        <div className={s['hints-battery__hint']} key={pos}>
          <div className={s['bullet']}>
            {requirements[index].fulfilled ? (
              <SvgIcon name="status-ok" />
            ) : (
              <SvgIcon name="status-warning" />
            )}
          </div>
          <div className={s['text']}>{t('{label}', { label: t(requirements[index].label) })}</div>
        </div>
      ))}
    </div>
  );
};

export default PasswordHints;
