import React from 'react';
import classNames from 'classnames/bind';

import { SvgIcon } from '../../../../common/SvgIcon';

import s from './ReportingOverviewDetail.module.scss';

const cx = classNames.bind(s);

const ReportingOverviewDetail = ({ icon, text, title, isLongText }) => (
  <div className={s['detail__container']}>
    <div className={s['detail__icon']}>
      <SvgIcon name={icon} />
    </div>
    <div
      className={cx(s['detail__content'], {
        [s['detail__content-line-clamp']]: isLongText,
      })}
    >
      <strong>{title}: </strong>
      {text ? text : 'N/A'}
    </div>
  </div>
);

export default ReportingOverviewDetail;
