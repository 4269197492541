import { createReducer } from 'eight.js.store-common';

import {
  clearModalContent,
  setModalDisplayMode,
  setModalContent,
  setModalQuestionsContent,
  setModalText,
  setModalTitle,
  setShowModalFunds,
  setStateStatus,
  toggleModalByName,
  toggleSavingToast,
  toggleSideBar,
  setStickyMenuItem,
  toggleUploadingToast,
} from './actions';
import { t } from '@transifex/native';

const initialState = {
  sideBarOpen: true,
  modals: {
    videoLengthNotification: {
      isOpen: false,
    },
    audienceTagsWarning: {
      isOpen: false,
    },
    businessSettingsWarning: {
      isOpen: false,
    },
    unitOpeningWarning: {
      isOpen: false,
    },
    success: {
      isOpen: false,
    },
    stripe: {
      isOpen: false,
    },
    campaignActivation: {
      isOpen: false,
      displayMode: true,
    },
    summary: {
      isOpen: false,
      modalContent: {
        title: 'Warning',
        text: '',
        details: '',
      },
    },
    default: {
      isOpen: false,
      modalContent: {
        title: '',
        text: '',
      },
    },
    charity: {
      isOpen: false,
      modalContent: {
        title: '',
        text: '',
      },
    },
    budgetAllocation: {
      isOpen: false,
      modalContent: {
        title: '',
        text: '',
      },
    },
    notification: {
      isOpen: false,
      modalContent: {
        title: '',
        text: '',
      },
    },
    questions: {
      id: '',
      isOpen: false,
      items: [],
    },
    errors: {
      isOpen: false,
      modalContent: {
        title: 'Error',
        text: '',
      },
    },
    customAudiences: {
      isOpen: false,
      modalContent: {
        title: t('Custom audience tags'),
        text: '',
      },
    },

    billing: {
      isOpen: false,
      displayMode: true,
      modalContent: {
        title: 'Manage Credit Cards',
      },
      isShowModalFunds: false,
    },

    requestInvoice: {
      isOpen: false,
      displayMode: true,
      modalContent: {
        title: '',
      },
    },

    funds: {
      isOpen: false,
    },

    crop: {
      isOpen: false,
    },
  },
  savingToast: {
    isOpen: false,
  },
  uploadingToast: {
    isOpen: false,
  },
  stickyMenuItem: null,
};

export const uiReducer = createReducer('@@ui', initialState, {
  [setStickyMenuItem]: ({ state, action }) => {
    state.stickyMenuItem = action.payload;
  },
  [setShowModalFunds]: ({ state, action }) => {
    const { name, value } = action.payload;

    state.modals = {
      ...state.modals,
      [name]: {
        ...state.modals[name],
        isShowModalFunds: value,
      },
    };
  },
  [clearModalContent]: ({ state, action }) => {
    const { name } = action.payload;
    state.modals = {
      ...state.modals,
      [name]: {
        ...state.modals[name],
        modalContent: {
          title: '',
          text: '',
        },
      },
    };
  },

  [setModalContent]: ({ state, action }) => {
    const { name, content } = action.payload;
    state.modals = {
      ...state.modals,
      [name]: {
        ...state.modals[name],
        modalContent: content,
      },
    };
  },

  [setModalText]: ({ state, action }) => {
    const { name, text, details } = action.payload;
    state.modals = {
      ...state.modals,
      [name]: {
        ...state.modals[name],
        modalContent: {
          ...state.modals[name].modalContent,
          text,
          details,
        },
      },
    };
  },

  [setModalTitle]: ({ state, action }) => {
    const { name, title } = action.payload;
    state.modals = {
      ...state.modals,
      [name]: {
        ...state.modals[name],
        modalContent: {
          ...state.modals[name].modalContent,
          title,
        },
      },
    };
  },

  [setModalDisplayMode]: ({ state, action }) => {
    const { name, value } = action.payload;

    state.modals = {
      ...state.modals,
      [name]: {
        ...state.modals[name],
        displayMode: value,
      },
    };
  },

  [setModalQuestionsContent]: ({ state, action }) => {
    const { content } = action.payload;
    state.modals.questions.items = content;
  },

  [setStateStatus]: ({ state, action }) => {
    const { name, value } = action.payload;

    state.stateIsEqual = {
      ...state.stateIsEqual,
      [name]: value,
    };
  },

  [toggleSideBar]: ({ state }) => {
    state.sideBarOpen = !state.sideBarOpen;
  },

  [toggleModalByName]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.modals = {
      ...state.modals,
      [name]: {
        ...state.modals[name],
        isOpen: value,
      },
    };
  },

  [toggleSavingToast]: ({ state, action }) => {
    state.savingToast.isOpen = action.payload;
  },

  [toggleUploadingToast]: ({ state, action }) => {
    state.uploadingToast.isOpen = action.payload;
  },
});
