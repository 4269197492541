import * as yup from 'yup';
import { t } from '@transifex/native';

import { EMAIL_REGEXP, LETTERS_REGEXP } from '../../../../utils/validate';

const oneMB = 1048576;
// const fourMB = 4194304;

const validateOptionTextFieldSchema = yup.lazy((value) => {
  if (value?.length > 0) {
    return yup
      .string()
      .min(2, t('Minimum length should be 2 chars'))
      .max(50, t('Maximum length should be 50 chars'));
  }
  return yup.string().notRequired();
});

const baseShema = {
  brandName: yup
    .string()
    .min(3, t('Minimum length should be 3 chars'))
    .required(t('Brand name is a required field')),
  emailAddress: yup
    .string()
    .matches(EMAIL_REGEXP, t('Email must be in e-mail format'))
    .required(t('Email is a required field')),
  website: yup
    .string()
    .min(3, t('Website is a required field'))
    .required(t('Website is a required field')),
  billingEmail: yup.lazy((value) => {
    if (value?.length > 0) {
      return yup.string().matches(EMAIL_REGEXP, t('Email must be in e-mail format'));
    }
    return yup.string().notRequired();
  }),
  brandType: yup.string().min(1, t('Brand type is required')),
  logoUri: yup.string().when('logoFile', (logoFile, schema) => {
    if (!logoFile?.size) return schema;
    return schema.test(
      'is-more-1MB',
      t('Cropped file should not be more than 1MB'),
      () => logoFile.size < oneMB
    );
  }),
};

export const validationSchemaWithBrandAddress = yup.object().shape({
  ...baseShema,
  addressLineFirst: validateOptionTextFieldSchema,
  addressLineSecond: validateOptionTextFieldSchema,
  city: validateOptionTextFieldSchema,
  postCode: validateOptionTextFieldSchema,
  brandCountryCode: yup.lazy((value) => {
    if (value?.length > 0) {
      return yup
        .string()
        .matches(LETTERS_REGEXP, t('Country code should only contain letters'))
        .min(2, t('Minimum length should be 2 chars'))
        .max(50, t('Maximum length should be 50 chars'));
    }
    return yup.string().notRequired();
  }),
});
