import { t } from '@transifex/native';
import { routes } from '../constants';

const settingsMenu = Object.freeze({
  items: [
    {
      name: t('Account Info'),
      path: routes.SETTINGS_ACCOUNT_INFO,
    },
    // {
    //   name: 'Password',
    //   path: routes.SETTINGS_PASSWORD,
    // },
    {
      name: t('Billing'),
      path: routes.SETTINGS_BILLING,
    },
    {
      name: '8Pixel',
      path: routes.SETTINGS_PIXEL,
    },
    {
      name: `8Pixel ${t('Setup')}`,
      path: routes.SETTINGS_PIXEL_SETUP,
    },
    {
      name: t('Brand Onboarding'),
      path: routes.SETTINGS_BRAND_ONBOARDING,
    },
    // {
    //   name: 'Notifications',
    //   path: routes.SETTINGS_NOTIFICATIONS,
    // },
    // {
    //   name: 'Contact Support',
    //   path: routes.SETTINGS_CONTACT_SUPPORT,
    // },
  ],
});

export default settingsMenu;
