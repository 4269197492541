import React from 'react';

import { Controller } from 'react-hook-form';

import AsyncSelect from 'react-select';

import selectStyles from '../selectStyles';
import s from './CustomAsyncSelect.module.scss';
import { useT } from '@transifex/react';

const CustomAsyncSelect = ({
  inputId,
  defaultValue,
  error,
  control,
  isLoading,
  isMulti,
  options,
  placeholder,
  name,
  handleChange,
  onInputChange,
  watchValue,
  components,
  innerRef,
  closeMenuOnSelect,
}) => {
  const t = useT();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange } }) => {
        return (
          <div className={s['select']}>
            <AsyncSelect
              inputId={inputId}
              closeMenuOnSelect={closeMenuOnSelect}
              name={name}
              options={options}
              styles={selectStyles(error)}
              placeholder={placeholder}
              isMulti={isMulti}
              isLoading={isLoading}
              onInputChange={onInputChange}
              onChange={(value, event) => {
                onChange && onChange(value, event);
                handleChange && handleChange(value, event);
              }}
              innerRef={innerRef}
              value={watchValue ? watchValue : ''}
              components={components}
            />
            {error && <p className={s['error']}>{t(error.message)}</p>}
          </div>
        );
      }}
    />
  );
};

export default CustomAsyncSelect;
