import _ from 'lodash';
import React from 'react';

import s from './Radio.module.scss';

const Radio = ({
  onChange,
  name,
  value,
  label,
  checked,
  shape,
  testId,
  disabled,
  register = {},
}) => {
  return (
    <>
      <label htmlFor={value} className={s['radio-label']}>
        {label}
        {!_.isEmpty(register) ? (
          <input
            className={s['radio-input']}
            onChange={onChange}
            type="radio"
            id={value}
            name={name}
            value={value}
            checked={checked === value}
            disabled={disabled}
            {...register(name)}
          />
        ) : (
          <input
            className={s['radio-input']}
            onChange={onChange}
            type="radio"
            id={value}
            name={name}
            value={value}
            disabled={disabled}
            checked={checked === value}
          />
        )}
        <span className={s['checkmark']} />
      </label>
    </>
  );
};

export default Radio;
