import React, { useEffect } from 'react';

import { ReportingDemographicsContent } from './ReportingDemographicsContent';
import { DemographicFilters } from '../ReportingFilters/DemographicFilters';

import s from './ReportingDemographics.module.scss';

import { setDemographicsFilterOption } from '../../../../store/analytics';
import { useDispatch } from 'react-redux';

const ReportingDemographics = ({
  acceptanceOptions,
  brandInfo,
  demographicsFilterRequest,
  demographics,
  filteringDatesRange,
  filterOptions,
  initialFetchedData,
  influencersByGender,
  questions,
  questionsResponses,
  handleDownloadCSV,
  noReportingData,
  heatMap,
  type,
  setDemographicsNode,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const { dateFrom, dateTo } = filteringDatesRange;

    dispatch(
      setDemographicsFilterOption({
        name: 'initialDemographicsDateFrom',
        value: dateFrom,
      })
    );
    dispatch(
      setDemographicsFilterOption({
        name: 'initialDemographicsDateTo',
        value: dateTo,
      })
    );
    // setDemographicsFilterOption({
    //   name: 'demographicsDateFrom',
    //   value: dateFrom,
    // });
    // setDemographicsFilterOption({
    //   name: 'demographicsDateTo',
    //   value: dateTo,
    // });
  }, [dispatch, filteringDatesRange]);

  return (
    <>
      <DemographicFilters
        acceptanceOptions={acceptanceOptions}
        demographicsFilterRequest={demographicsFilterRequest}
        filterOptions={filterOptions}
        initialFetchedData={initialFetchedData}
        questions={questions}
        questionsResponses={questionsResponses}
        setDemographicsFilterOption={setDemographicsFilterOption}
        handleDownloadCSV={handleDownloadCSV}
        countryCode={brandInfo.countryCode}
        type={type}
        filteringDatesRange={filteringDatesRange}
        brandIdFromAdmin={brandInfo.brandIdFromAdmin}
      />
      {noReportingData ? (
        <div className={s['demographics-overview__no-data']}>
          Couldn't find anything for your request. Please change filters and try again.
        </div>
      ) : (
        <ReportingDemographicsContent
          demographics={demographics}
          brandInfo={brandInfo}
          influencersByGender={influencersByGender}
          heatMap={heatMap}
          setDemographicsNode={setDemographicsNode}
        />
      )}
    </>
  );
};

export default ReportingDemographics;
