import config from '../../../config';

export class SponsorshipSetService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  activateSponsorshipSet = async ({ campaignId, sponsorshipSetId: adSetId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/adset/publish/${campaignId}/adset/${adSetId}/manually-activate`,
      method: 'POST',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };

  deactivateSponsorshipSet = async ({ campaignId, sponsorshipSetId: adSetId }) => {
    const res = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v1/adset/publish/${campaignId}/adset/${adSetId}/manually-deactivate`,
      method: 'POST',
    });

    if (res.status !== 200 && res.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = res.status || 500;
      throw error;
    }

    return res.data;
  };

  updateSponsorshipSet = async ({
    campaignId,
    sponsorshipSetId,
    name,
    ageRanges,
    genders,
    isEightPixelEnabled,
    pixelConversionEvent,
    includeLocations,
    excludeLocations,
    interests,
    passions,
    eightPixelAudiences,
    includeTags,
    excludeTags,
    scheduleFrom,
    scheduleTo,
    charityInfo,
    timezoneId,
    timezoneInfo,
    charityDonation,
    netCashReward,
    weAre8Fee,
    pricePerMessage,
    minVideoLength,
    maxVideoLength,
    budgetTotal,
    samiPricingId,
    deviceType,
    frequencyCap,
  }) => {
    const additionalOtions = isEightPixelEnabled && { pixelConversionEvent };

    const response = await this.fetchService.fetch({
      url: `${config.campaignsAPI}/v6/watchandrespond/${campaignId}/sponsorshipset/`,
      method: 'POST',
      bodyType: 'json',
      body: {
        sponsorshipSetId,
        name,
        ageRanges,
        genders,
        isEightPixelEnabled,
        includeLocations,
        excludeLocations,
        interests,
        passions,
        eightPixelAudiences,
        includeTags,
        excludeTags,
        scheduleFrom,
        scheduleTo,
        timezoneId,
        timezoneInfo,
        charityInfo,
        charityDonation,
        netCashReward,
        weAre8Fee,
        pricePerMessage,
        minVideoLength,
        maxVideoLength,
        budgetTotal,
        samiPricingId,
        deviceType,
        frequencyCap,
        ...additionalOtions,
      },
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    return response.data;
  };
}
