import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';

import RobotoBlack from '../../assets/fonts/Roboto-Black.ttf';
import RobotoRegular from '../../assets/fonts/Roboto-Regular.ttf';
import Logo from '../../assets/images/logo-invoice.png';
import invoiceConstants from '../../constants/invoice/invoice-data';
import { amountToCurrency, numberToFixedDecimals } from '../../utils/budget';
import { setDocumentTitle, setPaymentName, setToWhoDocument } from '../../utils/settingsBilling';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoBlack, fontWeight: 600 },
    { src: RobotoRegular, fontWeight: 400 },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: '20pt 20pt 25pt 20pt',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12pt',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  logoArea: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20pt',
  },
  imageWrapper: {
    width: '80pt',
    height: '80pt',
    marginRight: '20pt',
  },
  logo: {
    display: 'block',
    width: 80,
    height: 80,
  },
  brandDetails: {
    marginTop: '10pt',
  },
  brandName: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
    maxWidth: 150,
  },
  brandEmail: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 10,
  },
  brandDefault: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 10,
  },
  invoiceHeader: {
    width: '55%',
  },
  invoiceHeaderRow: {
    marginBottom: 12,
  },
  rowTitle: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: 5,
    textAlign: 'right',
  },
  rowContent: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 400,
    textAlign: 'right',
  },
  body: {
    width: '100%',
  },
  tableHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10pt 0pt',
    borderTop: '2pt solid #000',
    borderBottom: '2pt solid #000',
  },
  tableHeaderDescription: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  tableHeaderTransactionID: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 600,
  },
  tableHeaderMethod: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 600,
  },
  tableHeaderContent: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  tableHeaderItem: {
    width: '33%',
    textAlign: 'right',
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 600,
  },
  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10pt 0pt',
    borderBottom: '1pt dashed #808080',
  },
  tableRowDescription: {
    width: '40%',
  },
  descriptionTitle: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 5,
  },
  descriptionDates: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 400,
  },
  rowDescriptionContent: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowReceiptContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: '1pt dashed #808080',
  },
  rowReceiptItemMethod: {
    marginLeft: 'auto',
  },
  rowReceiptItemRate: {
    width: '50%',
    textAlign: 'right',
    marginLeft: 'auto',
  },

  rowItem: {
    width: '33%',
    textAlign: 'right',
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 400,
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerInner: {
    width: '45%',
    paddingBottom: '3pt',
    borderBottom: '1pt solid #808080',
    marginLeft: 'auto',
  },
  footerRow: {
    width: '100%',
    borderBottom: '1pt solid #808080',
    padding: '10pt 0pt',
  },
  footerRowSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5pt 0pt',
  },
  footerRowSectionTitle: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  footerRowSectionAmount: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  balanceAmount: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  brandAddressView: {
    display: 'flex',
    flexDirection: 'column',
    width: '130px',
  },
  brandAddressTitle: {
    fontWeight: 'bold',
  },
  brandAddressRow: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 5,
    wordWrap: 'break-word',
  },
  contractorView: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  paidInvoice: {
    color: 'red',
    width: '50%',
    paddingTop: '15pt',
    fontFamily: 'Roboto',
  },
  paidInvoiceTextRow: {
    marginTop: 3,
    marginBottom: 3,
  },
  paidInvoiceTextTitle: {
    fontWeight: 600,
  },
  paymentDetailsView: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
    fontSize: 10,
    marginTop: '30pt',
  },
  paymentDetailsViewInner: {
    marginTop: '20pt',
  },
  endPageFooter: {
    bottom: 20,
    left: '20pt',
    position: 'absolute',
    fontSize: 8,
  },
});

const Invoice = ({ data, brandInfo, isReceipt, isInvoicePartner }) => {
  const { brandEmail, brandName, taxReferenceNumber, entrance, address, city, postCode } =
    invoiceConstants[brandInfo.countryCode];

  let totalPaymentAmount = 0;
  if (data?.billAmount & data?.vatAmount & data?.stripeFee)
    totalPaymentAmount = +(data?.billAmount + data?.vatAmount + data?.stripeFee);
  const totalAmount = parseFloat(data?.billAmount) + parseFloat(data?.vatAmount);
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.logoArea}>
              <View style={styles.imageWrapper}>
                <Image source={Logo} style={styles.logo} />
              </View>
              <View style={styles.brandDetails}>
                <Text style={styles.brandName}>{brandName}</Text>
                <View style={styles.brandAddressView}>
                  <Text
                    style={{
                      ...styles.brandAddressRow,
                      ...styles.brandAddressTitle,
                    }}
                  >
                    Address:
                  </Text>
                  {entrance && <Text style={styles.brandAddressRow}>{entrance}</Text>}
                  {address && <Text style={styles.brandAddressRow}>{address}</Text>}
                  {city && <Text style={styles.brandAddressRow}>{city}</Text>}
                  {postCode && <Text style={styles.brandAddressRow}>{postCode}</Text>}
                </View>
                <Text style={styles.brandDefault}>{taxReferenceNumber}</Text>
                <Text style={styles.brandEmail}>{brandEmail}</Text>
                <View style={styles.contractorView}>
                  <Text style={styles.brandName}>
                    {setToWhoDocument(isReceipt, isInvoicePartner)} {brandInfo.legalName}
                  </Text>
                  <View style={styles.brandAddressView}>
                    <Text style={styles.brandAddressRow}>{brandInfo.addressLineFirst}</Text>
                    <Text style={styles.brandAddressRow}>{brandInfo.addressLineSecond}</Text>
                    <Text style={styles.brandAddressRow}>{brandInfo.city}</Text>
                    <Text style={styles.brandAddressRow}>{brandInfo.postcode}</Text>
                  </View>
                  <Text style={styles.brandEmail}>{brandInfo.billingEmail}</Text>
                </View>
              </View>
            </View>
            <View style={styles.invoiceHeader}>
              {!isInvoicePartner && isReceipt && (
                <View style={styles.invoiceHeaderRow}>
                  <Text style={styles.rowContent}>
                    {`This is not a ${invoiceConstants[brandInfo.countryCode]?.taxType} invoice`}
                  </Text>
                </View>
              )}
              <View style={styles.invoiceHeaderRow}>
                <Text style={styles.rowTitle}>{setDocumentTitle(isReceipt, isInvoicePartner)}</Text>
                <Text style={styles.rowContent}>
                  {isReceipt ? `${data?.transactionId}` : `${data?.invoiceId}`}
                </Text>
              </View>
              <View style={styles.invoiceHeaderRow}>
                <Text style={styles.rowTitle}>Date</Text>
                <Text style={styles.rowContent}>{isReceipt ? data?.date : data?.createdDate}</Text>
              </View>
              {!isReceipt && isInvoicePartner && (
                <View style={styles.invoiceHeaderRow}>
                  <Text style={styles.rowTitle}>Due Date</Text>
                  <Text style={styles.rowContent}>{data?.dueDate || ''}</Text>
                </View>
              )}
              {!isReceipt && isInvoicePartner && (
                <View style={styles.invoiceHeaderRow}>
                  <Text style={styles.rowTitle}>Balance Due</Text>
                  <Text style={styles.rowContent}>
                    {`${brandInfo?.currencyCode} ${amountToCurrency(+totalAmount)}` || ''}
                  </Text>
                </View>
              )}
            </View>
          </View>
          <View style={styles.body}>
            <View style={styles.tableHeader}>
              {!isReceipt ? (
                <Text style={styles.tableHeaderDescription}>Description</Text>
              ) : (
                <Text style={styles.tableHeaderTransactionID}>TransactionID</Text>
              )}
              {isReceipt && <Text style={styles.tableHeaderMethod}>Payment method</Text>}
              <View style={styles.tableHeaderContent}>
                {!isReceipt && <Text style={styles.tableHeaderItem}>Rate</Text>}
                {!isReceipt && <Text style={styles.tableHeaderItem}>Quantity</Text>}
                <Text style={styles.tableHeaderItem}>Amount</Text>
              </View>
            </View>
            {!isReceipt &&
              data?.brandBillDetails.length > 0 &&
              data?.brandBillDetails?.map((transaction, index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.tableRowDescription}>
                    <Text style={styles.descriptionTitle}>{transaction.campaignName}</Text>
                    <Text style={styles.descriptionDates}>
                      {`${transaction.fromDate} - ${transaction.toDate}`}
                    </Text>
                  </View>
                  <View style={styles.rowDescriptionContent}>
                    <Text style={styles.rowItem}>
                      {amountToCurrency(parseFloat(transaction.cpcv))}
                    </Text>
                    <Text style={styles.rowItem}>{transaction.quantity}</Text>
                    <Text style={styles.rowItem}>
                      {amountToCurrency(parseFloat(transaction.totalCampaignCostAmount))}
                    </Text>
                  </View>
                </View>
              ))}
            {isReceipt && (
              <View style={styles.rowReceiptContent}>
                <Text style={styles.rowReceiptItem}>{data?.transactionId}</Text>
                <Text style={styles.rowReceiptItemMethod}>
                  {setPaymentName(data?.paymentMethod)}
                </Text>
                <Text style={styles.rowReceiptItemRate}>
                  {amountToCurrency(parseFloat(data?.subTotal))}
                </Text>
              </View>
            )}
          </View>
          <View style={styles.footer}>
            {((data?.billStatus === 'paid' && isInvoicePartner) || !isInvoicePartner) && (
              <View style={styles.paidInvoice}>
                <Text style={styles.paidInvoiceTextTitle}>PAID IN FULL</Text>
                {isInvoicePartner && (
                  <Text style={styles.paidInvoiceTextRow}>
                    {`Payment Date: ${data?.paymentDate || ''}`}
                  </Text>
                )}
                <Text style={styles.paidInvoiceTextRow}>
                  {`Payment method: ${setPaymentName(data?.paymentMethod) || ''} `}
                </Text>
                <Text style={styles.paidInvoiceTextRow}>
                  {`Payment Processing Fee: ${amountToCurrency(parseFloat(data?.stripeFe) || 0)}`}
                </Text>
                <Text style={styles.paidInvoiceTextRow}>
                  {`Total Payment amount: ${
                    isInvoicePartner
                      ? amountToCurrency(parseFloat(totalPaymentAmount))
                      : amountToCurrency(
                          parseFloat(data?.billAmount) || numberToFixedDecimals(data?.vatAmount, 2)
                        )
                  }`}
                </Text>
                {isInvoicePartner && (
                  <Text style={styles.paidInvoiceTextRow}>
                    Transaction id: {data?.paymentTransactionId || ''}
                  </Text>
                )}
              </View>
            )}
            {isInvoicePartner && (
              <View style={styles.footerInner}>
                <View style={styles.footerRow}>
                  <View style={styles.footerRowSection}>
                    <Text style={styles.footerRowSectionTitle}>Subtotal</Text>
                    <Text style={styles.footerRowSectionAmount}>
                      {amountToCurrency(parseFloat(data?.billAmount))}
                    </Text>
                  </View>
                  {invoiceConstants[brandInfo.countryCode]?.taxType && (
                    <View style={styles.footerRowSection}>
                      <Text style={styles.footerRowSectionTitle}>
                        {invoiceConstants[brandInfo.countryCode]?.taxType} (
                        {invoiceConstants[brandInfo.countryCode]?.taxPercentage})
                      </Text>
                      <Text style={styles.footerRowSectionAmount}>
                        {amountToCurrency(parseFloat(data?.vatAmount))}
                      </Text>
                    </View>
                  )}
                </View>
                <View style={styles.footerRow}>
                  <View style={styles.footerRowSection}>
                    <Text style={styles.footerRowSectionTitle}>Total</Text>
                    <Text style={styles.footerRowSectionAmount}>
                      {!isReceipt && amountToCurrency(totalAmount)}
                      {isReceipt && amountToCurrency(parseFloat(data?.total))}
                    </Text>
                  </View>
                </View>
                <View style={styles.footerRow}>
                  <View style={styles.footerRowSection}>
                    <Text style={styles.footerRowSectionTitle}>
                      {!isReceipt ? 'Balance due' : 'Payment amount'}
                    </Text>
                    <Text style={styles.balanceAmount}>
                      {!isReceipt && `${brandInfo?.currencyCode} ${amountToCurrency(+totalAmount)}`}
                      {isReceipt &&
                        `${brandInfo?.currencyCode} ${amountToCurrency(parseFloat(data?.total))}`}
                    </Text>
                  </View>
                </View>
              </View>
            )}
            {isReceipt && !isInvoicePartner && (
              <View style={styles.footerInner}>
                <View style={styles.footerRow}>
                  <View style={styles.footerRowSection}>
                    <Text style={styles.footerRowSectionTitle}>Subtotal</Text>
                    <Text style={styles.footerRowSectionAmount}>
                      {amountToCurrency(parseFloat(data?.subTotal))}
                    </Text>
                  </View>
                  {invoiceConstants[brandInfo.countryCode]?.taxType && (
                    <>
                      <View style={styles.footerRowSection}>
                        <Text style={styles.footerRowSectionTitle}>
                          {invoiceConstants[brandInfo.countryCode]?.taxType} (
                          {invoiceConstants[brandInfo.countryCode]?.taxPercentage})
                        </Text>
                        <Text style={styles.footerRowSectionAmount}>
                          {amountToCurrency(parseFloat(data?.vatAmount))}
                        </Text>
                      </View>
                      <View style={styles.footerRowSection}>
                        <Text style={styles.footerRowSectionTitle}>CC processing fee</Text>
                        <Text style={styles.footerRowSectionAmount}>
                          {amountToCurrency(parseFloat(data?.stripeFee))}
                        </Text>
                      </View>
                    </>
                  )}
                </View>
                <View style={styles.footerRow}>
                  <View style={styles.footerRowSection}>
                    <Text style={styles.footerRowSectionTitle}>Total</Text>
                    <Text style={styles.footerRowSectionAmount}>
                      {amountToCurrency(parseFloat(data?.total))}
                    </Text>
                  </View>
                </View>
                <View style={styles.footerRow}>
                  <View style={styles.footerRowSection}>
                    <Text style={styles.footerRowSectionTitle}>Payment amount</Text>
                    <Text style={styles.balanceAmount}>
                      {`${brandInfo?.currencyCode} ${amountToCurrency(parseFloat(data?.total))}`}
                    </Text>
                  </View>
                </View>
              </View>
            )}
            {!isReceipt && !isInvoicePartner && (
              <View style={styles.footerInner}>
                <View style={styles.footerRow}>
                  <View style={styles.footerRowSection}>
                    <Text style={styles.footerRowSectionTitle}>Subtotal</Text>
                    <Text style={styles.footerRowSectionAmount}>
                      {amountToCurrency(+parseFloat(data?.billAmount))}
                    </Text>
                  </View>
                  <View style={styles.footerRowSection}>
                    <Text style={styles.footerRowSectionTitle}>
                      {invoiceConstants[brandInfo.countryCode]?.taxType} (
                      {invoiceConstants[brandInfo.countryCode]?.taxPercentage})
                    </Text>
                    <Text style={styles.footerRowSectionAmount}>
                      {amountToCurrency(parseFloat(data?.vatAmount))}
                    </Text>
                  </View>
                </View>
                <View style={styles.footerRow}>
                  <View style={styles.footerRowSection}>
                    <Text style={styles.footerRowSectionTitle}>Total:</Text>
                    <Text style={styles.balanceAmount}>
                      {`${brandInfo?.currencyCode} ${amountToCurrency(totalAmount)}`}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>
          {isInvoicePartner && (
            <>
              <View style={styles.paymentDetailsView}>
                <Text>lease arrange payment using the following details: </Text>
                <View style={styles.paymentDetailsViewInner}>
                  <Text>Account Name -{brandName}</Text>
                  <Text>{invoiceConstants[brandInfo.countryCode]?.sortCode}</Text>
                  <Text>{invoiceConstants[brandInfo.countryCode]?.accountNumber}</Text>
                </View>
              </View>
              <Text style={styles.endPageFooter}>
                {invoiceConstants[brandInfo.countryCode]?.footer}
              </Text>
            </>
          )}
        </Page>
      </Document>
    </>
  );
};

export default Invoice;
