import { addDays, addMinutes, differenceInSeconds } from 'date-fns';
import { sponsorshipSetConstants } from '../../constants';

import { store } from '../../store';

import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const SECONDS_IN_A_DAY = 86400;

// Get timezone of current (edit,create) sponsorshipSet
const getTimezoneOffset = () => store?.getState()?.brand.brandInfo.brandTimezone?.offset;

export const addLocaleOffset = (date) => {
  if (!(date instanceof Date)) date = new Date(date);
  const offset = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() + offset);
};

export const addTimezoneOffset = (date, timezoneOffset) => {
  if (!(date instanceof Date)) date = new Date(date);
  let offset = 0;

  if (timezoneOffset) offset = timezoneOffset * 3600000;
  else if (getTimezoneOffset()) offset = getTimezoneOffset() * 3600000;

  return new Date(date.getTime() + offset);
};

export const removeLocaleOffset = (date) => {
  if (!(date instanceof Date)) date = new Date(date);
  const offset = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() - offset);
};

export const removeTimezoneOffset = (date, timezoneOffset) => {
  if (!(date instanceof Date)) date = new Date(date);
  let offset = 0;

  if (timezoneOffset) offset = timezoneOffset * 3600000;
  else if (getTimezoneOffset()) offset = getTimezoneOffset() * 3600000;

  return new Date(date.getTime() - offset);
};

export const addTimezoneAndLocaleOffset = (date, timezoneOffset) =>
  addTimezoneOffset(addLocaleOffset(date), timezoneOffset);

export const formatTimezonedDateToUTC = (date, timezoneUtcString) => {
  return date && zonedTimeToUtc(new Date(date).toISOString(), timezoneUtcString).toISOString();
};

export const formatToBrandsTimezone = (date, timezoneUtcString) => {
  return date ? utcToZonedTime(date, timezoneUtcString) : '';
};

export const removeTimezoneAndLocaleOffset = (date, timezoneOffset) =>
  removeTimezoneOffset(removeLocaleOffset(date), timezoneOffset);

export const calculateCampaignDuration = (startDate, endDate) =>
  endDate ? differenceInSeconds(new Date(endDate), new Date(startDate)) / SECONDS_IN_A_DAY : 0;

export const defaultStartDate = (withLocaleOffset) =>
  withLocaleOffset
    ? addTimezoneAndLocaleOffset(addMinutes(new Date(), 30))
    : addMinutes(new Date(), 30);

export const defaultEndDate = (withLocaleOffset, timezoneOffset) =>
  withLocaleOffset
    ? addTimezoneAndLocaleOffset(
        addMinutes(addDays(new Date(), sponsorshipSetConstants.minimumCampaignDurationInDays), 30),
        timezoneOffset
      )
    : addMinutes(addDays(new Date(), sponsorshipSetConstants.minimumCampaignDurationInDays), 30);

export const getTimezoneUTCText = (offset) => {
  let timeString = '00:00';
  let symbol = '';

  if (offset) {
    if (offset >= 0) symbol = '+';

    let hours = Math.floor(offset).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: false,
    });
    if (offset % 1 === 0) {
      timeString = `${symbol}${hours}:00`;
    } else if (offset % 0.5 === 0) {
      timeString = `${symbol}${hours}:30`;
    } else if (offset % 0.25 === 0) {
      timeString = `${symbol}${hours}:45`;
    }

    return `(UTC${timeString})`;
  } else return '';
};
