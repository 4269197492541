import config from '../../config';
import { campaignConstants, sponsorshipConstants, sponsorshipSetConstants } from '../../constants';
import { formatToBrandsTimezone } from '../../utils/date/dateHelpers';
import {
  processAge,
  processCharity,
  processDifferences,
  processGender,
  processInterests,
  processLocations,
  processOs,
  processPassions,
  processQuestions,
  processReceivedText,
  processTags,
  processVodStatus,
} from '../../utils/receivedData';

export class CampaignDataService {
  constructor(fetchService) {
    this.fetchService = fetchService;
  }

  getCompleteCampaign = async ({ campaignId, utcString }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/v3/campaigns/campaign/${campaignId}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }

    const {
      id,
      name,
      brandName,
      brandLogoUri,
      category,
      deliveryStatus,
      moderationStatus,
      isActive,
      isArchive,
      isReadOnly,
      charityId,
      charityName,
      charityLogoUri,
      sponsorshipsSets,
    } = response.data;

    const responseAdSetsData = sponsorshipsSets;

    let formattedAdSetsData = [sponsorshipSetConstants.initialState];
    if (responseAdSetsData)
      formattedAdSetsData = responseAdSetsData.map((resAdSet) => {
        const {
          deliveryStatus,
          moderationStatus,
          id,
          isActive,
          isReadOnly,
          isUniteIncentive,
          name,
          interests,
          passions,
          eightPixelAudiences,
          includeAnyTags,
          includeLocations,
          deviceType,
          totalBudget,
          dailyBudget,
          timezoneInfo,
          ageRanges,
          genders,
          scheduleFrom,
          scheduleTo,
          excludeTags,
          isEightPixelEnabled,
          pixelConversionEvent,
          charityId,
          charityName,
          charityLogoUri,
          sponsorships,
          pricePerMessage,
          minVideoLength,
          maxVideoLength,
          weAre8Fee,
          donationReward,
          cashReward,
          samiPricingId,
          videoDurationAllowedRange,
          frequencyCap,
        } = resAdSet;

        const resAds = sponsorships;

        let formattedAds = [sponsorshipConstants.initialState];
        if (resAds)
          formattedAds = resAds.map((ad) => {
            const {
              deliveryStatus,
              moderationStatus,
              id,
              isActive,
              isReadOnly,
              name,
              videoHlsUri,
              coverTileUri,
              detail,
              cta,
              thirdPartyTrackingScripts,
              smsMessage,
              playImpressionUrl,
              videoCompletionUrl,
              videoKey,
              vodStatus,
              videoLength,
              videoWidth,
              videoHeight,
            } = ad;
            return {
              id,
              isActive,
              isReadOnly,
              isDraft: moderationStatus === 'draft',
              name,
              deliveryStatus: deliveryStatus ? processReceivedText(deliveryStatus) : 'inactive',
              moderationStatus: moderationStatus
                ? processReceivedText(moderationStatus)
                : 'Unknown',
              adCover: coverTileUri || '',
              adVideo: videoHlsUri || '',
              questions: detail
                ? processQuestions(detail, brandName)
                : sponsorshipConstants.defaultQuestions,
              receivedQuestions: !!detail,
              smsMessage: smsMessage || '',
              impressionsUrl: playImpressionUrl || '',
              completionUrl: videoCompletionUrl || '',
              ctaCover: cta?.imageUrl || '',
              ctaLink: cta?.webLink || '',
              ctaButtonText: cta?.caption || '',
              ctaTrackingScript: cta?.adScript || '',
              ctaDiscount: cta?.discountCode || '',
              trackingUrl: cta?.clickTrackingUrl || '',
              videoUploadStatus: vodStatus ? processVodStatus(vodStatus) : '',
              videoKey: videoKey || '',
              videoLength: videoLength || '',
              videoWidth: videoWidth || '',
              videoHeight: videoHeight || '',
              isCreated: true,
              thirdPartyTrackingScripts:
                cta?.adScript && thirdPartyTrackingScripts
                  ? [
                      { provider: 'doubleClick', script: cta?.adScript },
                      ...thirdPartyTrackingScripts,
                    ]
                  : !cta?.adScript
                  ? thirdPartyTrackingScripts
                  : [{ provider: 'doubleClick', script: cta?.adScript }],
            };
          });

        return {
          id,
          isActive,
          isReadOnly,
          isDraft: moderationStatus === 'draft',
          isEightPixelEnabled,
          pixelConversionEvent,
          name,
          deliveryStatus: deliveryStatus ? processReceivedText(deliveryStatus) : 'inactive',
          moderationStatus: moderationStatus ? processReceivedText(moderationStatus) : 'unknown',
          isUnite: isUniteIncentive ? isUniteIncentive : false,
          age: ageRanges ? processAge(ageRanges) : sponsorshipSetConstants.defaultAgeData,
          gender: genders ? processGender(genders) : sponsorshipSetConstants.defaultGenderData,
          deviceType: deviceType ? processOs(deviceType) : sponsorshipSetConstants.defaultOsData,
          budget: totalBudget || '',
          dailyBudget: dailyBudget || '',
          startDate: scheduleFrom ? formatToBrandsTimezone(scheduleFrom, utcString) : '',
          endDate: scheduleTo ? formatToBrandsTimezone(scheduleTo, utcString) : '',
          interests: interests ? processInterests(interests) : [],
          passions: passions ? processPassions(passions) : [],
          frequencyCap: frequencyCap || '',
          locations: includeLocations ? processLocations(includeLocations) : [],
          eightPixelAudiences: eightPixelAudiences ?? [],
          locationsToRemove: includeLocations ? processLocations(includeLocations) : [],
          includeTags: includeAnyTags ? processTags(includeAnyTags) : [],
          excludeTags: excludeTags ? processTags(excludeTags) : [],
          timezoneInfo,
          pricePerMessage: '',
          incentive: {
            minVideoLength,
            maxVideoLength,
            pricePerMessage,
            weAre8Fee,
            charityDonation: donationReward,
            netCashReward: cashReward,
          },
          videoDurationAllowedRange: videoDurationAllowedRange[0],
          isCreated: true,
          selectedCharity: charityId
            ? processCharity(charityId, charityName, charityLogoUri)
            : { value: '', label: '' },
          charities: charityId ? [processCharity(charityId, charityName, charityLogoUri)] : [],
          samiPricingId,
          ads: formattedAds,
        };
      });

    return {
      id,
      name,
      campaignBrandName: brandName || '',
      campaignBrandLogoUri: brandLogoUri || '',
      category: category || campaignConstants.objectives[0].value,
      isActive,
      isArchive,
      isReadOnly,
      isDraft: moderationStatus === 'draft',
      deliveryStatus: deliveryStatus ? processReceivedText(deliveryStatus) : 'inactive',
      moderationStatus: moderationStatus ? processReceivedText(moderationStatus) : 'unknown',
      selectedCharity: charityId ? processCharity(charityId, charityName, charityLogoUri) : '',
      charities: charityId ? [processCharity(charityId, charityName, charityLogoUri)] : [],
      isCreated: true,
      adSets: formattedAdSetsData,
    };
  };

  getDifferences = async ({ campaignId }) => {
    const response = await this.fetchService.fetch({
      method: 'GET',
      url: `${config.campaignsAPI}/campaigns/${campaignId}/unpublished-differences`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }
    const data = response.data;

    return processDifferences(data);
  };

  deleteAdSet = async ({ campaignId, adSetId }) => {
    const response = await this.fetchService.fetch({
      method: 'DELETE',
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/sponsorshipset/${adSetId}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }
  };

  deleteAd = async ({ campaignId, adSetId, adId }) => {
    const response = await this.fetchService.fetch({
      method: 'DELETE',
      url: `${config.campaignsAPI}/watchandrespond/${campaignId}/sponsorshipset/${adSetId}/sponsorship/${adId}`,
    });

    if (response.status !== 200 && response.status !== 201) {
      const error = new Error('Unable to get acceptance over time chart.');
      error.statusCode = response.status || 500;
      throw error;
    }
  };
}
