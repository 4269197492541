const billingConstants = Object.freeze({
  itemsPerPage: [
    { name: '10 results', label: '10 results per page', value: 10 },
    { name: '25 results', label: '25 results per page', value: 25 },
    { name: '50 results', label: '50 results per page', value: 50 },
  ],
  monthlySpendTableHeaders: [
    { title: 'Month' },
    { title: 'Detailed report' },
    { title: 'Spend' },
    { title: 'Invoice #' },
  ],
  invoicePartnerMonthlySpendTableHeaders: [
    { title: 'Month' },
    { title: 'Detailed report' },
    { title: 'Spend' },
    { title: 'Status' },
    { title: 'Due Date' },
    { title: 'Invoice #' },
    { title: 'Pay' },
  ],
  invoicePartnerTransactionsHistoryTableHeaders: [
    { title: 'Date' },
    { title: 'Amount' },
    { title: 'Transaction #' },
    { title: 'Invoice #' },
    { title: 'Method' },
    { title: 'Status' },
    { title: 'Receipt' },
  ],
  transactionsHistory: {
    noData: 'There are no Data for Transactions History yet',
    tableHeaders: [
      { title: 'Date' },
      { title: 'Amount' },
      { title: 'Transaction #' },
      { title: 'Method' },
      { title: 'Status' },
      { title: 'Receipt' },
    ],
  },
  billingReport: {
    noData: 'There are no Data for Billing Report yet',
    tableHeaders: [
      { title: 'Campaign ID' },
      { title: 'Name' },
      { title: 'Report' },
      { title: 'Run dates' },
      { title: '# Participants' },
      { title: 'Spend' },
    ],
  },
});

export default billingConstants;
