const URIs = Object.freeze({
  production:
    'https://d3twz8usqacga9.cloudfront.net/c05fe66f-0645-45b5-842d-175592b0a00e/hls/congratulations-from-rio.m3u8',
  uat: 'https://d3twz8usqacga9.cloudfront.net/c05fe66f-0645-45b5-842d-175592b0a00e/hls/congratulations-from-rio.m3u8',
  development:
    'https://d1is7f7whmbwui.cloudfront.net/93b6b29a-4067-44a6-a453-4198f6d44af0/hls/congratulations-from-rio.m3u8',
  test: 'https://d1is7f7whmbwui.cloudfront.net/93b6b29a-4067-44a6-a453-4198f6d44af0/hls/congratulations-from-rio.m3u8',
});

export default URIs;
