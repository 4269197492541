import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRouter } from '../../hooks/useRouter';
import { findEnvironment } from '../../utils/findEnvironment';
function PixelLanding() {
  const environmentString = findEnvironment();

  const pixel = `!(function (e, t, i, n, s, c, a) {
    e[n] ||
      (((s = e[n] =
        function () {
          s.process ? s.process.apply(s, arguments) : s.queue.push(arguments);
        }).queue = []),
      (s.t = +new Date()),
      ((c = t.createElement(i)).async = 1),
      (c.src =
        "https://pixel-${environmentString}.test.aws.the8app.com/eightpixel.min.js?t=" +
        864e5 * Math.ceil(new Date() / 864e5)),
      (a = t.getElementsByTagName(i)[0]).parentNode.insertBefore(c, a));
  })(window, document, "script", "eight"),
    eight("init", "37dfb27d-34b7-4478-a118-6fbdfde9b0d6"),
    eight("event", "pageView");`;
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = pixel;
    document.body.appendChild(script);
  }, [pixel]);
  const { query } = useRouter();
  return (
    <>
      <div>PixelLanding</div>
      {query.conversionEvent === 'purchase' && <Link to="/pixel-purchase">Buy Now</Link>}
      {query.conversionEvent === 'subscription' && (
        <Link to="/pixel-subscription">Subscribe now</Link>
      )}
    </>
  );
}

export default PixelLanding;
