import React from 'react';

import { useT } from '@transifex/react';

import { SvgIcon } from '../SvgIcon';

import s from './FileSpecifications.module.scss';

const FileSpecifications = ({ fileSpecs }) => {
  const t = useT();

  return (
    <div className={s['specs']}>
      <div className={s['specs-title']}>{t('File specifications')}</div>
      {fileSpecs.map((spec) => (
        <div key={spec.name} className={s['specs-file']}>
          <div className={s['specs-file__type']}>
            <div className={s['specs-file__type-icon']}>
              <SvgIcon name={spec.icon} />
            </div>
            <div className={s['specs-file__type-title']}>{t(spec.name)}</div>
          </div>
          <div className={s['specs-file__values']}>{t(spec.values)}</div>
        </div>
      ))}
    </div>
  );
};

export default FileSpecifications;
