import { T, UT } from '@transifex/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  pageViewTestEvent,
  purchaseTextEvent,
  subscriptionTestEvent,
} from '../../../../constants/pixel';
import {
  fetchTestPixelEventsAsync,
  isPixelTestInProgressSelector,
  pixelTestEventsSelector,
  setIsPixelTestInProgress,
  setPixelTestEvents,
} from '../../../../store/brand';
import TablePixelTestingEvents from '../../../TablePixelTestingEvents';
import { Copy } from '../../../common/Copy';
import { SvgIcon } from '../../../common/SvgIcon';
import SecondaryTitle from '../../../common/Titles/SecondaryTitle/SecondaryTitle';
import s from './PixelSetupContent.module.scss';

const PixelTesting = () => {
  const pixelTestEvents = useSelector(pixelTestEventsSelector);
  const isPixelTestInProgress = useSelector(isPixelTestInProgressSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isPixelTestInProgress) {
      dispatch(setPixelTestEvents([]));
      dispatch(fetchTestPixelEventsAsync.request({ offset: 0, limit: 20 }));
    }
  }, [dispatch, isPixelTestInProgress]);

  const handleToggleTest = () => {
    if (isPixelTestInProgress) {
      return dispatch(setIsPixelTestInProgress(false));
    }
    dispatch(setPixelTestEvents([]));
    dispatch(fetchTestPixelEventsAsync.request({ offset: 0, limit: 20, minDate: 1 * new Date() }));
    dispatch(setIsPixelTestInProgress(true));
  };
  return (
    <>
      <section className={s['pixel-testing']}>
        <SecondaryTitle className={s['title']}>
          <T _str="Testing Instructions" />
        </SecondaryTitle>
        <div className={s['description']}>
          <div className={s['testing']}>
            <div className={s['content']}>
              <UT _str="You can test the <b>8Pixel</b> integration to ensure it is correctly set up before going live. By passing the property <b>testMode</b> with a value of <b>true</b>, you can enable event logging for testing purposes before deploying to a live environment. Below are examples for the three provided events:" />

              <pre>
                <Copy textToCopy={pageViewTestEvent} />
                <code>{pageViewTestEvent}</code>
              </pre>
              <pre>
                <Copy textToCopy={purchaseTextEvent} />
                <code>{purchaseTextEvent}</code>
              </pre>
              <pre>
                <Copy textToCopy={subscriptionTestEvent} />
                <code>{subscriptionTestEvent}</code>
              </pre>

              <T _str="After completing the setup, return to SAM-i and navigate to the test events section at the end of this page:" />
              <ol>
                <li>
                  <T _str='Click "Start new test" button.' />
                </li>
                <li>
                  <T _str="Visit your website and trigger an event." />
                </li>
                <li>
                  <T _str="Return to SAM-i." />
                </li>
                <li>
                  <T _str="If everything works as expected, the events table will be populated with the new test event sent from your website." />
                </li>
              </ol>
              <span className={s['explanation']}>
                <T _str="Event logging may take up to 20-30 seconds to update when running a test." />
              </span>
              <span className={s['reminder']}>
                <T _str="Dont forget to disable test mode before going live!" />
              </span>
            </div>
          </div>
        </div>
      </section>
      <section className={s['pixel-testing-events']}>
        <SecondaryTitle className={s['title']}>
          <T _str="Test events" />
        </SecondaryTitle>
        <div className={s['description']}>
          <div className={s['testing']}>
            <div className={s['content']}>
              <div className={s['button-container']}>
                <button className={s['button']} onClick={handleToggleTest}>
                  <T
                    _str="{buttonText}"
                    buttonText={isPixelTestInProgress ? 'Stop test' : 'Start new test'}
                  />
                </button>
                {isPixelTestInProgress && (
                  <div className={s['progress-container']}>
                    <SvgIcon name="progress" />
                    <span>Test in progress...</span>
                  </div>
                )}
              </div>
              <TablePixelTestingEvents
                isPixelTestInProgress={isPixelTestInProgress}
                data={pixelTestEvents ?? []}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PixelTesting;
