import React, { useState, useMemo, useCallback, useEffect } from 'react';

import classNames from 'classnames/bind';
import s from './MobileMenu.module.scss';

const cx = classNames.bind(s);

const MobileMenu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleModalClose = useCallback(
    (e) => {
      const { target } = e;

      target.tagName.toLowerCase() === 'a' && setIsOpen(false);
    },
    [setIsOpen]
  );

  useEffect(() => {
    document.addEventListener('click', handleModalClose, false);

    return () => document.removeEventListener('click', handleModalClose, false);
  }, [handleModalClose]);

  const mobileClassNames = useMemo(
    () =>
      cx({
        [s['mobile-menu']]: true,
        [s['mobile-menu__open']]: isOpen === true,
        [s['mobile-menu__close']]: isOpen === false,
      }),
    [isOpen]
  );

  const arrowClassNames = useMemo(
    () =>
      cx({
        [s['arrow']]: true,
        [s['arrow-up']]: isOpen === true,
        [s['arrow-down']]: isOpen === false,
      }),
    [isOpen]
  );

  const handleToggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  return (
    <>
      <div className={s['arrow-wrapper']} onClick={handleToggleMenu}>
        <div className={arrowClassNames} />
      </div>
      <div className={mobileClassNames}>{children}</div>
    </>
  );
};

export default MobileMenu;
