import React, { useCallback, useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router';

import { StickyMenu } from '../../components/wrappers/StickyMenu';
import { MobileMenu } from '../../components/wrappers/MobileMenu';
import { SettingsMenu } from '../../components/Menus/SettingsMenu';
import { Title } from '../../components/common/Titles/Title';
import { AltSidebar } from '../../components/Sidebars/AltSidebar';

import { SettingsAccountInfo } from './SettingsAccountInfo';
// import { SettingsPassword } from './SettingsPassword';
// import { SettingsNotifications } from './SettingsNotifications';
import { SettingsBilling } from './SettingsBilling';

import { routes, settingsMenu } from '../../constants';
import { AltPageWrapper } from '../../components/wrappers/AltPageWrapper';
import { SettingsPixel } from './SettingsPixel';
import { SettingsPixelSetup } from './SettingsPixelSetup';
import { SettingsBrandOnboarding } from './SettingsBrandOnboarding';
import { useT } from '@transifex/react';

const Settings = () => {
  const [width, setWidth] = useState(0);

  const t = useT();

  const handleWindowWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidth);
    return () => window.removeEventListener('resize', handleWindowWidth);
  }, [handleWindowWidth]);

  return (
    <>
      <AltSidebar>
        <StickyMenu>
          <Title>{t('Business Settings')}</Title>
          <SettingsMenu items={settingsMenu.items} />
        </StickyMenu>
        {width <= 768 && (
          <MobileMenu>
            <SettingsMenu items={settingsMenu.items} />
          </MobileMenu>
        )}
      </AltSidebar>
      <AltPageWrapper>
        <Switch>
          <Route exact path={routes.SETTINGS_ACCOUNT_INFO} component={SettingsAccountInfo} />
          <Route path={routes.SETTINGS_BILLING} component={SettingsBilling} />
          <Route path={routes.SETTINGS_PIXEL} component={SettingsPixel} />
          <Route path={routes.SETTINGS_PIXEL_SETUP} component={SettingsPixelSetup} />
          <Route path={routes.SETTINGS_BRAND_ONBOARDING} component={SettingsBrandOnboarding} />
          {/*<Route exact path={routes.SETTINGS_PASSWORD} component={SettingsPassword} />*/}
          {/*<Route exact path={routes.SETTINGS_NOTIFICATIONS} component={SettingsNotifications} />*/}
          <Redirect from="*" to={routes.SETTINGS_ACCOUNT_INFO} />
        </Switch>
      </AltPageWrapper>
    </>
  );
};

export default Settings;
