import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';

import { ReportingDemographics } from './ReportingDemographics';
import { ReportingHero } from './ReportingHero';
import { ReportingOverview } from './ReportingOverview';
import { ReportingTabs } from './ReportingTabs';
import { ReportingValue } from './ReportingValue';

import { ToastContainer } from 'react-toastify';
import { generateCampaignPerformancePdf } from '../../../utils/pdf/generatePdf';
import { Button } from '../../common/Button';
import { SvgIcon } from '../../common/SvgIcon';

import { useRouter } from '../../../hooks/useRouter';

import { setBrandDateFormat } from '../../../utils/receivedData';
import { getPricePerCarbonTonne } from '../../../utils/reporting';

import { carbonOffsetInTones, numberToCurrency, percentageOfBudget } from '../../../utils/budget';

import { CARBON_OFFSET_IMPACT_PERCENTAGE } from '../../../constants/values';

import { useDispatch, useSelector } from 'react-redux';
import {
  acceptanceOptionsSelector,
  acceptanceOverTimeSelector,
  analyticsCampaignSelector,
  analyticsFilteredFetching,
  analyticsSponsorshipSelector,
  analyticsSponsorshipSetSelector,
  demographicsFilterRequest,
  demographicsSelector,
  fetchAnalyticsCampaignAsync,
  fetchAnalyticsSponsorshipAsync,
  fetchAnalyticsSponsorshipSetAsync,
  filterOptionsSelector,
  heatMapSelector,
  influencersByGenderSelector,
  initialFetchedDataSelector,
  overviewFilterRequest,
  questionsFilterSelector,
  questionsResponsesSelector,
  responsesSelector,
  shortAnswerResponsesSelector,
  totalsSelector,
} from '../../../store/analytics';
import { adminAccessTokenSelector } from '../../../store/auth';
import { brandInfoSelector } from '../../../store/brand';
import s from './ReportingContent.module.scss';

import { useT } from '@transifex/react';
import ReactPlayer from 'react-player';
import reportingEmptyImage from '../../../assets/images/reporting-empty.png';
import { useReportingData } from '../../../hooks/useReportingData';
import { Spinner } from '../../Spinner';
import { ReportingEmpty } from './ReportingEmpty';
import { ReportingOverviewDetail } from './ReportingOverview/ReportingOverviewDetail';

const ReportingContent = ({
  handleDownloadCSV,
  acceptanceOverTimeFetching,
  demographicsFetching,
  heatMapFetching,
  responsesFetching,
  type,
}) => {
  const { match, params, push, query } = useRouter();

  const t = useT();

  const acceptanceOverTime = useSelector(acceptanceOverTimeSelector);
  const acceptanceOptions = useSelector(acceptanceOptionsSelector);
  const brandInfo = useSelector(brandInfoSelector);
  const campaign = useSelector(analyticsCampaignSelector);
  const demographics = useSelector(demographicsSelector);
  const filterOptions = useSelector(filterOptionsSelector);
  const heatMap = useSelector(heatMapSelector);
  const influencersByGender = useSelector(influencersByGenderSelector);
  const initialFetchedData = useSelector(initialFetchedDataSelector);
  const questions = useSelector(questionsFilterSelector);
  const questionsResponses = useSelector(questionsResponsesSelector);
  const responses = useSelector(responsesSelector);
  const shortAnswerResponses = useSelector(shortAnswerResponsesSelector);
  const sponsorship = useSelector(analyticsSponsorshipSelector);
  const sponsorshipSet = useSelector(analyticsSponsorshipSetSelector);
  const totals = useSelector(totalsSelector);
  const adminMode = !!useSelector(adminAccessTokenSelector);
  const dispatch = useDispatch();
  const fetching = useSelector(analyticsFilteredFetching);

  const [overviewNode, setOverviewNode] = useState();
  const [responsesNode, setResponsesNode] = useState();
  const [demographicsNode, setDemographicsNode] = useState();
  const [activeTab, setActiveTab] = useState(query.type || 'overview');
  const heroNode = document.getElementById('reporting-hero-section');

  useEffect(() => setActiveTab(query.type), [query.type]);

  const data = useReportingData({ type, initialData: {}, campaign, sponsorshipSet, sponsorship });

  const [isPdfProcessing, setIsPdfProcessing] = useState(false);

  // If all nodes have content to print, then the page is ready to be exported to pdf
  const isPageReady = useMemo(() => {
    if (activeTab === 'overview')
      return type === 'ad' ? overviewNode && heroNode && responsesNode : overviewNode && heroNode;
    else {
      const areDemographicsNodesReady = demographicsNode?.filter((item) => item).length === 3;

      return heroNode && areDemographicsNodesReady;
    }
  }, [activeTab, type, overviewNode, heroNode, responsesNode, demographicsNode]);

  useEffect(() => {
    if (brandInfo.brandIdFromAdmin || brandInfo.id) {
      if (type === 'campaign')
        dispatch(
          fetchAnalyticsCampaignAsync.request({
            campaignId: params.cid,
            adSetId: params.ssid,
            adId: params.sid,
            type,
          })
        );
      if (type === 'ad')
        dispatch(
          fetchAnalyticsSponsorshipAsync.request({
            campaignId: params.cid,
            adSetId: params.ssid,
            adId: params.sid,
            type,
          })
        );
      if (type === 'ad' || type === 'ad-set')
        dispatch(
          fetchAnalyticsSponsorshipSetAsync.request({
            campaignId: params.cid,
            adSetId: params.ssid,
            adId: params.sid,
            type,
          })
        );

      dispatch(
        overviewFilterRequest({
          query: {},
          campaignId: params.cid,
          adSetId: params.ssid,
          adId: params.sid,
          type,
        })
      );
    }
  }, [
    adminMode,
    brandInfo.brandIdFromAdmin,
    brandInfo.currencyCode,
    brandInfo.id,
    dispatch,
    params.cid,
    params.sid,
    params.ssid,
    type,
  ]);

  const filteringDatesRange = useMemo(() => {
    let dateFrom;
    let dateTo;
    if (type === 'campaign') {
      const startDatesArray = campaign?.sponsorshipsSets?.map((set) => new Date(set.scheduleFrom));
      const endDatesArray = campaign?.sponsorshipsSets?.map((set) => new Date(set.scheduleTo));
      dateFrom = Math.min.apply(null, startDatesArray);
      dateTo = Math.min.apply(null, endDatesArray);
    } else {
      dateFrom = sponsorshipSet?.startDate;
      dateTo = sponsorshipSet?.endDate;
    }
    return { dateFrom, dateTo };

    // const names = acceptanceOverTime?.series?.reduce((combined, serie) => {
    //   return combined.concat(serie.data);
    // }, []);
    // const dateRangesArray = setAcceptanceCategoriesNames(names).map((date) => date?.name);
    // const length = dateRangesArray?.length;
    // return {
    //   ...(length > 0 && {
    //     dateFrom: processReceivedDate(dateRangesArray[0]),
    //   }),
    //   ...(length > 0 && {
    //     dateTo: processReceivedDate(dateRangesArray?.[length - 1]),
    //   }),
    // };
  }, [campaign?.sponsorshipsSets, sponsorshipSet?.endDate, sponsorshipSet?.startDate, type]);

  const handleTabSelect = useCallback(
    (title) => {
      push(`${match.url}?type=${title.toLowerCase()}`);
    },
    [match.url, push]
  );

  const getCostPerRelationship = useMemo(() => {
    if (campaign) {
      const sponsorshipSetsCount = campaign?.sponsorshipsSets?.length;

      const totalPricePerMessage = campaign?.sponsorshipsSets
        ?.map((adSet) => adSet.pricePerMessage)
        .reduce((a, b) => parseFloat(a) + parseFloat(b));

      const totalReward = totalPricePerMessage / sponsorshipSetsCount || 0;

      return numberToCurrency(totalReward, false, false, 4);
    }
    return numberToCurrency(sponsorshipSet?.pricePerMessage || 0, false, false, 4);
  }, [campaign, sponsorshipSet?.pricePerMessage]);

  const getImpact = useMemo(() => {
    if (totals?.completed?.total) {
      if (campaign) {
        const totalDonationReward = campaign?.sponsorshipsSets
          ?.map((adSet) => adSet.donationReward)
          .reduce((a, b) => parseFloat(a) + parseFloat(b));

        const totalImpact = totalDonationReward * totals?.completed?.total;
        return numberToCurrency(totalImpact);
      }
      return numberToCurrency(sponsorshipSet?.donationReward * totals?.completed?.total);
    }

    return numberToCurrency(0);
  }, [campaign, totals, sponsorshipSet?.donationReward]);

  const getIncentiveText = useMemo(() => {
    const { cashReward, donationReward } = sponsorshipSet || {};

    if (cashReward && donationReward) {
      return 'Cash & Donation';
    } else if (cashReward) {
      return 'Cash';
    } else if (donationReward) {
      return 'Donation';
    }
  }, [sponsorshipSet]);

  const getAgeText = useMemo(() => {
    const ageRanges = sponsorshipSet?.ageRanges || [];
    if (ageRanges.length === 0) return '';
    if (ageRanges.length === 7) return 'All';
    else {
      const agesToDisplay = [...ageRanges]
        .sort((a, b) => {
          // Ascending order
          if (a.ageFrom < b.ageFrom) {
            return -1;
          }
          if (a.ageFrom > b.ageFrom) {
            return 1;
          }
          return 0;
        })
        .map((ageRange) => {
          let range;
          if (!ageRange.ageTo) range = `${ageRange?.ageFrom}+`;
          else range = `${ageRange?.ageFrom}-${ageRange?.ageTo}`;
          return range;
        })
        .join(', ');
      return agesToDisplay;
    }
  }, [sponsorshipSet?.ageRanges]);

  const getGenderText = useMemo(() => {
    return sponsorshipSet?.gender?.length > 0 &&
      sponsorshipSet?.gender?.some((gender) => gender?.name === 'All')
      ? 'All'
      : sponsorshipSet?.gender?.map((gender) => gender?.name).join(', ');
  }, [sponsorshipSet?.gender]);

  const getBudgetText = useMemo(() => {
    // FIXME - hide daily budget for future releases
    const {
      // dailyBudget,
      totalBudget,
    } = sponsorshipSet || {};

    // const dailyFormat = dailyBudget && dailyBudget >= 1000 ? '0.0a' : '0';

    // if (dailyBudget) {
    //   return `${currencyCode}${numeral(dailyBudget).format(
    //     dailyFormat
    //   )} Daily / ${currencyCode}${numeral(totalBudget).format(
    //     totalFormat
    //   )} Total`;
    // } else {
    //   return `${currencyCode}0 Daily / ${currencyCode}${numeral(totalBudget)
    //     .format(
    //       totalFormat
    //     )} Total`;
    // }

    return `${numberToCurrency(totalBudget, true)}`;
  }, [sponsorshipSet]);

  const getScheduleText = useMemo(() => {
    const { startDate, endDate } = sponsorshipSet || {};

    if (!endDate) {
      return `Start Date: ${setBrandDateFormat(startDate, brandInfo.countryCode, true)}`;
    }

    return `${setBrandDateFormat(startDate, brandInfo.countryCode, true)} - ${
      endDate ? setBrandDateFormat(endDate, brandInfo.countryCode, true) : ''
    }`;
  }, [sponsorshipSet, brandInfo?.countryCode]);

  const getOsText = useMemo(() => {
    return sponsorshipSet?.deviceType?.length > 2 ? 'All' : sponsorshipSet?.deviceType[0]?.name;
  }, [sponsorshipSet?.deviceType]);

  const getLocationText = useMemo(() => {
    return sponsorshipSet?.locations?.length > 0
      ? sponsorshipSet?.locations?.map((location) => location?.name).join(', ')
      : brandInfo.country.name;
  }, [sponsorshipSet?.locations, brandInfo.country.name]);

  const getInterestsText = useMemo(() => {
    return (
      sponsorshipSet?.interests?.length > 0 &&
      sponsorshipSet?.interests?.map((interest) => interest.join(', '))
    );
  }, [sponsorshipSet?.interests]);

  const getPassionsText = useMemo(() => {
    return (
      sponsorshipSet?.passions?.length > 0 &&
      sponsorshipSet?.passions?.map((passion) => passion.join(', '))
    );
  }, [sponsorshipSet?.passions]);

  const getIncludeTagsText = useMemo(() => {
    return sponsorshipSet?.includeTags?.length > 0 && sponsorshipSet?.includeTags?.join(', ');
  }, [sponsorshipSet?.includeTags]);

  const getEcologiContribution = useMemo(() => {
    const ecologiContribution = totals?.spent?.total
      ? percentageOfBudget(CARBON_OFFSET_IMPACT_PERCENTAGE, totals?.spent?.total)
      : 0;

    return numberToCurrency(ecologiContribution);
  }, [totals?.spent?.total]);

  const getCarbonPrice = useMemo(
    () => numberToCurrency(getPricePerCarbonTonne(brandInfo?.countryCode), false, false, 2),
    [brandInfo?.countryCode]
  );
  const getTotalDonationValue = useMemo(() => {
    const sampleSponsorshipSet = campaign?.sponsorshipsSets[0];
    const cpcv = parseFloat(sampleSponsorshipSet?.pricePerMessage);

    const charityDonationPercentage =
      (parseFloat(sampleSponsorshipSet?.donationReward) * 100) / cpcv;

    return `${Math.round(charityDonationPercentage)}%`;
  }, [campaign]);

  const noReportingData = useMemo(() => {
    return totals
      ? Object.keys(totals).reduce((total, key) => {
          return key === 'costPerEngagement' ? 0 + total : totals[key]?.total + total;
        }, 0) === 0
      : false;
  }, [totals]);

  const videoElementRef = useRef(null);

  const [computedHeight, setComputedHeight] = useState('100%');
  const [computedWidth, setComputedWidth] = useState('100%');

  const videoContainerRef = useCallback(
    (node) => {
      if (node && sponsorship?.videoWidth && sponsorship?.videoHeight) {
        const videoRatio = sponsorship?.videoWidth / sponsorship?.videoHeight;
        const containerRatio = node.clientWidth / node.clientHeight;
        if (videoRatio >= containerRatio) {
          setComputedHeight(
            (node.clientWidth * (sponsorship?.videoHeight - 45)) / sponsorship?.videoWidth
          );
          setComputedWidth('100%');
        } else {
          setComputedHeight(node.clientHeight - 45);
          setComputedWidth(
            (node.clientHeight * sponsorship?.videoWidth) / (sponsorship?.videoHeight - 45)
          );
        }
      }
    },
    [sponsorship?.videoWidth, sponsorship?.videoHeight]
  );

  if (!demographics && !heatMap && !responses && !totals) return <Spinner />;

  if (initialFetchedData?.initialTotals) {
    // const total = Object.keys(initialFetchedData?.initialTotals).reduce((total, key) => {
    //   return key === 'costPerEngagement' ? 0 + total : totals[key]?.total + total;
    // }, 0);

    if (!initialFetchedData?.initialTotals?.completed?.total) {
      return <ReportingEmpty campaign={campaign} reportingEmptyImage={reportingEmptyImage} />;
    }
  }

  return (
    <div
      className={classNames(s['reporting__content'], {
        [s['reporting__content--admin']]: adminMode,
      })}
    >
      {fetching && <Spinner />}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
      />
      <div className={s['reporting__header-container']}>
        <div className={s['reporting__headers']}>
          <h1 className={s['reporting__header']}>{data.title}</h1>
          <h2 className={s['reporting__subheader']}>{t(data.subtitle)}</h2>
        </div>
        <Button
          customClass={s['reporting__header-pdf-download-button']}
          styling="secondary"
          name={t('Download in ') + 'PDF'}
          callback={() =>
            generateCampaignPerformancePdf(
              heroNode,
              overviewNode,
              responsesNode,
              demographicsNode,
              setIsPdfProcessing,
              data?.title,
              type,
              activeTab
            )
          }
          disabled={isPdfProcessing || !isPageReady}
          icon={<SvgIcon name="csv-download" />}
        ></Button>
      </div>
      <div id="reporting-hero-section">
        <div className={s['reporting__hero-container']}>
          <ReportingHero
            type="people"
            textContent={`${t('You have valued, paid, and built relationships with')} ${
              totals?.completed?.total
            } ${t('people')}`}
            detailContent={
              t('Your current cost per relationship (CPR) is ') + getCostPerRelationship
            }
          ></ReportingHero>

          <div className={s['reporting__hero-divider']} />

          <ReportingHero
            type="purpose"
            textContent={`${t('You have impacted and contributed')} ${getImpact} ${t('to')} ${
              sponsorshipSet?.selectedCharity?.name || campaign?.charityName || '-'
            }`}
            reverse
          >
            <ReportingValue
              iconName="happy-face"
              value={getTotalDonationValue}
              text={t('Each completed offer donates')}
            />
          </ReportingHero>

          <div className={s['reporting__hero-divider']} />

          <ReportingHero
            type="planet"
            textContent={`${t('You have offset ')}${
              totals?.spent?.total
                ? carbonOffsetInTones(totals?.spent?.total, brandInfo.countryCode)
                : 0
            }${t('T of carbon emissions through verified carbon avoidance projects.')}`}
            detailContent={t('Total contribution towards Ecologi: ') + getEcologiContribution}
          >
            <ReportingValue
              iconName="co2"
              value={getCarbonPrice}
              text={t('Price per tonne of CO2e offset')}
            />
          </ReportingHero>
        </div>
      </div>
      {data.targeting && (
        <>
          <h1 className={s['reporting__subheader']}>{t('Targeting Details')}</h1>

          <div className={s['reporting__targeting']}>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="person" title={t('Age')} text={getAgeText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="gender" title={t('Gender')} text={getGenderText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="money" title={t('Budget')} text={getBudgetText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail
                icon="calendar"
                title={t('Schedule')}
                text={getScheduleText}
              />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="mobile" title="OS" text={getOsText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail
                icon="pin"
                title={t('Interest(s)')}
                text={getInterestsText}
              />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="pin" title={t('Passion(s)')} text={getPassionsText} />
            </div>
            <div className={s['grid-item']}>
              <ReportingOverviewDetail icon="pin" title={t('Location(s)')} text={getLocationText} />
            </div>
            {sponsorshipSet?.includeTags?.length > 0 && (
              <div className={s['grid-item']}>
                <ReportingOverviewDetail
                  icon="tag"
                  title={t('Custom audiences')}
                  text={getIncludeTagsText}
                />
              </div>
            )}
          </div>
        </>
      )}

      {data.creativeAndIncentive && (
        <>
          <h1 className={s['reporting__subheader']}>{t('Creative and incentive')}</h1>

          <div className={s['reporting__media']}>
            <div className={s['media-item__video']} ref={videoContainerRef}>
              <div className={s['title']}>{t('Content')}</div>
              <ReactPlayer
                ref={videoElementRef}
                className={s['player']}
                volume={1}
                controls={true}
                height={computedHeight}
                width={computedWidth}
                url={sponsorship?.adVideo || ''}
              />
            </div>

            <div className={s['media-item__cta']}>
              <div className={s['title']}>CTA {t('Tile')}</div>
              <div className={s['img__wrap']}>
                <img alt="Sponsorship CTA Tile" src={sponsorship?.ctaCover} className={s['img']} />
              </div>
            </div>
          </div>

          <div className={s['reporting__incentive']}>
            <div className={s['logo-container']}>
              <div className={s['logo-container__picture']}>
                <img alt="Brand Logo" className={s['img']} src={brandInfo?.logoUri} />
              </div>
              <div className={s['logo-container__name']}>{brandInfo?.brandName}</div>
            </div>
            <div className={s['reporting__incentive__grid-overall']}>
              <div className={s['grid-item']}>
                <ReportingOverviewDetail
                  icon="link"
                  title={t('Link to product')}
                  text={sponsorship?.ctaLink}
                  isLongText
                />
              </div>
              {sponsorship?.ctaButtonText && (
                <div className={s['grid-item']}>
                  <ReportingOverviewDetail
                    // icon="text"
                    title={t('Link button text')}
                    text={sponsorship?.ctaButtonText}
                  />
                </div>
              )}
              {sponsorship?.ctaDiscount && (
                <div className={s['grid-item']}>
                  <ReportingOverviewDetail
                    icon="percentage"
                    title={t('Discount code')}
                    text={sponsorship?.ctaDiscount}
                  />
                </div>
              )}
              <div className={s['grid-item']}>
                <ReportingOverviewDetail
                  icon="medal"
                  title={t('Incentive')}
                  text={getIncentiveText}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div className={s['reporting__routes-container']}>
        <div className={s['reporting__overview-header']}>
          <h1 className={s['reporting__subheader']}>
            {t('{type} Overview', { type: data.typeName })}
          </h1>
        </div>
        <ReportingTabs onSelect={handleTabSelect} activeTab={activeTab} />
        {activeTab === 'overview' && (
          <ReportingOverview
            acceptanceOverTime={acceptanceOverTime}
            acceptanceOverTimeFetching={acceptanceOverTimeFetching}
            brandInfo={brandInfo}
            demographics={demographics}
            demographicsFetching={demographicsFetching}
            filteringDatesRange={filteringDatesRange}
            filterOptions={filterOptions}
            heatMapFetching={heatMapFetching}
            initialFetchedData={initialFetchedData}
            influencersByGender={influencersByGender}
            overviewFilterRequest={(val) => dispatch(overviewFilterRequest(val))}
            responses={responses}
            responsesFetching={responsesFetching}
            shortAnswerResponses={shortAnswerResponses}
            sponsorship={sponsorship}
            sponsorshipSet={sponsorshipSet}
            totals={totals}
            handleDownloadCSV={handleDownloadCSV}
            noReportingData={noReportingData}
            heatMap={heatMap}
            setOverviewNode={setOverviewNode}
            setResponsesNode={setResponsesNode}
            data={data}
            type={type}
            campaign={campaign}
          />
        )}
        {activeTab === 'demographics' && (
          <ReportingDemographics
            acceptanceOptions={acceptanceOptions}
            brandInfo={brandInfo}
            demographics={demographics}
            demographicsFetching={demographicsFetching}
            demographicsFilterRequest={(val) => dispatch(demographicsFilterRequest(val))}
            filteringDatesRange={filteringDatesRange}
            filterOptions={filterOptions}
            heatMapFetching={heatMapFetching}
            initialFetchedData={initialFetchedData}
            influencersByGender={influencersByGender}
            questions={questions}
            questionsResponses={questionsResponses}
            sponsorship={sponsorship}
            handleDownloadCSV={handleDownloadCSV}
            noReportingData={noReportingData}
            heatMap={heatMap}
            type={type}
            setDemographicsNode={setDemographicsNode}
          />
        )}
      </div>
    </div>
  );
};

export default ReportingContent;
