import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from '../../constants';
import NewCombinedCampaignMenu from '../../containers/NewCombinedCampaignMenu/NewCombinedCampaignMenu';
import { HomeLink } from '../common/HomeLink';

import { SvgIcon } from '../common/SvgIcon';
import { AdminPanel } from './AdminPanel';

import s from './Topbar.module.scss';

const Topbar = ({
  amount,
  brandId,
  brandEmail,
  currencySymbol,
  logoUri,
  logout,
  name,
  isInvoicePartner,
  homeName,
  location,
  topbarRef,
  accountStatus,
  fetching,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowWidth = useCallback(() => {
    setWidth(window.innerWidth);
  }, [setWidth]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidth);

    return () => window.removeEventListener('resize', handleWindowWidth);
  }, [handleWindowWidth]);
  return (
    <div className={s['topbar']} ref={topbarRef}>
      {location !== '/' && <HomeLink name={homeName} path={routes.HOME} />}
      <div className={s['topbar__inner']}>
        <div className={s['topbar__inner-home']}>
          <NavLink exact to={'/'} className={s['topbar__inner-home__link']}>
            <SvgIcon name="home" />
          </NavLink>
        </div>
        <AdminPanel
          amount={amount}
          brandId={brandId}
          brandEmail={brandEmail}
          currencySymbol={currencySymbol}
          logoUri={logoUri}
          logout={logout}
          name={name}
          isInvoicePartner={isInvoicePartner}
          accountStatus={accountStatus}
          fetching={fetching}
        />
        {/* HOTFIX for mobile menu*/}
        {location.includes('/campaign/') && width < 768 && <NewCombinedCampaignMenu />}
      </div>
    </div>
  );
};

export default Topbar;
