import React, { useMemo, useState } from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { CustomCheckbox } from '../../CustomCheckbox';
import DropdownNestedOption from './DropdownNestedOption';

import s from './DropdownSelect.module.scss';

function DropdownOption({ data, value, withoutExpand, setValue, options }) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded((prevState) => !prevState);
  };

  const atLeastASingleSelectedChild = useMemo(() => {
    let result = [];
    data.cities.forEach((city) => {
      result = [...result, value.find((val) => val.value === city.value)];
    });
    return result.some((res) => res);
  }, [data.cities, value]);

  const isChecked =
    (value?.length > 0 && value?.some((option) => option.value === data.value)) || false;

  const handleValueChange = useMemo(() => {
    let finalValue = value || [];
    const valueAlreadyExists = value?.some((val) => val.value === data.value);
    if (valueAlreadyExists) {
      finalValue = value?.filter((val) => val.value !== data.value);
      return finalValue;
    }
    data.cities.forEach((city) => {
      finalValue = finalValue.filter((val) => val.value !== city.value);
    });
    finalValue = [...finalValue, data];
    return finalValue;
  }, [value, data]);

  const handleClick = (e) => {
    const clickTarget = e.target;
    const optionElement = e.currentTarget;
    const expandElement = optionElement.querySelector('#expand');

    if (!expandElement) {
      setValue(handleValueChange);
    }

    const isChild = expandElement?.contains(clickTarget) || expandElement === clickTarget;
    const clickedOnExpand = isChild;
    if (clickedOnExpand) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    setValue(handleValueChange);
  };

  return (
    <div className={s['option']}>
      <div className={s['data']} onClick={handleClick}>
        {!withoutExpand && (
          <div
            className={expanded ? s['expanded'] : s['closed']}
            id="expand"
            onClick={handleExpandClick}
          >
            <SvgIcon name="expand" />
          </div>
        )}

        <div className={s['checkbox']}>
          <CustomCheckbox
            checked={isChecked}
            dashStyle={!isChecked && atLeastASingleSelectedChild}
          />
        </div>
        <span>{data.label}</span>
      </div>

      {expanded && data?.cities && (
        <div className={s['children']}>
          {data?.cities?.map((city) => (
            <DropdownNestedOption
              data={city}
              value={value}
              parentValue={data.label}
              setValue={setValue}
              options={options}
              key={city.value}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default DropdownOption;
