import React from 'react';
import { SectionTitlePlaceholder } from '../../../components/Placeholders/SectionTitlePlaceholder.js/index.js';
import SingleLinePlaceholder from '../../../components/Placeholders/SingleLinePlaceholder/SingleLinePlaceholder.js';
import Placeholder from '../Placeholder.js';

function SummaryPlaceholder() {
  return (
    <>
      <Placeholder gap="40px">
        <SectionTitlePlaceholder />
        <SingleLinePlaceholder />
        <SectionTitlePlaceholder />
        <SectionTitlePlaceholder />
        <SectionTitlePlaceholder />
        <SectionTitlePlaceholder />
        <SectionTitlePlaceholder />
        <SectionTitlePlaceholder />
        <SectionTitlePlaceholder />
      </Placeholder>
    </>
  );
}

export default SummaryPlaceholder;
