import React from 'react';

// import { Controller } from 'react-hook-form';
import Select from 'react-select';

import selectStyles from '../selectStyles';
import DropdownOption from './DropdownOption';
import s from './DropdownSelect.module.scss';
import { useT } from '@transifex/react';

const DropdownSelect = ({
  inputId,
  defaultValue,
  error,
  control,
  isLoading,
  options,
  placeholder,
  name,
  handleSelect,
  onInputChange,
  setValue,
  value,
  locationsAreActive,
  inputRef,
  disabled = false,
}) => {
  const t = useT();
  const formatOptionLabel = (data, { context }) => {
    return context === 'menu' ? (
      <DropdownOption
        data={data}
        value={value}
        setValue={setValue}
        options={options}
        withoutExpand={!data.cities.length}
      />
    ) : (
      data.label
    );
  };

  const handleChange = (data, { action }) => {
    if (action === 'select-option' || action === 'deselect-option') return;
    setValue(data);
  };

  return (
    <div className={s['select']}>
      <Select
        ref={inputRef}
        closeMenuOnSelect={false}
        name={name}
        options={options}
        styles={selectStyles(error, 'dropdown', !locationsAreActive || disabled)}
        placeholder={placeholder}
        isMulti
        formatOptionLabel={formatOptionLabel}
        backspaceRemovesValue={true}
        hideSelectedOptions={false}
        onChange={handleChange}
        isDisabled={disabled}
        value={value}
      />
      {error && <p className={s['error']}>{t(error.message)}</p>}
    </div>
  );
};

export default DropdownSelect;
