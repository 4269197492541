import React, { useMemo } from 'react';
import { ErrorMessage } from '../../common/ErrorMessage';

import classNames from 'classnames/bind';
import s from './TextInput.module.scss';
import { useT } from '@transifex/react';

const cx = classNames.bind(s);

const TextInput = ({
  formValue,
  value,
  label,
  id,
  name,
  onChange,
  onFocus,
  register,
  error,
  type,
  draggable,
  icon,
  handleIconClick,
  disabled,
  customInputFieldClass,
  defaultValue,
  watchValue,
  withActionButton = false,
  actionButtonDisabled,
  actionButtonCallback,
  actionButtonText,
  'data-testid': testId,
  role,
  autocomplete = 'on',
}) => {
  const t = useT();
  const inputFieldClassNames = useMemo(
    () =>
      cx(customInputFieldClass, {
        [s['input-field']]: true,
        [s['input-field__draggable']]: draggable,
      }),
    [draggable, customInputFieldClass]
  );

  const formValues = useMemo(() => {
    if (formValue === null) {
      return true;
    } else if (formValue === 0) {
      return true;
    }
    return formValue;
  }, [formValue]);

  const inputClassNames = useMemo(
    () =>
      cx({
        [s['input-field__input']]: true,
        [s['input-field__input--error']]: error,
        [s['input-field__input--disabled']]: disabled,
        [s['input-field__input--icon']]: icon,
      }),
    [error, disabled, icon]
  );

  const actionButtonClassNames = useMemo(
    () =>
      cx({
        [s['input-field__link']]: true,
        [s['input-field__link--disabled']]: actionButtonDisabled,
      }),
    [actionButtonDisabled]
  );

  const labelClassNames = useMemo(
    () =>
      cx({
        [s['input-field__label--active']]: value || formValues || watchValue,
      }),
    [value, formValues, watchValue]
  );

  return (
    <div className={inputFieldClassNames}>
      {register ? (
        <input
          data-testid={testId}
          className={inputClassNames}
          id={id}
          onChange={onChange}
          type={type}
          value={formValue}
          onFocus={onFocus}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          role={role}
          autoComplete={autocomplete}
          {...register(name, { onChange: (e) => onChange && onChange(e.target.value) })}
        />
      ) : (
        <input
          data-testid={testId}
          className={inputClassNames}
          id={id}
          onChange={onChange}
          type={type}
          value={formValue}
          onFocus={onFocus}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          role={role}
          autoComplete={autocomplete}
        />
      )}
      {icon && (
        <div className={s['input-field__icon']} onClick={handleIconClick}>
          {icon}
        </div>
      )}
      {error && <ErrorMessage error={t(error.message)} />}

      <label htmlFor={id} className={labelClassNames}>
        {label?.toUpperCase()}
      </label>
      {withActionButton && (
        <button
          onClick={actionButtonCallback}
          className={actionButtonClassNames}
          type="button"
          href="/"
        >
          {actionButtonText || ''}
        </button>
      )}
    </div>
  );
};

export default TextInput;
