import * as yup from 'yup';
import { EMAIL_REGEXP, LETTERS_REGEXP } from '../../../utils/validate';
import { t } from '@transifex/native';

export const addBrandInfoValidationSchema = yup.object().shape({
  billingEmail: yup
    .string()
    .required(t('Billing email address is a required field'))
    .matches(EMAIL_REGEXP, t('Email must be in e-mail format')),
  legalName: yup
    .string()
    .required(t('Legal name is a required field'))
    .min(3, t(t('Minimum length should be 3 chars'))),
  addressLineFirst: yup
    .string()
    .required(t('Address is a required field'))
    .min(2, t('Minimum length should be 2 chars'))
    .max(50, t('Maximum length should be 50 chars')),
  addressLineSecond: yup.lazy((value) => {
    if (value.length > 0) {
      return yup
        .string()
        .min(2, t('Minimum length should be 2 chars'))
        .max(50, t('Maximum length should be 50 chars'));
    }
    return yup.string().notRequired();
  }),
  city: yup
    .string()
    .required(t('City is a required field'))
    .min(2, t('Minimum length should be 2 chars'))
    .max(50, t('Maximum length should be 50 chars')),
  postCode: yup
    .string()
    .required(t('Postcode is a required field'))
    .min(2, t('Minimum length should be 2 chars'))
    .max(50, t('Maximum length should be 50 chars')),
  brandCountryCode: yup
    .string()
    .required(t('Brand country code is a required field'))
    .matches(LETTERS_REGEXP, t('Country code should only contain letters'))
    .min(2, t('Minimum length should be 2 chars'))
    .max(50, t('Maximum length should be 50 chars')),
  brandType: yup.string().required(t('Brand type is required')).min(1, t('Brand type is required')),
});
