import { toast } from 'react-toastify';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import UnpublishedChangesToastContent from '../../components/UnpublishChangesToastContent/UnpublishedChangesToastContent';
import { container } from '../../container';
import { brandInfoSelector } from '../brand';
import { setCampaignEditId } from '../campaign/campaign';

import {
  campaignDataFetching,
  deleteAd,
  deleteAdSet,
  fetchCampaignAsync,
  fetchCampaignDifferencesAsync,
  setUnpublishedDifferences,
} from './actions';
import { campaignIdSelector } from './selectors';

const campaignDataService = container.get('CampaignDataService');

function* fetchCampaignSaga({ payload }) {
  try {
    yield put(campaignDataFetching(true));

    let { timezoneId } = yield select(brandInfoSelector);

    const response = yield call(campaignDataService.getCompleteCampaign, {
      campaignId: payload,
      utcString: timezoneId,
    });
    yield put(setCampaignEditId(response.id));
    yield put(fetchCampaignAsync.success(response));
    if (!response.isDraft) yield put(fetchCampaignDifferencesAsync.request(payload));
    yield put(campaignDataFetching(false));
  } catch (error) {
    yield put(campaignDataFetching(false));
  }
}

function* fetchCampaignDifferencesSaga({ payload }) {
  try {
    yield put(campaignDataFetching(true));
    const response = yield call(campaignDataService.getDifferences, {
      campaignId: payload,
    });

    if (response.length)
      toast(<UnpublishedChangesToastContent />, {
        containerId: 'unpublished-changes',
        toastId: 'unpublished-toast',
        closeButton: false,
      });

    yield put(setUnpublishedDifferences(response));
    yield put(campaignDataFetching(false));
  } catch (error) {
    yield put(campaignDataFetching(false));
  }
}

function* deleteAdSetSaga({ payload }) {
  try {
    const campaignId = yield select(campaignIdSelector);
    yield call(campaignDataService.deleteAdSet, {
      campaignId,
      adSetId: payload.adSetId,
    });
  } catch (error) {}
}

function* deleteAdSaga({ payload }) {
  try {
    const campaignId = yield select(campaignIdSelector);
    yield call(campaignDataService.deleteAd, {
      campaignId,
      adSetId: payload.adSetId,
      adId: payload.adId,
    });
  } catch (error) {}
}

export function* campaignDataActionWatcher() {
  yield takeLatest(fetchCampaignAsync.request.type, fetchCampaignSaga);
  yield takeLatest(deleteAdSet, deleteAdSetSaga);
  yield takeLatest(deleteAd, deleteAdSaga);
  yield takeLatest(fetchCampaignDifferencesAsync.request.type, fetchCampaignDifferencesSaga);
}
