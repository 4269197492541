import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { useT } from '@transifex/react';

import { Tag } from '../common/Tag';

import s from './TagsGenerator.module.scss';
import { addResponseOptionTag, removeResponseOptionTag } from '../../utils/form/formHelpers';
import { useInfiniteScroll } from '../../hooks/useInfiniteScroll';
import { fetchTagsAsync } from '../../store/brand';

const TagsGenerator = ({
  localTags,
  tags,
  tagsOptions,
  optionId,
  handleQuestionStateChange,
  question,
}) => {
  const t = useT();

  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState('');
  const wrapperRef = useRef(null);
  const [isInputError, setInputError] = useState(false);

  const handleClickOutside = useCallback(
    (e) => {
      const { target } = e;
      const { current } = wrapperRef;
      if (current && !current.contains(target)) {
        setDisplay(false);
      }
    },
    [wrapperRef, setDisplay]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);

    return () => document.removeEventListener('click', handleClickOutside, false);
  }, [handleClickOutside]);

  const handleKeyDown = useCallback(
    (e) => {
      const { which } = e;

      if (which === 13 && search.trim().length) {
        const newTag = {
          id: nanoid(),
          label: search,
          value: search.toLowerCase(),
          name: search,
          description: search,
        };

        // const newlocalTags = cloneDeep(localTags);

        // if (newlocalTags.filter((e) => e.value === newTag.value).length === 0) {
        //   newlocalTags.push(newTag);
        //   dispatch(setLocalQuestionsTags(newlocalTags));
        // }

        addResponseOptionTag(optionId, question, newTag, handleQuestionStateChange);

        setDisplay(false);
        setSearch('');
        setInputError(false);
      }

      if (which === 13 && !search.trim().length) {
        setDisplay(false);
        setInputError(true);
      }
    },
    [search, optionId, question, handleQuestionStateChange]
  );

  const handleOptionsOpen = useCallback(() => {
    setDisplay(true);
  }, [setDisplay]);

  const handleOptionClick = useCallback(
    (responseId) => () => {
      const selectedTag = tagsOptions?.find((tag) => tag.id === responseId);

      addResponseOptionTag(optionId, question, selectedTag, handleQuestionStateChange);

      setSearch('');
      setDisplay(false);
    },
    [tagsOptions, optionId, question, handleQuestionStateChange]
  );

  const handleRemoveTag = (e) => {
    const { id: tagId } = e.target;
    removeResponseOptionTag(optionId, question, tagId, handleQuestionStateChange);
  };

  const filteredOptions = useMemo(
    () =>
      tagsOptions?.filter(
        (option) => option?.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      ),
    [tagsOptions, search]
  );

  const handleInputBlur = useCallback(() => {
    setInputError(false);
  }, [setInputError]);

  const {
    moreToFetch: moreTagsToFetch,
    fetchMore: fetchMoreTags,
    setKeyword: setTagsKeyword,
    fetchInitials: fetchInitialTags,
  } = useInfiniteScroll(fetchTagsAsync.request, localTags, true);

  const handleSearchChange = useCallback(
    (e) => {
      const { value } = e.target;

      setInputError(false);
      setTagsKeyword(value);
      setDisplay(true);
      setSearch(value);
    },
    [setTagsKeyword]
  );

  // Observer
  const observer = useRef(); // ref to store observer

  const lastElementRef = useCallback(
    (element) => {
      if (observer.current) observer.current.disconnect();

      if (!moreTagsToFetch) return;

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && moreTagsToFetch) fetchMoreTags();
      });

      if (element) observer.current.observe(element);
    },
    [moreTagsToFetch, fetchMoreTags]
  );

  return (
    <div className={s['tags-generator']}>
      <div ref={wrapperRef} className={s['tags-generator__input-wrap']}>
        <input
          className={s['tags-input']}
          id={nanoid()}
          type="text"
          value={search}
          placeholder={t('create or select a tag')}
          onChange={handleSearchChange}
          onClick={handleOptionsOpen}
          onKeyDown={handleKeyDown}
          onBlur={handleInputBlur}
          onFocus={() => fetchInitialTags('')}
        />
        <p className={s['tags-generator__input-error']}>
          {isInputError && t('Empty tag is not allowed')}
        </p>
        {display && (
          <div className={s['options-container']}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  className={s['options-container__option']}
                  onClick={handleOptionClick(option.id)}
                  ref={index === localTags.length - 5 ? lastElementRef : undefined}
                >
                  {option.name}
                </div>
              ))
            ) : (
              <div className={s['options-container__empty']}>
                {t('Type and press Enter to create new tag')}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={s['tags-generator__tags-battery']}>
        {tags?.length > 0 &&
          tags?.map((tag, index) => (
            <div key={index} className={s['tags-generator__tags-battery__tag-wrapper']}>
              <Tag text={tag?.label} callback={handleRemoveTag} id={tag.id} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default TagsGenerator;
