import React from 'react';
import logoLogin from '../../assets/images/logo-login.svg';
import s from './LogoAndTitle.module.scss';
import eightLogo from '../../assets/images/rebrand-eight.png';
import { T } from '@transifex/react';

const LogoAndTitle = () => {
  return (
    <>
      <div className={s['logo-and-title']}>
        <div className={s['logo-and-title__section']}>
          <img src={eightLogo} className={s['logo']} /> i
        </div>
        <div className={s['logo-and-title__section__logo-subtitle']}>
          <T
            _str="The ad manager driven by data and Ai that helps you get the attention and results from the
          people you want to reach"
          />
        </div>
      </div>
    </>
  );
};

export default LogoAndTitle;
