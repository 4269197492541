import React from 'react';
import ContentLoader from 'react-content-loader';

function TopBarPlaceholder({ ...props }) {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={40}
      viewBox="0 0 400 40"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="20" cy="20" r="20" />
      <rect x="50" y="12" rx="4" ry="4" width="113" height="15" />
      <rect x="177" y="12" rx="4" ry="4" width="210" height="15" />
    </ContentLoader>
  );
}

export default TopBarPlaceholder;
