import React from 'react';

import { useT } from '@transifex/react';

import { TableBilling } from '../../../../TableBilling';

import { billingConstants } from '../../../../../constants';

import s from './BillingTransactionsHistory.module.scss';
import { useDispatch } from 'react-redux';

const BillingTransactionsHistory = ({
  brandInfo,
  transactions,
  setDataArray,
  getBrandTransactionsAsync,
  brandBills,
}) => {
  const t = useT();

  const dispatch = useDispatch();

  return (
    <div className={s['transactions']}>
      <h2 className={s['transactions__title']}>{t('Transaction History')}</h2>

      <div className={s['transactions__table']}>
        {transactions?.data?.length ? (
          <TableBilling
            headers={
              brandInfo.isInvoicePartner
                ? billingConstants.invoicePartnerTransactionsHistoryTableHeaders
                : billingConstants.transactionsHistory.tableHeaders
            }
            tableData={transactions}
            content="transactions history"
            isInvoicePartner={brandInfo.isInvoicePartner}
            setDataArray={(val) => dispatch(setDataArray(val))}
            itemsPerPageOptions={billingConstants.itemsPerPage}
            getBrandTransactionsAsync={(val) => dispatch(getBrandTransactionsAsync(val))}
            currencySymbol={brandInfo.country.currencySymbol}
            brandInfo={brandInfo}
            brandBills={brandBills}
          />
        ) : (
          <div className={s['transactions__no-data']}>
            {t('{noTransactionData}', {
              noTransactionData: t(billingConstants.transactionsHistory.noData),
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingTransactionsHistory;
