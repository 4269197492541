import React, { useMemo, useRef, useCallback, useEffect } from 'react';

import classNames from 'classnames/bind';
import { useT } from '@transifex/react';

import { SvgIcon } from '../common/SvgIcon';
import { MiniModal } from '../common/MiniModal';
import s from './PaymentCard.module.scss';

const cx = classNames.bind(s);

const PaymentCard = ({
  miniModalOpen,
  setMiniModalOpen,
  makeMethodPrimary,
  removePaymentCard,
  cardBrand,
  paymentMethodId,
  lastFourDigits,
  primary,
  expiration,
  isDefault,
  cardType,
}) => {
  const t = useT();

  const cardClassNames = useMemo(
    () =>
      cx({
        [s['card']]: true,
        [s['active']]: primary,
        [s['card-default']]: true,
        [s['card-navy']]: cardBrand === 'visa',
        [s['card-green']]: cardBrand === 'amex',
        [s['card-red']]: cardBrand === 'mastercard',
      }),
    [cardBrand, primary]
  );

  const editModalRef = useRef(null);
  const modalRef = useRef(null);

  const handleModalOpen = useCallback(
    (e) => {
      e.stopPropagation();

      setMiniModalOpen(`${paymentMethodId}`);
    },
    [setMiniModalOpen, paymentMethodId]
  );

  const handleModalClose = useCallback(
    (e) => {
      e.preventDefault();
      const { target } = e;

      if (modalRef.current && !modalRef.current.contains(target)) {
        setMiniModalOpen('');
      }
    },
    [setMiniModalOpen, modalRef]
  );

  const handleFirstItemActions = useCallback(
    (e) => {
      e.stopPropagation();

      makeMethodPrimary({ paymentMethodId });
      setMiniModalOpen('');
    },
    [makeMethodPrimary, setMiniModalOpen, paymentMethodId]
  );

  const handleSecondItemActions = useCallback(
    (e) => {
      e.stopPropagation();

      removePaymentCard({ paymentMethodId });
      setMiniModalOpen('');
    },
    [removePaymentCard, setMiniModalOpen, paymentMethodId]
  );

  useEffect(() => {
    miniModalOpen.length > 0 && document.addEventListener('click', handleModalClose, true);
    return () => document.removeEventListener('click', handleModalClose, true);
  }, [handleModalClose, miniModalOpen]);

  return (
    <div className={cardClassNames}>
      <div className={s['card-type']}>
        <SvgIcon name={cardBrand} />
      </div>
      <div className={s['card-title']}>
        {cardType !== 'bacs_debit' ? t('Card Number') : t('Bank Account')}
      </div>
      <div className={s['card-number']}>•••• •••• •••• {lastFourDigits}</div>

      <div className={s['card-footer']}>
        <div className={s['card-expiration']}>
          {cardType !== 'bacs_debit' ? (
            <>
              <div className={s['card-title']}>{t('Expiration')}</div>
              <div className={s['card-number']}>{expiration}</div>
            </>
          ) : null}
        </div>
        <div
          ref={editModalRef}
          id={paymentMethodId}
          className={s['card-footer__edit-icon']}
          onClick={(e) => handleModalOpen(e)}
        >
          <SvgIcon name="edit-card" />
          <MiniModal
            modalRef={modalRef}
            isShowing={miniModalOpen === `${paymentMethodId}`}
            firstItem={t('Make Primary')}
            secondItem={t('Remove Method')}
            firsCallback={handleFirstItemActions}
            secondCallback={handleSecondItemActions}
            isNotActive={isDefault}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
