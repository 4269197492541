import { createReducer, initialAsyncState } from 'eight.js.store-common';

import {
  activateSponsorshipSetFromCreationAsync,
  clearSponsorshipSet,
  deactivateSponsorshipSetFromCreationAsync,
  setDataArray,
  setSponsorshipSetIsReadOnly,
  setSponsorshipSetInternalFetching,
  toggleActivateSponsorshipSet,
  updateSponsorshipSetAsync,
  setSponsorshipSetData,
  setCustomAudiencesTags,
  setActiveAdSet,
  setSponsorshipSetName,
  fetchAdSetPricingOptionsAsync,
} from './actions';
import { sponsorshipSetConstants } from '../../../constants';

const initialState = {
  ...initialAsyncState,
  sponsorshipSetCreate: sponsorshipSetConstants.initialState,
  sponsorshipSetEdit: {
    id: '',
  },
  error: '',
  fetching: false,
};

export const sponsorshipSetReducer = createReducer('@@sponsorshipSet', initialState, {
  [setActiveAdSet]: ({ state, action }) => {
    // Remove dirtyFields and isDirty fields from object
    const { dirtyFields, isDirty, customAudiencesTags, ...sponsorshipSet } =
      state.sponsorshipSetCreate;
    state.sponsorshipSetCreate = { ...sponsorshipSet, ...action.payload, customAudiencesTags };
  },

  [setCustomAudiencesTags]: ({ state, action }) => {
    state.sponsorshipSetCreate.customAudiencesTags = action.payload;
  },
  [setSponsorshipSetData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipSetCreate[name] = value;
  },
  [activateSponsorshipSetFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [clearSponsorshipSet]: ({ state }) => {
    state.sponsorshipSetCreate = initialState.sponsorshipSetCreate;
    state.sponsorshipSetEdit = initialState.sponsorshipSetEdit;
  },

  [deactivateSponsorshipSetFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },
  [setDataArray]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipSetCreate = {
      ...state.sponsorshipSetCreate,
      [name]: value,
    };
  },

  [setSponsorshipSetInternalFetching]: ({ state, action }) => {
    state.sponsorshipSetCreate.internalFetching = action.payload;
  },

  [updateSponsorshipSetAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },
  [setSponsorshipSetIsReadOnly]: ({ state, action }) => {
    state.sponsorshipSetCreate.isReadOnly = action.payload;
  },

  [toggleActivateSponsorshipSet]: ({ state, action }) => {
    state.sponsorshipSetCreate.isActive = action.payload;
  },
  [updateSponsorshipSetAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },
  [setSponsorshipSetName]: ({ state, action }) => {
    state.sponsorshipSetCreate.name = action.payload;
  },
  [fetchAdSetPricingOptionsAsync.success]: ({ state, action }) => {
    state.sponsorshipSetCreate.videoAdPricingData = action.payload.pricingList;
  },
});
