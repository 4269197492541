import React from 'react';

import { ReactComponent as Home } from '../../../assets/images/icons/home.svg';
import { ReactComponent as Create } from '../../../assets/images/icons/create-campaign.svg';
import { ReactComponent as Manage } from '../../../assets/images/icons/manage-campaign.svg';
import { ReactComponent as Reporting } from '../../../assets/images/icons/reporting.svg';
import { ReactComponent as Settings } from '../../../assets/images/icons/settings.svg';
import { ReactComponent as Burger } from '../../../assets/images/icons/burger.svg';
import { ReactComponent as Notification } from '../../../assets/images/icons/notification.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/images/icons/arrow-left.svg';
import { ReactComponent as Horn } from '../../../assets/images/icons/horn.svg';
import { ReactComponent as ArrowTopLeft } from '../../../assets/images/icons/arrow-top-left.svg';
import { ReactComponent as Media } from '../../../assets/images/icons/media.svg';
import { ReactComponent as Phone } from '../../../assets/images/icons/phone.svg';
import { ReactComponent as People } from '../../../assets/images/icons/people.svg';
import { ReactComponent as FileType } from '../../../assets/images/icons/file-type.svg';
import { ReactComponent as AspectRatio } from '../../../assets/images/icons/aspect-ratio.svg';
import { ReactComponent as Resolution } from '../../../assets/images/icons/resolution.svg';
import { ReactComponent as Upload } from '../../../assets/images/icons/upload.svg';
import { ReactComponent as StatusWarning } from '../../../assets/images/icons/status-warning.svg';
import { ReactComponent as StatusOk } from '../../../assets/images/icons/status-ok.svg';
import { ReactComponent as Plus } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as Minus } from '../../../assets/images/icons/minus.svg';
import { ReactComponent as PaginationLeft } from '../../../assets/images/icons/pagination-left.svg';
import { ReactComponent as PaginationRight } from '../../../assets/images/icons/pagination-right.svg';
import { ReactComponent as Performance } from '../../../assets/images/icons/performance.svg';
import { ReactComponent as Demographics } from '../../../assets/images/icons/demographics.svg';
import { ReactComponent as HappyFace } from '../../../assets/images/icons/happy-face.svg';
import { ReactComponent as Medal } from '../../../assets/images/icons/medal.svg';
import { ReactComponent as MedalYellow } from '../../../assets/images/icons/yellow-medal.svg';
import { ReactComponent as CSVDownload } from '../../../assets/images/icons/csv-download.svg';
import { ReactComponent as PDFDownload } from '../../../assets/images/icons/pdf-download.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/icons/arrow-right.svg';
import { ReactComponent as Calendar } from '../../../assets/images/icons/calendar.svg';
import { ReactComponent as Gender } from '../../../assets/images/icons/gender.svg';
import { ReactComponent as Link } from '../../../assets/images/icons/link.svg';
import { ReactComponent as Mobile } from '../../../assets/images/icons/mobile.svg';
import { ReactComponent as Money } from '../../../assets/images/icons/money.svg';
import { ReactComponent as Percentage } from '../../../assets/images/icons/percentage.svg';
import { ReactComponent as Person } from '../../../assets/images/icons/person.svg';
import { ReactComponent as Pin } from '../../../assets/images/icons/pin.svg';
import { ReactComponent as Tag } from '../../../assets/images/icons/tag.svg';
import { ReactComponent as ArrowUpDown } from '../../../assets/images/icons/arrow-up-down.svg';
import { ReactComponent as CheckCircle } from '../../../assets/images/icons/check-circle.svg';
import { ReactComponent as NotificationsClose } from '../../../assets/images/icons/notifications-close.svg';
import { ReactComponent as Visa } from '../../../assets/images/icons/visa.svg';
import { ReactComponent as VisaRadio } from '../../../assets/images/icons/visa-radio.svg';
import { ReactComponent as AmericanExpress } from '../../../assets/images/icons/american-express.svg';
import { ReactComponent as AmericanExpressRadio } from '../../../assets/images/icons/american-express-radio.svg';
import { ReactComponent as EditCard } from '../../../assets/images/icons/edit-card.svg';
import { ReactComponent as BigPlus } from '../../../assets/images/icons/plus-big.svg';
import { ReactComponent as MasterCard } from '../../../assets/images/icons/master-card.svg';
import { ReactComponent as MasterCardRadio } from '../../../assets/images/icons/mastercard-radio.svg';
import { ReactComponent as CreditCard } from '../../../assets/images/icons/credit-card.svg';
import { ReactComponent as DatePicker } from '../../../assets/images/icons/date-picker.svg';
import { ReactComponent as CO2 } from '../../../assets/images/icons/co2.svg';
import { ReactComponent as Tree } from '../../../assets/images/icons/tree.svg';
import { ReactComponent as Close } from '../../../assets/images/icons/close-circle.svg';
import { ReactComponent as PlayButton } from '../../../assets/images/icons/play-button.svg';
import { ReactComponent as Copy } from '../../../assets/images/icons/copy.svg';
import { ReactComponent as Eye } from '../../../assets/images/icons/eye.svg';
import { ReactComponent as EyeOff } from '../../../assets/images/icons/eye-off.svg';
import { ReactComponent as Search } from '../../../assets/images/icons/lupa.svg';
import { ReactComponent as Archive } from '../../../assets/images/icons/archive.svg';
import { ReactComponent as Unarchive } from '../../../assets/images/icons/unarchive.svg';
import { ReactComponent as X } from '../../../assets/images/icons/x.svg';
import { ReactComponent as GiftPreview } from '../../../assets/images/icons/gift-preview.svg';
import { ReactComponent as SettingsPreview } from '../../../assets/images/icons/settings-preview.svg';
import { ReactComponent as HappyFacePreview } from '../../../assets/images/icons/happy-face-preview.svg';
import { ReactComponent as DonatePreview } from '../../../assets/images/icons/donate-preview.svg';
import { ReactComponent as Logout } from '../../../assets/images/icons/logout.svg';
import { ReactComponent as BurgerArrowRight } from '../../../assets/images/icons/burger-arrow-right.svg';
import { ReactComponent as BurgerArrowLeft } from '../../../assets/images/icons/burger-arrow-left.svg';
import { ReactComponent as DefaultMenuOptions } from '../../../assets/images/icons/menu-options-default.svg';
import { ReactComponent as CreateAd } from '../../../assets/images/icons/create-ad.svg';
import { ReactComponent as DuplicateAd } from '../../../assets/images/icons/duplicate-ad.svg';
import { ReactComponent as DeleteAd } from '../../../assets/images/icons/delete-ad.svg';
import { ReactComponent as Expand } from '../../../assets/images/icons/expand.svg';
import { ReactComponent as AdSet } from '../../../assets/images/icons/ad-set.svg';
import { ReactComponent as Ad } from '../../../assets/images/icons/ad.svg';
import { ReactComponent as Important } from '../../../assets/images/icons/important.svg';
import { ReactComponent as Warning } from '../../../assets/images/icons/warning.svg';
import { ReactComponent as AccountVerified } from '../../../assets/images/icons/account-verified.svg';
import { ReactComponent as AccountPending } from '../../../assets/images/icons/account-pending.svg';
import { ReactComponent as AccountUnverified } from '../../../assets/images/icons/account-unverified.svg';
import { ReactComponent as Document } from '../../../assets/images/icons/document.svg';
import { ReactComponent as CheckboxFill } from '../../../assets/images/icons/checkbox-fill.svg';
import { ReactComponent as Progress } from '../../../assets/images/icons/progress.svg';
import { ReactComponent as Clear } from '../../../assets/images/icons/close.svg';
import { ReactComponent as Completed } from '../../../assets/images/icons/completed.svg';
import { ReactComponent as Retry } from '../../../assets/images/icons/retry.svg';

const Svg = ({ name }) => {
  switch (name) {
    case 'co2':
      return <CO2 />;
    case 'tree':
      return <Tree />;
    case 'home':
      return <Home />;
    case 'burger':
      return <Burger />;
    case 'notification':
      return <Notification />;
    case 'create-campaign':
      return <Create />;
    case 'manage-campaign':
      return <Manage />;
    case 'reporting':
      return <Reporting />;
    case 'settings':
      return <Settings />;
    case 'arrow-left':
      return <ArrowLeft />;
    case 'arrow-top-left':
      return <ArrowTopLeft />;
    case 'media':
      return <Media />;
    case 'phone':
      return <Phone />;
    case 'people':
      return <People />;
    case 'horn':
      return <Horn />;
    case 'file-type':
      return <FileType />;
    case 'aspect-ratio':
      return <AspectRatio />;
    case 'resolution':
      return <Resolution />;
    case 'upload':
      return <Upload />;
    case 'status-warning':
      return <StatusWarning />;
    case 'status-ok':
      return <StatusOk />;
    case 'plus':
      return <Plus />;
    case 'minus':
      return <Minus />;
    case 'pagination-left':
      return <PaginationLeft />;
    case 'pagination-right':
      return <PaginationRight />;
    case 'performance':
      return <Performance />;
    case 'demographics':
      return <Demographics />;
    case 'happy-face':
      return <HappyFace />;
    case 'medal':
      return <Medal />;
    case 'medal-yellow':
      return <MedalYellow />;
    case 'csv-download':
      return <CSVDownload />;
    case 'pdf-download':
      return <PDFDownload />;
    case 'arrow-right':
      return <ArrowRight />;
    case 'calendar':
      return <Calendar />;
    case 'gender':
      return <Gender />;
    case 'link':
      return <Link />;
    case 'mobile':
      return <Mobile />;
    case 'money':
      return <Money />;
    case 'percentage':
      return <Percentage />;
    case 'person':
      return <Person />;
    case 'pin':
      return <Pin />;
    case 'tag':
      return <Tag />;
    case 'arrow-up-down':
      return <ArrowUpDown />;
    case 'check-circle':
      return <CheckCircle />;
    case 'notifications-close':
      return <NotificationsClose />;
    case 'visa':
      return <Visa />;
    case 'amex':
      return <AmericanExpress />;
    case 'edit-card':
      return <EditCard />;
    case 'plus-big':
      return <BigPlus />;
    case 'mastercard':
      return <MasterCard />;
    case 'mastercard-radio':
      return <MasterCardRadio />;
    case 'visa-radio':
      return <VisaRadio />;
    case 'amex-radio':
      return <AmericanExpressRadio />;
    case 'credit-card':
      return <CreditCard />;
    case 'date-picker':
      return <DatePicker />;
    case 'close-circle':
      return <Close />;
    case 'play-btn':
      return <PlayButton />;
    case 'eye':
      return <Eye />;
    case 'eye-off':
      return <EyeOff />;
    case 'copy':
      return <Copy />;
    case 'search':
      return <Search />;
    case 'archive':
      return <Archive />;
    case 'unarchive':
      return <Unarchive />;
    case 'x':
      return <X />;
    case 'settings-preview':
      return <SettingsPreview />;
    case 'gift-preview':
      return <GiftPreview />;
    case 'donate-preview':
      return <DonatePreview />;
    case 'happy-face-preview':
      return <HappyFacePreview />;
    case 'logout':
      return <Logout />;
    case 'burgerRight':
      return <BurgerArrowRight />;
    case 'burgerLeft':
      return <BurgerArrowLeft />;
    case 'defaultMenuOptions':
      return <DefaultMenuOptions />;
    case 'create-ad':
      return <CreateAd />;
    case 'duplicate-ad':
      return <DuplicateAd />;
    case 'delete-ad':
      return <DeleteAd />;
    case 'expand':
      return <Expand />;
    case 'adset':
      return <AdSet />;
    case 'ad':
      return <Ad />;
    case 'important':
      return <Important />;
    case 'warning':
      return <Warning />;
    case 'account-verified':
      return <AccountVerified />;
    case 'account-unverified':
      return <AccountUnverified />;
    case 'account-pending':
      return <AccountPending />;
    case 'document':
      return <Document />;
    case 'checkbox-fill':
      return <CheckboxFill />;
    case 'progress':
      return <Progress />;
    case 'clear':
      return <Clear />;
    case 'completed':
      return <Completed />;
    case 'retry':
      return <Retry />;
    default:
      return null;
  }
};

export default Svg;
