import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import s from './ReportingHero.module.scss';

import reportingPeopleImage from '../../../../assets/images/reporting-people.png';
import reportingPurposeImage from '../../../../assets/images/reporting-purpose.png';
import reportingPlanetImage from '../../../../assets/images/reporting-planet.png';

const cx = classNames.bind(s);

const ReportingHero = ({ children, type, reverse, textContent, detailContent }) => {
  const wrapperClassNames = useMemo(
    () =>
      cx({
        [s['reporting__hero']]: true,
        'reporting__hero-reverse': reverse,
      }),
    [reverse]
  );

  const imageWrapperClassNames = useMemo(
    () =>
      cx({
        [s['image-wrapper']]: true,
        [s['image-wrapper__reverse']]: reverse,
      }),
    [reverse]
  );

  const image = useMemo(() => {
    if (type === 'people') return reportingPeopleImage;
    else if (type === 'purpose') return reportingPurposeImage;
    else if (type === 'planet') return reportingPlanetImage;
  }, [type]);

  return (
    <div className={wrapperClassNames}>
      <div className={s['content']}>
        <h1 className={s['content--title']}>{textContent}</h1>
        {detailContent && <div className={s['content--subtitle']}>{detailContent}</div>}
        {children && <div className={s['content--value']}>{children}</div>}
      </div>
      <div className={imageWrapperClassNames}>
        <div className={s['image-block']}>
          <img className={s['image']} alt="hero" src={image} />
        </div>
      </div>
    </div>
  );
};

export default ReportingHero;
