import React from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { formatDateUTC, setBrandDateFormat } from '../../../utils/receivedData';

import { COLORS } from '../colors';

const Line = ({ acceptanceOverTime, countryCode }) => {
  Highcharts.seriesTypes.spline.prototype.drawLegendSymbol =
    Highcharts.seriesTypes.area.prototype.drawLegendSymbol;

  const options = {
    title: false,
    chart: {
      type: 'spline',
    },
    colors: [COLORS.Green, COLORS.Blue, COLORS.Red, COLORS.Yellow, COLORS.Brown],
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        marker: {
          enabled: false,
        },
      },
    },
    xAxis: {
      type: 'datetime',
      lineWidth: 0,
      tickWidth: 0,
      categories: acceptanceOverTime.xAxis.categories,
      crosshair: true,
      labels: {
        formatter() {
          return setBrandDateFormat(new Date(this.value), countryCode);
        },
      },
    },
    yAxis: {
      gridLineDashStyle: 'Dash',
      title: false,
      labels: {
        style: {
          color: 'rgb(180, 180, 180)',
        },
      },
    },
    series: acceptanceOverTime.series?.map(({ name, data }) => ({
      marker: {
        symbol: 'circle',
      },
      name,
      data,
    })),

    tooltip: {
      backgroundColor: COLORS.White,
      padding: 10,
      useHTML: true,
      formatter: function () {
        return this.points.reduce(function (s, point) {
          return (
            s +
            `<div class="highchart-spline__tooltip-row">
                        <div class="highchart-spline__tooltip-row__circle" style="background-color: ${point.series.color}"></div>
                        <div class="highchart-spline__tooltip-row__content">${point.series.name}: ${point.y}</div>
                      </div>`
          );
        }, `<div class="highchart-spline__tooltip-header">${formatDateUTC(
          new Date(this?.x).toISOString(),
          'LLLL d'
        )}</div>`);
      },
      borderWidth: 0,
      shared: true,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} immutable={true} />;
};

export default Line;
