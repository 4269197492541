import React, { useCallback, useEffect } from 'react';
import { SvgIcon } from '../common/SvgIcon';
import { Spinner } from '../Spinner';
import s from './Tracking.module.scss';
import { useTrackingScript } from '../../hooks/useTrackingScript';
import { Helmet } from 'react-helmet';
import { htmlStringToElement } from '../../utils/formatting';
import { Button } from '../common/Button';
import { useT } from '@transifex/react';

export default function Tracking() {
  const [loaded, error, noScript, src] = useTrackingScript();
  const t = useT();

  useEffect(() => {
    if (noScript) {
      document.body.appendChild(htmlStringToElement(noScript));
      return () => document.body.removeChild(htmlStringToElement(noScript));
    }
  }, [noScript]);

  const handlePageClose = useCallback(() => {
    window.close();
  }, []);

  return (
    <>
      <Helmet>{!noScript && <script language="JavaScript1.1" src={src}></script>}</Helmet>
      <div className={s['content-wrapper']}>
        <div className={s['content']}>
          {!loaded && !error && (
            <>
              <div className={s['spinner-container']}>
                <Spinner position="relative" height="auto" />
              </div>
              <span className={s['details']}>{t('Test tracking event in progress')}...</span>
            </>
          )}
          {loaded && !error && (
            <>
              <div className={s['svg-container']}>
                <SvgIcon name="status-ok" />
              </div>
              <span className={s['details']}>
                {t(
                  "Tracking event sent. Please check your ad's provider dashboard to confirm that you have a new event. You can close this tab now."
                )}
              </span>
              <div className={s['buttons-container']}>
                <Button
                  type="buttton"
                  customText={t('Close Tab')}
                  styling="primary"
                  callback={handlePageClose}
                />
              </div>
            </>
          )}
          {error && (
            <>
              <div className={s['svg-container']}>
                <SvgIcon name="status-warning" />
              </div>
              <span className={s['details']}>{t('No tracking script provided.')}</span>
            </>
          )}
        </div>
      </div>
    </>
  );
}
