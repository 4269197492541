import React, { useMemo } from 'react';

import s from './FormGroup.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(s);

const FormGroup = ({ children, title, sideTitle, smalltitle, subtitle, required, customClass }) => {
  const fieldClassNames = useMemo(
    () =>
      cx(customClass, {
        [s['form-group']]: true,
      }),
    [customClass]
  );
  return (
    <div className={fieldClassNames}>
      <div className={s['form-group__field']}>
        <div className={s['form-group__field-title']}>
          <div>
            {title}
            {required ? <span className={s['required']}> *</span> : ''}
          </div>
          {sideTitle && <div>{sideTitle}</div>}
        </div>
        {smalltitle && <div className={s['form-group__field-smalltitle']}>{smalltitle}</div>}
        {subtitle && <div className={s['form-group__field-subtitle']}>{subtitle}</div>}
        <div className={s['form-group__field-content']}>{children}</div>
      </div>
    </div>
  );
};

export default FormGroup;
