import * as yup from 'yup';
import { t } from '@transifex/native';

import { EMAIL_REGEXP } from '../../../utils/validate';

export const validationSchema = yup.object().shape({
  loginEmail: yup
    .string()
    .matches(EMAIL_REGEXP, t('Email field should be in e-mail format'))
    .required(t('Email is required')),
  loginPassword: yup.string().required(t('Password is required')),
});
