import React from 'react';

import s from './Label.module.scss';

const Label = ({ value, label, id, name, 'data-testid': testId }) => {
  return (
    <div className={s['input-field']} data-testid={testId} id={id} type="text" name={name}>
      <label htmlFor={id} className={s['input-field__label']}>
        {label}
      </label>
      <div className={s['input-field__text']}>{value}</div>
    </div>
  );
};

export default Label;
