import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import { useT } from '@transifex/react';

import { Button } from '../common/Button';

import s from './StepButtons.module.scss';
import { constructTestId } from '../../utils/test-ids';

const cx = classNames.bind(s);

const StepButtons = ({
  'data-testid': SECTION,
  handlePreviousStep,
  saving = false,
  customText,
  styling,
  isSubmitDisabled,
}) => {
  const t = useT();
  const continueButtonElementClassNames = useMemo(
    () =>
      cx({
        [s['buttons__continue']]: true,
        [s['buttons__continue--review']]: styling === 'review',
      }),
    [styling]
  );

  return (
    <div className={s['buttons']}>
      <div className={s['buttons__back']}>
        <Button
          data-testid={constructTestId(SECTION, 'back-btn')}
          type="button"
          callback={handlePreviousStep}
          name={t('Back')}
          styling="secondary"
          fullwidth
        />
      </div>
      <div className={continueButtonElementClassNames}>
        <Button
          data-testid={constructTestId(SECTION, 'continue-btn')}
          customText={customText}
          type="submit"
          name={t('Save and Continue')}
          styling="primary"
          fullwidth
          disabled={saving || isSubmitDisabled}
        />
      </div>
    </div>
  );
};

export default StepButtons;
