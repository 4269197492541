import { createSelector } from 'reselect';

const sponsorshipStateSelector = (state) => state.sponsorship;

export const sponsorshipCreateSelector = createSelector(
  sponsorshipStateSelector,
  (state) => state.sponsorshipCreate
);

export const sponsorshipIsActiveSelector = createSelector(
  sponsorshipCreateSelector,
  (state) => state.isActive
);

export const sponsorshipUploadStatusSelector = createSelector(
  sponsorshipCreateSelector,
  (state) => state.videoUploadStatus
);

export const sponsorshipIdSelector = createSelector(sponsorshipCreateSelector, (state) => state.id);

export const internalFetching = createSelector(
  sponsorshipCreateSelector,
  (state) => state.internalFetching
);

export const updateSponsorshipSelector = createSelector(
  sponsorshipStateSelector,
  (state) => state.updatingSponsorship
);

export const sponsorshipEditSelector = createSelector(
  sponsorshipStateSelector,
  (state) => state.sponsorshipEdit
);

export const sponsorshipEditIdSelector = createSelector(
  sponsorshipEditSelector,
  (state) => state.id
);

export const sponsorshipFetchingSelector = createSelector(
  sponsorshipStateSelector,
  (state) => state.fetching
);
