import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  checkForCSVCompletionAsync,
  createPerformanceReportingCSVAsync,
  demographicsFilterRequest,
  fetchAnalyticsCampaignAsync,
  fetchAnalyticsSponsorshipAsync,
  fetchAnalyticsSponsorshipSetAsync,
  fetchFilteredAcceptanceOverTimeAsync,
  fetchFilteredDemographicsAsync,
  fetchFilteredResponsesAsync,
  fetchFilteredShortAnswerResponsesAsync,
  fetchFilteredTotalsAsync,
  fetchHeatMapAsync,
  getCSVDataAsync,
  overviewFilterRequest,
  setCSVDataDefault,
  setInitialFetchedData,
  setIsResponsesCSVLoading,
  toggleIsLoadingCSVData,
  updateDownloadPercentage,
} from './actions';

import { csvRequestIdSelector, isResponsesCSVLoadingSelector } from './selectors';

import { container } from '../../container';

import {
  formatAcceptanceOverTimeResponse,
  processAnalyticsDemographics,
  processFilteringBodyParams,
  processReceivedResponses,
  processReceivedShortAnswers,
  processReportingGenderOptions,
  processReportingQuestions,
  transformSeries,
} from '../../utils/receivedData';

import { formatTimezonedDateToUTC } from '../../utils/date/dateHelpers';
import {
  processReportingAgeOptions,
  processReportingCitiesOptions,
  processReportingOsOptions,
  setDemographicCsvDataType,
} from '../../utils/reporting';
import { brandInfoSelector } from '../brand';

const analyticsService = container.get('AnalyticsService');

function* processOverviewFilterRequest({ payload }) {
  try {
    const { campaignId, adSetId, adId, type } = payload;
    const { selectedGender, selectedDevice, selectedCity, ageFrom, ageTo, fromDate, toDate } =
      payload.query;
    const brandInfo = yield select(brandInfoSelector);

    const bodyParams = processFilteringBodyParams({
      campaignId,
      adSetId,
      adId,
      type,
      gender: selectedGender,
      platform: selectedDevice,
      city: selectedCity,
      fromAge: ageFrom,
      toAge: ageTo,
      fromDate: formatTimezonedDateToUTC(fromDate, brandInfo.timezoneId),
      toDate: formatTimezonedDateToUTC(toDate, brandInfo.timezoneId),
      brandId: brandInfo?.brandIdFromAdmin,
    });

    yield put(fetchFilteredAcceptanceOverTimeAsync.request(bodyParams));
    yield put(fetchFilteredDemographicsAsync.request(bodyParams));
    yield put(fetchFilteredResponsesAsync.request(bodyParams));
    yield put(fetchFilteredShortAnswerResponsesAsync.request(bodyParams));
    yield put(fetchFilteredTotalsAsync.request(bodyParams));
    yield put(fetchHeatMapAsync.request(bodyParams));
  } catch (error) {
    console.log(error);
  }
}

function* processDemographicsFilterRequest({ payload }) {
  try {
    const { campaignId, adSetId, adId, type } = payload;
    const { gender, platform, city, fromAge, toAge, fromDate, toDate, activity, responseFilter } =
      payload.query;
    const brandInfo = yield select(brandInfoSelector);

    const bodyParams = processFilteringBodyParams({
      campaignId,
      adSetId,
      adId,
      type,
      gender,
      platform,
      city,
      fromAge,
      toAge,
      fromDate: formatTimezonedDateToUTC(fromDate, brandInfo.timezoneId),
      toDate: formatTimezonedDateToUTC(toDate, brandInfo.timezoneId),
      activity,
      responseFilter,
      brandId: brandInfo?.brandIdFromAdmin,
    });

    yield put(fetchFilteredAcceptanceOverTimeAsync.request(bodyParams));
    yield put(fetchFilteredDemographicsAsync.request(bodyParams));
    yield put(fetchFilteredResponsesAsync.request(bodyParams));
    yield put(fetchFilteredShortAnswerResponsesAsync.request(bodyParams));
    yield put(fetchFilteredTotalsAsync.request(bodyParams));
  } catch (error) {
    console.log(error);
  }
}

function* fetchFilteredAcceptanceOverTime({ payload }) {
  try {
    const response = yield call(analyticsService.fetchAcceptanceOverTime, {
      campaignId: payload.campaignId,
      adSetId: payload.adSetId,
      adId: payload.adId,
      type: payload.type,
      gender: payload.gender,
      platform: payload.platform,
      city: payload.city,
      fromAge: payload.fromAge,
      toAge: payload.toAge,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      brandId: payload.brandId,
    });

    const formattedResponse = formatAcceptanceOverTimeResponse(response);

    yield put(fetchFilteredAcceptanceOverTimeAsync.success(formattedResponse));
  } catch (error) {
    yield put(fetchFilteredAcceptanceOverTimeAsync.failure(error));
  }
}

function* fetchFilteredDemographics({ payload }) {
  try {
    const response = yield call(analyticsService.fetchDemographics, {
      campaignId: payload.campaignId,
      adSetId: payload.adSetId,
      adId: payload.adId,
      type: payload.type,
      gender: payload.gender,
      platform: payload.platform,
      city: payload.city,
      fromAge: payload.fromAge,
      toAge: payload.toAge,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      activity: payload.activity,
      responseFilter: payload.responseFilter,
      brandId: payload.brandId,
    });

    const transformedData = processAnalyticsDemographics(response);

    const { influencersByAge, influencersByGender, influencersByCityName, influencersByPlatform } =
      transformedData;

    const agesOptions = processReportingAgeOptions(influencersByAge);
    const gendersOptions = processReportingGenderOptions(influencersByGender);
    const osOptions = processReportingOsOptions(influencersByPlatform);
    const citiesOptions = processReportingCitiesOptions(influencersByCityName);

    yield put(setInitialFetchedData({ name: 'agesOptions', value: agesOptions }));
    yield put(setInitialFetchedData({ name: 'gendersOptions', value: gendersOptions }));
    yield put(setInitialFetchedData({ name: 'osOptions', value: osOptions }));
    yield put(setInitialFetchedData({ name: 'citiesOptions', value: citiesOptions }));

    yield put(fetchFilteredDemographicsAsync.success(transformedData));
  } catch (error) {
    yield put(fetchFilteredDemographicsAsync.failure(error));
  }
}

function* fetchFilteredResponses({ payload }) {
  try {
    const response = yield call(analyticsService.fetchResponses, {
      campaignId: payload.campaignId,
      adSetId: payload.adSetId,
      adId: payload.adId,
      type: payload.type,
      gender: payload.gender,
      platform: payload.platform,
      city: payload.city,
      fromAge: payload.fromAge,
      toAge: payload.toAge,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      brandId: payload.brandId,
    });

    const { resultQuestions, resultOptions } = processReportingQuestions(response);

    const transformedData = processReceivedResponses(response);

    yield put(
      setInitialFetchedData({
        name: 'responses',
        value: resultQuestions,
      })
    );
    yield put(
      setInitialFetchedData({
        name: 'responsesOptions',
        value: resultOptions,
      })
    );

    yield put(fetchFilteredResponsesAsync.success(transformedData));
  } catch (error) {
    yield put(fetchFilteredResponsesAsync.failure(error));
  }
}

function* fetchFilteredShortAnswersResponses({ payload }) {
  try {
    const response = yield call(analyticsService.fetchShortAnswerResponses, {
      campaignId: payload.campaignId,
      adSetId: payload.adSetId,
      adId: payload.adId,
      type: payload.type,
      gender: payload.gender,
      platform: payload.platform,
      city: payload.city,
      fromAge: payload.fromAge,
      toAge: payload.toAge,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      brandId: payload.brandId,
    });

    const transformedData = processReceivedShortAnswers(response);

    yield put(fetchFilteredShortAnswerResponsesAsync.success(transformedData));
  } catch (error) {
    yield put(fetchFilteredShortAnswerResponsesAsync.failure(error));
  }
}

function* fetchFilteredTotals({ payload }) {
  try {
    const response = yield call(analyticsService.fetchTotals, {
      campaignId: payload.campaignId,
      adSetId: payload.adSetId,
      adId: payload.adId,
      type: payload.type,
      gender: payload.gender,
      platform: payload.platform,
      city: payload.city,
      fromAge: payload.fromAge,
      toAge: payload.toAge,
      fromDate: payload.fromDate,
      toDate: payload.toDate,
      brandId: payload.brandId,
    });

    yield put(setInitialFetchedData({ name: 'initialTotals', value: response }));

    yield put(fetchFilteredTotalsAsync.success(response));
  } catch (error) {
    yield put(fetchFilteredTotalsAsync.failure(error));
  }
}

function* fetchHeatMap({ payload }) {
  try {
    const response = yield call(analyticsService.fetchHeatMap, payload);
    let { countryCode } = yield select(brandInfoSelector);
    const { heatMapByInfluencerCity } = response;
    const transformedData = heatMapByInfluencerCity
      ? transformSeries(heatMapByInfluencerCity[0], countryCode)
      : {};

    yield put(fetchHeatMapAsync.success(transformedData));
  } catch (err) {
    yield put(fetchHeatMapAsync.failure(err));
  }
}

function* fetchAnalyticsCampaign({ payload }) {
  try {
    const { campaignId } = payload;
    const response = yield call(analyticsService.fetchAnalyticsCampaign, campaignId);

    yield put(fetchAnalyticsCampaignAsync.success(response));
  } catch (err) {
    yield put(fetchAnalyticsCampaignAsync.failure(err));
  }
}

function* fetchAnalyticsSponsorship({ payload }) {
  try {
    const { adId } = payload;
    const response = yield call(analyticsService.fetchAnalyticsSponsorship, adId);

    yield put(fetchAnalyticsSponsorshipAsync.success(response));
  } catch (err) {
    yield put(fetchAnalyticsSponsorshipAsync.failure(err));
  }
}

function* fetchAnalyticsSponsorshipSet({ payload }) {
  try {
    const { adSetId } = payload;
    let { tiomezoneId } = yield select(brandInfoSelector);

    const response = yield call(analyticsService.fetchAnalyticsSponsorshipSet, {
      adSetId,
      utcString: tiomezoneId,
    });

    // const { startDate, endDate } = response;

    yield put(fetchAnalyticsSponsorshipSetAsync.success(response));

    // yield put(
    //   setOverviewFilterOption({
    //     name: 'dateFrom',
    //     value: startDate,
    //   })
    // );

    // yield put(
    //   setOverviewFilterOption({
    //     name: 'dateTo',
    //     value: endDate,
    //   })
    // );

    // yield put(
    //   setDemographicsFilterOption({
    //     name: 'demographicsDateFrom',
    //     value: startDate,
    //   })
    // );

    // yield put(
    //   setDemographicsFilterOption({
    //     name: 'demographicsDateTo',
    //     value: endDate,
    //   })
    // );
  } catch (err) {
    yield put(fetchAnalyticsSponsorshipSetAsync.failure(err));
  }
}

function* getCSVDataSaga({ payload }) {
  try {
    yield put(setIsResponsesCSVLoading(true));
    const { csvDataType, currentTab, brandId, campaignId, adSetId, adId, type } = payload;
    const { questionId } = payload;

    const { selectedGender, selectedDevice, selectedCity, ageFrom, ageTo } = payload.filters;

    const { gender, platform, city, fromAge, toAge, fromDate, toDate, activity } = payload.filters;

    const additionalData = brandId ? { brandId } : {};
    const brandInfo = yield select(brandInfoSelector);

    const bodyParams = processFilteringBodyParams({
      campaignId,
      adSetId,
      adId,
      type,
      gender: currentTab === 'demographicTab' ? gender : selectedGender,
      platform: currentTab === 'demographicTab' ? platform : selectedDevice,
      city: currentTab === 'demographicTab' ? city : selectedCity,
      fromAge: currentTab === 'demographicTab' ? fromAge : ageFrom,
      toAge: currentTab === 'demographicTab' ? toAge : ageTo,
      fromDate,
      toDate,
    });

    let queryParams = {
      campaignId: bodyParams.campaignId,
      adSetId: bodyParams.adSetId,
      adId: bodyParams.adId,
      type: bodyParams.type,
      gender: bodyParams.gender,
      platform: bodyParams.platform,
      city: bodyParams.city,
      fromAge: bodyParams.fromAge,
      toAge: bodyParams.toAge,
      fromDate: formatTimezonedDateToUTC(fromDate, brandInfo.timezoneId),
      toDate: formatTimezonedDateToUTC(toDate, brandInfo.timezoneId),
      paginationId: '',
      csvDataType:
        currentTab === 'demographicTab' ? setDemographicCsvDataType(activity?.value) : csvDataType,
      ...additionalData,
    };

    if (questionId) queryParams = { ...queryParams, questionId };

    let response;

    if (csvDataType === 'ShortAnswer')
      response = yield call(analyticsService.fetchAnsweredQuestionDataForCSV, queryParams);
    else response = yield call(analyticsService.fetchDataForCSV, queryParams);

    const pages = response.Pages;
    let combineData = response.Data;

    // Removing some attributes from each row
    const Data = response.Data.map((row) => {
      const { InfluencerId, DeviceId, AdvertiserId, ...data } = row;
      return data;
    });

    combineData = Data;

    const isCSVResponsesLoading = yield select(isResponsesCSVLoadingSelector);

    if (pages > 1) {
      for (let i = 2; i <= pages; i++) {
        const isCSVResponsesLoadingInCircle = yield select(isResponsesCSVLoadingSelector);

        if (!isCSVResponsesLoadingInCircle) {
          combineData = [];
          yield put(setCSVDataDefault());
          break;
        }

        queryParams = {
          ...queryParams,
          paginationId: response.PaginationId,
        };

        response = yield call(analyticsService.fetchAnsweredQuestionDataForCSV, queryParams);

        // Removing some attributes from each row
        const Data = response.Data.map((row) => {
          const { InfluencerId, DeviceId, AdvertiserId, ...data } = row;
          return data;
        });

        combineData = [...combineData, ...Data];

        yield put(updateDownloadPercentage(((i / pages) * 100).toFixed()));
      }
    } else {
      yield put(updateDownloadPercentage(100));
    }

    if (!isCSVResponsesLoading) {
      combineData = [];
      yield put(setCSVDataDefault());
    }

    yield put(getCSVDataAsync.success(combineData));
    yield put(setIsResponsesCSVLoading(false));
    yield put(updateDownloadPercentage(0));
  } catch (err) {
    yield put(getCSVDataAsync.failure(err));
    yield put(setIsResponsesCSVLoading(false));
    yield put(updateDownloadPercentage(0));
    yield put(setCSVDataDefault());
  }
}

function* createPerformanceReportingCSVSaga({ payload }) {
  try {
    yield put(toggleIsLoadingCSVData(true));

    const {
      campaignId,
      adSetId,
      adId,
      filters,
      csvDataType: performanceReportEventType,
      type,
    } = payload;

    const { selectedGender, selectedDevice, selectedCity, ageFrom, ageTo, fromDate, toDate } =
      filters;

    const brandInfo = yield select(brandInfoSelector);

    const bodyParams = processFilteringBodyParams({
      campaignId,
      adSetId,
      adId,
      gender: selectedGender,
      platform: selectedDevice,
      city: selectedCity,
      fromAge: ageFrom,
      toAge: ageTo,
      fromDate: formatTimezonedDateToUTC(fromDate, brandInfo.timezoneId),
      toDate: formatTimezonedDateToUTC(toDate, brandInfo.timezoneId),
    });

    let queryParams = {
      id: bodyParams.id,
      gender: bodyParams.gender,
      platform: bodyParams.platform,
      city: bodyParams.city,
      fromAge: bodyParams.fromAge,
      toAge: bodyParams.toAge,
      fromDate: bodyParams.fromDate,
      toDate: bodyParams.toDate,
      includeAnswersEvents: true,
      performanceReportEventType,
    };

    const response = yield call(analyticsService.fetchPerformanceReportingCSV, {
      body: queryParams,
      type,
    });
    yield put(toggleIsLoadingCSVData(false));

    yield put(createPerformanceReportingCSVAsync.success({ requestId: response.requestId }));
  } catch (err) {
    yield put(createPerformanceReportingCSVAsync.failure(err));
    yield put(toggleIsLoadingCSVData(false));
  }
}

function* checkForCSVCompletionSaga() {
  try {
    yield put(toggleIsLoadingCSVData(true));

    const requestId = yield select(csvRequestIdSelector);
    const response = yield call(analyticsService.fetchCSVCompletionStatus, requestId);

    yield put(toggleIsLoadingCSVData(false));

    yield put(checkForCSVCompletionAsync.success(response));
  } catch (err) {
    yield put(toggleIsLoadingCSVData(false));

    yield put(checkForCSVCompletionAsync.failure(err));
  }
}

export function* analyticsActionWatcher() {
  yield takeLatest(demographicsFilterRequest, processDemographicsFilterRequest);
  yield takeLatest(overviewFilterRequest, processOverviewFilterRequest);
  yield takeLatest(fetchFilteredDemographicsAsync.request.type, fetchFilteredDemographics);
  yield takeLatest(fetchFilteredResponsesAsync.request.type, fetchFilteredResponses);
  yield takeLatest(
    fetchFilteredAcceptanceOverTimeAsync.request.type,
    fetchFilteredAcceptanceOverTime
  );
  yield takeLatest(
    fetchFilteredShortAnswerResponsesAsync.request.type,
    fetchFilteredShortAnswersResponses
  );
  yield takeLatest(fetchFilteredTotalsAsync.request.type, fetchFilteredTotals);
  yield takeLatest(fetchAnalyticsCampaignAsync.request.type, fetchAnalyticsCampaign);
  yield takeLatest(fetchAnalyticsSponsorshipAsync.request.type, fetchAnalyticsSponsorship);
  yield takeLatest(fetchAnalyticsSponsorshipSetAsync.request.type, fetchAnalyticsSponsorshipSet);
  yield takeLatest(fetchHeatMapAsync.request.type, fetchHeatMap);
  yield takeLatest(getCSVDataAsync.request.type, getCSVDataSaga);
  yield takeLatest(checkForCSVCompletionAsync.request.type, checkForCSVCompletionSaga);
  yield takeLatest(
    createPerformanceReportingCSVAsync.request.type,
    createPerformanceReportingCSVSaga
  );
}
