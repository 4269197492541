import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBrandBillDetails } from '../../store/brand';

const useReportDataByUrl = (query, brandBills) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const year = query.year;
    const month = query.month;
    const billedMonth = `${month}, ${year}`;
    const brandBDetails = brandBills.data.find(
      (item) => item.billedMonth === billedMonth
    )?.brandBillDetails;
    dispatch(setBrandBillDetails(brandBDetails));
  }, [dispatch, query.month, query.year, brandBills.data]);
};

export default useReportDataByUrl;
