import { createReducer, initialAsyncState } from 'eight.js.store-common';
import { sponsorshipConstants } from '../../../constants';

import {
  activateSponsorshipFromCreationAsync,
  checkUploadStatusAsync,
  clearSponsorship,
  deactivateSponsorshipFromCreationAsync,
  fetchUploadStatusAsync,
  resetProgress,
  setAdData,
  setDataArray,
  setUploadingVideo,
  setLocalQuestionsTags,
  setInternalFetching,
  setSponsorshipIsReadOnly,
  toggleActivateSponsorship,
  updateSponsorshipAsync,
  uploadVideoAsync,
  setUploadPercentage,
  uploadCoverAsync,
  watchUploadStop,
  setActiveAd,
  setSponsorshipName,
  setSponsorshipData,
  setUpdatingSponsorship,
} from './actions';

const initialState = {
  ...initialAsyncState,
  sponsorshipCreate: sponsorshipConstants.initialState,
  sponsorshipEdit: {
    id: '',
  },
  error: '',
  fetching: false,
  updatingSponsorship: false,
};

export const sponsorshipReducer = createReducer('@@sponsorship', initialState, {
  [setActiveAd]: ({ state, action }) => {
    // Remove dirtyFields and isDirty fields from object
    // Remove local tags from object
    const { dirtyFields, isDirty, ...sponsorship } = state.sponsorshipCreate;
    state.sponsorshipCreate = {
      ...sponsorship,
      ...action.payload,
      localTags: [],
    };
  },
  [setUpdatingSponsorship]: ({ state, action }) => {
    state.updatingSponsorship = action.payload;
  },

  [activateSponsorshipFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [clearSponsorship]: ({ state }) => {
    state.sponsorshipCreate = initialState.sponsorshipCreate;
    state.sponsorshipEdit = initialState.sponsorshipEdit;
  },

  [checkUploadStatusAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [deactivateSponsorshipFromCreationAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [fetchUploadStatusAsync.success]: ({ state, action }) => {
    const { status } = action.payload;
    state.sponsorshipCreate.videoUploadStatus = status;
  },

  [fetchUploadStatusAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [resetProgress]: ({ state, action }) => {
    state.sponsorshipCreate.progress = action.payload;
  },

  [setInternalFetching]: ({ state, action }) => {
    state.sponsorshipCreate.internalFetching = action.payload;
  },

  [setAdData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipCreate[name] = value;
  },

  [setDataArray]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipCreate = {
      ...state.sponsorshipCreate,
      [name]: value,
    };
  },

  [setLocalQuestionsTags]: ({ state, action }) => {
    state.sponsorshipCreate.localTags = action.payload;
  },

  [setSponsorshipIsReadOnly]: ({ state, action }) => {
    state.sponsorshipCreate.isReadOnly = action.payload;
  },

  [setUploadingVideo]: ({ state, action }) => {
    state.sponsorshipCreate.uploadingVideo = action.payload;
  },

  [toggleActivateSponsorship]: ({ state, action }) => {
    state.sponsorshipCreate.isActive = action.payload;
  },

  [uploadCoverAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [setUploadPercentage]: ({ state, action }) => {
    state.sponsorshipCreate.progress = action.payload;
  },

  [updateSponsorshipAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [uploadVideoAsync.failure]: ({ state, action }) => {
    state.error = action.payload;
  },

  [watchUploadStop]: ({ state, action }) => {
    const { status } = action.payload;
    state.sponsorshipCreate.videoUploadStatus = status;
  },

  [setSponsorshipName]: ({ state, action }) => {
    state.sponsorshipCreate.name = action.payload;
  },

  [setSponsorshipData]: ({ state, action }) => {
    const { name, value } = action.payload;
    state.sponsorshipCreate[name] = value;
  },
});
