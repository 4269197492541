import React from 'react';
import ContentLoader from 'react-content-loader';

const TablePlaceholder = ({ width = 0, height, ...props }) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#eaeaea"
    {...props}
  >
    <rect x="0" y="30" rx="4" ry="4" width="38" height="20" />
    <rect x="64" y="30" rx="4" ry="4" width="156" height="20" />
    <rect x="245" y="30" rx="4" ry="4" width="156" height="20" />
    <rect x="430" y="30" rx="4" ry="4" width="156" height="20" />
    <rect x="615" y="30" rx="4" ry="4" width="156" height="20" />
    <rect x="800" y="30" rx="4" ry="4" width="156" height="20" />
    <rect x="980" y="30" rx="4" ry="4" width="156" height="20" />
    <rect x="1160" y="30" rx="4" ry="4" width="156" height="20" />
    <rect x="1340" y="30" rx="4" ry="4" width="156" height="20" />
  </ContentLoader>
);

export default TablePlaceholder;
