import { t } from '@transifex/native';

export const passwordRequirements = {
  minLength: {
    label: t('Min 6 characters long'),
    fulfilled: false,
  },
  lowercase: {
    label: t('1 Uppercase letter'),
    fulfilled: false,
  },
  uppercase: { label: t('1 Lowercase letter'), fulfilled: false },
  digit: { label: t('1 Digit'), fulfilled: false },
};
