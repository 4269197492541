import React, { useMemo } from 'react';
import TextAreaAutosize from 'react-autosize-textarea';

import { ErrorMessage } from '../../common/ErrorMessage';

import classNames from 'classnames/bind';
import s from './TextArea.module.scss';
import { useT } from '@transifex/react';

const cx = classNames.bind(s);

const TextArea = ({
  value,
  formValue,
  label,
  id,
  name,
  onChange,
  error,
  register,
  disabled,
  styling,
  defaultValue,
  rows,
  readOnly,
  watchValue,
  inputRef,
  'data-testid': testId,
}) => {
  const t = useT();
  const textAreaClassNames = useMemo(
    () =>
      cx({
        [s['textarea-field__textarea']]: true,
        [s['textarea-field__textarea--noborder']]: styling === 'no-border',
        [s['textarea-field__textarea--multirows']]: styling === 'multirows',
        [s['textarea-field__textarea--error']]: error,
      }),
    [error, styling]
  );

  const labelClassNames = useMemo(
    () =>
      cx({
        [s['textarea-field__label--active']]: value || formValue || watchValue || defaultValue,
      }),
    [value, formValue, watchValue, defaultValue]
  );

  const registerOptions = useMemo(
    () =>
      register ? register(name, { onChange: (e) => onChange && onChange(e.target.value) }) : {},
    [register, name, onChange]
  );

  return (
    <div className={s['textarea-field']}>
      <TextAreaAutosize
        ref={inputRef}
        className={textAreaClassNames}
        name={name}
        id={id}
        onChange={onChange}
        value={formValue}
        disabled={disabled}
        defaultValue={defaultValue}
        data-testid={testId}
        rows={rows}
        readOnly={readOnly}
        {...registerOptions}
      />
      {error && <ErrorMessage error={t(error.message)} />}
      <label htmlFor={id} className={labelClassNames}>
        {label}
      </label>
    </div>
  );
};

export default TextArea;
