import { getYear, getMonth } from 'date-fns';
import { t } from '@transifex/native';

import config from '../../config';
import { SvgIcon } from '../../components/common/SvgIcon';

export const setStripePublicKey = (countryCode) => {
  if (countryCode === 'US') return config.stripePublishableKeyUSA;
  else if (countryCode === 'AU' || countryCode === 'NZ') return config.stripePublishableKeyAU;
  else return config.stripePublishableKeyGB;
};

export const getBalanceTitle = (isInvoicePartner) => {
  return isInvoicePartner ? t('Current spend') : t('Available Balance');
};

export const getBalanceSum = (isInvoicePartner, balance) => {
  return isInvoicePartner
    ? Math.abs(parseFloat(balance).toFixed(2))
    : parseFloat(balance).toFixed(2);
};

export const hasExpiredCard = (defaultPaymentMethod) => {
  const { cardExpMonth, cardExpYear } = defaultPaymentMethod;

  if (!cardExpMonth || !cardExpYear) {
    return false;
  }

  const currentYear = getYear(Date.now());
  const currentMonth = getMonth(Date.now()) + 1;

  if (currentYear > cardExpYear || (currentYear === cardExpYear && currentMonth > cardExpMonth)) {
    return true;
  }

  return false;
};

export const setPaymentName = (paymentMethod) => {
  if (!paymentMethod) {
    return '';
  }

  if (paymentMethod.includes('BASC')) {
    return `BANK - ${paymentMethod.split('-')[1]}`;
  }

  return paymentMethod;
};

export const setDocumentTitle = (isReceipt, isInvoicePartner) => {
  if (isReceipt && !isInvoicePartner) {
    return 'Receipt';
  }

  return 'Invoice';
};

export const setToWhoDocument = (isReceipt, isInvoicePartner) => {
  if (isReceipt && !isInvoicePartner) {
    return 'To: ';
  }

  if (!isReceipt && !isInvoicePartner) {
    return 'For: ';
  }

  return 'Bill to: ';
};

export const getVerificationText = (accountStatus) => {
  switch (accountStatus) {
    case 'verified':
      return t('Verified');
    case 'unverified':
      return t('Unverified');
    case 'rejected':
      return t('Unverified');
    case 'pending':
      return t('Verification Pending');
    default:
      return '';
  }
};

export const getVerificationIcon = (accountStatus) => {
  switch (accountStatus) {
    case 'unverified':
      return <SvgIcon name={`account-unverified`} />;
    case 'rejected':
      return <SvgIcon name={`account-unverified`} />;
    default:
      return <SvgIcon name={`account-${accountStatus}`} />;
  }
};
