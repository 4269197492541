import React, { useMemo } from 'react';

import { generatePath } from 'react-router';

import { DisabledNavLink } from '../../common/DisabledNavLink';

import { campaignMenu } from '../../../constants';

import s from './Summary.module.scss';
import { SvgIcon } from '../../common/SvgIcon';
import { useT } from '@transifex/react';

const SummaryMenu = ({ campaignId, summaryAdSets, unpublishedChanges, isEdit }) => {
  const summaryNav = campaignMenu.menuItems.find((menuItem) => menuItem.name === 'Summary');
  const t = useT();
  //disable Summary for campaigns that dont have a single created Ad, yet
  const isDisabled = useMemo(() => {
    const atLeastOneAd = summaryAdSets?.find((adSet) => adSet.ads?.find((ad) => ad.isCreated));
    return !atLeastOneAd;
  }, [summaryAdSets]);

  return (
    <>
      <hr className={s['horizontal']} />
      <div className={s['summary-menu']}>
        <div className={s['summary-menu__item']}>
          <DisabledNavLink
            to={generatePath(summaryNav.editPath, {
              cid: campaignId,
            })}
            isDisabled={isDisabled}
            id="summary-link"
          >
            {unpublishedChanges?.length && isEdit ? <SvgIcon name="warning" /> : ''}
            <span style={{ marginLeft: unpublishedChanges?.length && isEdit ? '3px' : '0px' }}>
              {t(summaryNav.name)}
            </span>
          </DisabledNavLink>
        </div>
      </div>
    </>
  );
};

export default SummaryMenu;
