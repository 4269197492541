import React, { useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useT, T } from '@transifex/react';

import { useScroll } from '../../../../hooks/useScroll';

import { SectionTitle } from '../../../common/Titles/SectionTitle';
import { SectionSubtitle } from '../../../common/Titles/SectionSubtitle';
import { UploadZone } from '../../../common/UploadZone';
import { FileSpecifications } from '../../../common/FileSpecifications';

import { FormGroup } from '../../../FormElements/FormGroup';
// import { TextArea } from '../../../FormElements/TextArea';

import { sponsorshipConstants } from '../../../../constants';

// import { base64StringToFile, extractImageFileExtension } from '../../../../utils/media';

import s from './AdNameAndCreative.module.scss';

import { constructTestId } from '../../../../utils/test-ids/';
import { CREATE_CAMPAIGN_PAGE } from '../../../../constants/test-ids/create-campaing-sections/create-campaign-sections';

import { useDispatch } from 'react-redux';
import { SuggestionsTextInput } from '../../../FormElements/SuggestionsTextInput';
import { setSponsorshipName } from '../../../../store/campaign/sponsorship';
import { Switch } from '../../../FormElements/Switch';
import { SvgIcon } from '../../../common/SvgIcon';

const SECTION = CREATE_CAMPAIGN_PAGE.AD_NAME_AND_CREATIVE;

const AdNameAndCreative = ({
  brand,
  modals,
  sponsorshipCreate,
  toggleModalByName,
  sponsorshipSetCreate,
  previousSponsorshipNames,
  setAdData,
  setValue,
  errors,
  control,
  register,
  trigger,
  handleAdActivation,
  adNameRef,
  creativeRef,
  adIsLocked,
  dirtyFieldsList,
  handleVideoDurationAndPricing,
  fetchMoreSponsorshipNames,
  moreSponsorshipNamesToFetch,
  setNamesKeyword,
}) => {
  const t = useT();
  const dispatch = useDispatch();

  const watchAdName = useWatch({ control, name: 'name' });
  const watchVideoLength = useWatch({ control, name: 'videoLength' });
  const watchAdVideoFile = useWatch({ control, name: 'adVideoFile' });

  useScroll([adNameRef], 0.45);
  useScroll([creativeRef], 0.7);

  // AD VIDEO COVER FEATURE, MAYBE WILL BE NEEDED IN FUTURE

  // const cropperRef = useRef(null);

  // const handleCropImage = useCallback(() => {
  //   const cropper = cropperRef?.current.cropper;

  //   const cropperData = cropper.getCroppedCanvas().toDataURL();
  //   const fileExtension = extractImageFileExtension(cropperData);
  //   const fileName = `adCover${sponsorshipCreate.name}-cropped.${fileExtension}`;
  //   const croppedFile = base64StringToFile(cropperData, fileName);
  //   const croppedUrl = URL.createObjectURL(croppedFile);

  //   setCurrentAdVideoUri(croppedUrl);

  //   dispatch(toggleModalByName({ name: 'crop', value: false }));
  // }, [cropperRef, sponsorshipCreate.name, dispatch, toggleModalByName]);

  // const handleCloseCropModal = useCallback(() => {
  //   dispatch(toggleModalByName({ name: 'crop', value: false }));
  // }, [dispatch, toggleModalByName]);

  // const handleChangeAdCover = useCallback(
  //   (acceptedFiles) => {
  //     const adCover = URL.createObjectURL(acceptedFiles[0]);
  //     const adCoverFile = acceptedFiles[0];

  //     setProccessingAdVideo(adCover);

  //     dispatch(toggleModalByName({ name: 'crop', value: true }));
  //   },
  //   [dispatch]
  // );

  // const isShowAdCover = useMemo(() => {
  //   return brand.role === 'WeAre8' && sponsorshipSetCreate.isUnite;
  // }, [sponsorshipSetCreate.isUnite, brand.role]);

  const handleChangeVideoAd = useCallback(
    (acceptedFiles) => {
      dispatch(setAdData({ name: 'videoUploadStatus', value: '' }));
      dispatch(setAdData({ name: 'videoKey', value: '' }));
      dispatch(setAdData({ name: 'videoWidth', value: '' }));
      dispatch(setAdData({ name: 'videoHeight', value: '' }));

      const blob = acceptedFiles[0].slice(0, acceptedFiles[0].size, acceptedFiles[0].type);
      const correctFileName = acceptedFiles[0].name
        .replace(/[&\\/\\#,+()$~%'":*?<>{}]/g, '')
        .toLowerCase();
      const newFile = new File([blob], correctFileName, { type: acceptedFiles[0].type });

      const adVideo = URL.createObjectURL(newFile);
      const adVideoFile = newFile;

      setValue('adVideo', adVideo);
      setValue('adVideoFile', adVideoFile);
      setValue('isVideoUploaded', false);

      handleVideoDurationAndPricing(adVideoFile, adVideo);
    },
    [dispatch, setAdData, setValue, handleVideoDurationAndPricing]
  );

  return (
    <div className={s['sponsorship']}>
      {sponsorshipCreate.isDirty && (
        <div className={s['sponsorship__unsaved']}>
          <div className={s['sponsorship__unsaved-message']}>
            <SvgIcon name="warning" />
            <span>{t('You have unsaved changes')}</span>
          </div>
          <ul>{dirtyFieldsList}</ul>
        </div>
      )}
      <SectionTitle>
        <h3>{t('Ad')}</h3>
        <Switch
          disabled={!sponsorshipCreate.isCreated}
          menuSwitch
          onChange={handleAdActivation}
          name="adStatus"
          checked={sponsorshipCreate.isActive}
          id={sponsorshipCreate.id}
        />
      </SectionTitle>
      <SectionSubtitle>
        <T _str="How are you going to inspire people with your brand today?" />
        <br />
        <T _str=" Upload your ad, and apply tracking?" />
      </SectionSubtitle>

      <div className={s['sponsorship__inner']}>
        <div id="ad-name" ref={adNameRef} className={s['sponsorship__inner-adname']}>
          <FormGroup title={t('Ad Name')} subtitle={t('Enter the name of your ad')} required>
            <SuggestionsTextInput
              data-testid={constructTestId(SECTION, 'ad-name-input')}
              type="text"
              id="name"
              name="name"
              label={t('AD NAME')}
              onChange={(name, value) => {
                setValue(name, value);
                setNamesKeyword(value);
              }}
              register={register}
              trigger={trigger}
              watchValue={watchAdName}
              error={errors.name}
              disabled={adIsLocked}
              data={previousSponsorshipNames}
              hasMoreData={moreSponsorshipNamesToFetch}
              fetchMoreData={fetchMoreSponsorshipNames}
              reduxAction={(value) => dispatch(setSponsorshipName(value))}
            />
          </FormGroup>
        </div>
        {/*FIXME://Uncomment in the future releases as well as in validation*/}
        {/*<div className={s['sponsorship__inner-sms']}>*/}
        {/*  <FormGroup*/}
        {/*    title='Custom SMS Message'*/}
        {/*    subtitle={*/}
        {/*      `Enter the message you will send to your opted-in consumers.*/}
        {/*       If you do not provide a message here, we provide the default message:*/}
        {/*        '(brand name) values you'`*/}
        {/*    }*/}
        {/*    required*/}
        {/*  >*/}
        {/*    <div className={s['sponsorship__inner-sms__wrap']}>*/}
        {/*      <div className={s['sponsorship__inner-sms__wrap-textarea']}>*/}
        {/*        <div className={s['sponsorship__inner-sms__wrap-textarea-counter']}>*/}
        {/*          {sponsorshipCreate.smsMessage.length} of 130*/}
        {/*        </div>*/}
        {/*        <TextArea*/}
        {/*          id='smsMessage'*/}
        {/*          name='smsMessage'*/}
        {/*          label='ENTER YOUR SMS'*/}
        {/*          register={register}*/}
        {/*          error={errors.smsMessage}*/}
        {/*          formValue={sponsorshipCreate.smsMessage}*/}
        {/*          disabled={sponsorshipCreate.moderationStatus === 'Approved' && brand.role !== 'WeAre8'}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      <div className={s['sponsorship__inner-sms__wrap-img']}>*/}
        {/*        <img*/}
        {/*          className={s['sms-image']}*/}
        {/*          src={`${process.env.PUBLIC_URL}/images/sms-sample.png`}*/}
        {/*          alt='sample sms'*/}
        {/*        />*/}
        {/*        <div className={s['sample-text']}>*/}
        {/*          Example SMS Message*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </FormGroup>*/}
        {/*</div>*/}
        {/* 
          {isShowAdCover && (
            <div className={s['sponsorship__inner-adcover']}>
              <FormGroup
                title={t('Upload Video Ad Cover')}
                subtitle={t(`This is the static image consumers will see after they
                 receive your SMS & opt in to view your video ad. Instagram and Facebook
                 images work well! If you do not add an image here, we will automatically
                 use your logo`)}
                required
              >
                <FileSpecifications fileSpecs={sponsorshipConstants.adCoverFileSpecs} />
                <div className={s['sponsorship__inner-adcover__upload']}>
                  <div className={s['sponsorship__inner-adcover__upload-zone']}>
                    <UploadZone
                      data-testid={constructTestId(SECTION, 'ad-cover-upload')}
                      styleType="square"
                      name="adCover"
                      type="image"
                      control={control}
                      error={errors.adCover}
                      handleChange={handleChangeAdCover}
                      contentType="image/jpeg, image/png"
                      fileUrl={sponsorshipCreate.adCover}
                      disabled={
                        sponsorshipCreate.moderationStatus === 'Approved' && brand.role !== 'WeAre8'
                      }
                    />
                  </div>
                  FIXME:// Uncomment in the future releases*/}
        {/*<div className={s['sponsorship__inner-adcover__upload-img']}>*/}
        {/*  <img*/}
        {/*    className={s['upload-image']}*/}
        {/*    src={`${process.env.PUBLIC_URL}/images/video-cover.png`}*/}
        {/*    alt='upload-sample'*/}
        {/*  />*/}
        {/*  <div className={s['sample-text']}>*/}
        {/*    Example Video Ad Cover*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* </div> */}
        {/* </FormGroup> */}
        {/* </div> */}
        {/* )}  */}

        <div className={s['sponsorship__inner-video']} id="creative" ref={creativeRef}>
          <FormGroup title={t('Upload Video Ad')} required>
            <div className={s['sponsorship__inner-video__subtitle']}>
              <T
                _str="This is the brand video that will communicate directly with real people… {recommendedLength} {canvaInfo}"
                recommendedLength={
                  <span className={s['strong']}>
                    {t('We run videos up to 2 minutes in length')}
                  </span>
                }
                canvaInfo={
                  <span>
                    If you need to create a video file first, please use{' '}
                    <a
                      href={sponsorshipConstants.canvaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      canva
                    </a>{' '}
                    link here.
                  </span>
                }
              />
            </div>

            <div className={s['video-and-specs']}>
              <div className={s['video-wrapper']}>
                <input type="hidden" name="videoLength" value={watchVideoLength} {...register} />

                <Controller
                  control={control}
                  name="adVideo"
                  render={({ field: { value, ref } }) => {
                    return (
                      <UploadZone
                        inputRef={ref}
                        data-testid={constructTestId(SECTION, 'video-ad-upload')}
                        styleType="portrait"
                        name="adVideo"
                        control={control}
                        type="video"
                        error={errors.adVideo}
                        handleChange={handleChangeVideoAd}
                        contentType="video/mp4, video/quicktime"
                        fileUrl={value}
                        videoWidth={sponsorshipCreate?.videoWidth}
                        videoHeight={sponsorshipCreate?.videoHeight}
                        hasFile={watchAdVideoFile?.name}
                        // disabled={adIsLocked && brand.role !== 'WeAre8'}
                        disabled={adIsLocked}
                      />
                    );
                  }}
                />
              </div>
              <FileSpecifications fileSpecs={sponsorshipConstants.videoAdFileSpecs} />
            </div>
          </FormGroup>
        </div>
      </div>
      {/* {modals.crop.isOpen && (
        <ModalCrop
          imageUri={processingAdVideo}
          cropperRef={cropperRef}
          actionCallback={handleCropImage}
          closeCallback={handleCloseCropModal}
        />
      )} */}
    </div>
  );
};

export default AdNameAndCreative;
