import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useT } from '@transifex/react';
import LogoAndTitle from '../../../LogoAndTitle/LogoAndTitle';
import s from './ResetPasswordEmail.module.scss';
import { TextInput } from '../../../FormElements/TextInput';
import { Button } from '../../../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { clearData, sendResetPasswordEmailAsync } from '../../../../store/brand';
import { eightAuth } from '../../../../store/eightauth';
import { ToastContainer } from 'react-toastify';
import { EMAIL_REGEXP } from '../../../../utils/validate';
import { isBrandErrorSelector } from '../../../../store/brand/selectors';

const ResetPasswordEmail = () => {
  const [isSentDisabled, setIsSentDisabled] = useState(false);
  const [email, setEmail] = useState(false);

  const isEmailSentError = useSelector(isBrandErrorSelector);
  useEffect(() => {
    if (isEmailSentError) {
      setIsSentDisabled(false);
    }
  }, [isEmailSentError]);

  const t = useT();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearData());
    dispatch(eightAuth.fetchMachineTokenAsync.request());
  }, [dispatch]);

  const sendPasswordResetEmail = useCallback(() => {
    dispatch(sendResetPasswordEmailAsync.request(email));
    setIsSentDisabled(true);
  }, [dispatch, email]);

  const handleEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const validEmail = useMemo(() => EMAIL_REGEXP.test(email), [email]);

  return (
    <>
      <div className={s['reset-password-email__container']}>
        <div className={s['reset-password-email__inner']}>
          <LogoAndTitle />
          <>
            <div className={s['reset-password-email__inner-title']}>{t('What’s your email?')}</div>
            <div className={s['reset-password-email__inner-subtitle']}>
              {t('To reset password, please provide your email below.')}
            </div>
            <div className={s['reset-password-email__inner-input']}>
              <TextInput
                label={t('EMAIL')}
                type="email"
                onChange={handleEmailChange}
                watchValue={email}
              ></TextInput>
            </div>
            <div className={s['reset-password-email__inner-button']}>
              <Button
                customText={t('Send reset instructions')}
                fullwidth
                type="button"
                styling="primary"
                callback={() => sendPasswordResetEmail()}
                disabled={isSentDisabled || !validEmail}
              ></Button>
            </div>
          </>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
      />
    </>
  );
};

export default ResetPasswordEmail;
