import React, { useMemo } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { COLORS } from '../colors';

const Bar = ({ question, series }) => {
  const transformedSeries = useMemo(() => {
    if (question) {
      const mapper = {
        1: '0x1F620',
        2: '0x1F641',
        3: '0x1F610',
        4: '0x1F642',
        5: '0x1F601',
      };

      return {
        ...series,
        data: series?.data
          .map((serie) => ({
            ...serie,
            name: +serie.name,
            emoji: serie?.name && mapper[serie?.name],
          }))
          .sort((a, b) => a.name - b.name),
      };
    } else {
      return series;
    }
  }, [question, series]);

  const options = {
    title: false,
    xAxis: {
      categories: question
        ? transformedSeries?.data?.map(({ emoji }) => emoji)
        : transformedSeries?.data?.map(({ x }) => x),
      lineWidth: 0,
      labels: {
        useHTML: !!question,
        autoRotation: false,
        formatter:
          question &&
          function () {
            return `
                  <span class="emoji" role="img" aria-label="jsx-a11y/accessible-emoji">
                    ${String.fromCodePoint(this.value)}
                  </span>
                `;
          },
      },
    },
    yAxis: {
      min: 0,
      title: false,
      gridLineDashStyle: 'Dash',
      labels: {
        style: {
          color: 'rgb(180, 180, 180)',
          fontWeight: 500,
        },
      },
    },
    series: [
      {
        data: transformedSeries?.data?.map(({ y, metadata }) => ({
          y,
          metadata: {
            ...metadata,
            percentage: +metadata.percentage,
          },
        })),
        borderRadius: 5,
      },
    ],
    chart: {
      type: 'column',
    },
    plotOptions: {
      column: {
        pointWidth: 13,
      },
    },
    colors: [COLORS.Blue],
    legend: false,
    tooltip: {
      backgroundColor: COLORS.White,
      borderWidth: 0,
      padding: 10,
      useHTML: true,
      formatter: function () {
        return `
                <div class="highchart-bar__tooltip-figure">${this.point.y}<div>
                <div class="highchart-bar__tooltip-percent">${this.point.metadata.percentage.toFixed(
                  2
                )}%<div>
               `;
      },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Bar;
