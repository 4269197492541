import React from 'react';
import TableRowPlaceholder from '../../../components/Placeholders/TablePlaceholder/TableRowPlaceholder/TableRowPlaceholder';
import Placeholder from '../Placeholder.js';

function ManagePlaceholder({ containerWidth }) {
  return (
    <>
      <Placeholder gap="5px" marginTop="20px">
        {[...Array(20)].map((item, index) => (
          <TableRowPlaceholder key={index} width={containerWidth} height="60" />
        ))}
      </Placeholder>
    </>
  );
}

export default ManagePlaceholder;
