import createAuthLib from 'eight.js.auth';

import config from './../config';

const authConfig = {
  clientId: config.auth.clientId,
  clientSecret: config.auth.clientSecret,
  machineId: config.auth.machineId,
  machineSecret: config.auth.machineSecret,
  apiToAuthenticate: config.identityAPI,
  identityAPI: config.identityAPI,
  type: config.auth.type,
  fetchMachineScope: config.auth.fetchMachineScope,
  loginScope: config.auth.loginScope,
};

export const eightAuth = {
  ...createAuthLib(authConfig),
};
