import React, { useMemo } from 'react';
import { SvgIcon } from '../../../common/SvgIcon';

import classNames from 'classnames/bind';
import s from './ReportingBox.module.scss';

const cx = classNames.bind(s);

const ReportingBox = ({
  children,
  downloadable,
  csvDataType,
  handleDownloadCSV,
  disabled,
  ...props
}) => {
  const csvLinkClassNames = useMemo(
    () =>
      cx({
        [s['csv-link']]: true,
        [s['csv-link--disabled']]: disabled,
      }),
    [disabled]
  );

  return (
    <div className={s['box__container']}>
      {children}
      {downloadable && (
        <div
          className={csvLinkClassNames}
          onClick={
            handleDownloadCSV && !disabled
              ? handleDownloadCSV(csvDataType, props?.questionId)
              : undefined
          }
        >
          <div className={s['download-icon']}>
            {/* If there's a questionId that means its a 'ShortAnswer' type of question */}
            {props?.questionId && <div className={s['download-text']}>Download Responses</div>}
            <SvgIcon name="csv-download" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportingBox;
