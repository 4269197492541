import React from 'react';

import { useT } from '@transifex/react';

import { processReceivedText } from '../../../utils/receivedData';

import s from './RejectionDetails.module.scss';

const RejectionDetails = ({ reason, details }) => {
  const t = useT();

  return (
    <div className={s['rejection']}>
      {processReceivedText(reason)?.includes('Campaign budget depleted') ? (
        <div className={s['completion-title']}>{t('Your campaign has reached its budget. ')}</div>
      ) : (
        <>
          <div className={s['rejection-title']}>{t('Reason for rejection')}:</div>
          <div className={s['rejection-text']}>{processReceivedText(reason)}.</div>
          {details && (
            <>
              <div className={s['rejection-title']}>{t('Rejection Details')}:</div>
              <div className={s['rejection-text']}>{details}.</div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default RejectionDetails;
