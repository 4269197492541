import React, { useMemo, useState, useCallback, useRef } from 'react';

import { ErrorMessage } from '../../common/ErrorMessage';

import classNames from 'classnames/bind';
import s from '../TextInput/TextInput.module.scss';
import selectStyles from '../Selects/selectStyles';
import CustomAsyncSelect from '../Selects/CustomAsyncSelect/CustomAsyncSelect';
import { components } from 'react-select';
import { useT } from '@transifex/react';

const cx = classNames.bind(s);

const SuggestionsTextInput = ({
  formValue,
  value,
  label,
  id,
  name,
  onChange,
  onInputChange,
  register,
  error,
  type,
  draggable,
  icons,
  disabled,
  customInputFieldClass,
  watchValue,
  'data-testid': testId,
  data,
  hasMoreData,
  fetchMoreData,
  trigger,
  reduxAction,
  isMulti = false,
  isSelect = false,
  control,
}) => {
  // const [isShown, setIsShown] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const [hovering, setHovering] = useState(false);
  const t = useT();
  const searchListRef = useRef(null);

  const inputFieldClassNames = useMemo(
    () =>
      cx(customInputFieldClass, {
        [s['input-field']]: true,
        [s['input-field__draggable']]: draggable,
      }),
    [draggable, customInputFieldClass]
  );

  const formValues = useMemo(() => {
    if (formValue === null) {
      return true;
    } else if (formValue === 0) {
      return true;
    }
    return formValue;
  }, [formValue]);

  const inputClassNames = useMemo(
    () =>
      cx({
        [s['input-field__input']]: true,
        [s['input-field__input--error']]: error,
        [s['input-field__input--disabled']]: disabled,
        // [s['input-field__input--icon']]: withIcon,
      }),
    [error, disabled]
  );

  const labelClassNames = useMemo(
    () =>
      cx({
        [s['input-field__label--active']]: value || formValues || watchValue,
      }),
    [value, formValues, watchValue]
  );

  // const handleShow = useCallback(() => {
  //   if (type === 'password') setIsShown((prevState) => !prevState);
  //   return;
  // }, [type]);

  // const calculatedType = useMemo(() => {
  //   if (type !== 'password') return type;
  //   if (!withIcon) return type;
  //   if (isShown) return 'text';
  //   return 'password';
  // }, [type, isShown, withIcon]);

  const handleChange = useCallback(
    (value, validate) => {
      onChange(name, value);
      reduxAction && reduxAction(value);
      validate && trigger && trigger(name);
    },
    [onChange, name, trigger, reduxAction]
  );

  const handleKeyDown = useCallback(
    (e) => {
      setHasFocus(true);

      const searchList = searchListRef.current;
      const selectedItem = searchList?.querySelector('div[active]');
      const nextItem = selectedItem?.nextElementSibling;
      const previousItem = selectedItem?.previousElementSibling;
      if (e.keyCode === 40) {
        if (selectedItem && nextItem) {
          selectedItem.nextElementSibling.setAttribute('active', true);
          selectedItem.removeAttribute('active');
        } else if (!selectedItem) {
          searchList.querySelector('div').setAttribute('active', true);
        }
        selectedItem?.scrollIntoView({ block: 'center', inline: 'start' });
        return;
      }
      if (e.keyCode === 38) {
        if (selectedItem && previousItem) {
          selectedItem.previousElementSibling.setAttribute('active', true);
          selectedItem.removeAttribute('active');
          selectedItem?.scrollIntoView({ block: 'center', inline: 'start' });
        }
        return;
      }
      if (selectedItem && e.keyCode === 13) {
        handleChange(selectedItem.innerText, true);
        setHasFocus(false);
      }
    },
    [handleChange]
  );

  const handleBlur = useCallback(() => {
    if (!hovering) setHasFocus(false);
  }, [hovering]);

  const handleItemClick = useCallback(
    (value) => {
      handleChange(value, true);
      setHasFocus(false);
    },
    [handleChange]
  );

  const handleFocus = useCallback((value) => {
    setHasFocus(true);
    searchListRef?.current?.focus();
  }, []);

  // Observer
  const observer = useRef(); // ref to store observer

  const lastElementRef = useCallback(
    (element) => {
      if (observer.current) observer.current.disconnect();

      if (!hasMoreData) return;

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreData) fetchMoreData();
      });

      if (element) observer.current.observe(element);
    },
    [hasMoreData, fetchMoreData]
  );

  return (
    <div className={inputFieldClassNames}>
      <div className={s['input-field__container']}>
        {isSelect ? (
          isMulti ? (
            <CustomAsyncSelect
              name={name}
              options={data}
              styles={selectStyles(error)}
              placeholder={'None'}
              isMulti
              onInputChange={onInputChange}
              handleChange={onChange}
              control={control}
              backspaceRemovesValue={true}
              hideSelectedOptions={true}
              isDisabled={disabled}
              watchValue={watchValue}
              innerRef={lastElementRef}
              components={{ Option }}
              closeMenuOnSelect={false}
            />
          ) : (
            <CustomAsyncSelect
              name={name}
              options={data}
              styles={selectStyles(error)}
              placeholder={t('Search...')}
              onInputChange={onInputChange}
              handleChange={onChange}
              control={control}
              backspaceRemovesValue={true}
              hideSelectedOptions={true}
              isDisabled={disabled}
              watchValue={watchValue}
              innerRef={lastElementRef}
              components={{ Option }}
              closeMenuOnSelect
            />
          )
        ) : (
          <input
            data-testid={testId}
            className={inputClassNames}
            id={id}
            type="text"
            value={formValue}
            onChange={handleChange}
            name={name}
            disabled={disabled}
            onFocus={(e) => handleFocus(e.target.value)}
            onKeyDown={handleKeyDown}
            autoComplete="off"
            {...register(name, {
              onChange: (e) => {
                handleChange(e.target.value);
                reduxAction && reduxAction(e.target.value);
              },
              onBlur: handleBlur,
            })}
          />
        )}

        {hasFocus && watchValue.length > 0 && (
          <div
            className={s['input-field__container__searchable']}
            ref={searchListRef}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
          >
            {data.map((item, index) => (
              <div
                key={index}
                className={s['input-field__container__searchable--item']}
                onClick={(e) => handleItemClick(e.target.innerText)}
                ref={index === data.length - 5 ? lastElementRef : undefined}
              >
                <span>{item}</span>
              </div>
            ))}
          </div>
        )}
        <label htmlFor={id} className={labelClassNames}>
          {label}
        </label>
      </div>

      {error && <ErrorMessage error={t(error.message)} />}
    </div>
  );
};

const Option = (props) => {
  const lastElementRef = props.selectProps.innerRef;
  const lastElement = props.options.at(-5);

  return (
    <components.Option
      {...props}
      innerRef={props.data?.id === lastElement?.id ? lastElementRef : undefined}
    />
  );
};

export default SuggestionsTextInput;
