class APILockService {
  constructor() {
    this.locked = false;
    this.lockToken = null;
    this.allPromises = [];
  }

  createPromise = () => {
    let resolver;
    return [
      new Promise((resolve, reject) => {
        resolver = resolve;
      }),
      resolver,
    ];
  };

  lock = (lockToken) => {
    if (this.locked) {
      throw new Error('APIService has already been locked.');
    }
    this.lockToken = lockToken;
    this.locked = true;
  };

  isLocked = (config) => {
    if (config.lockToken === this.lockToken) {
      //We do not want to block the API request which placed the lock in first place
      return false;
    }
    return this.locked;
  };

  releaseLock = (lockToken, tokenType, newAccessToken) => {
    if (lockToken === this.lockToken) {
      this.locked = false;
      this.allPromises.forEach((promise) => promise.resolver({ newAccessToken, tokenType }));
    }
  };

  waitTillUnlocked = () => {
    const [promise, resolver] = this.createPromise();
    this.allPromises.push({ promise, resolver });
    return promise;
  };

  getPendingPromises = () => {
    return this.allPromises;
  };
}

const apiServiceInstance = new APILockService();

export default apiServiceInstance;
