import React from 'react';
import { SvgIcon } from '../common/SvgIcon';
import { useT } from '@transifex/react';
import s from './UnpublishedChangesToastContent.module.scss';
import { generatePath, Link } from 'react-router-dom';
import { useRouter } from '../../hooks/useRouter';
import { routes } from '../../constants';

const UnpublishedChangesToastContent = ({ customText }) => {
  const t = useT();
  const { query } = useRouter();
  return (
    <Link
      className={s.container}
      to={generatePath(routes.CAMPAIGN_SUMMARY, {
        cid: query.cid ?? '',
      })}
    >
      <SvgIcon name="warning" />
      <span>{customText ? customText : t('You have unpublished changes!')}</span>
    </Link>
  );
};

export default UnpublishedChangesToastContent;
