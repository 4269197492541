import React from 'react';

import classNames from 'classnames';
import s from './SectionSubtitle.module.scss';

const SectionSubtitle = ({ children, className }) => {
  const combinedClassName = classNames({
    [s['section__subtitle']]: true,
    [className]: !!className,
  });

  return (
    <div className={combinedClassName}>
      <h3 className={s['section__subtitle--content']}>{children}</h3>
    </div>
  );
};

export default SectionSubtitle;
