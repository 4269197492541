import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { history, store } from './store';

// import { setupi18n } from './i18n';
import { setupErrorLogging } from './utils/errorLogging';

import * as serviceWorker from './serviceWorker';

import { App } from './containers/App';

import './assets/scss/index.scss';

// setupi18n();
setupErrorLogging();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
