import React from 'react';
import { Redirect, Route } from 'react-router';

const ProtectedRoute = ({ children, isAuthenticated, redirectPath, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              search: `?returnTo=${location.pathname}`,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default ProtectedRoute;
