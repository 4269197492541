export const getFromLocalStorage = (name) => {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch (error) {
    return localStorage.getItem(name);
  }
};

export const writeToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeFromLocalStorage = (name) => localStorage.removeItem(name);
