import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import s from './MenuOptions.module.scss';
import SvgIcon from '../SvgIcon/SvgIcon';
import MenuOptionsModal from '../MenuOptionsModal/MenuOptionsModal';
import { AnimatePresence } from 'framer-motion';
import { campaignMenu } from '../../../constants';
import classNames from 'classnames';
import { useClickAway } from 'react-use';

const cx = classNames.bind(s);

function MenuOptions({
  name,
  adSetId = '',
  adId = '',
  items,
  createFunction,
  deleteFunction,
  duplicateFunction,
  campaignId,
  type,
  isDisabled,
  isOpen,
  unitIsApproved,
  campaign,
}) {
  const [optionsModalOpen, setOptionsModalOpen] = useState(false);
  const [modalYPosition, setModalYPosition] = useState('0px');
  const [node, setNode] = useState(null);

  const getOptionsClassName = useMemo(() => {
    return cx({
      [s['menu-options']]: true,
      [s['open']]: optionsModalOpen || isOpen,
      [s['disabled']]: isDisabled,
    });
  }, [optionsModalOpen, isOpen, isDisabled]);

  const optionsButtonRef = useRef(null);

  //magic numbers included
  const modalRef = useCallback((node) => {
    const screenHeight = window.innerHeight;
    const button = optionsButtonRef.current;
    const modal = node;
    const modalHeight = modal?.clientHeight;
    const buttonY = button?.getBoundingClientRect().y;
    let newModalYPosition;
    if (buttonY + 30 > screenHeight - modalHeight) newModalYPosition = buttonY - modalHeight - 10;
    else newModalYPosition = buttonY + 30;
    setModalYPosition(newModalYPosition + 'px');
    setNode(modal);
  }, []);

  const handleModalShow = useCallback(() => {
    setOptionsModalOpen((prevState) => !prevState);
  }, []);

  const handleModalClickOutside = useCallback((e) => {
    if (e.target !== optionsButtonRef.current) setOptionsModalOpen(false);
  }, []);

  useClickAway({ current: node }, handleModalClickOutside);

  const menuOptions = campaignMenu.menuItems.find((item) => item.name === name).options;

  const handleScroll = useCallback(() => {
    setOptionsModalOpen(false);
  }, []);

  useEffect(() => {
    const menu = document.querySelector('#sticky-menu');
    menu?.addEventListener('scroll', handleScroll);

    return () => {
      menu?.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <button className={getOptionsClassName} onClick={handleModalShow} ref={optionsButtonRef}>
        <SvgIcon name="defaultMenuOptions" />
      </button>
      <AnimatePresence>
        {optionsModalOpen && (
          <MenuOptionsModal
            options={menuOptions}
            modalYPosition={modalYPosition}
            handleModalShow={handleModalShow}
            modalRef={modalRef}
            adSetId={adSetId}
            adId={adId}
            items={items}
            createFunction={createFunction}
            deleteFunction={deleteFunction}
            duplicateFunction={duplicateFunction}
            campaignId={campaignId}
            type={type}
            isDisabled={isDisabled}
            unitIsApproved={unitIsApproved}
            campaign={campaign}
          />
        )}
      </AnimatePresence>
    </>
  );
}

export default MenuOptions;
