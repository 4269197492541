import React from 'react';
import ContentLoader from 'react-content-loader';

const SingleCheckboxPlaceholder = (props) => (
  <ContentLoader
    speed={2}
    width={195}
    height={35}
    viewBox="0 0 195 35"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="35" height="35" />
    <rect x="46" y="13" rx="0" ry="0" width="118" height="9" />
  </ContentLoader>
);

export default SingleCheckboxPlaceholder;
