import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import s from './Checkbox.module.scss';
import { SvgIcon } from '../../common/SvgIcon';

const cx = classNames.bind(s);

const Checkbox = ({
  'data-testid': testId,
  onChange,
  name,
  shape,
  label,
  checked,
  disabled,
  value,
  register,
  inputRef,
}) => {
  const labelClasses = useMemo(
    () =>
      cx({
        [s['checkbox-label']]: true,
        [s['checkbox-label--disabled']]: disabled,
        [s['checkbox-label__rect']]: shape === 'rect',
        [s['checkbox-label__circ']]: shape === 'circ',
      }),
    [shape, disabled]
  );

  return (
    <div className={s['checkbox']}>
      {register ? (
        <input
          className={s['checkbox-input']}
          onChange={onChange}
          type="checkbox"
          id={value}
          data-testid={testId}
          value={value}
          name={name}
          checked={checked}
          disabled={disabled}
          {...register(name)}
        />
      ) : (
        <input
          ref={inputRef}
          className={s['checkbox-input']}
          onChange={onChange}
          type="checkbox"
          id={value}
          data-testid={testId}
          value={value}
          name={name}
          checked={checked}
          disabled={disabled}
        />
      )}
      <label className={labelClasses} htmlFor={value}>
        {label}
      </label>
      {shape !== 'circ' && checked && (
        <div className={s['custom-check']}>
          <SvgIcon name="checkbox-fill" />
        </div>
      )}
    </div>
  );
};

export default Checkbox;
