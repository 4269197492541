import React from 'react';

import { featureFlags } from '../../../constants';

const FeatureFlag = ({ name, children }) => {
  const isDeployed = Object.entries(featureFlags).find(([key, value]) => name === key && value);

  const content = isDeployed ? children : '';

  return <>{content}</>;
};

export default FeatureFlag;
