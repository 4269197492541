import { motion } from 'framer-motion';
import React, { useMemo } from 'react';

import s from './AltPageWrapper.module.scss';

const AltPageWrapper = ({ isOpen = true, children }) => {
  const withSidebarOpen = useMemo(() => {
    return '225px';
  }, []);

  const withSidebarClosed = useMemo(() => {
    return '70px';
  }, []);
  return (
    <motion.div
      className={s['alt-wrapper']}
      initial={{ paddingLeft: isOpen ? withSidebarOpen : withSidebarClosed }}
      animate={{ paddingLeft: isOpen ? withSidebarOpen : withSidebarClosed }}
      transition={{ type: 'linear' }}
    >
      <div className={s['alt-wrapper__inner']}>{children}</div>
    </motion.div>
  );
};

export default AltPageWrapper;
