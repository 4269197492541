import auMap from '@highcharts/map-collection/countries/au/au-all.topo.json';
import gbMap from '@highcharts/map-collection/countries/gb/gb-all.topo.json';
import usMap from '@highcharts/map-collection/countries/us/us-all.topo.json';
import nzMap from '@highcharts/map-collection/countries/nz/nz-all.topo.json';
import inMap from '@highcharts/map-collection/countries/in/in-all.topo.json';
import frMap from '@highcharts/map-collection/countries/fr/fr-all.topo.json';
import ieMap from '@highcharts/map-collection/countries/ie/ie-all.topo.json';
import esMap from '@highcharts/map-collection/countries/es/es-all.topo.json';
import deMap from '@highcharts/map-collection/countries/de/de-all.topo.json';

export const mapPropertiesByCountry = (countryCode) => {
  switch (countryCode) {
    case 'AU':
      return {
        map: auMap,
        projection: 'geoConicConformal',
        projectionConfig: {
          rotate: [0, 0, 97],
          center: [148, 45],
          scale: 1150,
        },
      };
    case 'GB':
      return {
        map: gbMap,
        projection: 'geoAlbers',
        projectionConfig: {
          center: [1, 55.4],
          rotate: [4.4, 0, 0],
          parallels: [50, 60],
          scale: 4000,
        },
      };
    case 'US':
      return {
        map: usMap,
        projection: 'geoAlbersUsa',
        projectionConfig: {
          center: [-96, 40],
          scale: 1100,
        },
      };
    case 'NZ':
      return {
        map: nzMap,
        projection: 'geoConicConformal',
        projectionConfig: {
          rotate: [0, 0, 70],
          center: [139.5, -8],
          scale: 3000,
        },
      };
    case 'IN':
      return {
        map: inMap,
        projection: 'geoMercator',
        projectionConfig: {
          center: [78.9629, 20.5937],
          scale: 1000,
        },
      };
    case 'FR':
      return {
        map: frMap,
        projection: 'geoMercator',
        projectionConfig: {
          center: [2.2137, 46.2276],
          scale: 2000,
        },
      };
    case 'IE':
      return {
        map: ieMap,
        projection: 'geoMercator',
        projectionConfig: {
          center: [-8, 53.4129],
          scale: 4000,
        },
      };
    case 'ES':
      return {
        map: esMap,
        projection: 'geoMercator',
        projectionConfig: {
          center: [-3.7038, 40.4168],
          scale: 1500,
        },
      };
    case 'DE':
      return {
        map: deMap,
        projection: 'geoMercator',
        projectionConfig: {
          center: [10.4515, 51.1657],
          scale: 2000,
        },
      };
    default:
      return {};
  }
};
