import { sponsorshipSetConstants } from '../../constants';
import { format, parseISO } from 'date-fns';

import { analyticsConstants } from '../../constants';
import { PRICE_PER_CARBON_TONE } from '../../constants/values';

export const setDemographicCsvDataType = (activity) => {
  let csvDataType;

  switch (activity) {
    case 'viewed':
      csvDataType = 'viewedInFeed';
      break;
    case 'clicked':
      csvDataType = 'clickThrough';
      break;
    default:
      csvDataType = activity ? activity : 'fullReport';
      break;
  }

  return csvDataType;
};

export const formatDateUTCWithoutDateFormat = (localTime) => {
  const date = parseISO(localTime);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
};

export const getCSVDataUrlFragment = (csvDataType) => {
  if (csvDataType === analyticsConstants.csvDataNames.ACCEPTED) {
    return analyticsConstants.urlFragments.ACCEPTED;
  }

  if (csvDataType === analyticsConstants.csvDataNames.VIEWED_IN_FEED) {
    return analyticsConstants.urlFragments.VIEWED_IN_FEED;
  }

  if (csvDataType === analyticsConstants.csvDataNames.VIDEO_VIEWED) {
    return analyticsConstants.urlFragments.VIDEO_VIEWED;
  }

  if (csvDataType === analyticsConstants.csvDataNames.CLICK_THROUGH) {
    return analyticsConstants.urlFragments.CLICK_THROUGH;
  }

  if (csvDataType === analyticsConstants.csvDataNames.COMPLETED) {
    return analyticsConstants.urlFragments.COMPLETED;
  }

  if (csvDataType && !analyticsConstants.csvDataTypes.includes(csvDataType)) {
    return `${analyticsConstants.urlFragments.ANSWERED_QIESTION}${csvDataType}`;
  }

  return '';
};

export const getUrlFragmentInfluencers = (csvDataType) => {
  if (
    csvDataType === analyticsConstants.csvDataNames.USER_ENGAGEMENT ||
    csvDataType === analyticsConstants.csvDataNames.FULL_REPORT
  ) {
    return analyticsConstants.urlFragmentsInfluencers.INFLUENCERSDATA;
  }

  return analyticsConstants.urlFragmentsInfluencers.INFLUENCERS_DATA;
};

export const setAgeOptions = (ageMin, ageMax) => {
  let modifiedOptions = [];
  for (let i = ageMin; i <= ageMax; i++) {
    const option = {
      name: i,
      value: i,
      label: i,
    };
    modifiedOptions.push(option);
  }
  return modifiedOptions;
};

// TODO Сommitted the code as it is not used anywhere.
// If not needed, need completely to remove it in future releases.
// export const setGenderSeries = influencers => {
//   let result = [];
//   let unspecified = {
//     name: 'Unspecified',
//     x: 'Unspecified',
//     y: 0,
//     metadata: {
//       percentage: 0,
//     }
//   };

//   const transformedData = influencers?.data.map(item => {
//     if (item.name === 'Male' || item.name === 'Female') {
//       result = [...result, item]
//     }

//     if (item.name === 'Prefer Not To Say' || item.name === 'Non Binary') {
//       unspecified.y += item.y;
//       unspecified.metadata.percentage += +item.metadata.percentage;
//       result = [...result, unspecified];
//     }

//   });

//   return {
//     name: influencers?.name,
//     data: [...new Set(result)],
//   }
// };

export const convertToK = (data) => {
  if (data >= 0 && data < 999) {
    return `${data}`;
  } else {
    return `${(data / 1000).toFixed(2).toString().replace('.00', '')}K`;
  }
};

export const setAcceptanceCategoriesNames = (data) => {
  const arrayOfNames = data?.map((item) => item.name);
  return [...new Set(arrayOfNames)]
    .sort((a, b) => a - b)
    .map((name) => ({
      name: name,
    }));
};

export const unifyAcceptanceData = (serie, mapper) => {
  const { data } = serie;

  let unique = [];
  let y = 0;
  if (mapper && mapper.length > 0) {
    mapper.forEach((mapItem) => {
      const foundItem = data.find((item) => mapItem.name === item.name);

      if (foundItem) {
        y = foundItem.y;
        unique.push(foundItem);
      } else {
        unique.push({
          ...mapItem,
          x: mapItem.name,
          y,
        });
      }
    });
  }

  return unique.map((item) => item.y);
};

export const modifyAcceptanceSeries = (data, mapper) => {
  const { series } = data;
  return {
    ...data,
    series: series?.map((singleSerie) => {
      return {
        name: singleSerie?.name,
        data: unifyAcceptanceData(singleSerie, mapper),
      };
    }),
  };
};

// export const processReportingAge = (ageRanges) => {
//   const length = ageRanges?.length;

//   if (!length) {
//     return {
//       ageFrom: 13,
//       ageTo: 65,
//     };
//   }

//   return {
//     ageFrom: ageRanges?.length > 0 && +ageRanges[0].ageFrom,
//     ageTo: ageRanges?.length > 0 && ageRanges[length - 1].ageTo ? +ageRanges[length - 1].ageTo : 65,
//   };
// };

export const processReportingAgeOptions = (incomingData) => {
  const { data } = incomingData;
  const arrayOfAges = data?.length > 0 && data?.map((item) => item.y)?.sort((a, b) => a - b);
  const length = arrayOfAges.length;

  return length > 0 ? setAgeOptions(arrayOfAges[0], arrayOfAges[length - 1]) : [];
};

export const processReportingOsOptions = (incomingData) => {
  const { data } = incomingData;
  let result = [];

  if (data?.length > 0) {
    data.forEach((dataItem) => {
      const targeted = sponsorshipSetConstants.osFilteredData.filter(
        (constant) => constant.name === dataItem?.name
      )[0];
      if (targeted) {
        result = [...result, targeted];
      }
    });

    if (result.length >= 2) {
      result = sponsorshipSetConstants.osFilteredData;
    }
  } else {
    result = [];
  }

  return result;
};

export const processReportingCitiesOptions = (incomingData) => {
  const { data } = incomingData;

  return data?.length > 0
    ? data?.map((dataItem) => ({
        name: dataItem.name,
        value: dataItem.name,
        label: dataItem.name,
      }))
    : [];
};

export const processReportingLocations = (locations) =>
  locations?.map((location) => ({
    label: location.display,
    value: location.value,
    name: location.display,
  }));

export const processReportingOs = (os) => {
  if (os !== 'any') {
    return sponsorshipSetConstants.osFilteredData.filter(
      (device) => device.label.toLowerCase() === os
    );
  } else {
    return sponsorshipSetConstants.osFilteredData;
  }
};

export const processReportingDate = (receivedDate) => format(new Date(receivedDate), 'MM.dd.yy');

export const getPricePerCarbonTonne = (countryCode) => PRICE_PER_CARBON_TONE[countryCode];
