import React, { useEffect, useMemo, useRef } from 'react';

import { isInteger, isNil } from 'lodash';
import { CountUp } from 'countup.js';

import s from './Countup.module.scss';
import {
  countDecimalPlaces,
  removeTrailingZeros,
} from '../../../utils/formatting/formattingHelpers';

const Countup = ({ currencySymbol, number, percentage }) => {
  const spanRef = useRef(null);
  const formattedNumber = useMemo(() => {
    let result;
    result = number.toFixed(2);
    result = removeTrailingZeros(result);
    return result;
  }, [number]);

  useEffect(() => {
    if (!isNil(formattedNumber)) {
      const countup = new CountUp(spanRef.current, Number(formattedNumber), {
        decimalPlaces: isInteger(formattedNumber) ? 0 : countDecimalPlaces(formattedNumber),
      });
      countup.start();
    }
  }, [formattedNumber]);

  return (
    <div className={s['count__container']}>
      {currencySymbol ? currencySymbol : ''}
      <span ref={spanRef}>{formattedNumber}</span>
      {percentage && <span>{` (${percentage}%)`}</span>}
    </div>
  );
};

export default Countup;
