import React from 'react';

import classNames from 'classnames';
import s from './SectionTitle.module.scss';

const SectionTitle = ({ children, className }) => {
  const combinedClassName = classNames({
    [s['section__title']]: true,
    [className]: !!className,
  });

  return (
    <div className={combinedClassName}>
      <div className={s['section__title--content']}>{children}</div>
    </div>
  );
};

export default SectionTitle;
