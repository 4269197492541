import React from 'react';

import classNames from 'classnames';
import s from './SecondaryTitle.module.scss';

const SecondaryTitle = ({ children, className }) => {
  const combinedClassName = classNames({
    [s['secondary-title']]: true,
    [className]: !!className,
  });

  return (
    <div className={combinedClassName}>
      <h1 className={s['secondary-title__content']}>{children}</h1>
    </div>
  );
};

export default SecondaryTitle;
