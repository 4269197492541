import { useCallback } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'react-use';

const useInfiniteScroll = (fetchMethod, data, checkWithEmptyKeyword = false, extraProp) => {
  const dispatch = useDispatch();
  const [limit] = useState(20);
  // eslint-disable-next-line no-unused-vars
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [extraPropValue, setExtraPropValue] = useState('');

  useDebounce(
    () => {
      if (keyword.length > 2) fetchInitials(keyword, extraPropValue);
      else if (checkWithEmptyKeyword) fetchInitials('');
    },
    500,
    [keyword]
  );

  const fetchMore = useCallback(() => {
    setOffset((prevOffset) => {
      dispatch(
        fetchMethod({
          limit,
          offset: prevOffset + limit,
          keyword,
          clear: false,
          [extraProp]: extraPropValue,
        })
      );
      return prevOffset + limit;
    });
  }, [dispatch, extraProp, extraPropValue, fetchMethod, keyword, limit]);

  const fetchInitials = useCallback(
    (keyword, id = '') => {
      setOffset(0);
      dispatch(
        fetchMethod({
          limit,
          offset: 0,
          keyword,
          clear: true,
          [extraProp]: id,
        })
      );
      setExtraPropValue(id);
    },
    [dispatch, extraProp, fetchMethod, limit]
  );

  const moreToFetch = useMemo(() => data.length === limit + offset, [data.length, limit, offset]);

  return {
    setKeyword,
    fetchMore,
    moreToFetch,
    fetchInitials,
  };
};

export default useInfiniteScroll;
