import React, { useCallback, useEffect } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { SvgIcon } from '../../common/SvgIcon';
import { Button } from '../../common/Button';
import { ErrorMessage } from '../../common/ErrorMessage';

import s from './AddEditCard.module.scss';
import { useDispatch } from 'react-redux';
import { getCardSetupIntentKeyAsync } from '../../../store/brand';
import { useT } from '@transifex/react';

const AddEditCard = ({
  customButtonText,
  setModalDisplayMode,
  setStripeFormError,
  stripeErrors,
  cardSetupIntentKey,
  getPaymentMethodsAsync,
  paymentMethods,
  toggleModalByName,
  modals,
  toggleSpinnerForThirdPartyLibs,
}) => {
  const dispatch = useDispatch();
  const t = useT();

  useEffect(() => {
    dispatch(getCardSetupIntentKeyAsync.request());
  }, [dispatch]);

  useEffect(() => {
    if (
      (stripeErrors.cardNumber || stripeErrors.cardCvc || stripeErrors.cardExpiry) &&
      stripeErrors.general?.message?.length > 0
    ) {
      dispatch(setStripeFormError({ name: 'general', value: null }));
    }
  }, [
    stripeErrors.cardNumber,
    stripeErrors.cardCvc,
    stripeErrors.cardExpiry,
    stripeErrors.general,
    setStripeFormError,
    dispatch,
  ]);

  const options = {
    style: {
      base: {
        color: '#000000',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#ff3d55',
      },
    },
  };

  const stripe = useStripe();
  const elements = useElements();

  const getPaymentMethods = useCallback(() => {
    dispatch(getPaymentMethodsAsync({ cardAdded: true }));
    dispatch(setStripeFormError({ name: 'general', value: null }));

    if (!paymentMethods.length && modals.billing.isShowModalFunds) {
      dispatch(toggleModalByName({ name: 'funds', value: true }));
      dispatch(toggleModalByName({ name: 'billing', value: false }));
    }

    dispatch(setModalDisplayMode({ name: 'billing', value: true }));
  }, [
    dispatch,
    getPaymentMethodsAsync,
    setModalDisplayMode,
    setStripeFormError,
    toggleModalByName,
    modals.billing.isShowModalFunds,
    paymentMethods,
  ]);

  const handleFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (!stripe || !elements) {
        return;
      }

      dispatch(toggleSpinnerForThirdPartyLibs(true));

      return await stripe
        .confirmCardSetup(cardSetupIntentKey, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        })
        .then((payload) => {
          if (payload.error) {
            dispatch(setStripeFormError({ name: 'general', value: payload.error }));
            dispatch(toggleSpinnerForThirdPartyLibs(false));
          } else {
            getPaymentMethods();
          }
        });
    },
    [
      dispatch,
      stripe,
      elements,
      setStripeFormError,
      getPaymentMethods,
      cardSetupIntentKey,
      toggleSpinnerForThirdPartyLibs,
    ]
  );

  const handleCardChange = useCallback(
    (e) => {
      const { elementType, error } = e;

      dispatch(setStripeFormError({ name: elementType, value: error }));
    },
    [dispatch, setStripeFormError]
  );

  return (
    <form className={s['card']} onSubmit={handleFormSubmit}>
      <div className={s['card-input__title']}>
        <span className={s['card-input__title-icon']}>
          <SvgIcon name="credit-card" />
        </span>
        <span className={'card-input__title-text'}>Card Number</span>
      </div>
      <div className={s['card-input__wrapper']}>
        <CardNumberElement
          className={
            stripeErrors?.cardNumber?.message ? 'stripe-container--error' : 'stripe-container'
          }
          options={options}
          onChange={handleCardChange}
        />
      </div>
      {stripeErrors.cardNumber && <ErrorMessage error={stripeErrors?.cardNumber?.message} />}
      <div className={s['card-input__section']}>
        <div className={s['card-input__section-expiration']}>
          <div className={s['card-input__title']}>MM/YY</div>
          <div className={s['card-input__wrapper']}>
            <CardExpiryElement
              className={
                stripeErrors?.cardExpiry?.message ? 'stripe-container--error' : 'stripe-container'
              }
              options={options}
              onChange={handleCardChange}
            />
          </div>
          {stripeErrors.cardExpiry && <ErrorMessage error={stripeErrors?.cardExpiry?.message} />}
        </div>
        <div className={s['card-input__section-cvc']}>
          <div className={s['card-input__title']}>CVC</div>
          <div className={s['card-input__wrapper']}>
            <CardCvcElement
              className={
                stripeErrors?.cardCvc?.message ? 'stripe-container--error' : 'stripe-container'
              }
              options={options}
              onChange={handleCardChange}
            />
          </div>
          {stripeErrors.cardCvc && <ErrorMessage error={stripeErrors?.cardCvc?.message} />}
        </div>
      </div>
      {stripeErrors.general && (
        <div className={s['stripe-general--error']}>{t(stripeErrors?.general?.message)}</div>
      )}
      <Button
        type="submit"
        styling="primary"
        fullwidth
        customText={customButtonText ? customButtonText : 'Confirm'}
        disabled={!stripe}
      />
    </form>
  );
};

export default AddEditCard;
