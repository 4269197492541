const invoiceConstants = Object.freeze({
  GB: {
    brandEmail: 'finance.uk@WeAre8.com',
    brandName: 'WeAre8 Media Ltd',
    taxReferenceNumber: 'VAT #: 348 9751 46',
    entrance: 'Aviation House',
    address: '125 Kingsway',
    city: 'London',
    postCode: 'WC2B 6NH',
    taxType: 'VAT',
    taxPercentage: '20%',
    sortCode: 'Sort Code - 20-00-00',
    accountNumber: 'Account number - 93919773',
    footer:
      'Company Registration No: 12470936. Registered Office: Arquen House, 4-6 Spicer Street, St. Albans, Hertfordshire, AL3 4PQ, GBR.',
  },
  AU: {
    brandEmail: 'finance.au@WeAre8.com',
    brandName: 'WEARE8 AUSTRALIA PTY LTD',
    taxReferenceNumber: 'ABN Number - 89 617 985 707',
    entrance: 'Level 11',
    address: '139 Macquarie St',
    city: 'Sydney',
    postCode: 'NSW 2000',
    taxType: 'GST',
    taxPercentage: '10%',
    sortCode: 'BSB No- 082 309',
    accountNumber: 'Account No – 80 853 1496',
    footer:
      'WEARE8 AUSTRALIA PTY LTD, ABN 89 617 985 707, Register office address- level 11 139 Macquarie St Sydney NSW 2000',
  },
  US: {
    brandEmail: 'finance.us@WeAre8.com',
    brandName: 'WeAre8, Pbc.',
    taxReferenceNumber: '',
    entrance: '224 W 35th St',
    address: 'Ste 500 PMB 195',
    city: 'New York',
    postCode: 'NY 10001',
    taxType: '',
    taxPercentage: '',
    sortCode: '',
    accountNumber: '',
    footer: 'WeAre8, Pbc. ; EIN: 45-5240322',
  },
  NZ: {
    brandEmail: 'finance.au@WeAre8.com',
    brandName: 'WEARE8 AUSTRALIA PTY LTD',
    taxReferenceNumber: 'ABN Number - 89 617 985 707',
    entrance: 'Level 11',
    address: '139 Macquarie St',
    city: 'Sydney',
    postCode: 'NSW 2000',
    taxType: '',
    taxPercentage: '',
    sortCode: 'BSB No- 082 309',
    accountNumber: 'Account No – 80 853 1496',
    footer:
      'WEARE8 AUSTRALIA PTY LTD, ABN 89 617 985 707, Register office address- level 11 139 Macquarie St Sydney NSW 2000',
  },
  IN: {
    brandEmail: 'finance.uk@WeAre8.com',
    brandName: 'WeAre8 Media Ltd',
    taxReferenceNumber: 'VAT #: 348 9751 46',
    entrance: 'Aviation House',
    address: '125 Kingsway',
    city: 'London',
    postCode: 'WC2B 6NH',
    taxType: 'VAT',
    taxPercentage: '18%',
    sortCode: 'Sort Code - 20-00-00',
    accountNumber: 'Account number - 93919773',
    footer:
      'Company Registration No: 12470936. Registered Office: Arquen House, 4-6 Spicer Street, St. Albans, Hertfordshire, AL3 4PQ, GBR.',
  },
  FR: {
    brandEmail: 'finance.uk@WeAre8.com',
    brandName: 'WeAre8 Media Ltd',
    taxReferenceNumber: 'VAT #: 348 9751 46',
    entrance: 'Aviation House',
    address: '125 Kingsway',
    city: 'London',
    postCode: 'WC2B 6NH',
    taxType: 'VAT',
    taxPercentage: '20%',
    sortCode: 'Sort Code - 20-00-00',
    accountNumber: 'Account number - 93919773',
    footer:
      'Company Registration No: 12470936. Registered Office: Arquen House, 4-6 Spicer Street, St. Albans, Hertfordshire, AL3 4PQ, GBR.',
  },
  IE: {
    brandEmail: 'finance.uk@WeAre8.com',
    brandName: 'WeAre8 Media Ltd',
    taxReferenceNumber: 'VAT #: 348 9751 46',
    entrance: 'Aviation House',
    address: '125 Kingsway',
    city: 'London',
    postCode: 'WC2B 6NH',
    taxType: 'VAT',
    taxPercentage: '20%',
    sortCode: 'Sort Code - 20-00-00',
    accountNumber: 'Account number - 93919773',
    footer:
      'Company Registration No: 12470936. Registered Office: Arquen House, 4-6 Spicer Street, St. Albans, Hertfordshire, AL3 4PQ, GBR.',
  },
  ES: {
    brandEmail: 'finance.uk@WeAre8.com',
    brandName: 'WeAre8 Media Ltd',
    taxReferenceNumber: 'VAT #: 348 9751 46',
    entrance: 'Aviation House',
    address: '125 Kingsway',
    city: 'London',
    postCode: 'WC2B 6NH',
    taxType: 'VAT',
    taxPercentage: '21%',
    sortCode: 'Sort Code - 20-00-00',
    accountNumber: 'Account number - 93919773',
    footer:
      'Company Registration No: 12470936. Registered Office: Arquen House, 4-6 Spicer Street, St. Albans, Hertfordshire, AL3 4PQ, GBR.',
  },
  DE: {
    brandEmail: 'finance.uk@WeAre8.com',
    brandName: 'WeAre8 Media Ltd',
    taxReferenceNumber: 'VAT #: 348 9751 46',
    entrance: 'Aviation House',
    address: '125 Kingsway',
    city: 'London',
    postCode: 'WC2B 6NH',
    taxType: 'VAT',
    taxPercentage: '19%',
    sortCode: 'Sort Code - 20-00-00',
    accountNumber: 'Account number - 93919773',
    footer:
      'Company Registration No: 12470936. Registered Office: Arquen House, 4-6 Spicer Street, St. Albans, Hertfordshire, AL3 4PQ, GBR.',
  },
});

export default invoiceConstants;
