import React, { useEffect, useMemo } from 'react';
import { Cropper } from 'react-cropper';

import { Button } from '../common/Button';

import classNames from 'classnames/bind';

import s from './ModalCrop.module.scss';
import { useT } from '@transifex/react';

const cx = classNames.bind(s);

const ModalCrop = ({ actionCallback, closeCallback, imageUri, cropperRef }) => {
  const t = useT();

  const modalClassNames = useMemo(
    () =>
      cx({
        [s['modal']]: true,
        [s['modal--active']]: true,
      }),
    []
  );

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className={s['backdrop']}>
      <div className={modalClassNames}>
        <div className={s['inner']}>
          <div onClick={() => closeCallback()} className={s['close-btn']}>
            <div className={s['bar']} />
            <div className={s['bar']} />
          </div>
          <div className={s['image-container']}>
            <Cropper
              src={imageUri}
              initialAspectRatio={1 / 1}
              aspectRatio={1}
              style={{ maxHeight: 380, maxWidth: 380 }}
              guides={true}
              viewMode={1}
              minCropBoxHeight={5}
              minCropBoxWidth={5}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              ref={cropperRef}
            />
          </div>
          <div className={s['btn-wrap']}>
            <Button
              type="button"
              styling="primary"
              customText={t('Crop and save image')}
              fullwidth={true}
              callback={actionCallback}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCrop;
